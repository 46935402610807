import { Circle, Path, Rect, Svg, Text, View } from '@react-pdf/renderer';
import classNames from 'classnames';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import { StateMan } from 'features/dialogue/dialogueSlice';
import { ILike, ILikeCreate } from 'features/message/likeAPI';
import { deleteLikeAsync, postLikeAsync } from 'features/message/likeSlice';
import { IMessage } from 'features/message/messageAPI';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { generateRandomArray } from 'helpers/helpers';
import { sameId } from 'helpers/objects';
import { ToolHint } from './ToolHint';

import styles from 'css/pdfStyles';
import './Like.scss';

interface ILikeProps {
  message: IMessage;
  asDot?: boolean;
  editable?: boolean;
  stateMan?: StateMan;
}

function UnconnectedLike(props: ILikeProps & UserStateProps) {
  const { userId, message, editable = true } = props;
  const dispatch = useAppDispatch();
  const likeRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const seedRef = useRef<number>(Math.random());
  const iLiked = !!findMyLike();

  function handleClick() {
    if (!message.id || !props.stateMan) return;
    const like = findMyLike();

    if (like && like.id) {
      dispatch(
        deleteLikeAsync({
          id: like.id,
          stateMan: props.stateMan,
        })
      ).then((response: any) => {
        switch (response.payload?.response.status) {
          case 204:
            break;
          case 500:
          default:
            console.log(response);
        }
      });
    } else {
      const newLike: ILikeCreate = {
        liker: userId,
        liked: message.id,
      };
      dispatch(
        postLikeAsync({
          data: newLike,
          stateMan: props.stateMan,
        })
      ).then((response: any) => {
        switch (response.payload?.response.status) {
          case 201:
            break;
          case 500:
          default:
            console.log(response);
        }
      });
    }
  }

  function liked(): boolean {
    return (message.likes?.length ?? 0) > 0;
  }

  function findMyLike(): ILike | null {
    if (!message.likes || !message.likes.length) return null;
    return message?.likes.find((like) =>
      sameId((like as ILike).liker, userId)
    ) as ILike;
  }

  function renderDots() {
    const mx = 8;
    const my = 10;
    if (props.asDot) {
      if (message.likes?.length) {
        const x = generateRandomArray(
          message.likes.length - (iLiked ? 1 : 0),
          0,
          mx,
          seedRef.current
        ).reverse();
        const y = generateRandomArray(
          message.likes.length - (iLiked ? 1 : 0),
          0,
          my,
          seedRef.current
        );
        return message.likes.map((like, i) => {
          if (i > 15) return null;
          if (!sameId((like as ILike).liker, userId))
            return (
              <div
                key={i}
                className="dot"
                style={{
                  transform: `translate(${x[i] - mx / 2}px,${y[i] - my / 2}px)`,
                }}
              />
            );
          return null;
        });
      }
    }
    return null;
  }

  return (
    <div
      className={classNames('like', {
        i_liked: iLiked,
        liked: liked(),
        dot: props.asDot,
      })}
      onClick={editable ? handleClick : undefined}
      ref={likeRef}
    >
      <div className="like_count">{message.likes?.length}</div>
      {renderDots()}
      {editable ||
      iLiked ||
      (!props.asDot && (message.likes?.length ?? 0) > 0) ? (
        <div
          className={classNames('like_icon', {
            dot: props.asDot,
          })}
        />
      ) : null}
      {editable || iLiked ? (
        <ToolHint
          hint={intl.formatMessage({
            id: !!findMyLike()
              ? props.asDot
                ? 'LISTS.UNDOT'
                : 'CHAT.UNTHUMB'
              : props.asDot
              ? 'LISTS.DOT'
              : 'CHAT.THUMB',
          })}
          offset={{ x: 20, y: 16 }}
          offsetRight={true}
          toolRef={likeRef}
        />
      ) : null}
    </div>
  );
}

const Like = connect(mapUserStateToProps)(UnconnectedLike);
export default Like;

export function LikeToPdf(props: ILikeProps) {
  const { message } = props;
  const seedRef = useRef<number>(Math.random());

  function liked(): boolean {
    return (message.likes?.length ?? 0) > 0;
  }

  function renderDots() {
    const mx = 4;
    const my = 5;
    if (props.asDot) {
      if (message.likes?.length) {
        const x = generateRandomArray(
          message.likes.length,
          0,
          mx,
          seedRef.current
        ).reverse();
        const y = generateRandomArray(
          message.likes.length,
          0,
          my,
          seedRef.current
        );
        return message.likes.map((like, i) => {
          if (i > 15) return null;
          return (
            <View
              key={i}
              style={{
                ...styles.like_dot,
                // transform: `translate(${x[i] - mx / 2}px,${y[i] - my / 2}px)`,
                transform: `translate(${x[i] - mx / 2}px,${y[i]}px)`,
              }}
            >
              <Svg id="dot" viewBox="0 0 24 24" width={12} height={12}>
                <Circle
                  cx="12"
                  cy="12"
                  r="9"
                  fill="#ffb561"
                  stroke="#ffb561"
                  stroke-miterlimit="10"
                  stroke-width="2"
                />
              </Svg>
            </View>
          );
        });
      }
    }
    return null;
  }

  return (
    <View style={{ ...styles.like, ...(props.asDot ? styles.like_dots : {}) }}>
      {liked() ? <Text>{message.likes?.length}</Text> : null}
      {renderDots()}
      {!props.asDot && liked() ? (
        <Svg id="like" viewBox="0 0 24 24" width={12} height={12}>
          <Path
            d="m2.58,11.41v8.29h3.51l.18.02c3.26.76,5.42,1.31,6.49,1.65,1.43.45,1.96.56,2.93.63.72.05,1.55-.23,1.92-.61.21-.21.36-.64.42-1.31.03-.29.21-.54.47-.65.29-.13.53-.33.73-.62.18-.27.31-.78.33-1.51,0-.27.16-.52.39-.66.67-.39,1.01-.83,1.08-1.35.08-.57-.11-1.21-.59-1.95-.24-.36-.15-.84.2-1.08.46-.33.72-.78.78-1.42.1-1.03-.55-1.79-2.03-1.94-1.29-.13-2.6-.02-3.93.33-.65.17-1.2-.49-.91-1.1.58-1.23.9-2.24.98-3.02.1-1.04-.14-1.79-.7-2.41-.42-.47-1.1-.74-1.36-.68-.34.07-.55.27-.84.95-.17.4-.25.75-.39,1.55-.13.76-.21,1.1-.36,1.55-.46,1.36-1.58,2.77-3.09,3.75-1.05.68-2.18,1.21-3.4,1.58-.08.02-.15.04-.23.04h-2.58Zm-.05,9.87c-.37.01-.71-.07-.99-.27-.36-.25-.53-.65-.53-1.1v-8.48c-.04-.45.1-.87.41-1.17.29-.29.68-.43,1.09-.42h2.53c1.04-.33,2.01-.79,2.9-1.37,1.21-.78,2.11-1.91,2.45-2.93.12-.35.18-.64.3-1.32.16-.91.26-1.34.49-1.88.48-1.14,1.1-1.69,1.96-1.88.85-.18,2.08.3,2.86,1.17.86.95,1.24,2.15,1.1,3.62-.06.63-.24,1.34-.53,2.13,1-.14,2-.17,2.98-.06,2.32.24,3.63,1.75,3.44,3.66-.08.82-.38,1.53-.89,2.08.43.82.6,1.64.49,2.43-.12.91-.63,1.68-1.48,2.27-.07.8-.25,1.44-.58,1.92-.27.4-.6.72-.99.96-.13.79-.39,1.41-.82,1.85-.71.72-1.99,1.16-3.15,1.08-1.1-.07-1.76-.21-3.3-.69-1.02-.32-3.12-.85-6.29-1.59h-3.47Zm1.97-10.23c0-.44.35-.79.79-.79s.79.35.79.79v8.89c0,.44-.35.79-.79.79s-.79-.35-.79-.79v-8.89Z"
            fill="#ff961e"
          />
          <Path
            d="m20.63,12.25c-.35.24-.44.72-.2,1.08.49.73.67,1.38.6,1.95-.07.51-.41.95-1.08,1.34-.24.14-.39.39-.4.66-.02.73-.14,1.24-.32,1.51-.2.3-.44.5-.73.62-.26.12-.44.37-.47.66-.06.67-.22,1.1-.42,1.31-.38.38-1.2.66-1.93.61-.97-.06-1.5-.17-2.93-.62-1.07-.34-3.23-.89-6.49-1.65l-.18-.02h-.01v-8.55c.96-.36,1.87-.81,2.72-1.36,1.5-.97,2.63-2.38,3.08-3.74.16-.45.23-.8.36-1.56.14-.8.22-1.14.39-1.54.28-.68.5-.88.84-.95.25-.06.93.21,1.36.68.56.62.8,1.38.7,2.42-.08.78-.4,1.79-.98,3.01-.28.61.27,1.28.91,1.1,1.33-.35,2.64-.46,3.93-.32,1.49.15,2.14.91,2.04,1.93-.07.65-.32,1.1-.79,1.43Z"
            fill="#ffca8e"
          />
          <Rect x="2.58" y="11.41" width="1.92" height="8.29" fill="#fff" />
        </Svg>
      ) : null}
    </View>
  );
}
