import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { prepareForBackend } from 'helpers/objects';

export type IList = API['List']['read'];
export type IListCreate = API['List']['create'];
type IListUpdate = API['List']['update'];

function prepareForUpdate(list: IList): IListUpdate {
  return prepareForBackend(list, ['id', 'listItems']) as IListUpdate;
}
function prepareForCreate(list: IList): IListCreate {
  return prepareForBackend(list, ['id']) as IListCreate;
}

export const deleteList = async (id: number) => {
  try {
    // const response = await api.patch('List/' + data.id, prepareForUpdate(data));
    const response = await api.delete('List/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const postList = async (data: IList) => {
  try {
    const response = await api.post('List/', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchList = async (data: IList) => {
  try {
    // const response = await api.patch('List/' + data.id, prepareForUpdate(data));
    const response = await api.patch('List/' + data.id, prepareForUpdate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
