import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';

export type ILibraryBlock = API['LibraryBlock']['read'];
export type ILibraryBlockCreate = API['LibraryBlock']['create'];

export const postLibraryBlock = async (data: ILibraryBlockCreate) => {
  try {
    return await api.post('LibraryBlock', data);
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchLibraryBlock = async (id: number, data: ILibraryBlock) => {
  try {
    return await api.patch('LibraryBlock/' + id, data);
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
