import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';

export type IChatBlock = API['ChatBlock']['read'];
export type IChatBlockCreate = API['ChatBlock']['create'];

export const postChatBlock = async (data: IChatBlockCreate) => {
  try {
    return await api.post('ChatBlock', data);
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchChatBlock = async (id: number, data: IChatBlock) => {
  try {
    return await api.patch('ChatBlock/' + id, data);
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
