import classNames from 'classnames';
import {
  Fragment,
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, useIntl } from 'react-intl';

import { AnyIdObject } from 'helpers/objects';

import './Autocomplete.scss';

/**
 * Autocomplete is a React component that will autocomplete the user's input with
 * suggestions from a list that can be provided to the component.
 * The component is highly configurable.
 * The suggestions are provided in the suggestions prop and must be an array of either
 * strings or objects of the format {id: string, text: string}
 * The list of suggestions is filtered based on the user's input.
 * When the user presses 'enter' or selects from the list of suggestions,
 * the callback function onCompleted is called and passed one or two arguments:
 * - the entered or selected string
 * - the id of that string in the suggestions list if this list contained objects
 */
type AutocompleteProps = {
  suggestionList: (string | AnyIdObject)[];
  attribute?: string;
  value?: string;
  className?: string;
  selectOnly?: boolean;
  onComplete: (selected: string, id?: number) => void;
  type?: string;
  placeholder?: string;
  clear?: boolean;
  feedback?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  min?: number;
  max?: number;
  step?: number;
  noListMsg?: string | JSX.Element;
  errorMsg?: string | JSX.Element;
};

function Autocomplete({
  suggestionList = [],
  attribute,
  value = '',
  className,
  selectOnly = false,
  onComplete,
  type = 'text',
  clear = true,
  feedback,
  required = false,
  minLength,
  maxLength,
  pattern,
  min,
  max,
  step,
  noListMsg = <FormattedMessage id="GENERAL.FORM.AUTOCOMPL_NONE" />,
  errorMsg = <FormattedMessage id="GENERAL.FORM.AUTOCOMPL_FDBCK" />,
  ...props
}: AutocompleteProps) {
  const intl = useIntl();
  const [validated, setValidated] = useState(false);
  const suggestionsObj: AnyIdObject[] | null =
    suggestionList && typeof suggestionList[0] === 'object'
      ? (suggestionList as AnyIdObject[])
      : null;
  if (suggestionsObj && !attribute)
    throw new Error('No attribute specified for autocomplete objects.');
  const suggestions = suggestionsObj
    ? suggestionsObj.map((s) => s[attribute!])
    : suggestionList;
  const [completed, setCompleted] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState<number>(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<string>(value);
  const placeholder =
    props.placeholder ||
    intl.formatMessage({ id: 'GENERAL.FORM.AUTOCOMPL_PLCH' });

  useEffect(() => {
    // if the input was completed (by pressing enter or selecting from the list)
    if (completed && onComplete) {
      // make the onComplete callback
      if (suggestionsObj && attribute) {
        // the suggestions list contained objects
        const id = suggestionsObj.find((s) => s[attribute] === userInput)?.id;
        onComplete(userInput, id);
      } else {
        // the suggestions list contained just strings
        onComplete(userInput);
      }
      // clear the input field
      if (clear) setUserInput('');
    }
    // reset to start again
    setCompleted(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  function onChange(e: SyntheticEvent) {
    setShowErrorMsg(false);
    const target: HTMLInputElement | HTMLTextAreaElement =
      type === 'text'
        ? (e.target as HTMLInputElement)
        : (e.target as HTMLTextAreaElement);
    const input = target.value;
    setUserInput(input);
    // filter by user input
    if (suggestions)
      setFilteredSuggestions(
        input === '*'
          ? suggestions
          : suggestions
              .filter(
                (suggestion) =>
                  suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
              )
              // sort the user input
              .sort((a, b) => {
                // if we have input
                if (input !== '') {
                  // we sort in lowercase
                  a = a.toLowerCase();
                  b = b.toLowerCase();
                  // get the index of the user input
                  let i = a.indexOf(input.toLowerCase());
                  let j = b.indexOf(input.toLowerCase());
                  // if one index is zero and the other not, then sort by index
                  if (i !== j && i + j === i - j) return i - j;
                  // if indexes are unequal and only one is smaller than 3,
                  // then sort by index (which means the nearest one gets priority)
                  if ((i !== j && i < 3) || j < 3) return i - j;
                }
                // by default sort alphabetically
                return a < b ? -1 : a > b ? 1 : 0;
              })
      );
    setActiveSuggestion(0);
    setShowSuggestions(true);
  }

  function onClick(e: SyntheticEvent<HTMLLIElement>) {
    // user selected from the list of suggestions
    setUserInput(e.currentTarget.innerText);
    setShowSuggestions(false);
    setCompleted(true);
  }

  function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    // handle special keys
    if (e.key === 'Enter') {
      // enter
      if (
        filteredSuggestions &&
        filteredSuggestions.length &&
        activeSuggestion >= 0
      )
        // a suggestion was available
        setUserInput(filteredSuggestions[activeSuggestion]);
      else if (selectOnly) {
        // no suggestion available, but we allow selection from the list only
        setShowErrorMsg(true);
        return;
      } else if (!e.currentTarget.checkValidity()) {
        // free input, but it did not validate
        setValidated(true);
        return;
      } else if (userInput === '') {
        setShowErrorMsg(true);
        return;
      }
      // free input that was valid
      else setValidated(false);
      // we're done
      setShowSuggestions(false);
      setCompleted(true);
    } else if (e.key === 'ArrowUp') {
      // User pressed the up arrow, decrement the index
      if (activeSuggestion === 0) return;
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.key === 'ArrowDown') {
      // User pressed the down arrow, increment the index
      if (activeSuggestion - 1 === filteredSuggestions.length) return;
      setActiveSuggestion(activeSuggestion + 1);
    } else if (e.key === 'Escape')
      // User pressed the escape key, do not select anything
      setActiveSuggestion(-1);
    // e.stopPropagation();
  }

  // create the suggestions list
  let suggestionsList = null;
  if (showSuggestions && userInput) {
    // we have user input and want to see suggestions
    if (filteredSuggestions.length) {
      suggestionsList = (
        <div className="autocomplete-suggestions as-form-control">
          {showErrorMsg ? (
            <span className="pd_error_msg">
              <em>
                {errorMsg}
                {showErrorMsg ? <br /> : ''}
              </em>
            </span>
          ) : (
            ''
          )}
          <ul>
            {filteredSuggestions.map((suggestion, index) => {
              let className;
              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'autocomplete-suggestion-active';
              }
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      // no suggestions available
      suggestionsList = (
        <div className="autocomplete-no-suggestions">
          {showErrorMsg ? (
            <span className="pd_error_msg">
              <em>{errorMsg}</em>
              <br />
            </span>
          ) : (
            ''
          )}
          <em>{noListMsg}</em>
        </div>
      );
    }
  }

  const classnames = classNames(className, {
    autocomplete: true,
  });

  return (
    <Fragment>
      <Form.Group
        controlId="autocomplete"
        className={validated ? 'validated' : undefined}
      >
        <Form.Control
          className={classnames}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          type={'text'}
          pattern={pattern}
          min={min}
          max={max}
          step={step}
          required={required}
          autoComplete="off"
        />
        {!!feedback ? (
          <Form.Control.Feedback type="invalid">
            {feedback}
          </Form.Control.Feedback>
        ) : (
          ''
        )}
        {suggestionsList}
      </Form.Group>
    </Fragment>
  );
}

export default Autocomplete;
