import classNames from 'classnames';
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { Status } from '@thuas/pd-schemas';
import { useAppDispatch } from 'app/hooks';
import Collapsable from 'components/collapsable/Collapsable';
import FileUpload from 'components/files/FileUpload';
import FormattedMessage from 'components/formatters/FormattedMessage';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { ModalDialog } from 'components/forms/ModalDialog';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import { getTextAreaHeight } from 'components/messages/TextAreaInput';
import { ToolHint, ToolHintProps } from 'components/messages/ToolHint';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { emptyDialogue } from 'features/dialogue/dialogueSlice';
import { imageFileTypes } from 'features/files/fileSlice';
import { IProject, IProjectCreate } from 'features/projects/projectsAPI';
import {
  ProjectsStateProps,
  emptyProject,
  mapProjectsStateToProps,
  postProjectPlusAsync,
} from 'features/projects/projectsSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { copyLink, generateUuidCode, getLink } from 'helpers/helpers';
import { useForms } from './Forms';

import './SettingsForm.scss';
import './WizardForm.scss';

type CreateProjectFormProps = {
  setProjectTitle: (s: string) => void;
};

function UnconnectedProjectForm(
  props: CreateProjectFormProps &
    FormProps &
    UserStateProps &
    ProjectsStateProps
) {
  const { show, setShow, onSubmit, onCancel, onClose } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const emptyData = {
    projectTitle: '',
    projectDescription: '',
    // background: null,
    registrationCode: generateUuidCode(),
    dialogueTitle: '',
    dialogueDescription: '',
  };
  const [data, setData] = useState({
    ...emptyData,
  });
  const [dialogues, setDialogues] = useState<IDialogue[]>([]);
  const [previewSrc, setPreviewSrc] = useState<string | null>(null); // state for storing previewImage
  const [file, setFile] = useState<File | undefined>(undefined); // for avatar image
  const [copied, setCopied] = useState<boolean>(false);
  const [validForm, setValidForm] = useState<boolean>(true);
  const [newDlgId, setNewDlgId] = useState<number>(0);
  // const newDlgId = useRef<number>(0);
  const [showStep, setShowStep] = useState<number>(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      setData({ ...emptyData });
      setDialogues([]);
      setFile(undefined);
      setPreviewSrc('');
      setValidForm(true);
      setShowStep(1);
      setNewDlgId(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    const ta = document.getElementById('projectDescription');
    if (ta) {
      ta.style.height =
        getTextAreaHeight(ta as HTMLTextAreaElement, 3, 8)[0] + 'px';
    }
  }, [show, data]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    const { name } = target;
    setData({
      ...data,
      [name]: target.value,
    });
  }

  async function handleSubmit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        if (newDlgId) {
          onSubmit && onSubmit();
          resolve();
          navigate(`/dialogue/${newDlgId}`);
          return;
        }
        const projectData = {
          ...emptyProject,
          title: data.projectTitle,
          description: data.projectDescription,
          status: Status.private,
        };
        const dialogueData: IDialogue = {
          ...emptyDialogue,
          project: projectData,
          title: data.dialogueTitle,
          description: data.dialogueDescription,
          status: Status.public,
          background: null,
          keywords: [],
          subscribers: [],
          moderators: [],
          userFeedbacks: [],
          phases: [],
        };
        dispatch(
          postProjectPlusAsync({
            data: projectData as IProjectCreate,
            regCode: data.registrationCode,
            dlgData: dialogueData as IDialogue,
            file: file,
            intl: intl,
          })
        ).then(async (response: { payload: any }) => {
          switch (response.payload?.response.status) {
            case 200:
            case 201:
              const id = response.payload.response.data._id;
              setNewDlgId(id);
              doFormMsg(
                {
                  message: intl.formatMessage({
                    id: 'PROJECT.CREATE.SUCCESS',
                  }),
                  success: true,
                  timeout: 5000,
                },
                setFormMsg
              );
              reject(); // we need another submit click to close the dialogue
              break;
            case 409:
              setValidForm(false);
              doFormMsg(
                {
                  message: intl.formatMessage({
                    id: 'PROJECT.CREATE.CONFLICT',
                  }),
                  success: false,
                  timeout: 5000,
                },
                setFormMsg
              );
              reject();
              break;
            case 500:
            default:
              setValidForm(false);
              doFormMsg(
                {
                  message: 'Internal error, project not created.',
                  success: false,
                },
                setFormMsg
              );
              reject();
              break;
          }
        });
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg
        );
        console.log(
          'Project update or post request failed for unclear reason.',
          err
        );
        reject();
      }
    });
  }

  function handleCancel(): boolean {
    doFormMsg(NoFormMsg, setFormMsg);
    onCancel && onCancel();
    setShow(false);
    return true;
  }

  function handleClose(): void {
    if (onClose) onClose();
  }

  function completed(): number {
    if (!data.projectTitle || data.projectTitle.length < 6) return 0;
    if (!data.projectDescription || data.projectDescription.length < 6)
      return 0;
    return 3;
  }

  let step = 1;
  if (!data) return null;
  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form project_form wizard_form"
      title={null} // no title for this dialog
      onSubmit={handleSubmit}
      submitBtnText={intl.formatMessage({
        id: newDlgId ? 'X.OK' : 'PROJECT.CREATE.CREATE',
      })}
      disableSubmit={!data.dialogueTitle.length}
      onCancel={newDlgId ? null : handleCancel}
      onClose={handleClose}
      formMsg={formMsg}
    >
      <div className="header">
        <FormattedMessage id="PROJECT.CREATE.NEW" />
      </div>
      {newDlgId ? (
        <div className="settings_row">
          <FormattedMessage id="PROJECT.CREATE.STEP3" />
          <br />
          <div className="small">
            {getLink(`signup/${data.registrationCode}`)}
          </div>
          &nbsp;
          <Icon
            symbol={IconSymbol.copy}
            hoverVariant={IconVariant.dark}
            onClick={() => {
              copyLink(`signup/${data.registrationCode}`, setCopied);
            }}
            hintProps={{
              hint: intl.formatMessage({
                id: copied
                  ? 'PROJECT.FORM.COPIEDLINK'
                  : 'PROJECT.FORM.COPYLINK',
              }),
              offset: { x: 20, y: 20 },
              offsetRight: true,
            }}
            inline
          />
        </div>
      ) : (
        <>
          <Collapsable dimension="height">
            <Collapsable.Controller
              open={!validForm || showStep === 1}
              setOpen={() => {
                if (showStep !== 1) setShowStep(1);
              }}
              noIcon={false}
              symbol={step++}
              noPlus={!validForm || showStep === 1}
            >
              <FormattedMessage id="PROJECT.CREATE.STEP1" />
              {showStep !== 1 && data.projectTitle && (
                <>
                  &nbsp;-&nbsp;
                  <strong>{data.projectTitle}</strong>
                </>
              )}
            </Collapsable.Controller>
            <Collapsable.Content
              dimension="height"
              open={!validForm || showStep === 1}
            >
              <Form.Group as={'div'} className="settings_item">
                <div className="settings_col">
                  <FormattedMessage
                    className="text"
                    id="PROJECT.CREATE.INTRO"
                  />
                  <div className="label">
                    <FormattedMessage
                      className="text"
                      id="PROJECT.CREATE.PROJECT_TITLE"
                    />
                    <span className="not_bold">
                      {' '}
                      <FormattedMessage id="PROJECT.CREATE.DESCRIBE" />
                    </span>
                  </div>
                  <Form.Control
                    className="field subtitle"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'PROJECT.FORM.TITLE_PLCH',
                    })}
                    name="projectTitle"
                    value={data.projectTitle || ''}
                    onChange={handleChangeData}
                    required
                    minLength={3}
                  />
                  <div className="subrow">
                    <Form.Control
                      // className="field text"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'PROJECT.FORM.DESCRIPTION_PLCH',
                      })}
                      as="textarea"
                      rows={3}
                      name="projectDescription"
                      value={data.projectDescription || ''}
                      onChange={handleChangeData}
                      required
                    />
                    {validForm ? (
                      <Button
                        className="right"
                        variant={
                          data.projectTitle?.length < 6 ||
                          data.projectDescription?.length < 6
                            ? 'outline-secondary'
                            : 'secondary'
                        }
                        size={'sm'}
                        onClick={() => {
                          if (
                            data.projectTitle?.length >= 6 &&
                            data.projectDescription?.length >= 6
                          )
                            setShowStep((prev) => prev + 1);
                        }}
                        disabled={
                          data.projectTitle?.length < 6 ||
                          data.projectDescription?.length < 6
                        }
                      >
                        <FormattedMessage id="X.OK" />
                      </Button>
                    ) : null}
                  </div>
                </div>
              </Form.Group>
            </Collapsable.Content>
          </Collapsable>
          <Collapsable dimension="height">
            <Collapsable.Controller
              open={!validForm || showStep === 2}
              setOpen={() => {
                if (
                  showStep !== 2 &&
                  data.projectTitle &&
                  data.projectDescription
                )
                  setShowStep(2);
              }}
              noIcon={false}
              symbol={step++}
              noPlus={!validForm || showStep === 2}
              disabled={completed() < 1}
            >
              <FormattedMessage id="PROJECT.CREATE.STEP2" />
              {showStep !== 2 && data.dialogueTitle && (
                <>
                  &nbsp;-&nbsp;
                  <strong>{data.dialogueTitle}</strong>
                </>
              )}
            </Collapsable.Controller>
            <Collapsable.Content
              dimension="height"
              open={!validForm || showStep === 2}
            >
              <div className="label">
                <FormattedMessage
                  className="text"
                  id="PROJECT.CREATE.DIALOGUE_TITLE"
                />
                <span className="not_bold">
                  {' '}
                  <FormattedMessage id="PROJECT.CREATE.DESCRIBE" />
                </span>
              </div>
              <Form.Group as={'div'} className="settings_item">
                <div className="settings_col">
                  <Form.Control
                    className="field subtitle"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'DIALOGUE.FORM.TITLE_PLCH',
                    })}
                    name="dialogueTitle"
                    value={data.dialogueTitle || ''}
                    onChange={handleChangeData}
                    required
                    minLength={3}
                  />
                  <div className="subrow">
                    <Form.Control
                      className="field text"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'DIALOGUE.FORM.DESCRIPTION_PLCH',
                      })}
                      as="textarea"
                      rows={3}
                      name="dialogueDescription"
                      value={data.dialogueDescription || ''}
                      onChange={handleChangeData}
                      required
                    />
                  </div>
                </div>
                <div className="settings_col img img_r">
                  <FileUpload
                    types={imageFileTypes}
                    currentSrc={
                      // data.background ? (data.background as IFile).uri! :
                      null
                    }
                    previewSrc={previewSrc}
                    setPreviewSrc={setPreviewSrc}
                    defaultImg={<img src="/img/backgroundImage.png" alt="" />}
                    showMsg={(m) => doFormMsg(m, setFormMsg)}
                    setFile={setFile}
                    onDelete={() => {
                      // set the id to null to indicate the image needs to be deleted
                      // data.background = null;
                    }}
                    // confirmationText={
                    //   <FormattedMessage id="PROJECT.FORM.DELETE_BACKGROUND_CNF" />
                    // }
                    withTrigger={true}
                    showTrigger={true}
                  />
                </div>
              </Form.Group>
            </Collapsable.Content>
          </Collapsable>
        </>
      )}
    </ModalDialog>
  );
}

const ProjectForm = connect(mapProjectsStateToProps)(
  connect(mapUserStateToProps)(UnconnectedProjectForm)
);
export default ProjectForm;

type CreateProjectFormLinkProps = {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  project?: IProject;
  tag?: 'span' | 'div';
  title?: string;
  hintProps?: ToolHintProps;
  children?: ReactNode;
};

export function CreateProjectFormLink(props: CreateProjectFormLinkProps) {
  const { onClick, children, title, tag, className, hintProps } = props;
  const forms = useForms();
  const TagName = tag ?? 'div';
  const triggerRef = useRef<HTMLDivElement>(null);

  function handleClick(e: React.MouseEvent) {
    forms.CreateProjectForm.setProjectTitle('');
    forms.CreateProjectForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <>
      <TagName
        className={classNames(className)}
        ref={triggerRef}
        onClick={handleClick}
        title={title}
      >
        {children}
        {hintProps ? <ToolHint {...hintProps} toolRef={triggerRef} /> : null}
      </TagName>
    </>
  );
}
