import { useCallback, useEffect, useRef, useState } from 'react';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import { useAppDispatch } from 'app/hooks';
import { registerUnreadCounter } from 'app/unreadCount';
import { Sudoku, SudokuHandle } from 'components/sudoku/Sudoku';
import { IBlock } from 'features/block/blockAPI';
import { BlockType } from 'features/block/blockSlice';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { getVisitedState } from 'features/uiState/uiStateSlice';
import { ChildBlockProps, RenderChildDropMenu } from './Block';

import { showModal } from 'components/forms/ModalDialog';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import DropMenu from 'components/navigation/DropMenu';
import { patchSudokuBlockAsync } from 'features/block/sudokuBlockSlice';
import {
  asyncApiSudokuNew,
  asyncApiSudokuRemoved,
  asyncApiSudokuUpdated,
} from 'features/sudoku/sudokuSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { Retrieving, constructURL } from 'helpers/apiTypes';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './ChatBlock.scss';
import './ListBlock.scss';

function getUnread(block: IBlock): number {
  const lastVisited: Date | null = getVisitedState('block', block.id)[2];
  // const allMsgs = (block.childListBlock?.lists ?? [])
  // .reduce((accumulator: IListItem[], currentList: IList) => {
  //   return accumulator.concat(currentList.listItems ?? []);
  // }, [])
  // .map((item) => item.message ?? {});
  // let count = getUnreadMessagesCount(allMsgs, lastVisited);
  // return count;
  return 0;
}
registerUnreadCounter(BlockType.List, getUnread);

type SudokuBlockContentProps = {
  block: IBlock;
  showDescription?: boolean;
  lastVisited: Date | null;
  registerDropMenu?: (renderer: RenderChildDropMenu) => void;
  stateMan: StateMan;
};

const UnconnectedSudokuBlockContent = (
  props: SudokuBlockContentProps & UserStateProps & DialogueStateProps
) => {
  const { block, lastVisited, registerDropMenu, userCanEdit, dialogue } = props;
  const dispatch = useAppDispatch();
  const sudokuRef = useRef<SudokuHandle>(null);
  const intl = useIntl();
  let challenge = block.childSudokuBlock?.challenge || '';
  let attempt = block.childSudokuBlock?.attempt || '.'.repeat(9 * 9);
  const hints = block.childSudokuBlock?.hints ?? 0;

  useEffect(() => {
    if (block.childSudokuBlock?.challenge === '') doNewPuzzle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block]);

  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o;
  }

  const saveChanges = useCallback(
    (ch?: string, att?: string) => {
      if (ch || att)
        dispatch(
          patchSudokuBlockAsync({
            id: block.childSudokuBlock?.id!,
            stateMan: stateMan,
            data: {
              challenge: ch,
              attempt: att || '.'.repeat(9 * 9),
            },
          })
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [block]
  );

  const handleAttempt = useCallback(
    (newAttempt?: string) => {
      saveChanges(undefined, newAttempt);
    },
    [saveChanges]
  );

  const doNewPuzzle = useCallback(
    (n?: number): boolean => {
      const newPuzzle = sudokuRef.current?.newPuzzle(n ?? 28);
      if (newPuzzle) {
        saveChanges(newPuzzle);
      }
      return true;
    },
    [saveChanges]
  );

  const difficultyRef = useRef<HTMLInputElement>(null);
  const newPuzzle = useCallback(() => {
    showModal(
      <div>
        <FormattedMessage id="SUDOKU.NEW" />
      </div>,
      <>
        <div className="title">
          <FormattedMessage id="SUDOKU.FORM.NEW_CONF" />
          <br />
          <FormattedMessage id="SUDOKU.FORM.NEW_CONF_2" />
        </div>
        <Icon
          symbol={IconSymbol.alert}
          variant={IconVariant.accent}
          size={50}
        />
        <div className="sudoku_difficulty">
          <span
            className="sudoku_difficulty_level"
            onClick={() => {
              if (difficultyRef.current) difficultyRef.current.checked = false;
            }}
          >
            <FormattedMessage id="SUDOKU.FORM.EASY" />
          </span>
          <Form.Check
            className="sudoku_difficulty_level"
            ref={difficultyRef}
            type="switch"
            id="difficulty"
            label={intl.formatMessage({ id: 'SUDOKU.FORM.HARD' })}
          />
        </div>
      </>,
      {
        intl: intl,
        className: 'pd_alert',
        noFooter: false,
        submitBtnText: <FormattedMessage id="X.YES" />,
        onSubmit: () => {
          doNewPuzzle(difficultyRef.current?.checked ? 28 : 32);
          return true;
        },
        editing: true,
        cancelDefault: true,
      }
    );
  }, [doNewPuzzle, intl]);

  const clearPuzzle = useCallback(() => {
    showModal(
      <div>
        <FormattedMessage id="SUDOKU.CLEAR" />
      </div>,
      <>
        <div className="title">
          <FormattedMessage id="SUDOKU.FORM.CLEAR_CONF" />
          <br />
          <FormattedMessage id="SUDOKU.FORM.CLEAR_CONF_2" />
        </div>
        <Icon
          symbol={IconSymbol.alert}
          variant={IconVariant.accent}
          size={50}
        />
      </>,
      {
        intl: intl,
        className: 'pd_alert',
        noFooter: false,
        submitBtnText: <FormattedMessage id="X.YES" />,
        onSubmit: () => {
          // console.log('difficultyRef.current:', difficultyRef.current);
          handleAttempt('.'.repeat(9 * 9));
          return true;
        },
        editing: true,
        cancelDefault: true,
      }
    );
  }, [handleAttempt, intl]);

  type HintLevelProps = {
    level: number;
  };
  function HintLevel(props: HintLevelProps) {
    const { level } = props;
    function setHintLevel(n: number) {
      dispatch(
        patchSudokuBlockAsync({
          id: block.childSudokuBlock?.id!,
          data: { hints: n },
          stateMan: stateMan,
        })
      );
    }
    return (
      <div className="sudoku_hintlevel">
        <FormattedMessage id="SUDOKU.HINT_LEVEL" />: &nbsp;
        {[0, 1, 2, 9].map((lev) => {
          return (
            <div
              className={
                lev === (level ?? 0) ? 'sudoku_hintlevel_selected' : ''
              }
              key={lev}
              onClick={() => {
                setHintLevel(lev);
              }}
            >
              {lev}
            </div>
          );
        })}
      </div>
    );
  }
  useEffect(() => {
    if (registerDropMenu)
      registerDropMenu(() => {
        return (
          <DropMenu.Items>
            <div
              onClick={clearPuzzle}
              className={
                attempt.replaceAll('.', '').length === 0 ? 'disabled' : ''
              }
            >
              <FormattedMessage id="SUDOKU.CLEAR" />
            </div>
            <div onClick={newPuzzle}>
              <FormattedMessage id="SUDOKU.NEW" />
            </div>
            {userCanEdit(dialogue) ? (
              <>
                <DropMenu.Divider />
                <DropMenu.Note>
                  <FormattedMessage id="NAVIGATION.MODERATION" />
                </DropMenu.Note>
                <HintLevel level={block.childSudokuBlock?.hints ?? 0} />
                <div
                  onClick={() => {
                    sudokuRef.current?.solvePuzzle();
                  }}
                >
                  <FormattedMessage id="SUDOKU.SOLVE" />
                </div>
              </>
            ) : null}
          </DropMenu.Items>
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block, block.childSudokuBlock?.hints]);

  return (
    <>
      {props.showDescription ? (
        <div className="block_description">
          {block.description || intl.formatMessage({ id: 'SUDOKU.INTRO' })}
        </div>
      ) : null}
      <Sudoku
        ref={sudokuRef}
        disabled={block.locked ?? false}
        challenge={challenge}
        attempt={attempt}
        makeAttempt={handleAttempt}
        hints={hints}
      />
      <Link
        to={`https://${intl.locale}.wikipedia.org/wiki/Sudoku`}
        target="_blank"
      >
        <FormattedMessage id="SUDOKU.LEARN" />
      </Link>
    </>
  );
};

const SudokuBlockContent = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(UnconnectedSudokuBlockContent)
);

export default function SudokuBlock(props: ChildBlockProps) {
  const { block, onUpdate, showDescription, registerDropMenu } = props;
  const dispatch = useAppDispatch();
  const lastVisited: Date | null = getVisitedState('block', block.id)[2];

  const [webSocket] = useState(AsyncAPI.connection);

  useEffect(() => {
    if (block.childSudokuBlock == null) return;
    const asyncAPIQuery = `SudokuBlock/${
      block.childSudokuBlock?.id
    }?and=${constructURL({
      '*': true,
    } as const satisfies Retrieving<'SudokuBlock'>)}`;
    console.log(
      `Main useEffect called! (SudokuBlock) - id: ${block.childSudokuBlock.id} `
    );
    if (webSocket?.readyState !== 1) {
      console.log(
        'useEffect() main (SudokuBlock) --> connection is not ready, readyState is not on 1'
      );
      return;
    }
    AsyncAPI.doMessage(asyncAPIQuery);
    const onMessageCallback = (payload: any, change: string) => {
      switch (change) {
        case 'new':
          dispatch(asyncApiSudokuNew({ data: payload, stateMan }));
          break;
        case 'updated':
          dispatch(asyncApiSudokuUpdated({ data: payload, stateMan }));
          break;
        case 'removed':
          dispatch(asyncApiSudokuRemoved({ data: payload, stateMan }));
          break;
      }
      onUpdate();
    };
    AsyncAPI.addOnMessageCallbackQueries(asyncAPIQuery, onMessageCallback);
    return () => {
      if (webSocket?.readyState !== 1) {
        console.log(
          'useEffect() return (SudokuBlock) --> connection is not ready, readyState is not on 1'
        );
        return;
      }
      AsyncAPI.doMessageDisconnect(asyncAPIQuery);
      const index = AsyncAPI.onMessageCallbacksQueries.findIndex(
        ({ callback }) => callback === onMessageCallback
      );
      if (index !== -1) {
        AsyncAPI.onMessageCallbacksQueries.splice(index, 1);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, webSocket?.readyState, block.childSudokuBlock?.id]);

  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o?.childSudokuBlock;
  }

  return (
    <div className="block_content">
      <SudokuBlockContent
        block={block}
        stateMan={stateMan}
        showDescription={showDescription}
        lastVisited={lastVisited}
        registerDropMenu={registerDropMenu}
      />
    </div>
  );
}
