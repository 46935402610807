import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { useAppDispatch } from 'app/hooks';
import { navigateIn } from 'components/navigation/Navigation';
import { resetDialogue } from 'features/dialogue/dialogueSlice';
import { resetProjects } from 'features/projects/projectsSlice';
import { logout } from 'features/user/userSlice';

export function UserLogoutLink(props: { className?: string }) {
  const { className } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  async function handleClick() {
    navigateIn('/', 0, navigate);
    dispatch(logout());
    dispatch(resetProjects());
    dispatch(resetDialogue());
  }
  return (
    <div onClick={handleClick} className={classNames(className)}>
      <FormattedMessage id="NAVIGATION.LOGOUT" />
    </div>
  );
}
