import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import ColorPickerButton, {
  ColorPickerColors,
  asColor,
} from 'components/colorpicker/ColorPickerButton';
import { StateMan } from 'features/dialogue/dialogueSlice';
import { IMessage } from 'features/message/messageAPI';
import { patchMessageAsync } from 'features/message/messageSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { PDColor, PDPalet } from 'helpers/colors';

import { Path, Svg, View } from '@react-pdf/renderer';
import pfdStyles from 'css/pdfStyles';
import './Message.scss';

interface IMessageColorProps {
  message: IMessage;
  palet?: PDPalet;
  editable?: boolean;
  stateMan?: StateMan;
}

function UnconnectedMessageColor(props: IMessageColorProps & UserStateProps) {
  const { message, palet = ColorPickerColors, editable = true } = props;
  const dispatch = useAppDispatch();
  const intl = useIntl();

  function chooseColor(color: PDColor | string, palet?: PDPalet) {
    if (!props.stateMan) return;
    const col = asColor(typeof color === 'string' ? color : color.name, palet);
    dispatch(
      patchMessageAsync({
        data: { ...message, color: col.name },
        stateMan: props.stateMan,
      })
    ).then((response: any) => {
      switch (response.payload?.response.status) {
        case 200:
          break;
        case 500:
        default:
          console.log(response);
      }
    });
  }

  return (
    <>
      <ColorPickerButton
        color={asColor(message.color || palet.black, palet)}
        setColor={editable ? chooseColor : null}
        palet={palet}
        pickerwidth={'twoRows'}
        className={'msg_color'}
        noBW={true}
        buttonStyle={{
          borderColor: `${
            asColor(message.color || palet.black, palet).hex
          } transparent transparent transparent`,
        }}
        hintProps={{
          hint: intl.formatMessage({ id: 'LISTS.COLOR_HINT' }),
          offset: { x: 10, y: 20 },
        }}
      />
    </>
  );
}

const MessageColor = connect(mapUserStateToProps)(UnconnectedMessageColor);
export default MessageColor;

export function MessageColorToPdf(props: IMessageColorProps) {
  const { message, palet = ColorPickerColors } = props;
  if (asColor(message.color || palet.black, palet).hex === 'transparent')
    return null;
  return (
    <>
      <View style={pfdStyles.message_color}>
        <Svg>
          <Path
            d="M0,12V0h12L0,12Z"
            fill={asColor(message.color || palet.black, palet).hex}
            stroke-width="0"
          />
        </Svg>
      </View>
    </>
  );
}
