import classNames from 'classnames';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { Collapse } from 'react-bootstrap';

import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import { evaluateThis } from 'helpers/helpers';

import './Collapsable.scss';

type Dimension = 'height' | 'width';

interface ICollapsableControllerProps {
  open: boolean; // open state
  setOpen: Dispatch<SetStateAction<boolean>>; // state action that toggles the open state
  className?: string; // extra classname for the controller
  active?: boolean; // if true, renders top div with class 'active'
  hide?: boolean; // if true, renders controller with class 'hide' when opened
  icon?: IconSymbol | null; // name of the icon to be displayed on the controller
  closeIcon?: IconSymbol | null; // the name of the icon to use for closing
  iconSize?: number | 'large';
  iconVariant?: IconVariant; // variant (dark, light) of the icon
  noPlus?: boolean; // if true, hides the plus and minus
  noIcon?: boolean; // if true, hides all icons and the symbol
  symbol?: number | string; // symbol to be displayed on the controller (only if there's no icon)
  onCollapse?: (open: boolean) => void; // callback to be called on collapse and uncollapse
  tooltip?: string | JSX.Element | (() => string) | (() => JSX.Element) | null;
  disabled?: boolean;
  children?: ReactNode;
}
function CollapsableController(props: ICollapsableControllerProps) {
  const {
    open,
    setOpen,
    className,
    active,
    hide,
    icon,
    closeIcon,
    iconSize,
    iconVariant,
    noPlus,
    noIcon,
    symbol,
    onCollapse,
    tooltip,
    disabled = false,
  } = props;
  const children = React.Children.toArray(props.children);
  const hasTooltip = Boolean(
    (icon || symbol || children.length > 0) && tooltip
  );

  function wrapTooltipTrigger(content: ReactNode) {
    if (!hasTooltip) return content;
    return <div className="collapsable_tooltip_trigger">{content}</div>;
  }

  const onControllerClicked = () => {
    if (disabled) return;
    setOpen((prevOpen) => !prevOpen);
    if (onCollapse) onCollapse(!open);
  };

  return (
    <div
      key={1}
      className={classNames('collapsable_controller', className, {
        hide: hide && open,
        active: active,
        disabled: disabled,
      })}
      onClick={onControllerClicked}
    >
      {noIcon ? null : (
        <>
          {noPlus ? null : (
            <>
              <Icon
                className="collapsable_icon"
                symbol={open ? IconSymbol.minus : IconSymbol.plus}
                variant={iconVariant}
                size={iconSize === 'large' ? undefined : iconSize}
              />
            </>
          )}
          {icon || symbol
            ? wrapTooltipTrigger(
                icon ? (
                  <div
                    className={classNames({
                      collapsable_symbol: iconSize === 'large',
                      collapsable_icon: iconSize !== 'large',
                    })}
                  >
                    <Icon
                      symbol={open ? closeIcon ?? icon : icon}
                      variant={iconVariant}
                      size={iconSize === 'large' ? undefined : iconSize}
                      style={{ margin: 'auto' }}
                    />
                  </div>
                ) : symbol ? ( // if there's no icon, there may be a symbol
                  <div className="collapsable_symbol">{symbol}</div>
                ) : null
              )
            : null}
        </>
      )}
      {noIcon || (!icon && !symbol) ? (
        <>
          {wrapTooltipTrigger(children[0])}
          {children.slice(1)}
        </>
      ) : (
        children
      )}
      {hasTooltip ? (
        <div key={2} className="collapsable_tooltip">
          {evaluateThis(tooltip)}
        </div>
      ) : null}
    </div>
  );
}

interface ICollapsableContentProps {
  open: boolean; // open state
  className?: string; // extra classname for the content
  dimension?: Dimension;
  children?: ReactNode;
  timeout?: number;
}
function CollapsableContent(props: ICollapsableContentProps) {
  const { open, className, dimension, children, timeout = 3000 } = props;
  return (
    <Collapse
      key={3}
      in={open}
      dimension={dimension || 'width'}
      timeout={timeout}
      className={className}
    >
      <div className={'collapsable_content'}>{children}</div>
    </Collapse>
  );
}

interface ICollapsableProps {
  className?: string; // extra class given to the top div (e.g., 'phase' or 'block')
  dimension?: Dimension;
  style?: React.CSSProperties;
  ref?: React.RefObject<HTMLDivElement>;
  children: ReactNode; // the controller and the content of the collapsable (that which collapses)
}
const CollapsableFC = React.forwardRef<HTMLDivElement, ICollapsableProps>(
  (props, ref) => {
    const { className, dimension, style, children } = props;
    return (
      <div
        className={classNames(className, 'collapsable', dimension || 'width')}
        ref={ref}
        style={style}
      >
        {children}
      </div>
    );
  }
);

interface ICollapsableContainerProps {
  className?: string;
  dimension?: Dimension;
  children: ReactNode;
}
export function CollapsableContainer(props: ICollapsableContainerProps) {
  const { className, dimension, children } = props;
  return (
    <div
      className={classNames(
        className,
        'collapsable_container',
        dimension || 'width'
      )}
    >
      {children}
    </div>
  );
}

const Collapsable = Object.assign(CollapsableFC, {
  Controller: CollapsableController,
  // Tooltip: CollapsableTooltip,
  Content: CollapsableContent,
});
// Collapsable.Controller = CollapsableController;
// // Collapsable.Tooltip = CollapsableTooltip;
// Collapsable.Content = CollapsableContent;

export default Collapsable;
