import { useAppDispatch } from 'app/hooks';
import classNames from 'classnames';
import { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import FormattedDate from 'components/formatters/FormattedDate';
import Icon from 'components/icons/Icon';
import { ImageViewLink } from 'components/messages/ImageViewLink';
import LinkifyText from 'components/messages/Linkify';
import TextAreaInput from 'components/messages/TextAreaInput';
import DropMenu from 'components/navigation/DropMenu';
import {
  DialogueStateProps,
  mapDialogueStateToProps,
  StateMan,
} from 'features/dialogue/dialogueSlice';
import { IFile } from 'features/files/fileAPI';
import {
  deleteFileAsync,
  getFileTypeInfo,
  patchFileAsync,
} from 'features/files/fileSlice';
import {
  deleteMessageAsync,
  patchMessageAsync,
} from 'features/message/messageSlice';
import { mapUserStateToProps, UserStateProps } from 'features/user/userSlice';
import { IS_DEV } from 'helpers/consts';
import { fileSize } from 'helpers/helpers';
import { sameId } from 'helpers/objects';

import { confirmDelete } from 'components/messages/confirmDelete';
import '../../css/forms.scss';
import '../blocks/LibraryBlock.scss';
import '../messages/Message.scss';

type LibraryFileProps = {
  file: IFile;
  blockRef?: React.RefObject<HTMLDivElement>;
  stateMan: StateMan;
};

function UnconnectedLibraryFile(
  props: LibraryFileProps &
    PropsWithChildren &
    UserStateProps &
    DialogueStateProps
) {
  const { file, children } = props;
  const { dialogue, userId, userCanEdit } = props;
  const [editing, setEditing] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    setDescription(file.description || '');
  }, [setDescription, file]);

  function submitFile() {
    if (file.uri)
      dispatch(
        patchFileAsync({
          data: {
            ...file,
            description: description,
          },
          stateMan: props.stateMan,
        })
      ).then((response: any) => {
        switch (response.payload?.response.status) {
          case 200:
            break;
          default:
        }
        setEditing(false);
        setDescription(file.description || '');
      });
    else {
      const { description: _, uploadTime: __, ...msg } = file;
      dispatch(
        patchMessageAsync({
          data: {
            ...msg,
            content: description,
          },
          stateMan: stateMan,
        })
      ).then((response: any) => {
        switch (response.payload?.response.status) {
          case 200:
            break;
          default:
        }
        setEditing(false);
        // setDescription(msg.content || '');
      });
    }
  }

  function handleDescriptionEdit() {
    file.description && setDescription(file.description);
    setEditing(true);
  }

  function handleFileDelete() {
    function doDelete() {
      if (file.id)
        if (file.uri)
          dispatch(
            deleteFileAsync({
              file: file,
              stateMan: props.stateMan,
            })
          ).then((response: any) => {
            switch (response.payload?.response.status) {
              case 204:
                break;
              case 500:
              default:
                console.log(response);
            }
          });
        else
          dispatch(
            deleteMessageAsync({
              message: file,
              stateMan: props.stateMan,
            })
          ).then((response: any) => {
            switch (response.payload?.response.status) {
              case 204:
                break;
              case 500:
              default:
                console.log(response);
            }
          });
      return true;
    }
    const isModeration = !sameId(file.author, userId) && userCanEdit(dialogue);
    confirmDelete(file.description, isModeration, doDelete, intl);
  }

  function handleDescriptionChange(input: string, hasText: boolean) {
    setDescription(input);
  }

  function handleBlur() {
    setEditing(false);
  }

  // stateMan for patchMessageAsync
  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    const oo = o?.hasOwnProperty('links')
      ? o?.links?.find((item: any) => item?.id === file.id)
      : o;
    return oo;
  }

  let descr = file.description;
  let path = [];
  if (file.id && file.description) {
    path = (file.description as string).split('/');
    if (path.length > 1) descr = path.slice(1).join('/').trim();
  }

  return (
    <>
      <div
        className={classNames('file', {})}
        data-file={IS_DEV ? file.id : undefined}
      >
        <div className="files_icon">
          <Icon symbol={getFileTypeInfo(file).icon} />
        </div>
        <div className={classNames('files_description', { editing })}>
          {editing ? (
            <TextAreaInput
              // asInput={true}
              onChange={handleDescriptionChange}
              onBlur={handleBlur}
              onSubmit={submitFile}
              value={description}
            />
          ) : file.uri ? (
            <ImageViewLink className="files_link" file={file} noPreview={true}>
              {descr}
            </ImageViewLink>
          ) : (
            <LinkifyText useText>{file.description}</LinkifyText>
          )}
        </div>
        <div className="files_size">{file.size && fileSize(file.size)}</div>
        {userCanEdit(dialogue) ? (
          <div className="msg_menu">
            <DropMenu
              containerRef={props.blockRef}
              containerTailHeight={57}
              debug
            >
              <DropMenu.Trigger className="ellipsis">
                <div className="files_date">
                  {file.uploadTime ? (
                    <FormattedDate
                      value={file.uploadTime}
                      showYear={true}
                      format={{ year: '2-digit' }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </DropMenu.Trigger>
              <DropMenu.Items>
                {sameId(file.author, userId) ? (
                  <div onClick={handleDescriptionEdit}>
                    <FormattedMessage id="FILES.EDIT" />
                  </div>
                ) : undefined}
                {userCanEdit(dialogue) || sameId(file.author, userId) ? (
                  <div onClick={handleFileDelete}>
                    <FormattedMessage id="X.DELETE" />
                  </div>
                ) : undefined}
              </DropMenu.Items>
            </DropMenu>
          </div>
        ) : (
          <div className="files_date">
            {file.uploadTime ? (
              <FormattedDate value={file.uploadTime} showYear />
            ) : (
              ''
            )}
          </div>
        )}
        {children}
      </div>
    </>
  );
}

const LibraryFile = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(UnconnectedLibraryFile)
);
export default LibraryFile;
