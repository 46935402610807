import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import {
  IDocument,
  IDocumentCreate,
  deleteDocument,
  patchDocument,
  postDocument,
} from './documentAPI';

export const postDocumentAsync = createAsyncThunk(
  'document/postDocument',
  async (
    {
      data,
      stateMan,
    }: {
      stateMan: StateMan;
      data: IDocumentCreate;
    },
    thunkAPI
  ) => {
    try {
      return { response: await postDocument(data), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const patchDocumentAsync = createAsyncThunk(
  'document/patchDocument',
  async (
    {
      data,
      id,
      stateMan,
    }: {
      data: IDocument;
      id: number;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await patchDocument(data, id), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const deleteDocumentAsync = createAsyncThunk(
  'document/deleteDocument',
  async (
    {
      id,
      stateMan,
    }: {
      id: number;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await deleteDocument(id), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export function addDocumentCases(
  builder: ActionReducerMapBuilder<IDialogueState>
) {
  builder
    .addCase(postDocumentAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(postDocumentAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      // const stateObj = action.meta.arg.stateMan(state);

      // TODO
    })
    .addCase(postDocumentAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(patchDocumentAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchDocumentAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      // const stateObj = action.meta.arg.stateMan(state);

      // TODO
    })
    .addCase(patchDocumentAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(deleteDocumentAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(deleteDocumentAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      // const stateObj = action.meta.arg.stateMan(state);

      // TODO
    })
    .addCase(deleteDocumentAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });
}
