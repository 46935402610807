import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import './PopMenu.scss';

type PopMenuItemsProps = {
  isItems?: boolean;
  className?: string;
  disabled?: boolean;
};
function PopMenuItems({
  // isItems = true, // used externally
  className,
  children,
  disabled = false,
}: PopMenuItemsProps & PropsWithChildren) {
  return (
    <div className={classNames('popmenu_items', className, { disabled })}>
      {children}
    </div>
  );
}
PopMenuItems.defaultProps = {
  isItems: true,
};

// type DropMenuNoteProps = {
//   isNote?: boolean;
//   className?: string;
// };
// function DropMenuNote({
//   className,
//   children,
// }: DropMenuNoteProps & PropsWithChildren) {
//   return (
//     <div className={classNames('dropmenu_note label', className)}>
//       {children}
//     </div>
//   );
// }
// DropMenuNote.defaultProps = {
//   isNote: true,
// };
// function DropMenuDivider({ className }: DropMenuNoteProps) {
//   return <div className={classNames('dropmenu_divider', className)} />;
// }
// DropMenuDivider.defaultProps = {
//   isNote: true,
// };

type PopMenuProps = {
  className?: string;
  noPosition?: boolean;
  right?: boolean;
  // shift?: number; // translate horizontally in px
  // lift?: number; // translate vertically in px
  up?: boolean;
  // containerRef?: React.RefObject<HTMLDivElement>; // the ancestor relative div in which the menu is rendered
  // containerTailHeight?: number; // extra space in px to observe in the tail of the container
  // // called before showing the menu, return false to prevent
  // onOpen?: (e: SyntheticEvent) => boolean;
  // // boolean or function that determines if the menu should be closed on click
  // shouldClose?: boolean | ((e: SyntheticEvent) => boolean); // set this to true to close the menu
  hideWithOpacity?: boolean;
  debug?: boolean;
  children: ReactNode;
};

export default function PopMenu(props: PopMenuProps): JSX.Element {
  const {
    className,
    noPosition = false,
    right = false,
    // shift,
    // lift,
    up = false,
    // containerRef,
    // containerTailHeight = 0,
    // onOpen,
    // shouldClose = true,
    debug = false,
    children,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const popMenuRef = useRef<HTMLDivElement | null>(null);

  function onClickOutside() {
    if (isActive) {
      const items = popMenuRef.current!.querySelector(
        '.dropmenu_items'
      ) as HTMLElement;
      transform(items);
      setIsActive(false);
    }
  }
  useOnClickOutside(popMenuRef, onClickOutside);

  type XY = {
    x?: number;
    y?: number;
  };
  function transform(el: HTMLElement, xy?: XY) {
    el.style.transform =
      (xy?.x ? `translateX(${xy.x}px) ` : '') +
      (xy?.y ? `translateY(${xy.y}px)` : '');
    // el.style.bottom = xy?.y ? `-15px` : '';
    // el.style.top = xy?.y ? 'unset' : '';
  }

  function popmenuClassNames() {
    return classNames('popmenu', className, {
      popmenu_noposition: noPosition,
      popmenu_active: isActive,
      popmenu_right: right,
      popmenu_up: up,
    });
  }

  function hasItems(): boolean {
    for (const child of React.Children.toArray(children)) {
      if (React.isValidElement(child)) {
        if (child.props.isItems) {
          return React.Children.toArray(child.props.children).some((c) => !!c);
        }
      }
    }
    return false;
  }

  return hasItems() ? (
    <div className={popmenuClassNames()} ref={popMenuRef}>
      {children}
    </div>
  ) : (
    <></>
  );
}

PopMenu.Items = PopMenuItems;
// DropMenu.Note = DropMenuNote;
// DropMenu.Divider = DropMenuDivider;
