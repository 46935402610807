import { ToolHint } from 'components/messages/ToolHint';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

type UnreadCounterProps = {
  unread: number;
};
export default function UnreadCounter(props: UnreadCounterProps) {
  const { unread } = props;
  const unreadRef = useRef<HTMLDivElement>(null);
  return (
    <>
      {unread ? (
        <>
          <div className="unread_count" ref={unreadRef}>
            {unread < 100 ? unread : '99+'}
            <ToolHint
              hint={
                <FormattedMessage
                  id="X.UNREAD"
                  // id="X.UNREAD_ITEMS"
                  // values={{ count: unread }}
                />
              }
              toolRef={unreadRef}
              width={100}
              offset={{ x: -20, y: 30 }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}
