import { Text } from '@react-pdf/renderer';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { truncateTo } from 'helpers/helpers';

type ReadMoreProps = {
  children: ReactNode;
  maxLen?: number;
};
export default function ReadMore(props: ReadMoreProps) {
  const { children, maxLen = 50 } = props;
  const [more, setMore] = useState<boolean>(false);

  if (typeof children !== 'string') {
    console.error('ReadMore only accepts text as children');
    return <>{children?.toString()}</>;
  }
  const truncatedText = truncateTo(children, maxLen);
  return (
    <div className="readmore">
      {children.length > truncatedText?.length ? (
        <>
          {more ? children : truncatedText}&nbsp;
          <div
            className="more button right"
            onClick={() => setMore((prev) => !prev)}
          >
            {more ? (
              <FormattedMessage id="X.LESS" />
            ) : (
              <FormattedMessage id="X.MORE" />
            )}
            &hellip;
          </div>
        </>
      ) : (
        children
      )}
    </div>
  );
}

export function ReadMoreToPdf(props: ReadMoreProps) {
  const { children, maxLen = 50 } = props;

  if (typeof children !== 'string') {
    console.error('ReadMore only accepts text as children');
    return <>{children?.toString()}</>;
  }
  const truncatedText = truncateTo(children, maxLen);
  return (
    <Text>
      {children.length > truncatedText?.length ? (
        <>{truncatedText}...</>
      ) : (
        children
      )}
    </Text>
  );
}
