import React, { useEffect, useState } from 'react';

interface TimeInputProps {
  date: Date | null;
  onChange: (newDate: Date) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({ date, onChange }) => {
  const [time, setTime] = useState<string>('');

  useEffect(() => {
    const hours = date?.getHours().toString().padStart(2, '0') ?? '00';
    const minutes = date?.getMinutes().toString().padStart(2, '0') ?? '00';
    setTime(`${hours}:${minutes}`);
  }, [date]);

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = e.target.value;
    setTime(newTime);

    const [hours, minutes] = newTime.split(':');
    const newDate = date ? new Date(date) : new Date();
    newDate.setHours(parseInt(hours, 10));
    newDate.setMinutes(parseInt(minutes, 10));
    newDate.setSeconds(0, 0);
    onChange(newDate);
  };

  return <input type="time" value={time} onChange={handleTimeChange} />;
};

export default TimeInput;
