import { Dispatch, SetStateAction } from 'react';
import { Pagination } from 'react-bootstrap';

type PaginatorProps = {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  itemCount: number;
  itemsPerPage: number;
};

export function Paginator(props: PaginatorProps) {
  const { currentPage, setCurrentPage, itemCount, itemsPerPage } = props;
  const pageCount = Math.ceil(itemCount / itemsPerPage);
  const pages = [];
  const maxPages = 5;
  const end = Math.min(
    pageCount,
    Math.max(1, currentPage - Math.floor((maxPages - 1) / 2)) + maxPages - 1
  );
  const start = Math.max(1, end - (maxPages - 1));
  pages.push(
    <Pagination.First
      key={-3}
      className="page_arrow"
      onClick={() => setCurrentPage(1)}
      disabled={currentPage === 1}
    />
  );
  pages.push(
    <Pagination.Prev
      key={-2}
      className="page_arrow"
      onClick={() => setCurrentPage(currentPage - 1)}
      disabled={currentPage === 1}
    />
  );
  for (let p = start; p <= end; p++) {
    pages.push(
      <Pagination.Item
        key={p}
        active={p === currentPage}
        onClick={() => setCurrentPage(p)}
      >
        {p}
      </Pagination.Item>
    );
  }
  pages.push(
    <Pagination.Next
      key={end + 2}
      className="page_arrow"
      onClick={() => setCurrentPage(currentPage + 1)}
      disabled={currentPage === pageCount}
    />
  );
  pages.push(
    <Pagination.Last
      key={end + 3}
      className="page_arrow"
      onClick={() => setCurrentPage(pageCount)}
      disabled={currentPage === pageCount}
    />
  );
  return (
    <div className="pagination_styles">
      <Pagination>{pages}</Pagination>
    </div>
  );
}

export function paginateItems<T>(
  items: T[],
  currentPage: number,
  itemsPerPage: number
): T[] {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return items.slice(startIndex, endIndex);
}
