import {
  AbsoluteBlockStateProps,
  mapAbsoluteBlockStateToProps,
} from 'features/block/absoluteBlockSlice';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import classNames from 'classnames';
import './AbsoluteContent.scss';

function UnconnectedAbsoluteContent(props: AbsoluteBlockStateProps) {
  const { renderers, open } = props;
  // const [reload, setReload] = useState<boolean>(false);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  // const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      className={classNames('absolute_content', {
        open: open,
      })}
    >
      {renderers.map((r) => {
        return (
          <Fragment key={r.key}>
            {r.renderer({ ...r.props, intl: intl })}
          </Fragment>
        );
      })}
    </div>
  );
}

const AbsoluteContent = connect(mapAbsoluteBlockStateToProps)(
  UnconnectedAbsoluteContent
);
export default AbsoluteContent;
