import classNames from 'classnames';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import './DialogHeader.scss';

type DialogHeaderProps = {
  title: string | JSX.Element;
  itemsPerPage: number;
  setItemsPerPage: Dispatch<SetStateAction<number>>;
  showIds: boolean;
  setShowIds: Dispatch<SetStateAction<boolean>>;
  searchItem?: string;
  setSearchItem?: Dispatch<SetStateAction<string>>;
};
export function DialogHeader(props: DialogHeaderProps) {
  const {
    title,
    itemsPerPage,
    setItemsPerPage,
    showIds,
    setShowIds,
    searchItem,
    setSearchItem,
  } = props;
  const intl = useIntl();

  return (
    <div className="dialog_header">
      <div>{title}</div>
      {setSearchItem && (
        <>
          <Form.Control
            className="search_item"
            type="text"
            name="searchItem"
            value={searchItem ?? ''}
            onChange={(e: SyntheticEvent) => {
              const target = e.target as HTMLInputElement;
              setSearchItem && setSearchItem(target.value);
            }}
            placeholder={intl.formatMessage({ id: 'X.SEARCH.ITEM' })}
          />
          <Icon
            className={classNames('search_close', {
              hidden: !searchItem,
            })}
            symbol={IconSymbol.close}
            hoverVariant={IconVariant.dark}
            size={18}
            onClick={() => {
              setSearchItem('');
            }}
          />
        </>
      )}
      <div className="items_per_page_label">items/page:</div>
      <Form.Control
        className="items_per_page"
        type="number"
        name="itemsPerPage"
        value={itemsPerPage}
        onChange={(e: SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          setItemsPerPage(parseInt(target.value));
        }}
        required
        min={1}
      />
      <Form.Check
        className="show_ids"
        type="checkbox"
        label="show ID's"
        name="showIds"
        checked={showIds}
        onChange={() => {
          setShowIds(!showIds);
        }}
      />
    </div>
  );
}
