import { Text, View } from '@react-pdf/renderer';
import {
  ISectionOptions,
  SectionType,
  Table,
  TableBorders,
  TableRow,
  WidthType,
} from 'docx';
import {
  Dispatch,
  Fragment,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import { useAppDispatch } from 'app/hooks';
import { getUnreadMessagesCount, registerUnreadCounter } from 'app/unreadCount';
import classNames from 'classnames';
import FileUpload from 'components/files/FileUpload';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import Message, {
  MessageDate,
  MessageDateToPdf,
  MessageToPdf,
  getMessageWidthClass,
  messageDateToDocx,
  messageToDocx,
} from 'components/messages/Message';
import TextAreaInput, {
  justWhitespace,
} from 'components/messages/TextAreaInput';
import DropMenu from 'components/navigation/DropMenu';
import ScrollObserver from 'components/scrollobserver/ScrollObserver';
import SortableItem from 'components/sortables/SortableItem';
import SortableList, {
  SortableItemType,
  SortableListInfo,
  SortableListType,
} from 'components/sortables/SortableList';
import Author from 'components/user/Author';
import { IBlock } from 'features/block/blockAPI';
import { BlockType } from 'features/block/blockSlice';
import { StateMan } from 'features/dialogue/dialogueSlice';
import { imageFileTypes } from 'features/files/fileSlice';
import { IMessage } from 'features/message/messageAPI';
import {
  asyncApiMessagesNew,
  asyncApiMessagesRemoved,
  asyncApiMessagesUpdated,
  postMessageAsync,
} from 'features/message/messageSlice';
import { getVisitedState } from 'features/uiState/uiStateSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { Retrieving, constructURL } from 'helpers/apiTypes';
import { IS_DEV } from 'helpers/consts';
import { onSameDay, similarTime } from 'helpers/datetime';
import { ExportProps } from 'helpers/export';
import { AnyOrderObject, idOf, sameId } from 'helpers/objects';
import { sortByTime } from 'helpers/sorting';
import { ChildBlockProps } from './Block';

import pfdStyles from '../../css/pdfStyles';
import './ChatBlock.scss';

function getUnread(block: IBlock): number {
  const lastVisited: Date | null = getVisitedState('block', block.id)[2];
  const count = getUnreadMessagesCount(
    block.childChatBlock?.messages ?? [],
    lastVisited
  );
  return count;
}
registerUnreadCounter(BlockType.Chat, getUnread);

export default function ChatBlock(props: ChildBlockProps) {
  const { block, onUpdate } = props;
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [newReplyTo, setNewReplyTo] = useState<IMessage | null>(null);
  const dispatch = useAppDispatch();
  const [hasNewMsg, setHasNewMsg] = useState<boolean>(false);
  const lastVisited: Date | null = getVisitedState('block', block.id)[2];

  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o?.childChatBlock;
  }

  const [webSocket] = useState(AsyncAPI.connection);

  useEffect(() => {
    if (block.childChatBlock == null) return;

    const asyncAPIQuery = `ChatBlock/${
      block.childChatBlock?.id
    }?and=${constructURL({
      messages: {
        '*': true,
        author: {
          // '*': true,
          about: true,
          email: true,
          id: true,
          isVerified: true,
          locale: true,
          username: true,
        },
        likes: {
          '*': true,
          liker: {
            id: true,
            username: true,
          },
        },
        replyTo: {
          '*': true,
          author: {
            // '*': true,
            about: true,
            email: true,
            id: true,
            isVerified: true,
            locale: true,
            username: true,
          },
        },
        files: {
          '*': true,
        },
      },
    } as const satisfies Retrieving<'ChatBlock'>)}`;

    console.log(
      `Main useEffect called! (ChatBlock) - id: ${block.childChatBlock.id}`
    );

    if (webSocket?.readyState !== 1) {
      console.log(
        'useEffect() main (ChatBlock) --> connection is not ready, readyState is not on 1'
      );
      return;
    }
    AsyncAPI.doMessage(asyncAPIQuery);

    const onMessageCallback = (payload: any, change: string) => {
      switch (change) {
        case 'new':
          dispatch(asyncApiMessagesNew({ data: payload, stateMan }));
          break;
        case 'updated':
          dispatch(asyncApiMessagesUpdated({ data: payload, stateMan }));
          break;
        case 'removed':
          dispatch(asyncApiMessagesRemoved({ data: payload, stateMan }));
          break;
      }
      onUpdate();
    };

    AsyncAPI.addOnMessageCallbackQueries(asyncAPIQuery, onMessageCallback);

    return () => {
      if (webSocket?.readyState !== 1) {
        console.log(
          'useEffect() return (Messages) --> connection is not ready, readyState is not on 1'
        );
        return;
      }
      AsyncAPI.doMessageDisconnect(asyncAPIQuery);

      const index = AsyncAPI.onMessageCallbacksQueries.findIndex(
        ({ callback }) => callback === onMessageCallback
      );

      if (index !== -1) {
        AsyncAPI.onMessageCallbacksQueries.splice(index, 1);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, webSocket?.readyState, block.childChatBlock?.id]);

  return (
    <>
      <ScrollObserver
        scrollableRef={scrollableRef}
        toBottom={true}
        mutations={true}
        customScrollbar={false}
        shouldScrollToBottom={hasNewMsg}
      >
        <div
          className={classNames('block_content', { hasNewMsg: hasNewMsg })}
          ref={scrollableRef}
        >
          <ChatBlockContent
            block={block}
            showDescription={props.showDescription}
            setNewReplyTo={setNewReplyTo}
            scrollableRef={scrollableRef}
            stateMan={stateMan}
            lastVisited={lastVisited}
          />
        </div>
      </ScrollObserver>
      {!block.locked ? (
        <div className="block_tail">
          <ChatBlockTail
            block={block}
            newReplyTo={newReplyTo}
            setNewReplyTo={setNewReplyTo}
            stateMan={stateMan}
            setHasNewMsg={setHasNewMsg}
          />
        </div>
      ) : null}
    </>
  );
}

type ChatBlockContentProps = {
  block: IBlock;
  showDescription?: boolean;
  setNewReplyTo: Dispatch<SetStateAction<IMessage | null>>;
  lastVisited: Date | null;
  scrollableRef: React.RefObject<HTMLDivElement> | null;
  stateMan: StateMan;
};

function UnconnectedChatBlockContent(
  props: ChatBlockContentProps & UserStateProps
) {
  const { block, lastVisited, scrollableRef, userId } = props;
  if (!block.childChatBlock || !block.childChatBlock.messages) return null;
  const chatMsgs = sortByTime(block.childChatBlock.messages);
  const listinfo: SortableListInfo = {
    id: block.id as number,
    listType: SortableListType.Messages,
    items: chatMsgs as AnyOrderObject[],
    canvas: false,
  };
  let newCount = chatMsgs.reduce((c: number, m: IMessage) => {
    return (
      c +
      (sameId(m.author, userId)
        ? 0
        : (m.time && lastVisited && new Date(lastVisited) < new Date(m.time)) ??
          true
        ? 1
        : 0)
    );
  }, 0);
  let countNew = 0;

  return (
    <div className="chat" ref={scrollableRef}>
      {props.showDescription && block.description ? (
        <div className="block_description">{block.description}</div>
      ) : null}
      {chatMsgs && chatMsgs.length ? (
        <SortableList
          key={listinfo.id}
          listInfo={listinfo}
          // itemType={'message'} // do not set the itemType!
          className={'messages'}
          horizontal={false}
          // ref={scrollableRef}
          copySource={true}
          disabled={true}
        >
          {chatMsgs.map((message: IMessage, i: number, a: Array<IMessage>) => {
            let date = new Date(message.time as Date);
            let prevDate = new Date(i > 0 ? (a[i - 1].time as Date) : 0);
            let prevAuthor = i > 0 ? idOf(a[i - 1].author) : 0;
            const isNew =
              (!sameId(message.author, userId) &&
                message.time &&
                lastVisited &&
                new Date(lastVisited) < new Date(message.time)) ??
              true;
            if (isNew || countNew) countNew++;
            return (
              <Fragment key={i}>
                <MessageDate
                  first={i === 0}
                  date={date}
                  last={prevDate}
                  unread={countNew === 1 ? newCount : 0}
                />
                <SortableItem
                  className={classNames(
                    // sameId(userId, message.author) ? 'byme' : '',
                    // sameId(prevAuthor, message.author) ? 'same_author' : '',
                    getMessageWidthClass(message),
                    {
                      byme: sameId(userId, message.author),
                      same_author:
                        !!prevAuthor &&
                        sameId(message.author, prevAuthor) &&
                        onSameDay(message.time as Date, prevDate),
                      same_time: similarTime(message.time as Date, prevDate),
                      is_new: isNew,
                    }
                  )}
                  key={message.id!}
                  list={listinfo}
                  item={message as AnyOrderObject}
                  itemType={SortableItemType.Message}
                  index={i}
                  onCanvas={listinfo.canvas}
                  withHandle={true}
                  copySource={true}
                  // disabled={true}
                >
                  <Message
                    message={message}
                    block={block}
                    setNewReplyTo={props.setNewReplyTo}
                    stateMan={props.stateMan}
                    prevTime={prevDate}
                    prevAuthor={prevAuthor}
                    blockRef={scrollableRef ?? undefined}
                    showLikes={true}
                    isNew={
                      (message.time &&
                        lastVisited &&
                        new Date(lastVisited) < new Date(message.time)) ??
                      true
                    }
                  />
                </SortableItem>
              </Fragment>
            );
          })}
        </SortableList>
      ) : (
        <div className={'no_messages'}>
          <FormattedMessage id="DIALOGUE.VIEW.NO_CONTENT" />
        </div>
      )}
    </div>
  );
}

const ChatBlockContent = connect(mapUserStateToProps)(
  UnconnectedChatBlockContent
);

export function ChatBlockToPdf(
  props: {
    block: IBlock;
  } & ExportProps
) {
  const { dialogue, block, intl } = props;
  const chat = block.childChatBlock ?? null;
  if (!chat || !chat.messages) return null;
  const chatMsgs = sortByTime(chat.messages);
  return (
    <>
      <View>
        {chatMsgs && chatMsgs.length ? (
          chatMsgs.map((message: IMessage, i: number, a: Array<IMessage>) => {
            let date = new Date(message.time as Date);
            let prevDate = new Date(i > 0 ? (a[i - 1].time as Date) : 0);
            let prevAuthor = i > 0 ? idOf(a[i - 1].author) : 0;
            return (
              <Fragment key={i}>
                <MessageDateToPdf date={date} last={prevDate} intl={intl} />
                <View
                  style={pfdStyles.chat_message_wrapper}
                  minPresenceAhead={20}
                >
                  <MessageToPdf
                    message={message}
                    block={block}
                    prevTime={prevDate}
                    prevAuthor={prevAuthor}
                    showLikes={true}
                    intl={intl}
                    dialogue={dialogue}
                  />
                </View>
              </Fragment>
            );
          })
        ) : (
          <Text>{intl.formatMessage({ id: 'DIALOGUE.VIEW.NO_CONTENT' })}</Text>
        )}
      </View>
    </>
  );
}

export async function chatBlockToDocx(
  props: {
    block: IBlock;
  } & ExportProps
): Promise<ISectionOptions> {
  const promise = new Promise<ISectionOptions>(async (resolve, reject) => {
    const { dialogue, block, intl } = props;
    const chat = block.childChatBlock ?? null;
    if (!chat || !chat.messages)
      return { properties: { type: SectionType.CONTINUOUS }, children: [] };
    const chatMsgs = sortByTime(chat.messages);
    const rows: TableRow[] = [];
    if (chatMsgs && chatMsgs.length)
      for (const [i, message] of chatMsgs.entries()) {
        // chatMsgs.forEach((message: IMessage, i: number, a: Array<IMessage>) => {
        let date = new Date(message.time as Date);
        let prevDate = new Date(i > 0 ? (chatMsgs[i - 1].time as Date) : 0);
        let prevAuthor = i > 0 ? idOf(chatMsgs[i - 1].author) : 0;
        let row = messageDateToDocx({ date, last: prevDate, intl });
        if (row) rows.push(row);
        row = await messageToDocx({
          message: message,
          block: block,
          prevTime: prevDate,
          prevAuthor: prevAuthor,
          showLikes: true,
          intl,
          dialogue,
        });
        if (row) rows.push(row);
      }
    resolve({
      properties: { type: SectionType.CONTINUOUS },
      children: [
        new Table({
          width: { size: '100%', type: WidthType.PERCENTAGE },
          borders: TableBorders.NONE,
          rows: rows,
        }),
      ],
    });
  });
  return promise;
}

type ChatBlockTailProps = {
  block: IBlock;
  showDescription?: boolean;
  newReplyTo: IMessage | null;
  setNewReplyTo: Dispatch<SetStateAction<IMessage | null>>;
  setHasNewMsg: Dispatch<SetStateAction<boolean>>;
  stateMan: StateMan;
};

function UnconnectedChatBlockTail(props: ChatBlockTailProps & UserStateProps) {
  const { block, user, userId, setHasNewMsg } = props;
  const [chatMsg, setChatMsg] = useState<string>('');
  const [showSend, setShowSend] = useState(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [file, setFile] = useState<File | undefined>(undefined); // for uploading image
  const [previewSrc, setPreviewSrc] = useState<string | null>(null); // state for storing previewImage
  const uploadTrigger = useRef<MouseEventHandler | undefined>(undefined);
  const submitting = useRef<boolean>(false);

  useEffect(() => {
    submitting.current = false;
    setShowSend(!justWhitespace(chatMsg) || !!previewSrc);
  }, [chatMsg, previewSrc]);

  function handleMsgChange(input: string, hasText: boolean) {
    if (!submitting.current) {
      setShowSend(hasText || !!file);
      setChatMsg(input);
    }
  }

  function submitMessage() {
    if (!(chatMsg || file) || submitting.current) return;
    submitting.current = true;
    dispatch(
      postMessageAsync({
        data: {
          chatBlockContainer: block.childChatBlock?.id,
          content: chatMsg,
          edited: false,
          time: new Date(),
          author: userId,
          replyTo: props.newReplyTo ? props.newReplyTo : null,
          checked: false,
          color: 'none',
          likes: [],
          replies: [],
          files: [],
        },
        author: user,
        file: file,
        stateMan: props.stateMan,
      })
    ).then((response: any) => {
      switch (response.payload?.response.status) {
        case 201:
          break;
        default:
          console.log(
            response,
            response?.payload.response.status,
            response?.payload.response.data?.message
          );
      }
      setHasNewMsg(true);
      setTimeout(() => {
        setHasNewMsg(false);
      }, 100);
      setChatMsg('');
      setShowSend(false);
      props.setNewReplyTo(null);
      setPreviewSrc(null);
      setFile(undefined);
    });
  }

  return (
    <>
      <FileUpload
        types={imageFileTypes}
        currentSrc={null}
        previewSrc={previewSrc}
        defaultImg={null}
        setPreviewSrc={setPreviewSrc}
        withTrigger={true}
        setFile={setFile}
        onDelete={() => {}}
        className={'tail_container'}
        renderTrigger={(trigger) => (uploadTrigger.current = trigger)}
      >
        <div className="msg_tail">
          <DropMenu right={true} up={true}>
            <DropMenu.Trigger>
              <div className="msg_input_plus" />
            </DropMenu.Trigger>
            <DropMenu.Items>
              <div onClick={uploadTrigger.current}>
                <Icon
                  symbol={IconSymbol.picture}
                  className="msg_input_option"
                />
                <div>
                  <FormattedMessage id="DOCUMENTS.UPLOAD.IMAGE" />
                </div>
              </div>
              {IS_DEV ? ( // TODO: implement this
                <>
                  <div>
                    <Icon
                      symbol={IconSymbol.video}
                      className="msg_input_option"
                    />
                    <div>
                      <FormattedMessage id="DOCUMENTS.UPLOAD.VIDEO" />
                    </div>
                  </div>
                  <div>
                    <Icon
                      symbol={IconSymbol.audio}
                      className="msg_input_option"
                    />
                    <div>
                      <FormattedMessage id="DOCUMENTS.UPLOAD.AUDIO" />
                    </div>
                  </div>
                </>
              ) : null}
            </DropMenu.Items>
          </DropMenu>
          <div className="msg_input">
            {props.newReplyTo && (
              <div className="msg_replyto">
                <Author message={props.newReplyTo as IMessage} />
                {(props.newReplyTo as IMessage).content}
                <Icon
                  className="close_btn"
                  symbol={IconSymbol.close}
                  hoverVariant={IconVariant.dark}
                  onClick={() => props.setNewReplyTo(null)}
                  size={18}
                />
              </div>
            )}
            <TextAreaInput
              onChange={handleMsgChange}
              onSubmit={submitMessage}
              placeholder={intl.formatMessage({ id: 'CHAT.NEW_MSG' })}
              value={chatMsg}
              escapeClears={false}
              emojiesBtn={true}
            />
          </div>
          {showSend ? (
            <div className="msg_input_send" onClick={submitMessage} />
          ) : null}
        </div>
      </FileUpload>
    </>
  );
}

const ChatBlockTail = connect(mapUserStateToProps)(UnconnectedChatBlockTail);
export { ChatBlockTail };
