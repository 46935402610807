import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IUser, patchUserData } from 'features/user/userAPI';
import { mutateObjectById } from 'helpers/objects';
import { deleteUser, fetchUsers } from './usersAPI';

export interface IUsersState {
  users: IUser[];
  status: 'idle' | 'loading' | 'failed';
  errors: any;
}

export type UsersStateProps = {
  users: IUser[];
};

export function mapUsersStateToProps(state: RootState): UsersStateProps {
  return {
    users: state.users.users,
  };
}

export const initialState: IUsersState = {
  users: [],
  status: 'idle',
  errors: '',
};

export const fetchUsersAsync: any = createAsyncThunk(
  'users/fetchUsers',
  async () => {
    return { response: await fetchUsers() };
  }
);

export const modifyUserAsync = createAsyncThunk(
  'user/modifyUserData',
  async (
    {
      data,
      newUser,
    }: {
      data: any;
      newUser: boolean;
    },
    thunkAPI
  ) => {
    try {
      return {
        response: await patchUserData({
          id: data.id,
          username: data.username,
          email: data.email,
          roles: data.roles,
          isVerified: data.isVerified,
          moderatesDialogues: data.moderatesDialogues,
          subscribedToProjects: data.subscribedToProjects,
          subscribedToDialogues: data.subscribedToDialogues,
        }),
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  'user/deleteUser',
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      return {
        response: await deleteUser(id),
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    /*  setUsers: (state, action: PayloadAction<IUser>) => {
      state.users = action.payload;
    }, */
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = action.payload.response.data;
      })
      .addCase(fetchUsersAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(modifyUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(modifyUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        let data = action.meta.arg.data;
        let newUser = action.meta.arg.newUser;
        let newUserInfo = {
          email: data.email,
          username: data.username,
          subscribedToProjects: data.subscribedToProjects,
          subscribedToDialogues: data.subscribedToDialogues,
          moderatesDialogues: data.moderatesDialogues,
          id: data.id,
          roles: data.roles,
          isVerified: data.isVerified,
        };
        if (newUser) {
          state.users.push(newUserInfo);
        } else {
          mutateObjectById(state.users, {
            ...newUserInfo,
          });
        }
      })
      .addCase(modifyUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(deleteUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.users = state.users.filter(
          (user) => user.id !== action.meta.arg.id
        );
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      });
  },
});

//export const { setUsers } = usersSlice.actions;

export default usersSlice.reducer;
