import { API, ReadableModelNames, Status } from '@thuas/pd-schemas';
import { store } from 'app/store';
import api from 'helpers/api';
import { Retrieval, Retrieving, callGet } from 'helpers/apiTypes';
import { withoutSeconds } from 'helpers/helpers';
import { prepareForBackend, toArrayOfIdObject } from 'helpers/objects';

export type IProject = API['Project']['read'];
export type IProjectCreate = API['Project']['create'];
export type IProjectUpdate = API['Project']['update'] & {
  dialoguesOverwrite?: { id: number }[];
  subscribersOverwrite?: { id: number }[];
  moderatorsOverwrite?: { id: number }[];
  userFeedbacksOverwrite?: { id: number }[];
};
function prepareForUpdate(project: IProject): IProjectUpdate {
  project.startedAt = withoutSeconds(project.startedAt);
  project.closedAt = withoutSeconds(project.closedAt);
  let updated = prepareForBackend(project, [
    'id',
    'subscribersCount',
    'author',
    'dialogues',
    'subscribers',
    'moderators',
    'userFeedbacks',
  ]) as IProjectUpdate;
  updated.dialoguesOverwrite = toArrayOfIdObject(project.dialogues);
  updated.subscribersOverwrite = toArrayOfIdObject(project.subscribers);
  updated.moderatorsOverwrite = toArrayOfIdObject(project.moderators);
  updated.userFeedbacksOverwrite = toArrayOfIdObject(project.userFeedbacks);
  return updated;
}

function prepareForCreate(project: IProjectCreate): IProjectCreate {
  project.startedAt = withoutSeconds(project.startedAt);
  project.closedAt = withoutSeconds(project.closedAt);
  return project;
}

// patch/user/id->{subscribedToProjects: [1,2,3]}

/*
type IUserUpdate = API['User']['update'] & 
subscribedToProjectAdd?: { id: number }[];
subscribedToProjectRemove?: { id: number }[];
subscribedToDialogueAdd?: { id: number }[];
subscribedToDialogueRemove?: { id: number }[];
moderatesProjectAdd?: { id: number }[];
moderatesProjectRemove?: { id: number }[];
moderatesDialogueAdd?: { id: number }[];
moderatesDialogueRemove?: { id: number }[];

*/
/* 
prepareForUpdate(user: IUserUpdate) {

let updated = prepareForBackend(project, [
    'id',
    'dialogues',
    'projects',
    'subscribedToProject',
    'subscribedToDialogue',
    'moderatesProject',
    'moderatesDialogues',
  ]) as IUserUpdate;

  updates.subscribedToProjectAdd = toArrayOfIdObject(user.subscribedToProject) ==> subscribedToProject: [{id: 123, id: 456}]
}

*/
export const fetchProjects = async () => {
  try {
    // New way ---
    const query = {
      '*': true,
      background: {
        '*': true,
      },
      subscribers: {
        id: true,
        username: true,
      },
      moderators: {
        id: true,
        username: true,
      },
      dialogues: {
        '*': true,
        project: {
          id: true,
        },
        subscribers: {
          id: true,
          username: true,
        },
        moderators: {
          id: true,
          username: true,
        },
        background: {
          '*': true,
        },
        phases: {
          '*': true,
          blocks: {
            id: true,
            childType: true,
            childChatBlock: {
              messages: {
                author: {
                  id: true,
                },
                time: true,
              },
            },
            childLibraryBlock: {
              files: {
                author: {
                  id: true,
                },
                uploadTime: true,
              },
            },
            childListBlock: {
              lists: {
                listItems: {
                  message: {
                    author: {
                      id: true,
                    },
                    time: true,
                    replies: {
                      author: {
                        id: true,
                      },
                      time: true,
                    },
                  },
                },
              },
            },
            childPollBlock: {
              poll: {
                options: {
                  message: {
                    author: {
                      id: true,
                    },
                    time: true,
                  },
                  votes: {
                    voted: true,
                  },
                },
              },
            },
            // Can we add a 'lastEdited: Date' attribute to Document?
            // childDocumentBlock: {
            //   '*': true,
            //   document: {
            //     '*': true,
            //   },
            // },
          },
        },
      },
    } as const satisfies Retrieving<'Project'>;
    const filter = undefined; // '[status=public]';
    const response = await callGet('Project', query, undefined, filter);
    response.data = filterProjects(response.data);
    response.response.data = response.data;

    // ---

    // Old way
    // const response = await api.get(
    //   'Project?and=*,dialogues.(*,subscribers.(id,username),moderators.(id,username),background.*,phases.(*))'
    // );
    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

function filterProjects<
  MN extends ReadableModelNames,
  T extends Retrieving<MN>
>(projects: Retrieval<MN, T>): Retrieval<MN, T> {
  const user = store.getState().user.user;
  if (user.roles?.includes('admin')) return projects;
  if (user.roles?.includes('manager')) return projects;
  if (Array.isArray(projects)) {
    const filtered = projects.filter((p: any) => {
      return (
        p.status === Status.public ||
        (p.status === Status.private &&
          ((p.subscribers &&
            p.subscribers.map((s: any) => s.id).includes(user.id)) ||
            (p.moderators &&
              p.moderators.map((s: any) => s.id).includes(user.id)))) ||
        (p.status === Status.draft &&
          p.moderators &&
          p.moderators.map((s: any) => s.id).includes(user.id))
      );
    }) as Retrieval<MN, T>;
    if (Array.isArray(filtered))
      filtered.forEach((p: any) => {
        p.dialogues = p.dialogues.filter((d: any) => {
          return (
            d.status === Status.public ||
            (d.status === Status.private &&
              ((d.subscribers &&
                d.subscribers.map((s: any) => s.id).includes(user.id)) ||
                (d.moderators &&
                  d.moderators.map((s: any) => s.id).includes(user.id)))) ||
            (d.status === Status.draft &&
              d.moderators &&
              d.moderators.map((s: any) => s.id).includes(user.id))
          );
        });
      });
    return filtered;
  }
  return projects;
}

export const postProjectData = async (data: IProjectCreate) => {
  try {
    const response = await api.post('Project', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchProjectData = async (data: IProject) => {
  try {
    const response = await api.patch(
      'Project/' + data.id,
      prepareForUpdate(data)
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteProjectData = async (id: number) => {
  try {
    const response = await api.delete('Project/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
