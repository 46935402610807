import api from 'helpers/api';
import { Retrieving, callGet } from 'helpers/apiTypes';

type UserRetrieving = Omit<Retrieving<'User'>, 'roles'> & {
  roles?: true | undefined;
};

export async function fetchUserNames() {
  try {
    const query = {
      id: true,
      username: true,
      email: true,
    } as const satisfies Retrieving<'User'>;
    const response = await callGet('User', query);
    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export const deleteUser = async (id: number) => {
  try {
    const response = await api.delete('User/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export async function fetchUsers() {
  try {
    const query = {
      id: true,
      username: true,
      email: true,
      subscribedToProjects: {
        '*': true,
      },
      subscribedToDialogues: {
        '*': true,
        project: {
          id: true,
        },
      },
      moderatesDialogues: {
        '*': true,
        project: {
          id: true,
        },
      },
      avatar: {
        '*': true,
      },
      roles: true,
      isVerified: true,
      messages: { id: true },
      votes: { voted: true },
      likes: { id: true },
      uploadedFiles: { id: true },
    } as const satisfies UserRetrieving;
    const response = await callGet('User', query as any);
    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}
