import {
  Draggable,
  DraggableStateSnapshot,
  DraggableStyle,
} from '@hello-pangea/dnd';
import classNames from 'classnames';
import { CSSProperties, PropsWithChildren, useRef } from 'react';

import { AnyOrderObject } from 'helpers/objects';
import {
  isConsideredLeft,
  relativePos,
  xyOrigin,
  xyPosition,
} from 'helpers/positions';
import { toUniqueId } from 'helpers/sorting';
import { useSortableContext } from './SortableContext';
import { SortableItemType, SortableListInfo } from './SortableList';

import './Sortable.scss';

type SortableItemProps = {
  list: SortableListInfo;
  item: AnyOrderObject;
  itemType: SortableItemType;
  index: number;
  onCanvas?: boolean;
  pos?: xyPosition;
  className?: string;
  withHandle?: boolean;
  disabled?: boolean;
  copySource?: boolean;
  noAnimation?: boolean;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
};

export default function SortableItem(
  props: SortableItemProps & PropsWithChildren
) {
  const {
    list,
    item,
    itemType,
    index,
    onCanvas = false,
    className,
    withHandle = false,
    disabled = false,
    copySource = false,
    noAnimation = false,
    style,
    onClick,
    children,
  } = props;
  const draggableRef = useRef<HTMLDivElement | null>(null);
  const {
    setDragItemType,
    setDragItem,
    setDragSource,
    setDragPos,
    getDropTarget,
    getDropTargetDiv,
    isCopying,
  } = useSortableContext();
  const uniqueId = toUniqueId(
    item.id,
    itemType || SortableItemType.SortableItem
  );

  function getStyle(snapshot: DraggableStateSnapshot, style: DraggableStyle) {
    const { isDragging, draggingOver, isDropAnimating } = snapshot;
    if (isDragging)
      document.body.style.cursor =
        props.copySource || isCopying() ? 'copy' : 'move';
    // rendering on canvas
    if (onCanvas && !isDragging) {
      // rendering a static item on a canvas
      return { left: item.pos?.x, top: item.pos?.y };
    }
    const target = draggingOver ? getDropTarget(draggingOver) : undefined;
    if (
      (isDropAnimating && noAnimation) ||
      (copySource && !target) ||
      (target?.isCanvas && isDropAnimating)
    )
      // skip the animation for cancelled copy sources (e.g., the chat)
      // and for canvas targets
      return { ...style, transition: 'all 0.001s' }; // must not be 0s, see RBD docs
    // in all other cases, use provided style
    return {
      ...style,
      zIndex: isDragging ? 200 : undefined,
      // backgroundColor: isDragging ? 'blue' : undefined,
    };
  }

  return (
    <Draggable draggableId={uniqueId} index={index} isDragDisabled={disabled}>
      {(provided, snapshot, rubric) => {
        const draggableStyle = getStyle(
          snapshot,
          provided.draggableProps.style || {}
        );
        let pos: xyPosition = xyOrigin;
        if (snapshot.isDragging && snapshot.draggingOver) {
          setDragItemType(itemType);
          setDragItem(item);
          setDragSource(list);
          const dragged = draggableRef.current;
          const target = getDropTargetDiv(snapshot.draggingOver);
          if (dragged && target) {
            pos = relativePos(dragged, target);
            setDragPos(pos);
          }
        }
        const makeCopy = copySource || (snapshot.isDragging && isCopying());
        return (
          <>
            <div
              id={uniqueId}
              className={classNames('sortable_item', className, {
                onCanvas: onCanvas,
                dragging: snapshot.isDragging,
                noHandle: !withHandle,
                copying: makeCopy,
                draggingLeft: snapshot.isDragging && isConsideredLeft(pos),
                draggingRight: snapshot.isDragging && !isConsideredLeft(pos),
              })}
              ref={(element) => {
                provided.innerRef(element);
                draggableRef.current = element;
              }}
              {...provided.draggableProps}
              {...(withHandle ? undefined : provided.dragHandleProps)}
              style={{ ...draggableStyle, ...style }}
              onClick={onClick}
            >
              <div className="sortable_wrapper">
                {withHandle && !disabled ? (
                  <div
                    className="sortable_handle"
                    {...provided.dragHandleProps}
                  />
                ) : null}
                {children}
              </div>
            </div>
            {snapshot.isDragging && makeCopy ? (
              <div
                className={classNames(
                  'sortable_item',
                  'sortable_clone',
                  className,
                  {
                    onCanvas: onCanvas,
                    // dragging: snapshot.isDragging,
                    noHandle: !withHandle,
                    copying: makeCopy,
                  }
                )}
                {...provided.draggableProps}
                {...(withHandle ? undefined : provided.dragHandleProps)}
                style={{
                  ...draggableStyle,
                  position: 'relative',
                  zIndex: undefined,
                  top: undefined,
                  left: undefined,
                  transform: 'none !important',
                }}
              >
                <div className="sortable_wrapper">
                  {withHandle ? (
                    <div
                      className="sortable_handle"
                      {...provided.dragHandleProps}
                    />
                  ) : null}
                  {children}
                </div>
              </div>
            ) : null}
          </>
        );
      }}
    </Draggable>
  );
}
