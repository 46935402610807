interface Obj {
  [key: string]: any;
}

export function deepSearchKey(obj: Obj, searchKey: string): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === searchKey) {
        return true;
      }
      const value = obj[key];
      if (
        typeof value === 'object' &&
        value !== null &&
        deepSearchKey(value, searchKey)
      ) {
        return true;
      }
    }
  }
  return false;
}

export function deepSearchKeyValue(
  obj: Obj,
  searchKey: string,
  searchValue: any
): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (key === searchKey && value === searchValue) {
        return true;
      }
      if (
        typeof value === 'object' &&
        value !== null &&
        deepSearchKeyValue(value, searchKey, searchValue)
      ) {
        return true;
      }
    }
  }
  return false;
}

type SearchType = 'array' | 'object' | 'string' | 'number' | 'boolean';

export function deepSearchKeyType(
  obj: Obj,
  searchKey: string,
  searchType: SearchType,
  invert: boolean
): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (key === searchKey && checkValueType(value, searchType, invert)) {
        return true;
      }
      if (
        typeof value === 'object' &&
        value !== null &&
        deepSearchKeyType(value, searchKey, searchType, invert)
      ) {
        return true;
      }
    }
  }
  return false;
}

function checkValueType(
  value: any,
  searchType: SearchType,
  invert: boolean
): boolean {
  switch (searchType) {
    case 'array':
      return invert ? !Array.isArray(value) : Array.isArray(value);
    case 'object':
      return invert ? !isObject(value) : isObject(value);
    case 'string':
      return invert ? typeof value !== 'string' : typeof value === 'string';
    case 'number':
      return invert ? typeof value !== 'number' : typeof value === 'number';
    case 'boolean':
      return invert ? typeof value !== 'boolean' : typeof value === 'boolean';
    default:
      return false;
  }
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function findIndexById(array: any[], id: any): number {
  return array.findIndex((obj) => obj.id === id);
}

export function arraySomeById(array: any[], id: any): boolean {
  if (!array) return false;
  return array.some((item) => item.id === id);
}

export function convertToWebSocketURL(url: string): string {
  const parsedURL = new URL(url);
  const protocol = parsedURL.protocol;
  const webSocketProtocol = protocol === 'https:' ? 'wss:' : 'ws:';

  return `${webSocketProtocol}//${parsedURL.host}`;
}

export function isInBothArrays(
  value: any,
  array1: any[],
  array2: any[]
): boolean {
  return array1.includes(value) && array2.includes(value);
}

export function removeCommonValuesSimple(array1: any[], array2: any[]): any[] {
  return array1.filter((value) => !array2.includes(value));
}

export function removeCommonValuesComplex(array1: any[], array2: any[]): any[] {
  const array2Ids = array2.map((obj) => obj.id);
  return array1.filter((obj) => !array2Ids.includes(obj.id));
}

/*
NOTE: 
This function has been changed/refactored on 25-09-2023, to resolve the issue of the toggling of checkboxes in ListSettings (PR #853). 
If it is noticed that the change is detrimental to other AsyncAPI features, please refactor or revert to the function before 25-09-2023.
- SK
*/
export function removeCommonValuesDeep(array1: any[], array2: any[]): any[] {
  // Use the filter method to create a new array containing elements from array1 that satisfy the given condition.
  return array1.filter((obj1) => {
    // Create an array of matching objects from array2 by comparing their 'id' property with obj1's 'id'.
    const matchingObjects = array2.filter((obj2) => obj2.id === obj1.id);

    // If no matching objects were found in array2, keep obj1 in the result.
    if (matchingObjects.length === 0) {
      return true;
    }

    // Iterate through each matching object in array2.
    for (const obj2 of matchingObjects) {
      // Check if both obj1 and obj2 are objects and if their JSON representations are not equal (deep comparison).
      if (
        typeof obj1 === 'object' &&
        typeof obj2 === 'object' &&
        JSON.stringify(obj1) !== JSON.stringify(obj2)
      ) {
        // If at least one matching object has a different deep structure from obj1, keep obj1 in the result.
        return true;
      }
    }

    // If none of the matching objects had different deep structures from obj1, exclude obj1 from the result.
    return false;
  });
}

// Alternative deepEqual function, can be used instead of JSON.stringify, but not needed right now as JSON.stringify works
function deepEqual(obj1: any, obj2: any) {
  // Check if both objects are the same reference or are primitives
  if (obj1 === obj2) {
    return true;
  }

  // Check if both objects are null or undefined
  if (obj1 == null || obj2 == null) {
    return false;
  }

  // Check if both objects are of different types
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // Check if both objects are arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!deepEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }

  // Check if both objects are objects
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  // If all other checks fail, compare them using strict equality
  return obj1 === obj2;
}

export function findChildInObject(obj: any, accessor: string): any {
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (key === accessor) {
        return obj[key];
      }
      const result = findChildInObject(obj[key], accessor);
      if (result !== null) {
        return result;
      }
    }
  } else if (Array.isArray(obj)) {
    for (const item of obj) {
      const result = findChildInObject(item, accessor);
      if (result !== null) {
        return result;
      }
    }
  }
  return null;
}

export function currentTime() {
  return new Date().toTimeString().split(' ')[0];
}
