import { AlignmentType, IStylesOptions } from 'docx';

const styles: IStylesOptions = {
  paragraphStyles: [
    {
      id: 'Normal',
      name: 'Normal',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        font: 'Arial',
        size: 12 * 2, // 12 pt
      },
      paragraph: {
        spacing: {
          after: 3 * 20, // 3 pt
        },
      },
    },
    {
      id: 'dialogueTitle',
      name: 'dialogue',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 10 * 2,
        bold: true,
      },
      paragraph: {
        spacing: {
          after: 0 * 20,
        },
      },
    },
    {
      id: 'phaseName',
      name: 'phase',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 10 * 2,
        italics: true,
      },
      paragraph: {
        spacing: {
          after: 6 * 20,
        },
      },
    },
    {
      id: 'date',
      name: 'date',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 10 * 2,
        italics: true,
      },
      paragraph: {
        spacing: {
          after: 6 * 20,
        },
      },
    },
    {
      id: 'blockName',
      name: 'block',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 12 * 2,
        bold: true,
      },
      paragraph: {
        spacing: {
          after: 0 * 20,
        },
      },
    },
    {
      id: 'description',
      name: 'description',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 12 * 2,
      },
      paragraph: {
        spacing: {
          after: 6 * 20,
        },
      },
    },
    {
      id: 'spaced',
      name: 'spaced',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      paragraph: {
        spacing: {
          after: 12 * 20,
        },
      },
    },
    {
      id: 'messageDate',
      name: 'message date',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 8 * 2,
      },
      paragraph: {
        spacing: {
          after: 0 * 20,
        },
        alignment: AlignmentType.CENTER,
      },
    },
    {
      id: 'messageAuthor',
      name: 'message author',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 9 * 2,
      },
      paragraph: {
        spacing: {
          before: 6 * 20,
        },
      },
    },
    {
      id: 'messageTime',
      name: 'message time',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 8 * 2,
      },
      paragraph: {
        spacing: {
          before: 6 * 20,
        },
      },
    },
    {
      id: 'messageLikes',
      name: 'message likes',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 8 * 2,
      },
      paragraph: {
        spacing: {
          before: 6 * 20,
        },
      },
    },
    {
      id: 'message',
      name: 'message',
      basedOn: 'Normal',
      next: 'message',
      quickFormat: false,
      run: {
        size: 12 * 2,
      },
      paragraph: {
        spacing: {
          before: 3 * 20,
          after: 6 * 20,
        },
      },
    },
    {
      id: 'message_pro',
      name: 'message pro',
      basedOn: 'message',
      next: 'message_pro',
      quickFormat: false,
      paragraph: {
        indent: {
          right: 120 * 20,
        },
      },
    },
    {
      id: 'message_con',
      name: 'message con',
      basedOn: 'message',
      next: 'message_con',
      quickFormat: false,
      paragraph: {
        alignment: AlignmentType.END,
        indent: {
          left: 120 * 20,
        },
      },
    },
    {
      id: 'messageReplyTo',
      name: 'message reply to',
      basedOn: 'message',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 10 * 2,
      },
      paragraph: {
        spacing: {
          before: 3 * 20,
          after: 3 * 20,
        },
      },
    },
    {
      id: 'listHeader',
      name: 'list header',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 12 * 2,
        bold: true,
      },
      paragraph: {
        spacing: {
          before: 12 * 20,
          after: 3 * 20,
        },
      },
    },
    {
      id: 'gauge',
      name: 'gauge',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: false,
      run: {
        size: 5 * 2,
      },
      paragraph: {
        spacing: {
          before: 0 * 20,
          after: 0 * 20,
        },
      },
    },
    {
      id: 'Title',
      name: 'Title',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: 36,
        bold: true,
      },
      paragraph: {
        spacing: {
          before: 12 * 20,
          after: 12 * 20,
        },
      },
    },
    {
      id: 'Heading1',
      name: 'Heading 1',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: 20 * 2,
        bold: true,
      },
      paragraph: {
        spacing: {
          after: 6 * 20,
        },
      },
    },
    {
      id: 'Heading2',
      name: 'Heading 2',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: 16 * 2,
        bold: true,
      },
      paragraph: {
        spacing: {
          before: 3 * 20,
          after: 6 * 20,
        },
      },
    },
  ],
};

export default styles;
