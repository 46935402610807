// takes an array of percentages that add up to 100%
// returns them rounded off, but still adding up to 100%
export function roundPercentagesToSum100(input: number[]): number[] {
  if (input.reduce((sum, value) => sum + value, 0) === 0)
    return input.map(() => 0);
  const roundedPercentages: number[] = input.map(Math.floor);
  const remainder =
    100 - roundedPercentages.reduce((sum, value) => sum + value, 0);
  // If there is a remainder, distribute it by rounding up the values with the highest fractional parts
  for (let i = 0; i < remainder; i++) {
    const fractionalParts = input.map(
      (value, index) => value - roundedPercentages[index]
    );
    const maxIndex = fractionalParts.indexOf(Math.max(...fractionalParts));
    roundedPercentages[maxIndex]++;
  }
  return roundedPercentages;
}

export function calculatePercentages(input: number[]): number[] {
  const sum = input.reduce((acc, value) => acc + value, 0);
  if (sum === 0) {
    // Avoid division by zero if the sum is zero
    return input.map(() => 0);
  }
  const percentages = input.map((value) => (value / sum) * 100);
  return percentages;
}

export function calculateRoundedPercentages(input: number[]): number[] {
  return roundPercentagesToSum100(calculatePercentages(input));
}
