import {
  Dispatch,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  doFormMsg,
  FormMessage,
  FormMsg,
  NoFormMsg,
} from 'components/forms/FormMessage';
import { showModal } from 'components/forms/ModalDialog';
import Icon, {
  getIconUrl,
  IconSymbol,
  IconVariant,
} from 'components/icons/Icon';

import classNames from 'classnames';
import {
  AcceptedFileType,
  AcceptedMimeType,
  getFileTypeInfo,
  sizeAllowed,
  typesAllowed,
} from 'features/files/fileSlice';
import './FileUpload.scss';

export type ImageSize = {
  width: number;
  height: number;
};
type PreviewProps = {
  previewSrc: string | null;
  defaultImg?: JSX.Element | null;
  size?: ImageSize;
};

function Preview({ previewSrc, defaultImg, size }: PreviewProps) {
  const [imgSize, setImgSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    var image = new Image();
    if (previewSrc) {
      image.src = previewSrc;
      // console.log('image.height: ', image.height);
      setImgSize({ width: image.width, height: image.height });
    }
  }, [previewSrc]);

  return previewSrc ? (
    <div
      className="preview_image"
      style={{
        backgroundImage: `url(${previewSrc})`,
        height: size?.height ?? imgSize.width,
        width: size?.width ?? imgSize.height,
      }}
    />
  ) : (
    <div className="preview_image">
      {defaultImg !== undefined ? (
        defaultImg
      ) : (
        <Icon symbol={IconSymbol.user} className="default_user"></Icon>
      )}
    </div>
  );
}

type FIleUploadProps = {
  types: AcceptedFileType[];
  currentSrc: string | null;
  previewSrc: string | null;
  setPreviewSrc: Dispatch<SetStateAction<string | null>>;
  noPreview?: boolean;
  previewMimeType?: AcceptedMimeType;
  setPreviewMimeType?: Dispatch<SetStateAction<AcceptedMimeType>>;
  defaultImg?: JSX.Element | null;
  showMsg?: (m: FormMsg) => void;
  setFile: Dispatch<SetStateAction<File | undefined>>;
  onDelete: () => void;
  confirmationText?: string | JSX.Element;
  withTrigger?: boolean;
  showTrigger?: boolean; // show trigger also when no preview available
  className?: string;
  disabled?: boolean;
  // renderTrigger?: (trigger: JSX.Element) => {};
  renderTrigger?: (onClick: MouseEventHandler) => {};
  size?: number;
  children?: ReactNode;
};

export default function FileUpload(props: FIleUploadProps & PropsWithChildren) {
  const {
    types,
    showMsg,
    setFile,
    currentSrc,
    previewSrc,
    setPreviewSrc,
    noPreview,
    previewMimeType,
    setPreviewMimeType,
    defaultImg,
    onDelete,
    confirmationText,
    withTrigger = true,
    showTrigger = false,
    className,
    disabled = false,
    renderTrigger,
    children,
  } = props;
  const [imgDropped, setImgDropped] = useState(false);
  const dropRef = useRef<HTMLDivElement | null>(null); // ref for managing the hover state of droppable area
  const [mimeType, setMimeType] = useState<AcceptedMimeType>(
    AcceptedMimeType.image
  );
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const intl = useIntl();
  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  const showFormMsg = showMsg || doFormMsg;

  useEffect(() => {
    if (previewSrc) {
      if ((previewMimeType ?? mimeType) === AcceptedMimeType.image) {
        const image = new Image();
        image.onload = () => {
          setSize({ width: image.width, height: image.height });
        };
        image.src = previewSrc;
      } else {
        setSize({ width: 48, height: 48 });
      }
    } else {
      setSize({ width: 0, height: 0 });
    }
  }, [mimeType, previewMimeType, previewSrc]);

  function onDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    doFormMsg(NoFormMsg, setFormMsg);
    if (acceptedFiles.length) {
      const [uploadedFile] = acceptedFiles;
      const max = sizeAllowed(types, uploadedFile.type);
      if (uploadedFile.size > max) {
        showFormMsg(
          {
            message: intl.formatMessage(
              { id: 'FILES.UPLOAD.TOO_LARGE' },
              { sizeallowed: max / 1_000_000 }
            ),
            success: false,
          },
          setFormMsg
        );
      } else {
        setFile(uploadedFile);
        const ftInfo = getFileTypeInfo(uploadedFile);
        setPreviewMimeType
          ? setPreviewMimeType(ftInfo.mime)
          : setMimeType(ftInfo.mime);
        switch (ftInfo.mime) {
          case AcceptedMimeType.image:
            const fileReader = new FileReader();
            fileReader.onload = () => {
              setPreviewSrc(fileReader.result as string);
              // var image = new Image();
              // image.src = fileReader.result as string;
              // setSize({ width: image.width, height: image.height });
            };
            fileReader.readAsDataURL(uploadedFile);
            break;
          case AcceptedMimeType.doc:
          case AcceptedMimeType.docx:
          default:
            setPreviewSrc(getIconUrl(ftInfo.icon));
        }
        setImgDropped(true);
      }
    } else if (fileRejections.length > 0) {
      showFormMsg(
        {
          message: intl.formatMessage(
            { id: 'FILES.UPLOAD.WRONG_TYPE' },
            { typesallowed: typesAllowed(types) }
          ),
          success: false,
        },
        setFormMsg
      );
    }
    if (dropRef.current) dropRef.current.classList.remove('targeted');
  }

  // update border on hover over file drop zone
  const updateStyle = (
    e: React.DragEvent<HTMLElement>,
    draggedOver: boolean
  ) => {
    if (!dropRef.current) return;
    if (
      !draggedOver &&
      dropRef.current.contains(e.relatedTarget as HTMLElement)
    )
      return;
    if (draggedOver) dropRef.current.classList.add('targeted');
    else dropRef.current.classList.remove('targeted');
  };

  function removeImage(e: React.MouseEvent<HTMLDivElement>) {
    showFormMsg(NoFormMsg, setFormMsg);
    e.stopPropagation();
    function doDelete(): boolean {
      if (imgDropped) {
        // restore the original preview if there was one
        setPreviewSrc(currentSrc);
        setImgDropped(false);
      } else {
        // remove the preview
        setPreviewSrc(null);
        // tell the parent to delete the file
        onDelete && onDelete();
      }
      // remove any uploaded file
      setFile(undefined);
      return true;
    }
    if (!imgDropped) {
      // we're deleting the current file, please confirm
      showModal(
        null,
        <>
          <div className="title">
            {confirmationText || (
              <FormattedMessage id="GENERAL.DELETE.FILE_CNF" />
            )}
          </div>
          <Icon symbol={IconSymbol.alert} variant={IconVariant.accent} />
          <div className="text">
            <FormattedMessage id="GENERAL.DELETE.NOTICE" />
          </div>
        </>,
        {
          intl: intl,
          className: 'pd_alert',
          submitBtnText: <FormattedMessage id="X.DELETE" />,
          onSubmit: doDelete,
          editing: true,
          cancelDefault: true,
        }
      );
    } else doDelete(); // we're only deleting a candidate upload, so just do it
  }

  return (
    <>
      <Dropzone
        accept={types.reduce((acc, t) => ({ ...acc, [t.mime]: t.ext }), {})}
        onDrop={onDrop}
        onDragEnter={(e: React.DragEvent<HTMLElement>) => updateStyle(e, true)}
        // onDragOver={(e: React.DragEvent<HTMLElement>) => updateStyle(e, true)}
        onDragLeave={(e: React.DragEvent<HTMLElement>) => updateStyle(e, false)}
        noClick={!(withTrigger && !renderTrigger)}
        disabled={disabled}
        multiple={false}
      >
        {({ getRootProps, getInputProps, open }) => {
          if (renderTrigger) renderTrigger(open);
          return (
            <>
              <div
                {...getRootProps({
                  className: classNames(className, 'dropzone', {
                    dropzone_image: !!previewSrc,
                  }),
                })}
                ref={dropRef}
              >
                {noPreview ? null : (
                  <div className="dropzone_container">
                    {previewSrc ? (
                      <div
                        className="preview_image"
                        style={{
                          backgroundImage: `url(${previewSrc})`,
                          height:
                            props.size ??
                            (size.height ? size.height : undefined),
                          width:
                            props.size ?? (size.width ? size.width : undefined),
                        }}
                      />
                    ) : (
                      <div
                        className="preview_image"
                        style={{
                          height:
                            props.size ??
                            (size.height ? size.height : undefined),
                          width:
                            props.size ?? (size.width ? size.width : undefined),
                        }}
                      >
                        {defaultImg !== undefined ? (
                          defaultImg
                        ) : (
                          <Icon
                            symbol={IconSymbol.user}
                            className="default_user"
                            size={props.size}
                          />
                        )}
                      </div>
                    )}
                    <>
                      {formMsg ? (
                        <FormMessage
                          message={formMsg.message}
                          success={formMsg.success}
                          timeout={5000}
                          setter={setFormMsg}
                        />
                      ) : null}
                    </>
                    <input {...getInputProps()} />
                    {!disabled && withTrigger && (showTrigger || previewSrc) ? (
                      <>
                        <div
                          onClick={open}
                          className="dropzone_trigger"
                          style={{ cursor: 'Pointer' }}
                        />
                      </>
                    ) : null}
                    {!disabled && previewSrc && (
                      <div className="dropzone_bin" onClick={removeImage} />
                    )}
                  </div>
                )}
                {children}
              </div>
            </>
          );
        }}
      </Dropzone>
    </>
  );
}

FileUpload.Preview = Preview;
