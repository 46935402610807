import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { IPoll, postPoll } from 'features/poll/pollAPI';
import { IPollBlock, patchPollBlock } from './pollBlockAPI';

export const emptyPollBlock: IPollBlock = {
  poll: undefined,
};

export async function makePoll(cpb: IPollBlock, poll: IPoll) {
  let res = await postPoll({ ...poll, parent: cpb });
  if (res.status >= 300) throw new Error('Error saving new poll to database');
  poll.id = res.data._id;
  res.data.poll = poll;
  return res;
}

export const patchPollBlockAsync = createAsyncThunk(
  'poll/patchPollBlock',
  async (
    {
      data,
      stateMan,
      id,
    }: {
      stateMan: StateMan;
      data: any;
      id: number;
    },
    thunkAPI
  ) => {
    try {
      return { response: await patchPollBlock(id, data), stateMan: stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export function addPollBlockCases(
  builder: ActionReducerMapBuilder<IDialogueState>
) {
  builder
    .addCase(patchPollBlockAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchPollBlockAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const obj = action.meta.arg.stateMan(state);
      const childPollBlock = obj.childPollBlock;
      Object.assign(childPollBlock, action.meta.arg.data);
    })
    .addCase(patchPollBlockAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });
}
