import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { prepareForBackend } from 'helpers/objects';

export type IPoll = API['Poll']['read'];
export type IPollCreate = API['Poll']['create'];
type IPollUpdate = API['Poll']['update'];

export type IOption = API['Option']['read'];
export type IOptionCreate = API['Option']['create'];

export type IVote = API['Vote']['read'];
export type IVoteCreate = API['Vote']['create'];

function prepareForUpdate(poll: IPoll): IPollUpdate {
  return prepareForBackend(poll, ['id']) as IPollUpdate;
}
function prepareForCreate(poll: IPoll): IPollCreate {
  return prepareForBackend(poll, ['id', 'options']) as IPollCreate;
}

export const postPoll = async (data: IPoll) => {
  try {
    const response = await api.post('Poll/', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchPoll = async (data: IPoll) => {
  try {
    // const response = await api.patch('Poll/' + data.id, prepareForUpdate(data));
    const response = await api.patch('Poll/' + data.id, prepareForUpdate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deletePoll = async (id: number) => {
  try {
    // const response = await api.patch('List/' + data.id, prepareForUpdate(data));
    const response = await api.delete('Poll/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const postOption = async (data: IOption & IOptionCreate) => {
  try {
    const response = await api.post('Option/', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchOption = async (data: IOption) => {
  try {
    const response = await api.patch(
      'Option/' + data.id,
      prepareForUpdate(data)
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteOption = async (id: number) => {
  try {
    const response = await api.delete('Option/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const postVote = async (data: IVoteCreate) => {
  try {
    const response = await api.post('Vote/', data);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteVote = async (id: number) => {
  try {
    const response = await api.delete('Vote/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
