import { useIntl } from 'react-intl';

export function useRandomName(exclude?: string[]): () => string {
  const intl = useIntl();
  const excludeNames = exclude ?? [];
  const plants = intl
    .formatMessage({ id: 'USERS.ANONYMOUS.NAMES' })
    .split(',')
    .map((nm) => nm.trim())
    .filter((nm) => !excludeNames.includes(nm));
  return () => {
    const randomIndex: number = Math.floor(Math.random() * plants.length);
    return plants[randomIndex];
  };
}
