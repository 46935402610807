import {
  BeforeCapture,
  DragDropContext,
  DragStart,
  DragUpdate,
  DropResult,
  ResponderProvided,
  useMouseSensor,
  useTouchSensor,
} from '@hello-pangea/dnd';
import { xyPosition } from 'helpers/positions';
import { PropsWithChildren } from 'react';
import { useSortableContext } from './SortableContext';

export type DroppedInfo = {
  droppableId: string;
  index: number;
};

type SortableContainerProps = {
  reorder: (result: DropResult, newPos: xyPosition, isCopying: boolean) => void;
  onDragUpdate?: (update: DragUpdate, provided: ResponderProvided) => void;
};

export default function SortableContainer(
  props: SortableContainerProps & PropsWithChildren
) {
  const { reorder } = props;
  const { setDragId, getDragPos, isCopying, setIsCopying } =
    useSortableContext();

  function onBeforeCapture(before: BeforeCapture) {}

  function onBeforeDragStart(start: DragStart) {}

  function onDragStart(start: DragStart, provided: ResponderProvided) {
    // onDragStart needs to trigger a re-render of the SortableList,
    // so it can establish if it should be disabled or not, which it does based on dragItem in SortableContext.
    // So we set the dragId (a useState var) in SortableContext to trigger an update of the SortableContext
    // and thus the re-rendering of SortableList.
    // The dragId is actually not used for anything else.
    setDragId(start.draggableId);
    setIsCopying(false);
  }

  function onDragUpdate(update: DragUpdate, provided: ResponderProvided) {
    props.onDragUpdate && props.onDragUpdate(update, provided);
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    document.body.style.cursor = 'auto'; // cursor is set in SortableItem - getStyle function
    if (!result.destination) {
      setDragId(null);
      return;
    }
    const pos = getDragPos();
    reorder(result, pos, isCopying());
    setDragId(null);
    setIsCopying(false);
  }

  return (
    <DragDropContext
      enableDefaultSensors={false}
      sensors={[useMouseSensor, useTouchSensor]}
      onBeforeCapture={onBeforeCapture}
      onBeforeDragStart={onBeforeDragStart}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
      onDragEnd={onDragEnd}
    >
      {props.children}
    </DragDropContext>
  );
}
