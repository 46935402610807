import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';

export type IDocument = API['Document']['read'];
export type IDocumentCreate = API['Document']['create'];
export type IDocumentUpdate = API['Document']['update'];

export const postDocument = async (data: IDocumentCreate) => {
  try {
    const response = await api.post('Document', data);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchDocument = async (data: IDocument, id: number) => {
  try {
    const response = await api.patch(`Document/${id}`, data);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteDocument = async (id: number) => {
  try {
    const response = await api.delete(`Document/${id}`);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
