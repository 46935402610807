import classNames from 'classnames';
import { LocaleCode } from 'helpers/intl/messages';
import './Logo.scss';

export type LogoProps = {
  locale?: LocaleCode;
  bg?: boolean;
};
export function Logo({ locale = 'nl', bg = false }: LogoProps) {
  // const bgurl = `url('/img/pd_logo_${locale}_${bg ? 'bg_w' : 'bg_g'}.svg')`;
  const bgurl = `url('/img/pd_logo_${'nl'}_${bg ? 'bg_w' : 'bg_g'}.svg')`;

  return (
    <div
      className={classNames('logo')}
      style={
        {
          '--pd-logo-fn': bgurl,
        } as React.CSSProperties
      }
    />
  );
}
