import en from './messages_en';
import nl from './messages_nl';

export type LocaleCode = 'en' | 'nl';

export interface ITranslations {
  [key: string]: any;
}

export const messages: ITranslations = {
  en: en,
  nl: nl,
};

/* from: https://formatjs.io/docs/react-intl/#typing-message-ids-and-locale */
declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: keyof typeof messages;
    }
  }
}
declare global {
  namespace FormatjsIntl {
    interface IntlConfig {
      locale: LocaleCode;
    }
  }
}

export function flattenMessages(nestedMessages: ITranslations, prefix = '') {
  if (nestedMessages) {
    return Object.keys(nestedMessages).reduce(
      (messages: ITranslations, key) => {
        let value = nestedMessages[key];
        let prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
          messages[prefixedKey] = value;
        } else {
          Object.assign(messages, flattenMessages(value, prefixedKey));
        }
        return messages;
      },
      {}
    );
  } else return {};
}

export function unflattenMessages(
  flattenedMessages: ITranslations,
  refMessages: ITranslations
) {
  if (flattenedMessages) {
    const res: ITranslations = {};
    for (const key of Object.keys(flattenedMessages)) {
      if (flattenedMessages[key]) {
        const path = key.split('.');
        let currentObject = res;
        let currentRef = refMessages;
        for (let i = 0; i < path.length; i++) {
          const id = path[i];
          const isLast = i === path.length - 1;
          if (!(id in currentObject)) {
            if (isLast) currentObject[id] = currentRef[id];
            else currentObject[id] = {};
          }
          currentObject = currentObject[id];
          currentRef = currentRef[id];
        }
      }
    }
    return res;
  }
}

export function stringifyWithoutQuotes(
  obj: any,
  space?: string | number
): string {
  const spaces = typeof space === 'number' ? ' '.repeat(space) : space || '';
  const escapeSingleQuotes = (str: string): string => {
    return str.replace(/'/g, "\\'");
  };
  const serialize = (o: any, indent: string = ''): string => {
    if (typeof o !== 'object' || o === null) {
      if (typeof o === 'string') {
        return `'${escapeSingleQuotes(o)}'`;
      }
      return String(o);
    }
    const lines: string[] = [];
    for (const key in o) {
      if (o.hasOwnProperty(key)) {
        const value = o[key];
        const serializedValue = serialize(value, indent + spaces);
        lines.push(`${indent}${key}: ${serializedValue}`);
      }
    }
    return `{\n${lines.join(',\n')}\n${indent}}`;
  };
  return serialize(obj, spaces);
}
