import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Status } from '@thuas/pd-schemas/built/generated/api-types';
import {
  arraySomeById,
  deepSearchKey,
  deepSearchKeyType,
  findIndexById,
} from 'app/AsyncAPI/helpers';
import { RootState } from 'app/store';
import { getBlockAndRelatedData } from 'features/block/blockAPI';
import {
  BlockType,
  addBlockCases,
  addBlockReducers,
  emptyBlock,
  postBlockAsync,
} from 'features/block/blockSlice';
import { addListBlockCases } from 'features/block/listBlockSlice';
import { addPollBlockCases } from 'features/block/pollBlockSlice';
import { addSudokuBlockCases } from 'features/block/sudokuBlockSlice';
import { deleteFile, postFile } from 'features/files/fileAPI';
import {
  FolderLocation,
  addFileCases,
  getFolderName,
} from 'features/files/fileSlice';
import {
  addListItemCases,
  addListItemReducers,
} from 'features/list/listItemSlice';
import { addListCases } from 'features/list/listSlice';
import { addLikeCases } from 'features/message/likeSlice';
import { addMessageCases } from 'features/message/messageSlice';
import {
  IPhase,
  deletePhaseData,
  postPhaseData,
} from 'features/phase/phaseAPI';
import { addPhaseCases, patchPhaseAsync } from 'features/phase/phaseSlice';
import { addOptionReducers, addPollCases } from 'features/poll/pollSlice';
import { addSudokuCases } from 'features/sudoku/sudokuSlice';
import { IAuthorName } from 'features/user/userAPI';
import { getAuthors } from 'features/user/userSlice';
import { generateUuidCode } from 'helpers/helpers';
import { pickPrimitiveProps, updateObject } from 'helpers/objects';
import { getOrderForLast, sortBy } from 'helpers/sorting';
import { IntlShape } from 'react-intl';
import {
  IDialogue,
  IDialogueCreate,
  fetchDialogue,
  patchDialogueData,
  postDialogueData,
} from './dialogueAPI';

type Status = (typeof Status)[keyof typeof Status];

export interface IDialogueState {
  dialogue: IDialogue;
  authors: IAuthorName[];
  status: 'idle' | 'loading' | 'failed';
  errors: any;
}

export type DialogueStateProps = {
  dialogue: IDialogue;
  authors: IAuthorName[];
};

export function mapDialogueStateToProps(state: RootState): DialogueStateProps {
  return {
    dialogue: state.dialogue.dialogue,
    authors: state.dialogue.authors,
  };
}

const initialState: IDialogueState = {
  dialogue: {
    id: 0,
    order: '',
    title: '',
    description: '',
    goal: '',
    status: Status.draft,
    keywords: [],
    userFeedbacks: [],
    project: { id: 0 },
    subscribers: [],
    moderators: [],
    background: null,
    phases: [],
    startedAt: null,
    closedAt: null,
  },
  status: 'idle',
  errors: '',
  authors: [],
};

export const emptyDialogue: IDialogueCreate = {
  title: '',
  project: 0,
  description: '',
  goal: '',
  order: '',
  background: null,
  keywords: [],
  status: Status.draft,
  subscribers: [],
  moderators: [],
  userFeedbacks: [],
  phases: [],
  registrationCode: generateUuidCode(),
  guestAccessCode: generateUuidCode(),
  allowGuestAccess: false,
};

export type StateMan = (o: any) => any;

export const fetchDialogueAsync = createAsyncThunk(
  'dialogue/fetchDialogue',
  async (id: number, thunkAPI) => {
    try {
      return fetchDialogue(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const postDialogueAsync = createAsyncThunk(
  'dialogue/postDialogueData',
  async (
    {
      data,
      stateMan,
    }: {
      data: IDialogue;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await postDialogueData(data), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const patchDialogueAsync = createAsyncThunk(
  'dialogue/patchDialogueData',
  async (
    {
      data,
      file,
      dialogue,
      stateMan,
    }: {
      data: IDialogue;
      file?: File;
      dialogue?: IDialogue;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      if (file || data.background === null) {
        // we may be deleting the avatar
        // 1. if there was an avatar, it needs to be deleted
        if (dialogue?.background) {
          // 1.a from storage: TODO
          // 1.b from the db
          if (typeof dialogue.background === 'object')
            await deleteFile(dialogue.background.id);
          else await deleteFile(dialogue.background);
        }
      }
      if (file) {
        // we have a new avatar
        // 2. upload the new file
        const fd = await postFile(file, {
          description: 'Dialogue image',
          folder: getFolderName(FolderLocation.Dialogue),
          order: '',
          size: file.size,
          mimeType: file.type,
          uploadTime: new Date(),
        });
        // 3. keep the new file's id and uri
        data.background = { id: fd.data._id, uri: fd.data.message };
      }
      const res = { response: await patchDialogueData(data), stateMan };
      return res;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const postPatchAndDeletePhases = createAsyncThunk(
  'dialogue/postPatchAndDeletePhases',
  async (
    {
      phases,
      deletedPhaseIds,
      stateMan,
      intl,
    }: {
      phases: IPhase[];
      deletedPhaseIds: number[];
      stateMan: StateMan;
      intl: IntlShape;
    },
    thunkAPI
  ) => {
    try {
      const newPhases = phases.filter((l) => l.id !== undefined && l.id < 0);
      const oldPhases = phases.filter(
        (l) =>
          l.id !== undefined && l.id >= 0 && !deletedPhaseIds.includes(l.id)
      );
      let promises = deletedPhaseIds.map(async (id) => {
        if (id < 0) return true;
        const res = await deletePhaseData(id);
        if (res.status >= 300)
          throw new Error('Error removing phase from database');
        return res;
      });
      await Promise.all(promises);
      promises = newPhases.map(async (ph, i) => {
        // post the phase, but without any blocks in it
        const res = await postPhaseData({ ...ph, blocks: [] });
        if (res.status >= 300)
          throw new Error('Error saving new phase to database');
        else {
          newPhases[i].id = res.data._id;
          if (ph.fixed) {
            const libBlockData = {
              ...emptyBlock,
              name: intl.formatMessage({ id: 'DOCUMENTS.LIBRARY' }),
              childType: BlockType.Library,
              phase: ph,
              order: getOrderForLast([]),
            };
            thunkAPI.dispatch(
              postBlockAsync({
                stateMan: stateMan,
                data: libBlockData,
              })
            );
          }
          return res;
        }
      });
      await Promise.all(promises);
      oldPhases.map((ph) =>
        thunkAPI.dispatch(patchPhaseAsync({ data: ph, stateMan }))
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const asyncApiDialoguesNew = createAsyncThunk(
  '/dialogues/asyncApiDialoguesNew',
  async ({ data }: { data: any }) => {
    return data;
  }
);
export const asyncApiDialoguesUpdated = createAsyncThunk(
  '/dialogues/asyncApiDialoguesUpdated',
  async ({ data }: { data: any }) => {
    // See asyncApiDialoguesUpdated.fulfilled for explanation

    let extraData;

    const block =
      data?.updated?.phases?.updated?.[0]?.updated?.blocks?.new?.[0];

    if (block != null) {
      extraData = (await getBlockAndRelatedData(block.id)).data;
    }

    return { data, extraData };
  }
);
export const asyncApiDialoguesRemoved = createAsyncThunk(
  '/dialogues/asyncApiDialoguesRemoved',
  async ({ data }: { data: any }) => {
    return data;
  }
);

export const dialogueSlice = createSlice({
  name: 'dialogue',
  initialState,
  reducers: {
    resetDialogue: (state) => {
      return initialState;
    },
    ...addBlockReducers(),
    ...addListItemReducers(),
    ...addOptionReducers(),
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchDialogueAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDialogueAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dialogue = action.payload?.data;
        const authors = getAuthors(action.payload?.data);
        state.authors = authors;
        if (state.dialogue.subscribers)
          state.dialogue.subscribers = sortBy(
            state.dialogue.subscribers,
            'username'
          );
      })
      .addCase(fetchDialogueAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(postDialogueAsync.pending, (state, action) => {
        state.status = 'loading';
        state.errors = action.payload;
      })
      .addCase(postDialogueAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const stateObj = action.meta.arg.stateMan(state);
        if (stateObj.hasOwnProperty('dialogues')) {
          const id = action.payload.response.data._id;
          stateObj.dialogues.push({ ...action.meta.arg.data, id } as IDialogue);
        }
      })
      .addCase(postDialogueAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(patchDialogueAsync.pending, (state, action) => {
        state.status = 'loading';
        state.errors = action.payload;
      })
      .addCase(patchDialogueAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const stateObj = action.meta.arg.stateMan(state);
        let dlg: IDialogue;
        if (stateObj.hasOwnProperty('dialogues')) {
          const dlgs = stateObj.dialogues;
          const id = action.meta.arg.data.id;
          dlg = dlgs.find((d: any) => d.id === id);
        } else dlg = stateObj;
        Object.assign(dlg, {
          ...dlg,
          ...pickPrimitiveProps(action.meta.arg.data),
          subscribers: action.meta.arg.data.subscribers,
          moderators: action.meta.arg.data.moderators,
          background: action.meta.arg.data.background || dlg.background,
        });
      })
      .addCase(patchDialogueAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(postPatchAndDeletePhases.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(postPatchAndDeletePhases.pending, (state, action) => {
        state.status = 'loading';
        state.errors = action.payload;
      })
      .addCase(postPatchAndDeletePhases.fulfilled, (state, action) => {
        state.status = 'idle';
        const stateObj = action.meta.arg.stateMan(state);
        const dialogue = stateObj;
        if (dialogue) dialogue.phases = [...action.meta.arg.phases];
      })

      .addCase(asyncApiDialoguesNew.fulfilled, (state, action) => {})
      .addCase(asyncApiDialoguesUpdated.fulfilled, (state, action) => {
        const payload = action.meta.arg.data;
        const extraData = action.payload.extraData;

        const rootUpdated = payload.updated;

        const phases = state.dialogue.phases!;

        if (deepSearchKey(rootUpdated, 'phases')) {
          const payloadPhases = rootUpdated.phases;

          const isBlocks = deepSearchKeyType(
            payloadPhases,
            'blocks',
            'array',
            true
          );

          if (!isBlocks && deepSearchKey(payloadPhases, 'new')) {
            for (const newPhase of payloadPhases.new) {
              if (!arraySomeById(phases, newPhase.id)) {
                phases.push(newPhase);
              }
            }
          }

          if (deepSearchKey(payloadPhases, 'updated')) {
            for (const updatedPhase of payloadPhases.updated) {
              const phaseIndex = findIndexById(phases, updatedPhase.id);

              if (isBlocks) {
                const updatedBlocks = updatedPhase.updated.blocks;

                const stateBlocks = phases[phaseIndex].blocks!;

                /*
                What is it: 
                  A 'new' / 'add' equivalent, but with a API GET call instead of fully via AsyncAPI.
                  I implemented a unorthodox way of adding new Blocks via AsyncAPI. 
                  Namely: make an API GET call for the newly created Block and its children and then apply that data to the state. 
                  The AsyncAPI is only used to notify that there is 'a new block + children' available.
                
                Why?:
                  The race conditions, the delay, order of execution and the process 
                  of how we create Blocks makes it very difficult and inconsistent to do that purely via the AsyncAPI...

                - SK

                (see also: asyncApiDialoguesUpdated)
                */
                if (
                  extraData != null &&
                  !arraySomeById(stateBlocks, extraData.id)
                ) {
                  stateBlocks.push(extraData);
                }

                if (
                  extraData == null &&
                  deepSearchKey(updatedBlocks, 'updated')
                ) {
                  for (const block of updatedBlocks.updated) {
                    const blockIndex = findIndexById(stateBlocks, block.id);

                    stateBlocks[blockIndex] = updateObject(
                      stateBlocks[blockIndex],
                      block.updated
                    );

                    if (block.updated.childListBlock != null) {
                      stateBlocks[blockIndex].childListBlock = updateObject(
                        stateBlocks[blockIndex].childListBlock,
                        block.updated.childListBlock.updated
                      );
                    }

                    if (block.updated.childSudokuBlock != null) {
                      stateBlocks[blockIndex].childSudokuBlock = updateObject(
                        stateBlocks[blockIndex].childSudokuBlock,
                        block.updated.childSudokuBlock.updated
                      );
                    }
                  }
                }

                if (
                  deepSearchKey(updatedBlocks, 'removed') &&
                  updatedBlocks.removed != null
                ) {
                  for (const block of updatedBlocks.removed) {
                    const blockIndex = findIndexById(stateBlocks, block);

                    if (blockIndex !== -1) {
                      stateBlocks.splice(blockIndex, 1);
                    }
                  }
                }
              } else {
                phases[phaseIndex] = updateObject(
                  phases[phaseIndex],
                  updatedPhase.updated
                );
              }
            }
          }

          if (!isBlocks && deepSearchKey(payloadPhases, 'removed')) {
            for (const deletedPhase of payloadPhases.removed) {
              const phaseIndex = findIndexById(phases, deletedPhase);

              if (phaseIndex !== -1) {
                phases.splice(phaseIndex, 1);
              }
            }
          }
        } else if (deepSearchKey(rootUpdated, 'subscribers')) {
          const payloadSubscribers = rootUpdated.subscribers;
          const stateSubscribers = state.dialogue.subscribers!;

          if (deepSearchKey(payloadSubscribers, 'new')) {
            for (const newSubscriber of payloadSubscribers.new) {
              if (!arraySomeById(stateSubscribers, newSubscriber.id)) {
                stateSubscribers.push(newSubscriber);
              }
            }
          }

          if (deepSearchKey(payloadSubscribers, 'removed')) {
            for (const deletedSubscriber of payloadSubscribers.removed) {
              const subscriberIndex = findIndexById(
                stateSubscribers,
                deletedSubscriber
              );

              if (subscriberIndex !== -1) {
                stateSubscribers.splice(subscriberIndex, 1);
              }
            }
          }
        } else if (deepSearchKey(rootUpdated, 'moderators')) {
          const payloadModerators = rootUpdated.moderators;
          const stateModerators = state.dialogue.moderators!;

          if (deepSearchKey(payloadModerators, 'new')) {
            for (const newModerator of payloadModerators.new) {
              if (!arraySomeById(stateModerators, newModerator.id)) {
                stateModerators.push(newModerator);
              }
            }
          }

          if (deepSearchKey(payloadModerators, 'removed')) {
            for (const deletedModerator of payloadModerators.removed) {
              const moderatorIndex = findIndexById(
                stateModerators,
                deletedModerator
              );

              if (moderatorIndex !== -1) {
                stateModerators.splice(moderatorIndex, 1);
              }
            }
          }
        } else state.dialogue = updateObject(state.dialogue, rootUpdated);
      })
      .addCase(asyncApiDialoguesRemoved.fulfilled, (state, action) => {});

    addPhaseCases(builder);
    addBlockCases(builder);
    addMessageCases(builder);
    addLikeCases(builder);
    addListBlockCases(builder);
    addListCases(builder);
    addListItemCases(builder);
    addFileCases(builder);
    addPollBlockCases(builder);
    addPollCases(builder);
    addSudokuBlockCases(builder);
    addSudokuCases(builder);
  },
});

export const getDialogue = (state: RootState) => state.dialogue;

export const dialogueActions = dialogueSlice.actions;
export const { resetDialogue } = dialogueSlice.actions;

export default dialogueSlice.reducer;
