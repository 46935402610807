import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocaleCode } from 'helpers/intl/messages';

import en from 'helpers/intl/messages_en';
import nl from 'helpers/intl/messages_nl';

interface IIntlState {
  locale: LocaleCode;
}

const initialState: IIntlState = {
  locale: 'nl',
};

export const getMessagesForLocale = (locale: LocaleCode) => {
  switch (locale) {
    case 'en':
      return en;
    case 'nl':
      return nl;
    default:
      return nl;
  }
};

const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<LocaleCode>) => {
      state.locale = action.payload;
    },
    // rehydrate: (state, action) => {
    //   const locale = action.payload?.locale || initialState.locale;
    //   state.locale = locale;
    // },
  },
});

export const { setLocale } = intlSlice.actions;
export default intlSlice.reducer;
