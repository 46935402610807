import { API, Role } from '@thuas/pd-schemas';
import { IInitialState } from 'features/admin/appSettingsAPI';
import api from 'helpers/api';
import { Retrieving, callGet } from 'helpers/apiTypes';
import { convertToRoman } from 'helpers/helpers';
import {
  prepareForBackend,
  removeDuplicateIds,
  toArrayOfIdObject,
} from 'helpers/objects';

export { Role } from '@thuas/pd-schemas';
export type RoleType = (typeof Role)[keyof typeof Role];
export type IUser = API['User']['read'] & IInitialState;
export type IUserWithIsGuest = IUser & { isGuest?: boolean };
export type IUserCreate = API['User']['create'];
export type IUserUpdate = API['User']['update'] & {
  moderatesDialoguesOverwrite?: { id: number }[];
  subscribedToDialoguesOverwrite?: { id: number }[];
  subscribedToProjectsOverwrite?: { id: number }[];
};
export type IAuthorName = {
  id: number;
  username: string;
  authorname: string;
  seq?: number;
};

function prepareForUpdate(user: IUser): IUserUpdate {
  const updated = prepareForBackend(user, [
    'moderatesDialogues',
    'subscribedToDialogues',
    'subscribedToProjects',
    'logs',
    // 'ui',
  ]) as IUserUpdate;
  updated.moderatesDialoguesOverwrite = toArrayOfIdObject(
    user.moderatesDialogues
  );
  updated.subscribedToDialoguesOverwrite = toArrayOfIdObject(
    user.subscribedToDialogues
  );
  updated.subscribedToProjectsOverwrite = toArrayOfIdObject(
    user.subscribedToProjects
  );
  return updated;
}
function prepareForCreate(user: IUserCreate): IUserCreate {
  return { ...user, about: '' };
}

export interface ISubscription {
  subscribe?: boolean;
  projectId?: number;
  dialogueId?: number;
  registrationCode?: string;
}

function getAuthorname(author: IAuthorName) {
  if (author.seq) return author.username + ' ' + convertToRoman(author.seq);
  return author.username;
}
export function createAuthorName(user: IUser): IAuthorName {
  return {
    id: user.id!,
    username: user.username!,
    get authorname() {
      return getAuthorname(this);
    },
    seq: 0,
  };
}

export async function loginUser(data: any) {
  try {
    const response = await api.post('Session', {
      Email: data.email,
      Password: data.password,
    });
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function userForgot(data: any) {
  try {
    const response = await api.patch('Account', { Email: data.email });
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function resetPwd(data: any) {
  try {
    // we use the token parameter from the url to receive the token (see App.js)
    const response = await api.patch('Account', {
      Email: data.email,
      Token: data.token,
      Password: data.password,
    });
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function verifyMail(data: any) {
  try {
    // we use the token parameter from the url to receive the token (see App.js)
    const response = await api.patch('Account', {
      Token: data.token,
      RegistrationCode: data.regcode,
    });
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function postUserData(data: any) {
  try {
    const response = await api.post('User', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function postGuestData(data: any) {
  try {
    const response = await api.post('User', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function patchUserData(data: IUser) {
  try {
    const response = await api.patch('User/' + data.id, prepareForUpdate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function fetchUser(id: number) {
  try {
    const query = {
      '*': true,
      avatar: {
        id: true,
        uri: true,
      },
      logs: {
        '*': true,
      },
      ui: {
        '*': true,
      },
    } as const satisfies Retrieving<'User'>;
    const response = await callGet('User', query, id);
    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function fetchUserNames() {
  try {
    const query = {
      id: true,
      username: true,
      email: true,
    } as const satisfies Retrieving<'User'>;
    const response = await callGet('User', query);
    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function fetchUserNamesForProject(id: number) {
  try {
    const query = {
      subscribers: {
        id: true,
        username: true,
        email: true,
      },
      moderators: {
        id: true,
        username: true,
        email: true,
      },
    } as const satisfies Retrieving<'Project'>;
    const response = await callGet('Project', query, id);
    const subscribers = response.response.data.subscribers;
    const moderators = response.response.data.moderators;
    const users = removeDuplicateIds(subscribers?.concat(moderators));
    response.response.data = users;
    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function subscribe(subscription: ISubscription) {
  try {
    const { projectId, dialogueId, subscribe = true } = subscription;
    if (!projectId && !dialogueId)
      throw new Error('Insufficient data provided for subscription.');
    const data = {
      Subscribe: subscribe, // true to subscribe, false to unsubscribe
      ProjectId: projectId,
      // or, but both also possible
      DialogueId: dialogueId,
    };
    const response = await api.patch('Subscription', data);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function register(subscription: ISubscription) {
  try {
    const { registrationCode } = subscription;
    if (!registrationCode)
      throw new Error('Insufficient data provided for registration.');
    const data = {
      // Subscribe: true, // true to subscribe, false to unsubscribe
      RegistrationCode: registrationCode,
    };
    const response = await api.patch('Subscription', data);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}
