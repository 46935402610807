import { FC } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

type Props = Omit<MessageDescriptor, 'id'> & {
  id: string;
  lowercase?: boolean;
  uppercase?: boolean;
  [key: string]: any;
};

// This component replaces the FormattedMessage component from react-intl,
// making the id UPPERCASE (so we don't have to bother when providing it)
const FormattedMessage: FC<Props> = ({
  id,
  lowercase,
  uppercase,
  values,
  ...props
}) => {
  const intl = useIntl();
  if (!id) return null;
  const msg = intl.formatMessage(
    {
      id: id,
      ...props,
    },
    values
  );
  return (
    <>{lowercase ? msg.toLowerCase() : uppercase ? msg.toUpperCase : msg}</>
  );
};

export default FormattedMessage;
