import classNames from 'classnames';
import { debounce, isMacOs } from 'helpers/helpers';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import './ScrollObserver.scss';
/*
ScrollOserver requires:
- overflow: hidden on the parent
- overflow: auto on the scrollableRef element
  (i.e. probably the child element)
*/

type ScrollObserverProps = {
  scrollableRef: React.RefObject<HTMLElement>;
  toBottom?: boolean;
  mutations?: boolean;
  customScrollbar: boolean;
  shouldScrollToBottom?: boolean;
  noNew?: boolean;
};

export default function ScrollObserver(
  props: ScrollObserverProps & PropsWithChildren
): JSX.Element {
  const { shouldScrollToBottom = false, noNew = false } = props;
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [more, setMore] = useState(false);

  const scrollToBottom = useCallback(
    (toBottom?: boolean) => {
      const scrollable = props.scrollableRef.current;
      if (!scrollable) return;
      let scrollheight = 0;
      if (typeof toBottom === 'undefined') {
        // if parameter toBottom is undefined,
        // check isScrolledToBottom and shouldScrollToBottom
        // to see if we should scroll
        if (isScrolledToBottom) scrollheight = scrollable.scrollHeight;
        else setMore(true);
      } else {
        // if parameter toBottom is given, it determines how to scroll
        scrollheight = toBottom
          ? scrollable.scrollHeight
          : scrollable.clientHeight;
      }
      if (scrollheight) {
        scrollable.scrollBy({
          top: scrollheight,
          behavior: 'smooth',
        });
      }
    },
    [props.scrollableRef, isScrolledToBottom]
  );

  useEffect(() => {
    if (props.mutations) {
      const scrollable: HTMLElement | null = props.scrollableRef.current;
      let mutationObserver: MutationObserver;
      if (scrollable) {
        // observe mutations for automatic scrolling to bottom
        mutationObserver = new MutationObserver((mutationsList) => {
          mutationsList.forEach((mutation) => {
            // only if nodes are added we want to scroll
            let scroll = false;
            // look for addedNodes
            mutation.addedNodes.forEach(function (addedNode) {
              if (addedNode instanceof Element) {
                // ignore toolhint additions
                if (!addedNode.classList.contains('toolhint')) {
                  scroll = true;
                }
              }
            });
            scroll && scrollToBottom();
          });
        });
        const mutationObserverConfig: MutationObserverInit = {
          childList: true, // Observe child node additions/removals
          subtree: true, // Observe mutations in the entire subtree
          // attributes: true, // Observe attribute changes // no need to do this
        };
        mutationObserver.observe(scrollable, mutationObserverConfig);
      }

      return () => {
        if (scrollable) {
          mutationObserver.disconnect();
        }
      };
    }
  }, [props.mutations, props.scrollableRef, props.toBottom, scrollToBottom]);

  useEffect(() => {
    const scrollable: HTMLElement | null = props.scrollableRef.current;
    // handles scroll, but not too often
    const handleScroll = debounce(() => {
      if (!scrollable) return;
      const scrollTop = scrollable.scrollTop;
      const scrollHeight = scrollable.scrollHeight;
      const clientHeight = scrollable.clientHeight;
      const scrolledToBottom =
        Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      setIsScrolledToBottom(scrolledToBottom);
      if (scrolledToBottom) setMore(false);
    }, 100);
    let resizeObserver: ResizeObserver;
    let intersectionObserver: IntersectionObserver;
    if (scrollable) {
      // observe visibility of scollable in the viewport,
      // then listen for scroll events
      intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            scrollable.addEventListener('scroll', handleScroll);
          } else {
            scrollable.removeEventListener('scroll', handleScroll);
          }
        });
      });
      intersectionObserver.observe(scrollable);
      // observe resizing of the window
      resizeObserver = new ResizeObserver(handleScroll);
      resizeObserver.observe(scrollable);
    }

    return () => {
      if (scrollable) {
        scrollable.removeEventListener('scroll', handleScroll);
        resizeObserver.unobserve(scrollable);
        intersectionObserver.disconnect();
      }
    };
  }, [props.scrollableRef]);

  useEffect(() => {
    if (props.shouldScrollToBottom) scrollToBottom(true);
  }, [props.shouldScrollToBottom, scrollToBottom]);

  function handleClick() {
    scrollToBottom(props.toBottom);
  }

  const iconClassNames = classNames('scroll_icon', {
    hidden: isScrolledToBottom,
    more: more && !noNew,
  });
  return (
    <React.Fragment>
      {props.customScrollbar && !isMacOs() ? (
        <div className={'custom_scrollbar'}></div>
      ) : null}
      {props.children}
      <div
        className={classNames(iconClassNames, {
          shouldScrollToBottom: shouldScrollToBottom,
        })}
        onClick={handleClick}
      ></div>
    </React.Fragment>
  );
}
