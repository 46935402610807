import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import PeriodPicker from 'components/formatters/PeriodPicker';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { BlockInfo, useForms } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import Message from 'components/messages/Message';
import { getTextAreaHeight } from 'components/messages/TextAreaInput';
import { IBlock } from 'features/block/blockAPI';
import {
  BlockType,
  emptyBlock,
  patchBlockAsync,
  postBlockAsync,
} from 'features/block/blockSlice';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IMessage } from 'features/message/messageAPI';
import { IOption, IPoll } from 'features/poll/pollAPI';
import { patchPollAsync } from 'features/poll/pollSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { AnyOrderObject, pickPrimitiveProps } from 'helpers/objects';
import { getOrderForLast } from 'helpers/sorting';

import './PollSettingsForm.scss';

interface PollSettingsFormProps {
  stateMan: StateMan;
  blockInfo: BlockInfo;
}

function UnconnectedPollSettingsForm(
  props: PollSettingsFormProps & FormProps & UserStateProps & DialogueStateProps
) {
  const { show, setShow, blockInfo, userCanEdit, dialogue, stateMan, user } =
    props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [reset, setReset] = useState(true);
  const [data, setData] = useState<IBlock>({});
  const [poll, setPoll] = useState<IPoll>({});
  // const [options, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    if (reset || show) {
      if (blockInfo) {
        if (blockInfo.block) {
          // existing block
          setData({ ...blockInfo.block });
          if (blockInfo.block.childPollBlock?.poll) {
            setPoll({ ...blockInfo.block.childPollBlock?.poll });
            // setOptions(blockInfo.block.childPollBlock.poll.options ?? []);
          }
        } else if (blockInfo.phase) {
          // new block
          setData({
            ...emptyBlock,
            childType: BlockType.Poll,
            phase: blockInfo.phase,
            order: getOrderForLast(blockInfo.phase?.blocks as AnyOrderObject[]),
          });
          // const end = new Date();
          // end.setDate(end.getDate() + 3);
          setPoll({
            start: null,
            end: null,
            closed: false,
            options: [],
            showResults: true,
            allowCancellation: false,
          });
        }
      }
      setReset(false);
    }
  }, [blockInfo, reset, show]);

  useEffect(() => {
    const ta = document.getElementById('blockDescription');
    if (ta) {
      ta.style.height =
        getTextAreaHeight(ta as HTMLTextAreaElement, 1, 3, true, 4)[0] + 'px';
    }
  }, [show, data]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [target.name]: target.value,
    });
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    setPoll((prev) => {
      return {
        ...prev,
        [name]: checked,
      };
    });
  }

  function handleChangeOption(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [target.name]: target.value,
    });
  }

  // function addOption() {
  //   if (!options) return;
  //   const newOption: IOption = {
  //     id: newId(options.map((o) => o.id as number)),
  //     poll: poll,
  //     message: { author: user, content: '' },
  //   };
  //   const newOptions = [...options];
  //   newOption.order = getOrderForLast(newOptions as AnyOrderObject[], -1);
  //   newOptions.unshift(newOption);
  //   setOptions(newOptions);
  // }

  async function handleSubmit() {
    try {
      if (data && blockInfo.block) {
        // existing block
        let res: any = await dispatch(
          patchPollAsync({
            data: { ...pickPrimitiveProps(poll) },
            stateMan: stateMan,
          })
        );
        if (res.payload?.response?.status < 300)
          res = await dispatch(
            patchBlockAsync({
              data: { ...pickPrimitiveProps(data) },
              stateMan: stateMan,
            })
          );
        switch (res.payload?.response.status) {
          case 200:
          case 201:
            doFormMsg(
              {
                message: 'Library settings saved',
                success: true,
                timeout: 1500,
              },
              setFormMsg
            );
            setShow(false);
            break;
          case 500:
          default:
            const m = 'Internal error, library settings not saved.';
            doFormMsg(
              {
                message: m,
                success: false,
              },
              setFormMsg
            );
            break;
        }
      } else {
        // new block
        let res: any =
          // await dispatch(
          //   postPollAsync({
          //     data: { ...pickPrimitiveProps(poll) },
          //     stateMan: stateMan,
          //   })
          // );
          // if (res.payload?.response?.status < 300)
          //   res =
          await dispatch(
            postBlockAsync({
              data,
              stateMan,
              poll: poll,
            })
          );
        switch (res.payload?.response.status) {
          case 200:
          case 201:
            doFormMsg(
              {
                message: 'Library settings saved',
                success: true,
                timeout: 1500,
              },
              setFormMsg
            );
            setShow(false);
            break;
          case 500:
          default:
            const m = 'Internal error, chat settings not saved.';
            doFormMsg(
              {
                message: m,
                success: false,
              },
              setFormMsg
            );
            break;
        }
      }
    } catch (err) {
      doFormMsg(
        {
          message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
          success: false,
        },
        setFormMsg
      );
      console.log('List update request failed for unclear reason.', err);
    }
  }

  function handleCancel(): boolean {
    doFormMsg(NoFormMsg, setFormMsg);
    // onCancel && onCancel();
    setReset(true);
    setShow(false);
    return true;
  }

  function handleClose(): void {
    // onClose && onClose();
  }

  if (!data) return null;
  if (!userCanEdit(dialogue)) return null;
  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form poll_settings_form"
      title={
        <FormattedMessage
          id={
            blockInfo.block
              ? 'BLOCKS.FORM.SETTINGS'
              : 'POLLS.FORM.FORM_TITLE_POLL'
          }
        />
      }
      // footer={<FormFooter addOption={addOption} />}
      onSubmit={handleSubmit}
      onCancel={userCanEdit(dialogue) ? handleCancel : undefined}
      onClose={handleClose}
      enableEscape
      formMsg={formMsg}
      delayClose={0}
    >
      <Form.Group as={Row} controlId="blockTitle">
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            className="field subtitle"
            type="text"
            placeholder={intl.formatMessage({
              id: 'POLLS.FORM.TITLE_PLCH',
            })}
            name={'name'}
            value={data.name || ''}
            onChange={handleChangeData}
            required
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="blockDescription">
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            className="field"
            type="text"
            as="textarea"
            placeholder={intl.formatMessage({
              id: 'POLLS.FORM.DESCRIPTION_PLCH',
            })}
            name={'description'}
            value={data.description || ''}
            onChange={handleChangeData}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="pollPeriod">
        <Col sm={12} md={12} lg={12}>
          <div className="poll_period">
            <div className="poll_period_column">
              <div className="label">
                <FormattedMessage id="POLLS.FORM.PERIOD" />
                {': '}
              </div>
              <div className="poll_period_buttons">
                <button
                  className="as-form-control poll_formbutton"
                  onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    setPoll((prev) => ({ ...prev, start: new Date() }));
                  }}
                >
                  <FormattedMessage id="POLLS.FORM.NOW" />
                </button>
                <button
                  className="as-form-control poll_formbutton"
                  onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    const start = new Date();
                    start.setDate(start.getDate() + 1);
                    start.setHours(8, 0, 0, 0);
                    setPoll((prev) => ({ ...prev, start: start }));
                  }}
                >
                  <FormattedMessage id="POLLS.FORM.MORNING" />
                </button>
              </div>
            </div>
            <PeriodPicker
              from={poll.start}
              to={poll.end}
              setFrom={(date: Date) => {
                setPoll((prev) => ({ ...prev, start: date }));
              }}
              setTo={(date: Date) => {
                setPoll((prev) => ({ ...prev, end: date }));
              }}
              showDate={true}
              showYear={true}
              editing={true}
              labels={{
                from: {
                  before: <div className="center">start</div>,
                },
                to: {
                  before: <div className="center">end</div>,
                },
              }}
            />
            <div className="poll_period_column">
              <div className="poll_period_buttons">
                <button
                  className="as-form-control poll_formbutton"
                  onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    const end = new Date(poll.start ?? '');
                    end.setTime(end.getTime() + 4 * 3_600_000);
                    setPoll((prev) => ({ ...prev, end: end }));
                  }}
                >
                  <FormattedMessage id="POLLS.FORM.FOURHOURS" />
                </button>
                <button
                  className="as-form-control poll_formbutton"
                  onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    const end = new Date();
                    end.setDate(end.getDate() + 1);
                    end.setHours(23, 59, 0, 0);
                    setPoll((prev) => ({ ...prev, end: end }));
                  }}
                >
                  <FormattedMessage id="POLLS.FORM.TOMORROW" />
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={12} md={12} lg={12}>
          <Form.Text className="poll_instr">
            <FormattedMessage id="POLLS.FORM.INSTR" />
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="pollClosed">
        <Col sm={12} md={12} lg={12}>
          <Form.Check
            className="poll_checkbox"
            id={'closed'}
            type="checkbox"
            label={intl.formatMessage({ id: 'POLLS.FORM.CLOSED' })}
            name="closed"
            checked={poll.closed ?? false}
            onChange={handleCheckboxChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="pollShowResults">
        <Col sm={12} md={12} lg={12}>
          <Form.Check
            className="poll_checkbox"
            id={'showResults'}
            type="checkbox"
            label={intl.formatMessage({ id: 'POLLS.FORM.SHOW_RESULTS' })}
            name="showResults"
            checked={poll.showResults ?? true}
            onChange={handleCheckboxChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="pollAllowCancel">
        <Col sm={12} md={12} lg={12}>
          <Form.Check
            className="poll_checkbox"
            id={'allowCancellation'}
            type="checkbox"
            label={intl.formatMessage({ id: 'POLLS.FORM.ALLOW_CANCEL' })}
            name="allowCancellation"
            checked={poll.allowCancellation ?? false}
            onChange={handleCheckboxChange}
          />
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

const PollSettingsForm = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(UnconnectedPollSettingsForm)
);
export default PollSettingsForm;

type PollOptionProps = {
  poll: IPoll;
  option: IOption;
  stateMan: StateMan;
};
function PollOption(props: PollOptionProps) {
  const { poll, option, stateMan } = props;
  const intl = useIntl();
  return (
    <Message
      message={option.message as IMessage}
      block={poll.parent?.parent as IBlock}
      extraContent={(message?: IMessage) => {
        return <div>{poll.id + ' ' + option.order}</div>;
      }}
      stateMan={stateMan}
      setNewReplyTo={() => {}}
      showLikes={false}
      showColours={false}
      editState={true}
    />
  );
}

export function PollSettingsFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  block: IBlock;
  stateMan: StateMan;
  className?: string;
  children?: ReactNode;
}) {
  const { onClick, block } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.PollSettingsForm.setCurrentBlockInfo({ block });
    forms.PollSettingsForm.setCurrentStateMan(() => props.stateMan);
    forms.PollSettingsForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <div className="form_settings_link" onClick={handleClick}>
      {props.children}
    </div>
  );
}
