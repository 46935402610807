import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { prepareForBackend } from 'helpers/objects';

export type ISudokuBlock = API['SudokuBlock']['read'];
export type ISudokuBlockCreate = API['SudokuBlock']['create'];

function prepareForUpdate(block: ISudokuBlock): ISudokuBlock {
  const updated = prepareForBackend(
    block,
    ['id'],
    true // ignore array-attributes and attributes with value undefined
  );
  return updated;
}

export const postSudokuBlock = async (data: ISudokuBlockCreate) => {
  try {
    return await api.post('SudokuBlock', data);
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchSudokuBlock = async (id: number, data: ISudokuBlock) => {
  try {
    return await api.patch('SudokuBlock/' + id, prepareForUpdate(data));
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
