import classNames from 'classnames';
import React, { ReactElement } from 'react';

import { addKeyProp } from './helpers';
import { AnyObject } from './objects';

type WrapIfProps = {
  condition: boolean;
  wrapper: ReactElement;
  children: ReactElement | ReactElement[];
};
export function WrapIf(props: WrapIfProps): ReactElement {
  const wrapperChildren = React.Children.toArray(props.wrapper.props.children);
  const children = React.Children.toArray(props.children);
  if (props.condition && props.children)
    return React.cloneElement(
      props.wrapper,
      {},
      addKeyProp([...wrapperChildren, ...children] as ReactElement[])
    );
  return <>{props.children}</>;
}

export function clog(...args: any[]) {
  return args.map((arg) => arg.join(': ')).join(' / ');
}
export type CLogProps = AnyObject & {
  green?: boolean;
};
export function CLog(props: CLogProps) {
  const { green = true, ...args } = props;
  const res = clog(...Object.entries(args));
  return <div className={classNames('debug', { green })}>{res}</div>;
}
