// Code translated from Python source at http://norvig.com/sudoku.html and http://norvig.com/sudopy.shtml
import classNames from 'classnames';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import { setCharAt } from 'helpers/helpers';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import './Sudoku.scss';
import { generateSudokuAsString } from './SudokuGenerator';

type Square = string;
type Unit = Square[];
type UnitList = Unit[];
type Units = { [key: string]: Unit[] };
type Peers = { [key: string]: Set<string> };
type Values = { [key: string]: string };
type Options = { [key: string]: string[] };
type GridString = string;

export type SudokuHandle = {
  newPuzzle: (n?: number) => string;
  solvePuzzle: () => void;
};

type SudokuProps = {
  challenge: string;
  attempt: string;
  makeAttempt: (newAttempt?: string) => void;
  hints: number;
  disabled?: boolean;
};
export const Sudoku = forwardRef<SudokuHandle, SudokuProps>(
  (props: SudokuProps, ref) => {
    const { challenge, attempt, makeAttempt, hints, disabled = false } = props;
    const [parsedGrid, setParsedGrid] = useState<Values | false>(false);
    const [solving, setSolving] = useState<number>(0);
    const [numSolutions, setNumSolutions] = useState<number>(0);
    const intl = useIntl();
    const hasError = useRef<boolean>(false);
    const gridRef = useRef<HTMLDivElement>(null);

    const digits: string[] = '123456789'.split('');
    const rows: string[] = 'ABCDEFGHI'.split('');
    const cols: string[] = digits;
    const squares: string[] = cross(rows, cols);
    const unitlist: UnitList = [
      ...cols.map((c) => cross(rows, [c])),
      ...rows.map((r) => cross([r], cols)),
      ...['ABC', 'DEF', 'GHI'].flatMap((rs) =>
        ['123', '456', '789'].map((cs) => cross(rs.split(''), cs.split('')))
      ),
    ];
    const units: Units = Object.fromEntries(
      squares.map((s) => [s, unitlist.filter((u) => u.includes(s))])
    );
    const peers: Peers = Object.fromEntries(
      squares.map((s) => [s, new Set(units[s].flat().filter((x) => x !== s))])
    );

    useEffect(() => {
      if (challenge) {
        const parsed = parseGrid(challenge);
        if (parsed) {
          setParsedGrid(parsed);
          setNumSolutions(countSolutions(parsed));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challenge]);

    useEffect(() => {
      if (isSolved()) gridRef.current?.classList.add('sudoku_solved');
      else gridRef.current?.classList.remove('sudoku_solved');
    });

    function mergeGrid(ch: string, att: string) {
      let res = '';
      for (let i = 0; i < ch.length; i++) {
        res += att[i] !== '.' ? att[i] : ch[i];
      }
      return res;
    }
    function subtractGrid(ch: string, att: string) {
      let res = '';
      for (let i = 0; i < ch.length; i++) {
        res += ch[i] !== '.' ? '.' : att[i];
      }
      return res;
    }

    function countSolutions(values: Values): number {
      return Object.values(values).reduce(
        (maxLength, str) => Math.max(maxLength, str.length),
        0
      );
    }

    const gridValues = useCallback((grid: GridString): Values => {
      if (!grid.length) return {};
      const chars = Array.from(grid).filter(
        (c) => digits.includes(c) || '0.'.includes(c)
      );
      if (chars.length !== 81) {
        throw new Error('GridString must contain 81 characters');
      }
      return Object.fromEntries(squares.map((s, i) => [s, chars[i]]));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const parseGrid = useCallback((grid: GridString): Values | false => {
      if (!grid) return false;
      const values: Values = Object.fromEntries(
        squares.map((s) => [s, digits.join('')])
      );
      const gridVals = gridValues(grid);
      for (const [s, d] of Object.entries(gridVals)) {
        if (digits.includes(d) && !assign(values, s, d)) {
          return false; // Fail if we can't assign d to square s
        }
      }
      return values;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function valuesToGridString(values: Values): string {
      return squares
        .map((s) => (values[s].length === 1 ? values[s] : '.'))
        .join('');
    }

    const assign = useCallback(
      (values: Values, s: string, d: string): Values | false => {
        /** Eliminate all the other values (except d) from values[s] and propagate.
         * Return values, except return false if a contradiction is detected.
         */
        const other_values = values[s].replace(d, '');
        if (Array.from(other_values).every((d2) => eliminate(values, s, d2))) {
          return values;
        } else {
          return false;
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, react-hooks/exhaustive-deps
      []
    );

    const eliminate = useCallback(
      (values: Values, s: string, d: string): Values | false => {
        if (!values[s].includes(d)) {
          return values; // Already eliminated
        }
        values[s] = values[s].replace(d, '');
        // (1) If a square s is reduced to one value d2, then eliminate d2 from the peers.
        if (values[s].length === 0) {
          return false; // Contradiction: removed last value
        } else if (values[s].length === 1) {
          const d2 = values[s];
          if (!Array.from(peers[s]).every((s2) => eliminate(values, s2, d2))) {
            return false;
          }
        }
        // (2) If a unit u is reduced to only one place for a value d, then put it there.
        for (const u of units[s]) {
          const dplaces = u.filter((s2) => values[s2].includes(d));
          if (dplaces.length === 0) {
            return false; // Contradiction: no place for this value
          } else if (dplaces.length === 1) {
            // d can only be in one place in unit; assign it there
            if (!assign(values, dplaces[0], d)) {
              return false;
            }
          }
        }
        return values;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    function cross(A: string[], B: string[]): string[] {
      return A.flatMap((a) => B.map((b) => a + b));
    }

    function search(values: Values | false): Values | false {
      setSolving((prev) => prev + 1);
      if (solving > 250_000) return false;
      if (values === false) return false;
      if (squares.every((s) => values[s].length === 1)) return values;

      const [n, s] = squares
        .filter((s) => values[s].length > 1)
        .map((s) => [values[s].length, s] as [number, string])
        .reduce((min, curr) => (curr[0] < min[0] ? curr : min));

      return some(
        values[s].split('').map((d) => search(assign({ ...values }, s, d)))
      );
    }

    function some(seq: (Values | false)[]): Values | false {
      for (const e of seq) {
        if (e) return e;
      }
      return false;
    }

    function solve(grid: string): Values | false {
      setSolving(0);
      const solved = search(parseGrid(grid));
      if (solved)
        makeAttempt(subtractGrid(challenge, valuesToGridString(solved)));
      return solved;
    }

    function randomPuzzle(N = 17): string {
      return generateSudokuAsString(81 - N);
    }

    // ALTERNATIVE function for generating a puzzle
    // function randomPuzzle(N = 17): string {
    //   const values: Values = Object.fromEntries(
    //     squares.map((s) => [s, digits.join('')])
    //   );
    //   for (const s of shuffled(squares)) {
    //     if (!assign(values, s, randomChoice(values[s].split('')))) {
    //       break;
    //     }
    //     const ds = squares
    //       .filter((s) => values[s].length === 1)
    //       .map((s) => values[s][0]);
    //     if (ds.length >= N && new Set(ds).size >= 8) {
    //       return squares
    //         .map((s) => (values[s].length === 1 ? values[s][0] : '.'))
    //         .join('');
    //     }
    //   }
    //   return randomPuzzle(N); // Give up and make a new puzzle
    // }
    // function shuffled<T>(array: T[]): T[] {
    //   const result = [...array];
    //   for (let i = result.length - 1; i > 0; i--) {
    //     const j = Math.floor(Math.random() * (i + 1));
    //     [result[i], result[j]] = [result[j], result[i]];
    //   }
    //   return result;
    // }
    // function randomChoice<T>(array: T[]): T {
    //   return array[Math.floor(Math.random() * array.length)];
    // }

    useImperativeHandle(ref, () => ({
      newPuzzle(n: number = 28) {
        return randomPuzzle(n);
      },
      solvePuzzle() {
        solve(challenge);
      },
    }));

    function setSquare(row: number, col: number, value: string) {
      const newAttempt = setCharAt(attempt, row * 9 + col, value);
      makeAttempt(newAttempt);
    }

    function isChallenge(row: number, col: number): boolean {
      return challenge[row * 9 + col] !== '.';
    }
    function isAttempt(row: number, col: number): boolean {
      return attempt[row * 9 + col] !== '.';
    }

    const merged = mergeGrid(challenge, attempt);
    const mergedGrid = gridValues(merged);

    function isSolved(): boolean {
      if (!parsedGrid) return false;
      for (const key in mergedGrid) {
        if (!parsedGrid[key].includes(mergedGrid[key])) return false;
      }
      return true;
    }

    try {
      function peerValues(sq: string): string {
        let res = '';
        for (let s of peers[sq]) {
          res += mergedGrid[s];
        }
        return res;
      }
      function asOption(sq: string, d: string): string {
        // Return d if d is an option for square sq
        return peerValues(sq).includes(d) ? ' ' : d;
      }
      function squareOptions(sq: string): string[] {
        if (mergedGrid[sq] !== '.') return [];
        let s = [...digits];
        let options = hints ? s.map((d) => asOption(sq, d)) : s;
        return options;
      }
      function gridOptions(values: Values): Options {
        return Object.fromEntries(
          squares.map((s, i) => {
            return [s, squareOptions(s)];
          })
        );
      }
      function hasUniqueString(arr: string[][]): string | boolean {
        const frequency: { [key: string]: number } = {};
        const flattened = arr.flat().map((str) => str.trim());
        flattened.forEach((str) => {
          frequency[str] = (frequency[str] || 0) + 1;
        });
        for (const key in frequency) {
          if (frequency[key] === 1) return key;
        }
        return false;
      }
      function isUniqueOption(sq: string, d: string): boolean {
        let res: boolean = false;
        for (let u of units[sq]) {
          const opt = u.map((o) => options[o]);
          res = res || hasUniqueString(opt) === d;
        }
        return res;
      }
      const options = gridOptions(mergedGrid);

      type SquareProps = {
        sq: string;
        value: string;
        row: number;
        col: number;
      };
      function Square(props: SquareProps) {
        const { sq, value, row, col } = props;
        if (!parsedGrid || row > 8 || col > 8) return null;
        const is_c = isChallenge(row, col);
        const is_a = isAttempt(row, col);
        if (is_c || is_a) {
          // one value in the square, can be challenge or attempt
          const solution = parsedGrid
            ? parsedGrid[String.fromCharCode(65 + row) + (col + 1).toString()]
            : '';
          if (is_a && !solution.includes(attempt[row * 9 + col]))
            hasError.current = true;
          return (
            <div
              className={classNames('sudoku_square', {
                sudoku_challenge: is_c,
                sudoku_attempt: is_a,
                sudoku_error:
                  is_a && !solution.includes(attempt[row * 9 + col]),
              })}
            >
              <div
                onClick={
                  disabled
                    ? undefined
                    : () => {
                        if (is_a) setSquare(row, col, '.');
                      }
                }
              >
                {is_c ? value[0] : attempt[row * 9 + col]}
              </div>
            </div>
          );
        }
        // no challenge or attempt in the square
        let opt = hints ? options[sq] : [...digits];
        let n = opt.reduce((acc, s) => (acc += s.trim()), '').length;
        if (n > hints) opt = [...digits]; // do not show hints
        return (
          <div
            className={classNames('sudoku_square', {
              sudoku_hint: hints > 0 && n <= hints,
              sudoku_empty: n === 0,
            })}
          >
            {opt.map((c, i) => (
              <div
                key={i}
                className={classNames({
                  sudoku_option: c !== ' ',
                  sudoku_unique_option: n <= hints && isUniqueOption(sq, c),
                })}
                onClick={
                  disabled
                    ? undefined
                    : () => {
                        if (c.trim()) setSquare(row, col, c[0]);
                      }
                }
              >
                {c[0]}
              </div>
            ))}
          </div>
        );
      }
      type RowProps = {
        values: Values;
        row: number;
      };
      function Row(props: RowProps) {
        const { values, row } = props;
        return (
          <div className="sudoku_row">
            {Object.entries(values).map(([s, d], i) => (
              <Square key={i} sq={s} value={d} row={row} col={i} />
            ))}
          </div>
        );
      }
      type GridProps = {
        rows: Values[];
      };
      function Grid(props: GridProps) {
        hasError.current = false;
        return (
          <div className="sudoku_grid">
            {props.rows.map((row, i) => (
              <Row key={i} values={row} row={i} />
            ))}
          </div>
        );
      }

      let level = challenge.replaceAll('.', '').length > 30 ? 2 : 3;
      level += numSolutions > 1 ? 1 : 0;
      return (
        <>
          <div className="games sudoku" ref={gridRef}>
            {parsedGrid ? (
              <Grid
                rows={unitlist.slice(9, 18).map((u) =>
                  u.reduce((acc, str) => {
                    acc[str] = parsedGrid[str];
                    return acc;
                  }, {} as { [key: string]: string })
                )}
              />
            ) : null}
          </div>
          <div className="sudoku_level">
            {Array.from({ length: level }, (_, i) => {
              return (
                <Icon
                  key={i}
                  symbol={IconSymbol.dot}
                  variant={IconVariant.medium}
                  size={28}
                  hintProps={
                    i === level - 1
                      ? {
                          hint:
                            intl.formatMessage({
                              id: 'SUDOKU.DIFFICULTY_LEVEL',
                            }) +
                            ' ' +
                            level,
                          offset: { x: 10, y: 20 },
                          offsetBottom: true,
                        }
                      : undefined
                  }
                />
              );
            })}
          </div>
          <div className="sudoku_solutions">
            <FormattedMessage
              id="SUDOKU.COUNT"
              values={{ count: numSolutions }}
            />
          </div>
        </>
      );
    } catch (error) {
      return (
        <div className="sudoku_error">
          Invalid Sudoku puzzle state.
          <br />
          Please generate a new puzzle.
        </div>
      );
    }
  }
);
