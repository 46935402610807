// Convert Uint8Array to string
export function uint8ArrayToString(uint8Array: Uint8Array) {
  // Create an array to hold the characters
  const characters = [];

  // Loop through each byte in the Uint8Array
  for (let i = 0; i < uint8Array.length; i++) {
    // Convert the byte to a character and push it to the array
    characters.push(String.fromCharCode(uint8Array[i]));
  }

  // Join the array of characters into a single string and return it
  return characters.join('');
}

// Convert string to Uint8Array
export function stringToUint8Array(string: string) {
  const buffer = new ArrayBuffer(string.length);
  const bufferView = new Uint8Array(buffer);
  for (let i = 0; i < string.length; i++) {
    bufferView[i] = string.charCodeAt(i);
  }
  return bufferView;
}
