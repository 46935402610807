import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { mergeObjects } from 'helpers/objects';
import { patchSudokuBlock } from './sudokuBlockAPI';

export const patchSudokuBlockAsync = createAsyncThunk(
  'sudoku/patchSudokuBlock',
  async (
    {
      data,
      stateMan,
      id,
    }: {
      stateMan: StateMan;
      data: any;
      id: number;
    },
    thunkAPI
  ) => {
    try {
      return {
        response: await patchSudokuBlock(id, data),
        stateMan: stateMan,
      };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export function addSudokuBlockCases(
  builder: ActionReducerMapBuilder<IDialogueState>
) {
  builder
    .addCase(patchSudokuBlockAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchSudokuBlockAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const obj = action.meta.arg.stateMan(state);
      const childSudokuBlock = obj.childSudokuBlock;
      if (childSudokuBlock)
        mergeObjects(childSudokuBlock, action.meta.arg.data);
      else mergeObjects(obj, action.meta.arg.data);
    })
    .addCase(patchSudokuBlockAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });
}
