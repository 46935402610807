import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { store } from 'app/store';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { patchUserData } from 'features/user/userAPI';
import { IUserState } from 'features/user/userSlice';
import { getCurrent } from 'helpers/helpers';
import { IUiState, patchUiState, postUiState } from './uiStateAPI';

type UiElementState = [
  number, // element id
  boolean, // isOpen
  Date | null // lastClosed
];

export type UiSettings = {
  d?: UiElementState[]; // dialogues
  p?: UiElementState[]; // phases
  b?: UiElementState[]; // blocks
};
export type UiUpdate = {
  o?: number[]; // object id's
  level?: 'dialogue' | 'phase' | 'block';
  p?: number[]; // ids of phase elements to close
  b?: number[]; // ids of block elements to close
};
// export type UiState = {
//   uiState: UiSettings;
// };

export interface IUiStateState {
  uiState: IUiState;
  status: 'idle' | 'loading' | 'failed';
  errors: any;
}

export function getUiState(state?: any): IUiState | null {
  const s = state ?? store.getState().user;
  if (s) {
    if (s.user?.ui) {
      let ui = getCurrent(s.user.ui);
      ui = { ...ui, user: [s.user.id ?? s.userId] };
      return ui;
    }
    if (s.user?.id) return { user: [s.user?.id || s.userId] };
  }
  return null;
}

export const persistUiState = createAsyncThunk(
  'uiState/persistUiState',
  async (
    {
      data,
    }: {
      data: IUiState;
    },
    thunkAPI
  ) => {
    try {
      if (data.id) {
        // console.log('patching state');
        return { response: await patchUiState(data) };
      }
      const uId = data.user
        ? typeof data.user === 'number'
          ? data.user
          : typeof data.user[0] === 'number'
          ? data.user[0]
          : data.user[0].id
        : undefined;
      if (data.state && uId) {
        // console.log('posting state');
        const res = await postUiState({
          state: data.state,
          user: [uId],
        });
        if (res.status === 201)
          await patchUserData({ id: uId, ui: res.data._id });
        return {
          response: res,
        };
      }
      // console.log('ignoring state');
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

function updateOpenClose(
  arr: UiElementState[] | undefined, // map of element states, with id as key
  ids: number[], // element id's (of dialogue, phase, or block)
  open: boolean | undefined, // true if element is open
  time: Date | null = null // timestamp of closing (only if open !== true)
) {
  if (!ids || !arr) return;
  ids.forEach((id) => {
    if (id) {
      let last: Date | null = null;
      let lastOpen: boolean = typeof open === 'undefined' ? false : open;
      const i = arr.findIndex((el) => el[0] === id);
      if (i >= 0) {
        lastOpen = arr[i][1];
        last = arr[i][2];
        arr.splice(i, 1);
      }
      const newOpen = typeof open === 'undefined' ? lastOpen : open;
      const newTime =
        typeof open === 'undefined'
          ? lastOpen
            ? time
            : last
          : open
          ? last
          : time;
      arr.push([id, newOpen, newTime]);
    }
  });
}

function closeOthers(
  arr: UiElementState[], // array of element states
  id: number = 0 // element id (of dialogue, phase, or block)
) {
  arr.forEach((el, i) => {
    if (el[0] !== id) {
      const nel: UiElementState = [...el];
      // if element was open or if no previous closing date,
      // set closing date to now.
      if (el[1] || !el[2]) {
        nel[2] = new Date();
      }
      nel[1] = false;
      arr[i] = nel;
    }
  });
}

export function getDialogueStates(): UiElementState[] | null {
  const u = getUiState();
  if (u?.state) return u.state.d;
  return null;
}
export function getPhaseStates(dialogue: IDialogue): UiElementState[] {
  const u = getUiState();
  if (u?.state && u.state.p) {
    return u.state.p.filter((p: UiElementState) =>
      dialogue.phases?.map((ph) => ph.id).includes(p[0])
    );
  }
  return [];
}
export function getBlockStates(pId: number): UiElementState[] {
  const u = getUiState();
  if (u?.state && u.state.b) {
    return u.state.b;
  }
  return [];
}
export function getVisitedState(
  level: 'dialogue' | 'phase' | 'block',
  id?: number // element id
): UiElementState {
  const u = getUiState();
  if (u?.state) {
    let s: UiElementState;
    switch (level) {
      case 'dialogue':
        s = u.state.d?.find((s: UiElementState) => s[0] === id) ?? null;
        break;
      case 'phase':
        s = u.state.p?.find((s: UiElementState) => s[0] === id) ?? null;
        break;
      case 'block':
        s = u.state.b?.find((s: UiElementState) => s[0] === id) ?? null;
        break;
    }
    if (s) return s;
  }
  return [id ?? 0, false, null];
}

export const persistOpenDialogue = createAsyncThunk(
  'uiState/persistOpenDialogue',
  async (
    {
      data,
    }: {
      data: UiUpdate;
    },
    thunkAPI
  ) => {
    try {
      const u = getUiState();
      if (!u) return;
      let d: UiElementState[] = u?.state?.d ? [...u.state.d] : [];
      if (u.state) {
        if (d.length) closeOthers(d, data.o ? data.o[0] : 0);
        updateOpenClose(d, data.o ?? [], true);
      }
      const newu = {
        ...u,
        state: {
          ...u.state,
          d: d,
        },
      };
      await thunkAPI.dispatch(persistUiState({ data: newu }));
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const persistCloseDialogues = createAsyncThunk(
  'uiState/persistCloseDialogues',
  async (
    {
      data,
    }: {
      data: UiUpdate;
    },
    thunkAPI
  ) => {
    try {
      // TODO: first time after logging in, u only returns the user, no ui state read from db
      const u = getUiState();
      if (!u) return;
      let d: UiElementState[] = u?.state?.d ? [...u.state.d] : [];
      let p: UiElementState[] = u?.state?.p ? [...u.state.p] : [];
      let b: UiElementState[] = u?.state?.b ? [...u.state.b] : [];
      if (d.length) {
        closeOthers(d);
        if (data.o) {
          if (data.p) updateOpenClose(p, data.p, undefined, new Date());
          if (data.b) updateOpenClose(b, data.b, undefined, new Date());
          updateOpenClose(d, data.o ?? [], undefined, new Date());
        }
      } else updateOpenClose(d, data.o ?? [], undefined, new Date());
      const newu = {
        ...u,
        state: {
          ...u.state,
          d: d,
          p: p,
          b: b,
        },
      };
      await thunkAPI.dispatch(persistUiState({ data: newu }));
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const persistOpenPhase = createAsyncThunk(
  'uiState/persistOpenPhase',
  async (
    {
      data,
      doBlocks = false,
    }: {
      data: UiUpdate;
      doBlocks?: boolean;
    },
    thunkAPI
  ) => {
    try {
      const u = getUiState();
      if (!u || !data.o) return;
      let p: UiElementState[] = u?.state?.p ? [...u.state.p] : [];
      let b: UiElementState[] = u?.state?.b ? [...u.state.b] : [];
      updateOpenClose(p, data.o, true);
      if (doBlocks && data.b) updateOpenClose(b, data.b, true, new Date());
      const newu = {
        ...u,
        state: {
          ...u.state,
          p: p,
          b: b,
        },
      };
      await thunkAPI.dispatch(persistUiState({ data: newu }));
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const persistClosePhase = createAsyncThunk(
  'uiState/persistClosePhase',
  async (
    {
      data,
    }: {
      data: UiUpdate;
    },
    thunkAPI
  ) => {
    try {
      const u = getUiState();
      if (!u || !data.o) return;
      let p: UiElementState[] = u?.state?.p ? [...u.state.p] : [];
      let b: UiElementState[] = u?.state?.b ? [...u.state.b] : [];
      updateOpenClose(p, data.o, false, new Date());
      if (data.b) updateOpenClose(b, data.b, undefined, new Date());
      const newu = {
        ...u,
        state: {
          ...u.state,
          p: p,
          b: b,
        },
      };
      await thunkAPI.dispatch(persistUiState({ data: newu }));
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const persistOpenBlock = createAsyncThunk(
  'uiState/persistOpenBlock',
  async (
    {
      data,
    }: {
      data: UiUpdate;
    },
    thunkAPI
  ) => {
    try {
      const u = getUiState();
      if (!u || !data.o) return;
      let b: UiElementState[] = u?.state?.b ? [...u.state.b] : [];
      updateOpenClose(b, data.o, true);
      const newu = {
        ...u,
        state: {
          ...u.state,
          b: b,
        },
      };
      await thunkAPI.dispatch(persistUiState({ data: newu }));
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const persistCloseBlock = createAsyncThunk(
  'uiState/persistCloseBlock',
  async (
    {
      data,
    }: {
      data: UiUpdate;
    },
    thunkAPI
  ) => {
    try {
      const u = getUiState();
      if (!u || !data.o) return;
      let b: UiElementState[] = u?.state?.b ? [...u.state.b] : [];
      updateOpenClose(b, data.o, false, new Date());
      const newu = {
        ...u,
        state: {
          ...u.state,
          b: b,
        },
      };
      await thunkAPI.dispatch(persistUiState({ data: newu }));
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export function addUiReducers() {
  return {};
}

export function addUiStateCases(builder: ActionReducerMapBuilder<IUserState>) {
  builder
    .addCase(persistUiState.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(persistUiState.fulfilled, (state, action) => {
      state.user.ui = { ...action.meta.arg.data };
      if (!state.user.ui.id) {
        state.user.ui.id = action.payload?.response.data._id;
      }
    })
    .addCase(persistUiState.rejected, (state, action) => {});
}
