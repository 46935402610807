import axios from 'axios';
import { END_POINT } from './consts';

interface IPostData {
  [key: string]: any;
}

class API {
  async get(path: string, params = {}, headers = {}) {
    return await axios.get(END_POINT + path, {
      params,
      ...headers,
      withCredentials: true,
    });
  }
  async post(path: string, postdata: IPostData, params = {}, headers = {}) {
    return await axios.post(END_POINT + path, JSON.stringify(postdata), {
      params,
      headers: { 'Content-Type': 'application/json', ...headers },
      withCredentials: true,
    });
  }
  async patch(path: string, postdata: IPostData, params = {}, headers = {}) {
    return await axios.patch(END_POINT + path, JSON.stringify(postdata), {
      params,
      headers: { 'Content-Type': 'application/json', ...headers },
      withCredentials: true,
    });
  }
  async delete(path: string, params = {}, headers = {}) {
    return await axios.delete(END_POINT + path, {
      params,
      ...headers,
      withCredentials: true,
    });
  }
}

const api = new API();
export default api;
