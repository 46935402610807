import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { mergeObjects } from 'helpers/objects';

export const asyncApiSudokuNew = createAsyncThunk(
  '/sudoku/asyncApiSudokuNew',
  async ({ data, stateMan }: { data: any; stateMan: StateMan }) => {
    return { data, stateMan };
  }
);
export const asyncApiSudokuUpdated = createAsyncThunk(
  '/sudoku/asyncApiSudokuUpdated',
  async ({ data, stateMan }: { data: any; stateMan: StateMan }) => {
    return { data, stateMan };
  }
);
export const asyncApiSudokuRemoved = createAsyncThunk(
  '/sudoku/asyncApiSudokuRemoved',
  async ({ data, stateMan }: { data: any; stateMan: StateMan }) => {
    return { data, stateMan };
  }
);

export function addSudokuCases(
  builder: ActionReducerMapBuilder<IDialogueState>
) {
  builder
    .addCase(asyncApiSudokuNew.fulfilled, (state, action) => {})
    .addCase(asyncApiSudokuUpdated.fulfilled, (state, action) => {
      const payload = action.meta.arg.data;

      const stateObj = action.meta.arg.stateMan(state);

      const rootUpdated = payload.updated;
      mergeObjects(stateObj, rootUpdated);

      // if (deepSearchKey(rootUpdated, 'lists')) {
      //   const payloadSudokus = rootUpdated.lists;

      //   const isSudokuItems = deepSearchKeyType(
      //     payloadSudokus,
      //     'listItems',
      //     'array',
      //     true
      //   );

      //   const isLikes = deepSearchKeyType(payloadSudokus, 'likes', 'array', true);

      //   if (!isSudokuItems && !isLikes && deepSearchKey(payloadSudokus, 'new')) {
      //     for (const newSudoku of payloadSudokus.new) {
      //       if (!arraySomeById(lists, newSudoku.id)) {
      //         lists.push(newSudoku);
      //       }
      //     }
      //   }

      //   if (deepSearchKey(payloadSudokus, 'updated')) {
      //     for (const updatedSudoku of payloadSudokus.updated) {
      //       const listIndex = findIndexById(lists, updatedSudoku.id);

      //       const stateSudoku = lists[listIndex];
      //       const stateSudokuItems = stateSudoku.listItems;

      //       if (isSudokuItems) {
      //         const updatedSudokuItems = updatedSudoku.updated.listItems;

      //         if (
      //           deepSearchKey(updatedSudokuItems, 'new') &&
      //           updatedSudokuItems.new != null
      //         ) {
      //           for (const listItem of updatedSudokuItems.new) {
      //             if (!arraySomeById(stateSudokuItems, listItem.id)) {
      //               stateSudokuItems.push(listItem);
      //             }
      //           }
      //         }

      //         if (deepSearchKey(updatedSudokuItems, 'updated')) {
      //           for (const listItem of updatedSudokuItems.updated) {
      //             const listItemIndex = findIndexById(
      //               stateSudokuItems,
      //               listItem.id
      //             );

      //             const stateSudokuItem = stateSudokuItems[listItemIndex];

      //             if (isLikes) {
      //               const updatedLikes = listItem.updated.message.updated.likes;

      //               if (deepSearchKey(updatedLikes, 'new')) {
      //                 for (const like of updatedLikes.new) {
      //                   if (
      //                     !arraySomeById(stateSudokuItem.message.likes, like.id)
      //                   ) {
      //                     stateSudokuItem.message.likes.push(like);
      //                   }
      //                 }
      //               }
      //               if (deepSearchKey(updatedLikes, 'removed')) {
      //                 for (const like of updatedLikes.removed) {
      //                   const likeIndex = findIndexById(
      //                     stateSudokuItem.message.likes,
      //                     like
      //                   );

      //                   if (likeIndex !== -1) {
      //                     stateSudokuItem.message.likes.splice(likeIndex, 1);
      //                   }
      //                 }
      //               }
      //             } else {
      //               const updatedMessage = listItem.updated.message;

      //               if (deepSearchKey(updatedMessage, 'updated')) {
      //                 if (updatedMessage.updated.replies != null) {
      //                   const payloadReplies = updatedMessage.updated.replies;

      //                   if (payloadReplies.new != null) {
      //                     for (const reply of payloadReplies.new) {
      //                       if (
      //                         !arraySomeById(
      //                           stateSudokuItem.message.replies,
      //                           reply.id
      //                         )
      //                       ) {
      //                         stateSudokuItem.message.replies.push(reply);
      //                       }
      //                     }
      //                   }

      //                   if (payloadReplies.updated != null) {
      //                     for (const reply of payloadReplies.updated) {
      //                       const replyIndex = findIndexById(
      //                         stateSudokuItem.message.replies,
      //                         reply.id
      //                       );

      //                       if (replyIndex !== -1) {
      //                         stateSudokuItem.message.replies[replyIndex] =
      //                           updateObject(
      //                             stateSudokuItem.message.replies[replyIndex],
      //                             reply.updated
      //                           );
      //                       }
      //                     }
      //                   }

      //                   if (payloadReplies.removed != null) {
      //                     for (const reply of payloadReplies.removed) {
      //                       const replyIndex = findIndexById(
      //                         stateSudokuItem.message.replies,
      //                         reply
      //                       );

      //                       if (replyIndex !== -1) {
      //                         stateSudokuItem.message.replies.splice(
      //                           replyIndex,
      //                           1
      //                         );
      //                       }
      //                     }
      //                   }
      //                 } else {
      //                   stateSudokuItem.message = updateObject(
      //                     stateSudokuItem.message,
      //                     updatedMessage.updated
      //                   );
      //                 }
      //               }

      //               updateObjectDirectly(stateSudokuItem, listItem.updated);
      //             }
      //           }
      //         }

      //         if (
      //           deepSearchKey(updatedSudokuItems, 'removed') &&
      //           updatedSudokuItems.removed != null
      //         ) {
      //           for (const listItem of updatedSudokuItems.removed) {
      //             const listItemIndex = findIndexById(stateSudokuItems, listItem);

      //             if (listItemIndex !== -1) {
      //               stateSudokuItems.splice(listItemIndex, 1);
      //             }
      //           }
      //         }
      //       } else {
      //         updateObjectDirectly(stateSudoku, updatedSudoku.updated);
      //       }
      //     }
      //   }

      //   if (
      //     !isSudokuItems &&
      //     !isLikes &&
      //     deepSearchKey(payloadSudokus, 'removed')
      //   ) {
      //     for (const deletedSudoku of payloadSudokus.removed) {
      //       const listIndex = findIndexById(lists, deletedSudoku);

      //       if (listIndex !== -1) {
      //         lists.splice(listIndex, 1);
      //       }
      //     }
      //   }
      // }
    })
    .addCase(asyncApiSudokuRemoved.fulfilled, (state, action) => {});
}
