import ScrollObserver from 'components/scrollobserver/ScrollObserver';
import GridBG from 'components/static/GridBG';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

export default function Error404() {
  const navigate = useNavigate();
  const scrollableRef = useRef(null);

  function goBack() {
    navigate(-1);
  }

  return (
    <div className={'static_page'}>
      <div className="page_container">
        <GridBG />
        <ScrollObserver
          scrollableRef={scrollableRef}
          customScrollbar={false}
          toBottom={false}
        >
          <div className="page_content" ref={scrollableRef}>
            {/* {renderHtml(content)} */}
            <section>
              <h1 className="static_title">
                <FormattedMessage id="NAVIGATION.ERROR_404.TITLE" />
              </h1>
              <div className="static_text">
                <FormattedMessage id="NAVIGATION.ERROR_404.TEXT1" />
                <br />
                <FormattedMessage id="NAVIGATION.ERROR_404.TEXT2" />
                <br />
                <div className="inline_link" onClick={goBack}>
                  <FormattedMessage id="NAVIGATION.ERROR_404.BACK" />
                </div>
                <br />
                <a href="/">
                  <FormattedMessage id="NAVIGATION.ERROR_404.HOME" />
                </a>
              </div>
            </section>
          </div>
        </ScrollObserver>
      </div>
    </div>
  );
}
