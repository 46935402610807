import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Status } from '@thuas/pd-schemas';
import { useAppDispatch } from 'app/hooks';
import { getDialogueUnreadCount } from 'app/unreadCount';
import UnreadCounter from 'components/dialogues/UnreadCounter';
import FormattedDate from 'components/formatters/FormattedDate';
import FormattedMessage from 'components/formatters/FormattedMessage';
import { showModal } from 'components/forms/ModalDialog';
import { ProjectFormLink } from 'components/forms/ProjectForm';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import LinkifyText from 'components/messages/Linkify';
import DropMenu from 'components/navigation/DropMenu';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { IFile } from 'features/files/fileAPI';
import { IProject } from 'features/projects/projectsAPI';
import { getVisitedState } from 'features/uiState/uiStateSlice';
import {
  UserStateProps,
  mapUserStateToProps,
  subscribeAsync,
  unsubscribeAsync,
} from 'features/user/userSlice';
import { AnyOrderObject } from 'helpers/objects';
import { sortByOrder } from 'helpers/sorting';

import classNames from 'classnames';
import 'css/cards.scss';
import { deleteProjectAsync } from 'features/projects/projectsSlice';

type DialogueItemProps = {
  dialogue: IDialogue;
  last: boolean;
  triggerReload?: () => void;
};
function UnconnectedDialogueItem(props: DialogueItemProps & UserStateProps) {
  const { dialogue, last, user, userCanEdit, triggerReload } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (typeof dialogue !== 'object') return <></>;
  const visitedState = getVisitedState('dialogue', dialogue.id);
  const unread = getDialogueUnreadCount(dialogue);
  const subscribed = dialogue.subscribers
    ? dialogue.subscribers.map((s) => s.id).includes(user.id)
    : false;

  function toggleSubscription() {
    function doSubscribe(): boolean {
      if (subscribed) {
        const data = { dialogueId: dialogue.id };
        dispatch(unsubscribeAsync(data)).then(() => {
          if (triggerReload) triggerReload();
        });
      } else {
        const data = { dialogueId: dialogue.id };
        dispatch(subscribeAsync(data)).then(() => {
          // if (triggerReload) triggerReload();
          navigate(`/Dialogue/${dialogue.id}`);
        });
      }
      return true;
    }
    showModal(
      <div>
        <FormattedMessage
          id={`DIALOGUES.SUBSCRIPTION_FORM.TITLE${subscribed ? '_2' : ''}`}
        />
      </div>,
      <div className="pd_form">
        <div className="subheading bold">{dialogue.title}</div>
        <div className="text">{dialogue.description}</div>
        {subscribed && dialogue.status === Status.private ? (
          <div className="warning">
            <FormattedMessage id="DIALOGUES.SUBSCRIPTION_FORM.CONFIRM_2" />
          </div>
        ) : null}
      </div>,
      {
        intl: intl,
        // className: 'pd_alert',
        submitBtnText: (
          <FormattedMessage
            id={`DIALOGUES.SUBSCRIPTION_FORM.SUBMIT${subscribed ? '_2' : ''}`}
          />
        ),
        cancelBtnText: (
          <FormattedMessage
            id={`DIALOGUES.SUBSCRIPTION_FORM.CANCEL${subscribed ? '_2' : ''}`}
          />
        ),
        onSubmit: doSubscribe,
        onCancel: () => {
          return true;
        },
        editing: true,
        cancelDefault: subscribed,
      }
    );
  }

  function wrapInLink(toDialogue: boolean, content: JSX.Element) {
    if (toDialogue)
      return <Link to={`/dialogue/${dialogue.id}`}>{content}</Link>;
    return (
      <div className="subscribe_link" onClick={toggleSubscription}>
        {content}
      </div>
    );
  }

  return (
    <>
      {wrapInLink(
        userCanEdit(dialogue) || subscribed,
        <>
          <div className="dialogue">
            <div className="card_img">
              <img
                src={
                  dialogue.background
                    ? (dialogue.background as IFile).uri
                    : '/img/backgroundImage.png'
                }
                alt=""
              />
              <div className="status">
                <FormattedMessage
                  id={
                    dialogue.status
                      ? `DIALOGUE.STATUS.${dialogue.status.toUpperCase()}`
                      : ''
                  }
                />
                {/* {' '}
            <FormattedMessage id="DIALOGUES.DIALOGUE" /> */}
              </div>
            </div>
            <div className="card_descr">
              <div className="card_subtitle">
                <div>{dialogue.title}</div>
                {
                  // dialogue.latestNew &&
                  // new Date(dialogue.latestNew).getTime() >
                  unread && (visitedState[2] ?? true) ? (
                    <UnreadCounter unread={unread} />
                  ) : null
                }
              </div>
              <div className="text">{dialogue.description}</div>
              <div className="italic">
                {!!visitedState[2] ? (
                  <>
                    <FormattedMessage id="DIALOGUES.LASTVISITED" />{' '}
                    <FormattedDate
                      value={visitedState[2] ?? 0}
                      showTime={true}
                      format={{
                        hour: '2-digit',
                        minute: '2-digit',
                        second: undefined,
                      }}
                      nodate="never"
                    />
                  </>
                ) : (
                  <>
                    <FormattedMessage id="DIALOGUES.NOTVISITED" />.
                  </>
                )}
              </div>
            </div>
          </div>
          {/* {last ? null : <div className="card_subdivider" />} */}
        </>
      )}
      <Icon
        className="subscribed"
        style={{ backgroundPosition: 'left' }}
        size={18}
        symbol={subscribed ? IconSymbol.user : IconSymbol.user_unsubscribed}
        onClick={toggleSubscription}
        hintProps={{
          hint: intl.formatMessage({
            id: `DIALOGUES.${subscribed ? 'SUBSCRIBED' : 'SUBSCRIBE'}`,
          }),
          offset: { x: 40, y: -10 },
          offsetBottom: true,
        }}
      >
        {dialogue.subscribersCount}
      </Icon>
    </>
  );
}
const DialogueItem = connect(mapUserStateToProps)(UnconnectedDialogueItem);

type ProjectCardProps = {
  project: IProject;
  triggerReload?: () => void;
};

function UnconnectedProjectCard(props: ProjectCardProps & UserStateProps) {
  const { project, user, userCanEdit, triggerReload } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const subscribed = project.subscribers
    ? project.subscribers.map((s) => s.id).includes(user.id)
    : false;

  function toggleSubscription() {
    function doSubscribe(): boolean {
      if (subscribed) {
        const data = { projectId: project.id };
        dispatch(unsubscribeAsync(data)).then(() => {
          if (triggerReload) triggerReload();
        });
      } else {
        const data = { projectId: project.id };
        dispatch(subscribeAsync(data)).then(() => {
          if (triggerReload) triggerReload();
        });
      }
      return true;
    }
    showModal(
      <div>
        <FormattedMessage
          id={`PROJECTS.SUBSCRIPTION_FORM.TITLE${subscribed ? '_2' : ''}`}
        />
      </div>,
      <div className="pd_form">
        <div className="subheading bold">{project.title}</div>
        <div className="text">{project.description}</div>
        {subscribed && project.status === Status.private ? (
          <div className="warning">
            <FormattedMessage id="PROJECTS.SUBSCRIPTION_FORM.CONFIRM_2" />
          </div>
        ) : null}
      </div>,
      {
        intl: intl,
        // className: 'pd_alert',
        submitBtnText: (
          <FormattedMessage
            id={`PROJECTS.SUBSCRIPTION_FORM.SUBMIT${subscribed ? '_2' : ''}`}
          />
        ),
        cancelBtnText: (
          <FormattedMessage
            id={`PROJECTS.SUBSCRIPTION_FORM.CANCEL${subscribed ? '_2' : ''}`}
          />
        ),
        onSubmit: doSubscribe,
        onCancel: () => {
          return true;
        },
        editing: true,
        cancelDefault: subscribed,
      }
    );
  }

  function removeProject() {
    function doDelete(): boolean {
      if (!project) return false;
      dispatch(deleteProjectAsync(project));
      return true;
    }
    function confirmDelete(): boolean {
      showModal(
        <FormattedMessage id="PROJECTS.DELETE.CONFIRM" />,
        <>
          <div className="title">
            <FormattedMessage id="PROJECTS.DELETE.CONFIRM_CNF" />
          </div>
          <div className="text">
            <FormattedMessage id="PROJECTS.DELETE.CONFIRM_CNF2" />
          </div>
          <Icon
            symbol={IconSymbol.alert}
            variant={IconVariant.accent}
            size={80}
          />
          <div className="text">
            <FormattedMessage id="GENERAL.DELETE.NOTICE" />
          </div>
        </>,
        {
          intl: intl,
          className: 'pd_alert serious',
          submitBtnText: <FormattedMessage id="X.DELETE" />,
          onSubmit: doDelete,
          editing: true,
          cancelDefault: true,
        }
      );
      return true;
    }
    showModal(
      <FormattedMessage id="PROJECTS.DELETE.DELETE" />,
      <>
        <div className="title">
          <FormattedMessage id="PROJECTS.DELETE.DELETE_CNF" />
        </div>
        <div className="text">
          <FormattedMessage id="PROJECTS.DELETE.DELETE_CNF2" />
        </div>
        <Icon
          symbol={IconSymbol.alert}
          variant={IconVariant.accent}
          size={80}
        />
        <div className="text">
          <FormattedMessage id="GENERAL.DELETE.NOTICE" />
        </div>
      </>,
      {
        intl: intl,
        className: 'pd_alert',
        submitBtnText: <FormattedMessage id="X.DELETE" />,
        onSubmit: confirmDelete,
        editing: true,
        cancelDefault: true,
      }
    );
  }

  return (
    <div
      key={project.id}
      className={classNames('card', {
        card_wide:
          (project.dialogues?.length ?? 0) > 2 ||
          (project.description?.length ?? 0) > 250,
      })}
    >
      <div className="card_header">
        <div className="card_label">
          <FormattedMessage
            id={
              project.status
                ? `PROJECT.STATUS.${project.status.toUpperCase()}`
                : ''
            }
          />{' '}
          <FormattedMessage id="PROJECTS.PROJECT" />
        </div>
        <DropMenu right>
          <DropMenu.Trigger />
          <DropMenu.Items>
            <ProjectFormLink project={project}>
              <FormattedMessage
                id={
                  userCanEdit(project)
                    ? 'NAVIGATION.ADMIN_MENU.EDIT_PROJECT'
                    : 'PROJECT.ABOUT'
                }
              />
            </ProjectFormLink>
            {userCanEdit(project) ? (
              <div onClick={removeProject}>
                <FormattedMessage id="PROJECTS.DELETE.DELETE" />
              </div>
            ) : null}
          </DropMenu.Items>
        </DropMenu>
      </div>
      <div className={'card_body'}>
        <div className="card_body_top">
          {project.background ? (
            <div className="card_img">
              <img
                src={
                  project.background
                    ? (project.background as IFile).uri
                    : '/img/backgroundImage.png'
                }
                alt=""
              />
            </div>
          ) : null}
          <div className="card_title">{project.title}</div>
          <div className="card_descr">
            <LinkifyText>{project.description}</LinkifyText>
            <Icon
              className="subscribed"
              style={{ backgroundPosition: 'left' }}
              size={18}
              symbol={
                subscribed ? IconSymbol.user : IconSymbol.user_unsubscribed
              }
              onClick={toggleSubscription}
              hintProps={{
                hint: intl.formatMessage({
                  id: `PROJECTS.${subscribed ? 'SUBSCRIBED' : 'SUBSCRIBE'}`,
                }),
                offset: { x: 40, y: 16 },
                offsetBottom: true,
              }}
            >
              {project.subscribersCount}
            </Icon>
          </div>
          {/* {project.startedAt ? (
            <div className={project.closedAt ? 'period' : ''}>
              {project.closedAt ? (
                <FormattedPeriod
                  from={project.startedAt}
                  to={project.closedAt}
                />
              ) : (
                <FormattedDate value={project.startedAt} />
              )}
            </div>
          ) : null} */}
          {/* </ProjectFormLink> */}
        </div>
        <div className="card_body_bottom">
          {project.dialogues?.length ? (
            <>
              <div className="card_divider" />
              {/* <div className="card_subtitle uppercase">
                <FormattedMessage id="PROJECT.VIEW.DIALOGUES" />
                &nbsp;
                <span className="not_bold">
                  <FormattedMessage id="PROJECT.VIEW.INTHIS" />
                </span>
              </div> */}
              <div className="dialogues">
                {sortByOrder(
                  project.dialogues as IDialogue & AnyOrderObject[]
                ).map((dialogue, i, a) => {
                  return typeof dialogue === 'object' ? (
                    <DialogueItem
                      key={i}
                      dialogue={dialogue as IDialogue}
                      last={i === a.length - 1}
                      triggerReload={triggerReload}
                    />
                  ) : null;
                })}
              </div>
            </>
          ) : (
            <>
              <div className="card_divider" />
              <FormattedMessage id="PROJECT.VIEW.NO_DIALOGUES" />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const ProjectCard = connect(mapUserStateToProps)(UnconnectedProjectCard);
export default ProjectCard;
