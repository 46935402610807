import { PropsWithChildren, ReactNode, SyntheticEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { useForms } from 'components/forms/Forms';
import { isToolAvailable } from 'features/admin/appSettingsSlice';
import { BlockType, ListType } from 'features/block/blockSlice';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IPhase } from 'features/phase/phaseAPI';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { AnyOrderObject, sameId } from 'helpers/objects';
import { sortByOrder } from 'helpers/sorting';
import DropMenu from './DropMenu';

import './AddToolMenu.scss';

type AddToolMenuProps = {
  stateMan: StateMan;
};
function UnconnectedAddToolMenu(
  props: AddToolMenuProps & UserStateProps & DialogueStateProps
) {
  const { userCanEdit, dialogue } = props;
  const intl = useIntl();
  const [phase, setPhase] = useState<IPhase | null>(null);
  const forms = useForms();

  function onOpen(e: SyntheticEvent): boolean {
    if (!phase || !dialogue.phases?.map((ph) => ph.id).includes(phase.id)) {
      dialogue.phases?.forEach((ph) => {
        if (ph.active) setPhase(ph);
      });
      return true;
    }
    const ph = dialogue.phases?.find((ph) => sameId(phase, ph));
    if (ph) {
      setPhase(ph);
      return true;
    }
    return false;
  }

  function shouldClose(e: SyntheticEvent): boolean {
    if ((e.target as HTMLInputElement).classList.contains('form-check-input'))
      return false;
    return true;
  }

  function addBlock(blockType: BlockType, listType?: ListType) {
    if (!phase || !phase.blocks) return;
    const formName = blockType + 'SettingsForm';
    const form = (forms as any)[formName];
    if (form) {
      form.setCurrentBlockInfo({ phase: phase, listType: listType });
      form.setCurrentStateMan(() => props.stateMan);
      // the form handles everything else:
      // creating and posting the block
      form.setShow(true);
    } else {
      alert('This tool is not available yet... sorry!');
    }
  }

  type ToolProps = {
    blockType: BlockType;
    listType?: ListType;
    children?: ReactNode;
  };
  function Tool(props: ToolProps & PropsWithChildren) {
    const { blockType, listType, children } = props;
    if (blockType && !isToolAvailable(blockType)) return null;
    if (listType && !isToolAvailable(listType)) return null;
    return (
      <div
        className={classNames(
          'add_tool_tool',
          `add_tool_${listType || blockType}`
        )}
        id={listType || blockType}
        onClick={() => {
          addBlock(blockType, listType);
        }}
      >
        {children}
      </div>
    );
  }

  if (!dialogue || !userCanEdit(dialogue)) return null;
  const fixedPhases = sortByOrder(
    (dialogue.phases?.filter((ph) => ph.fixed) || []) as AnyOrderObject[]
  );
  const sortedPhases = sortByOrder(
    (dialogue.phases?.filter((ph) => !ph.fixed) || []) as AnyOrderObject[]
  );
  return (
    <DropMenu className="add_tool" shouldClose={shouldClose} onOpen={onOpen}>
      <DropMenu.Trigger
        hintProps={{ hint: intl.formatMessage({ id: 'BLOCKS.ADD.ADD' }) }}
      >
        <div className="add_tool_icon" />
      </DropMenu.Trigger>
      <DropMenu.Items>
        <DropMenu.Note>
          <FormattedMessage id="BLOCKS.ADD.SELECT_PHASE" />
        </DropMenu.Note>
        <DropMenu.Items>
          {fixedPhases.map((ph) =>
            ph.blocks.length > 0 ? null : (
              <Form.Check
                key={ph.id}
                type={'radio'}
                id={`${ph.id}`}
                label={
                  ph.name ||
                  (ph.fixed
                    ? intl.formatMessage({
                        id: 'PHASES.LIBRARY',
                      })
                    : '')
                }
                checked={phase?.id === ph.id}
                onChange={() => {
                  setPhase(ph);
                }}
              />
            )
          )}
          {sortedPhases.map((ph) => (
            <Form.Check
              key={ph.id}
              type={'radio'}
              id={`${ph.id}`}
              label={ph.name}
              checked={phase?.id === ph.id}
              onChange={() => {
                setPhase(ph);
              }}
            />
          ))}
        </DropMenu.Items>
        <DropMenu.Divider />
        <DropMenu.Note>
          <FormattedMessage id="BLOCKS.ADD.SELECT_TOOL" />
        </DropMenu.Note>
        {phase && phase.fixed ? (
          <DropMenu.Items disabled={!!phase.blocks?.length}>
            <Tool blockType={BlockType.Library}>
              <FormattedMessage id="DOCUMENTS.LIBRARY" />
            </Tool>
          </DropMenu.Items>
        ) : (
          <>
            <DropMenu.Items disabled={!phase}>
              <Tool blockType={BlockType.Chat}>
                <FormattedMessage id="TOOLS.CHAT" />
              </Tool>
              <Tool blockType={BlockType.List} listType={ListType.MultiList}>
                <FormattedMessage id="TOOLS.MULTILIST" />
              </Tool>
              <Tool blockType={BlockType.List} listType={ListType.ProCon}>
                <FormattedMessage id="TOOLS.PROCON" />
              </Tool>
              <Tool blockType={BlockType.List} listType={ListType.SWOT}>
                <FormattedMessage id="TOOLS.SWOT" />
              </Tool>
              <Tool blockType={BlockType.List} listType={ListType.Kanban}>
                <FormattedMessage id="TOOLS.KANBAN" />
              </Tool>
              <Tool blockType={BlockType.Poll}>
                <FormattedMessage id="TOOLS.POLL" />
              </Tool>
              <Tool blockType={BlockType.Document}>
                <FormattedMessage id="TOOLS.DOCUMENT" />
              </Tool>
              <Tool blockType={BlockType.Sudoku}>
                <FormattedMessage id="TOOLS.SUDOKU" />
              </Tool>
              {/* <Tool blockType={BlockType.Meeting}>
                <FormattedMessage id="TOOLS.MEETING" />
              </Tool> */}
            </DropMenu.Items>
          </>
        )}
      </DropMenu.Items>
    </DropMenu>
  );
}

const AddToolMenu = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(UnconnectedAddToolMenu)
);
export default AddToolMenu;
