import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: `${process.env.PUBLIC_URL}/fonts/Roboto-Regular.ttf` },
    {
      src: `${process.env.PUBLIC_URL}/fonts/Roboto-Bold.ttf`,
      fontWeight: 'bold',
    },
    {
      src: `${process.env.PUBLIC_URL}/fonts/Roboto-Italic.ttf`,
      fontStyle: 'italic',
    },
  ],
});

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '20 30',
    fontFamily: 'Roboto',
    fontSize: '10pt',
    marginTop: '32',
  },
  svg: {
    width: '100%',
    height: 'auto',
  },
  img: {
    width: '100%',
    height: 'auto',
  },

  title: {
    fontWeight: 700,
    fontSize: '18pt',
  },
  description: {
    fontSize: '12pt',
    marginTop: '12',
  },
  date: {
    fontSize: '12pt',
  },
  dialogue_title: {
    fontWeight: 700,
    fontSize: '12pt',
  },
  phase_title: {
    fontStyle: 'italic',
    fontSize: '12pt',
    marginBottom: '6px',
  },
  block_header: {
    marginBottom: '10px',
  },
  block_title: {
    fontWeight: 700,
    fontSize: '14pt',
  },
  block_description: {
    fontSize: '12pt',
  },
  block_content: {
    marginTop: '8px',
  },

  chat_message_wrapper: {
    marginBottom: '8px',
    padding: '4px 8px',
    borderRadius: '4px',
    border: '1px solid rgb(210,210,210)',
  },
  message: {},
  message_mini: { marginBottom: '4px' },
  message_content: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12pt',
  },
  message_date: {
    marginTop: '4px',
    marginBottom: '8px',
    padding: '4px',
    textAlign: 'center',
    borderRadius: '4px',
    backgroundColor: '#D1D1D1',
  },
  message_header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2px',
  },
  message_footer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'flex-end',
  },
  message_edited: {
    fontStyle: 'italic',
    fontSize: '8pt',
    color: 'rgb(160,160,160)',
  },
  message_replyto: {
    marginLeft: '8px',
    marginBottom: '4px',
    fontSize: '9pt',
  },
  message_image: {
    padding: '2px 0 8px',
    maxWidth: '50%',
    // border: '1px solid grey',
  },
  message_replies: {
    marginTop: '4px',
    marginBottom: '0',
    marginLeft: '8px',
  },
  message_color: {
    position: 'absolute',
    top: '-4px',
    left: '-8px',
    width: '20px',
    height: '20px',
    borderTopLeftRadius: '3px',
    overflow: 'hidden',
  },
  message_extraContent: {
    marginLeft: 'auto',
  },

  author: {
    // fontStyle: 'italic',
    fontWeight: 'demibold',
  },
  time: {
    marginLeft: 'auto',
    fontSize: '9px',
  },
  like: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    gap: '4px',
    alignItems: 'flex-end',
  },
  like_icon: {
    width: '18px',
    height: '18px',
  },
  like_dots: {
    // marginRight: '4px',
    // border: '1px solid grey',
  },
  like_dot: {
    width: '6px',
    height: '18px',
  },

  list_message_wrapper: {
    marginBottom: '8px',
    padding: '4px 8px',
    borderRadius: '4px',
    border: '1px solid rgb(210,210,210)',
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px',
  },
  lists_1: { width: '100%' },
  lists_2: { width: '48%' },
  lists_3: { width: '30%' },
  lists_4: { width: '48%' },
  list: {},
  list_header: {
    fontSize: '12px',
    fontWeight: 700,
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
  },
  list_header_2: { marginLeft: 'auto' },
  pro: { maxWidth: '70%', backgroundColor: 'rgb(182, 222, 197)' },
  con: {
    width: '70%',
    marginLeft: 'auto',
    backgroundColor: 'rgb(255, 213, 163)',
  },

  poll_message_wrapper: {
    marginBottom: '8px',
    padding: '4px 4px 10px 0',
    fontSize: '12pt',
  },
  poll: {},
  poll_results: {},
  poll_option: {
    // fontWeight: 'bold',
  },
  poll_gauge: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '6px',
    // border: '1px solid grey',
    backgroundColor: 'rgb(230, 230, 230)',
    overflow: 'hidden',
  },
  poll_gauge_rect: {
    width: '40%',
    // border: '1px solid red',
  },
});
