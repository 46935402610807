import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type AdminState = {
  demoMode: boolean;
  showIds: boolean;
};
export type AdminStateProps = AdminState;
const initialState: AdminState = {
  demoMode: false,
  showIds: false,
};

export function mapAdminStateToProps(state: RootState): AdminStateProps {
  // proxyLog('state', state);
  return {
    demoMode: state.admin.demoMode,
    showIds: state.admin.showIds,
  };
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setDemoMode: (state: any, action: PayloadAction<boolean>) => {
      state.demoMode = action.payload;
    },
    setShowIds: (state: any, action: PayloadAction<boolean>) => {
      state.showIds = action.payload;
    },
  },
});

export const adminActions = adminSlice.actions;
export const { setDemoMode, setShowIds } = adminSlice.actions;

export default adminSlice.reducer;
