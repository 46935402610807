import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { prepareForBackend } from 'helpers/objects';

export type IListBlock = API['ListBlock']['read'];
export type IListBlockCreate = API['ListBlock']['create'];

function prepareForCreate(list: IListBlock): IListBlockCreate {
  return prepareForBackend(list, ['id']) as IListBlockCreate;
}

export const getListBlockAndRelatedData = async (id: number) => {
  try {
    return await api.get(`ListBlock/${id}?and=lists.(*,listItems.*)`);
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const postListBlock = async (data: IListBlock) => {
  try {
    return await api.post('ListBlock', prepareForCreate(data));
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchListBlock = async (id: number, data: IListBlock) => {
  try {
    return await api.patch('ListBlock/' + id, data);
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
