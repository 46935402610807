import { apibaseJson } from './apibasejsonreader.js';
import {
  Access,
  AccessOption,
  ApiBase,
  Attribute,
  AttributePath,
  Links,
  Model,
  RWAccess,
  RWDAccess,
  RWDAllAccess,
  RoleName,
  basicTypes,
  isRoleCondition,
  noAccess,
} from './baseTypes.js';

import { includes } from './typescriptHelper.js';
import { version } from './version.js';

// These 'Raw types' are used to read the apibase.json file

type RawAttribute = {
  delete?: string;
  read?: string;
  CRU?: string;
  CRUD?: string;
  createUpdate?: string;
  reference?: string;
  type?: string;
  unique?: boolean;
  create?: string;
  update?: string;
};

declare type RawAttributes = {
  [attributeName: string]: RawAttribute;
};
declare type RawModels = { [modelName: string]: RawAttributes };

declare type RawApiBase = {
  version: string;
  classes: RawModels;
  enums: { [key: string]: string[] };
  roles: { [key: string]: { inherits?: string[] } };
};

declare type Version = {
  major: number;
  minor: number;
  patch: number;
  preReleaseLabel?: string;
  preReleaseType?: string;
  preReleaseIncrement?: number;
};

function parseVersion(version: string): Version | undefined {
  const match = version.match(
    /([0-9]+)\.([0-9]+)\.([0-9]+)(-(([a-z]+)([.-]([0-9]+))?)?)?/
  );
  if (!match) throw new Error(`Version '${version}' is not a correct version`);
  return {
    major: Number(match[1]),
    minor: Number(match[2]),
    patch: Number(match[3]),
    preReleaseLabel: match[5] ? match[5] : undefined,
    preReleaseType: match[5] ? match[6] : undefined,
    preReleaseIncrement: match[5] && match[8] ? Number(match[8]) : undefined,
  };
}
function versionHighEnough(supporting?: Version, testing?: Version) {
  if (supporting === undefined || testing === undefined) return true;
  return (
    supporting.major > testing.major ||
    (supporting.major == testing.major && supporting.minor > testing.minor) ||
    (supporting.major == testing.major &&
      supporting.minor == testing.minor &&
      supporting.patch >= testing.patch)
  );
}

let cacheApiBase: ApiBase | undefined;
export function parse(): ApiBase {
  if (cacheApiBase === undefined) {
    const data0: RawApiBase = apibaseJson as RawApiBase;
    if (!versionHighEnough(parseVersion(version), parseVersion(data0.version)))
      throw new Error('The version of the apibase is not supported yet!');

    // Add the role enum
    const roles = Object.entries(data0.roles).map(([roleName, role]) => ({
      name: roleName,
      inherits: role?.inherits ?? [],
    }));
    const enums = [
      ...Object.entries(data0.enums).map(([enumName, options]) => ({
        name: enumName,
        options,
      })),
      { name: 'Role', options: roles.map((r) => r.name) },
    ];
    const data = {
      enums,
      roles,
      models: [
        ...Object.entries(data0.classes).map(
          ([modelName, attributes]: [string, RawAttributes]) => {
            let allReadAccesss = [];
            for (const [attributeName, attribute] of Object.entries(
              attributes
            )) {
              if (attribute.createUpdate !== undefined) {
                attribute.create = attribute.createUpdate;
                attribute.update = attribute.createUpdate;
              }
              if (attribute.CRUD !== undefined) {
                attribute.create = attribute.CRUD;
                attribute.read = attribute.CRUD;
                attribute.update = attribute.CRUD;
                attribute.delete = attribute.CRUD;
              }
              if (attribute.CRU !== undefined) {
                attribute.create = attribute.CRU;
                attribute.read = attribute.CRU;
                attribute.update = attribute.CRU;
              }
              if (attribute.read) allReadAccesss.push(attribute.read);
            }
            attributes['id'] = {
              type: 'Int',
              unique: true,
              read: allReadAccesss.join('|'),
            };
            return new Model<null, null>(
              modelName,
              Object.keys(attributes)
                .filter((n) => n != 'null')
                .map((attributeName) => {
                  const attribute = attributes[attributeName];
                  if (attribute.delete)
                    throw new Error(
                      'Delete is only allowed on model level, using ~'
                    );
                  let type = attribute.type;
                  if (type == null)
                    throw new Error(
                      `All attributes should have a type, but ${attributeName} in ${modelName} does not!`
                    );
                  if (type == 'Roles') type = 'Role[]';
                  const optional: boolean = type.endsWith('?');
                  if (optional) type = type.substring(0, type.length - 1);
                  const array: boolean = type.endsWith('[]');
                  if (array) type = type.substring(0, type.length - 2);
                  if (optional && array)
                    throw new Error(
                      'Cannot be optional and array at the same time'
                    );
                  if (!type.match(/[A-Za-z_\d]+/))
                    throw new Error('Type name incorrect');
                  if (
                    !includes(basicTypes, type) &&
                    !Object.keys(data0.classes).includes(type) &&
                    !enums.map((e) => e.name).includes(type)
                  )
                    throw new Error(`Unkown type: ${type}!`);
                  const at: Attribute<null, null> = new Attribute<null, null>(
                    attributeName,
                    array,
                    optional,
                    null,
                    type,
                    attribute.unique ?? false,
                    attribute.reference,
                    null
                  );
                  return at;
                })
                .map((a) => a as Attribute),
              null
            );
          }
        ),
      ],
    };

    // The following links are important for databases:
    // then attributes should link to specific attributes on
    // the other side.

    let links_single: { model: string; type: string; attribute: string }[] = [];
    let links: {
      fromModel: string;
      toModel: string;
      fromAttribute: string;
      toAttribute: string;
    }[] = [];
    function getLink(
      themodel: string,
      theattribute: string,
      thetype: string
    ): { toModel: string; toAttribute: string } | null {
      for (let { fromModel, toModel, fromAttribute, toAttribute } of links) {
        if (fromModel == themodel && fromAttribute == theattribute)
          return {
            toModel,
            toAttribute,
          };
        if (toModel == themodel && toAttribute == theattribute)
          return {
            toModel: fromModel,
            toAttribute: fromAttribute,
          };
      }
      let found: { toModel: string; toAttribute: string } | null = null;
      for (let { model, type, attribute } of links_single) {
        let continue_ = false;
        for (let { fromModel, toModel, fromAttribute, toAttribute } of links)
          if (
            (model == fromModel && attribute == fromAttribute) ||
            (model == toModel && attribute == toAttribute)
          )
            continue_ = true;
        if (!continue_ && model == thetype && themodel == type)
          if (found != null)
            throw new Error(
              `Use reference! ${themodel}, ${theattribute}, ${thetype}`
            );
          else found = { toAttribute: attribute, toModel: model };
      }
      return found;
    }
    for (let model of data.models) {
      for (let attribute of model.attributes) {
        // let unique: boolean = attribute?.unique ?? false;
        let reference = attribute?.reference;
        if (reference != undefined)
          links.push({
            fromModel: model.name,
            toModel: attribute.type,
            fromAttribute: attribute.name,
            toAttribute: reference,
          });
        links_single.push({
          model: model.name,
          type: attribute.type,
          attribute: attribute.name,
        });
      }
    }

    let Links_: Links<null, null> = new Map();
    for (const model of data.models)
      for (const attribute of model.attributes) {
        const link = getLink(model.name, attribute.name, attribute.type);
        if (link != null)
          for (const model2 of data.models)
            if (model2.name == link.toModel)
              for (const attribute2 of model2.attributes)
                if (attribute2.name == link.toAttribute)
                  Links_.set(attribute, attribute2);
      }

    // Finished parsing

    let cacheApiBase2 = new ApiBase<null, null>(
      data.models,
      data.enums,
      Links_,
      data.roles.map((r) => ({ name: r.name, inherits: r.inherits ?? [] }))
    );

    // ugly workaround: this should actually be all the way below
    cacheApiBase = new ApiBase(
      <Model<never, never>[]>cacheApiBase2.models,
      cacheApiBase2.enums,
      <Links<never, never>>cacheApiBase2.links,
      cacheApiBase2.roles
    );

    // Do the access, can use parse() now, because cacheApiBase has been set

    function getAccess(
      modelName: string,
      attribute: RawAttribute,
      kind: 'create' | 'read' | 'delete' | 'update'
    ): Access | undefined {
      const access = attribute[kind];
      if (access === undefined) return undefined;
      const model = cacheApiBase2.findModel(modelName);
      if (access === '') return noAccess;
      let ret = new Access(
        access.split('|').map(
          (opt) =>
            new AccessOption(
              opt.split('&').map((part) => {
                if (part.includes('.')) {
                  const steps: Attribute[] = [];
                  for (const step of part.trim().split('.').slice(1))
                    steps.push(
                      <Attribute<never, never>>(
                        (steps.length == 0
                          ? model
                          : steps[steps.length - 1].model!
                        ).findAttribute(step)
                      )
                    );
                  return new AttributePath(steps);
                } else return new RoleName(part.trim());
              })
            )
        )
      );
      let changed = true;
      while (changed) {
        changed = false;
        if (!ret.notAlways()) return ret;
        for (const opt of ret.options) {
          for (const condition of opt.conditions) {
            if (!isRoleCondition(condition)) {
              const steps = condition.attributes;
              const lastStep = steps[steps.length - 1];
              if (!lastStep.isPrimitive()) {
                const reference = lastStep.references()!;
                doModel(reference.name); // recursive call!
                const lastAccess = reference.rootAccess![kind];
                ret = new Access([
                  ...ret.options!.filter((o) => o != opt),
                  ...(lastAccess.notAlways()
                    ? lastAccess.options.map(
                        (lastOption) =>
                          new AccessOption([
                            ...opt.conditions.filter((c) => c != condition),
                            ...lastOption.conditions.map((c) =>
                              isRoleCondition(c)
                                ? c
                                : new AttributePath([
                                    ...condition.attributes,
                                    ...c.attributes,
                                  ])
                            ),
                          ])
                      )
                    : [
                        new AccessOption(
                          opt.conditions.filter((c) => c != condition)
                        ),
                      ]),
                ]);
                changed = true;
                break;
              }
            }
          }
          if (changed) break;
        }
      }
      return ret;
    }

    function doModel(modelName: string) {
      const model = cacheApiBase2.findModel(modelName);
      const attributes = data0.classes[modelName];
      if (Object.keys(attributes).includes('null')) {
        const copy = { ...attributes['null'] };
        if (
          getAccess(modelName, copy, 'create') === undefined &&
          getAccess(modelName, copy, 'read') === undefined &&
          getAccess(modelName, copy, 'update') === undefined &&
          getAccess(modelName, copy, 'delete') === undefined
        )
          model.rootAccess = RWDAllAccess;
        else
          model.rootAccess = new RWDAccess(
            getAccess(modelName, copy, 'create') ?? noAccess,
            getAccess(modelName, copy, 'read') ?? noAccess,
            getAccess(modelName, copy, 'update') ?? noAccess,
            getAccess(modelName, copy, 'delete') ?? noAccess
          );
      } else model.rootAccess = RWDAllAccess;
    }
    for (const model of cacheApiBase2.models) doModel(model.name);
    for (const [modelName_, attributes] of Object.entries(data0.classes)) {
      function getAccessOrDefault(
        attribute: RawAttribute,
        kind: 'read' | 'create' | 'update'
      ) {
        const ret = getAccess(modelName, attribute, kind);
        if (ret === undefined)
          return cacheApiBase2.findModel(modelName).rootAccess![kind];
        else return ret;
      }
      const modelName = modelName_;
      for (const [attributeName, attribute] of Object.entries(attributes)) {
        if (attributeName == 'null') continue;
        if (attribute.delete)
          throw new Error('Delete is not allowed on attribute level!');
        cacheApiBase2
          .findModel(modelName)
          .findAttribute(attributeName).rwAccess = new RWAccess(
          getAccessOrDefault(attribute, 'create'),
          getAccessOrDefault(attribute, 'read'),
          getAccessOrDefault(attribute, 'update')
        );
      }
    }
    // TODO: better typing for roles!
  }

  return cacheApiBase;
}

export declare type typemap<T> = Partial<{
  [key in (typeof basicTypes)[number]]: T;
}>;

export function Roles() {
  parse().roles.map((roleItem) => roleItem.name);
}
