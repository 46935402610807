import 'reflect-metadata';
import { baseSchema } from './base.js';

type Ctor<T> = { new (): T };

export class Float {}
export class Integer {}
export class BigInteger {}
export class Bytes {}
export class Json {}

let store: { [key: string]: any } = {};
function make<T>(type: Ctor<T>) {
  if (!Object.keys(store).includes(type.name)) store[type.name] = new type();
  return store[type.name] as T;
}

export const ID = 'id';

export class It {
  [ID] = one(Number);
  constructor() {
    const name = this.constructor.name;
    if (!Object.keys(store).includes(name)) store[name] = this;
  }
}

interface Tracable {
  tracedPath: string[];
}

const setLater: { ret: any; toTrace: any }[] = [];
function wrap<T>(
  toTrace: T,
  at: string[],
  immediate: boolean = false
): T & Tracable {
  let ret = { tracedPath: at };
  if (immediate) makeProxy(ret, toTrace);
  else setLater.push({ ret, toTrace });
  return ret as T & Tracable;
  // this type cast is justified, when makeProxy is called on `setLater`
}

// The `Number` below is for `id`, it should be a path
export type Wrapped<T> = T extends It | Number
  ? T & Tracable & Referenceable<T>
  : T;

type Referenceable<T> = {
  _kind: string;
  _reference?: Tracable['tracedPath'] | ((t: T) => Tracable);
  _type: Ctor<any>;
};

function makeProxy(ret: any, toTrace: any) {
  const tracedPath = ret.tracedPath;
  Object.defineProperties(
    ret,
    Object.fromEntries(
      Object.keys(toTrace).map((key) => [
        key,
        {
          get() {
            if (key == 'tracedPath') return tracedPath;
            return wrap(toTrace[key], [...tracedPath, key], true);
          },
          set(value) {},
          enumerable: true,
        },
      ])
    )
  );
}
function doLater() {
  for (const { ret, toTrace } of setLater) makeProxy(ret, toTrace);
  for (const { ret, toTrace } of setLater)
    if (typeof ret._reference === 'function') {
      const retReferenceToTrace = ret._reference(toTrace);
      if (retReferenceToTrace != null)
        ret._reference = retReferenceToTrace.tracedPath;
    }
}

type Kind<T extends string> = { KIND: T };

type EnumType = string[];
const tmpF =
  <KindType extends string>(kind: KindType) =>
  <T extends object>(
    type: Ctor<T> | EnumType,
    reference?: (t: T) => Tracable
  ): Wrapped<T> & Kind<KindType> => {
    if (Array.isArray(type)) {
      return {
        enum: type,
        _kind: kind,
        _reference: reference,
        tracedPath: [],
      } as any; // this is for enums!
    }
    const ret: any = wrap(make(type), []);
    ret._kind = kind;
    ret._reference = reference;
    ret._type = type;
    return ret as Wrapped<T> & Kind<KindType>;
  };

const distinct_ = 'distinct';
const distinctmaybe_ = 'distinctmaybe';
const one_ = 'one';
const maybe_ = 'maybe';
const many_ = 'many';

export const distinct = tmpF(distinct_);
export const distinctmaybe = tmpF(distinctmaybe_);
export const one = tmpF(one_);
export const maybe = tmpF(maybe_);
export const many = tmpF(many_);

const arrayKinds = [many_];
const optionalKinds = [distinctmaybe_, maybe_];
const uniqueKinds = [distinct_, distinctmaybe_];

function CombineDecorators(a: any, b: any): any {
  // TODO: make this a typed function
  // TODO: accept an arbitrary amount of parameters
  return (p1: any, p2?: any) => {
    a(p1, p2);
    b(p1, p2);
  };
}

export const Constraint = <T>(func: (t: T) => boolean) =>
  Reflect.metadata('Constraint', func);

type CtorType<T> = {
  new (): T;
};

type AccessType = string | Tracable;
type CompositeAccessType = AccessType | Or | And;

type DType = '' | 'D';
type DContent<D extends DType> = D extends 'D'
  ? { delete: CompositeAccessType }
  : {};

type CRU<D extends DType> = {
  create: CompositeAccessType;
  read: CompositeAccessType;
  update: CompositeAccessType;
} & DContent<D>;

type CRUBuilder<D extends DType> =
  | Partial<CRU<D>>
  | ({
      createUpdate: CompositeAccessType;
      read?: CompositeAccessType;
    } & Partial<DContent<D>>)
  | (D extends 'D'
      ? {
          CRUD: CompositeAccessType;
        }
      : {
          CRU: CompositeAccessType;
        });

function isD(d: DType): d is 'D' {
  return d == 'D';
}
function accessesReducer<D extends DType>(
  accesses: CRUBuilder<D> | undefined,
  d: D
): Partial<CRU<D>> {
  if (!accesses) accesses = {};
  let ret: any; // TODO: make this "Partial<CRU<D>>", for type safety
  if ('createUpdate' in accesses) {
    ret = {
      create: accesses.createUpdate,
      update: accesses.createUpdate,
      read: accesses.read,
      ...(isD(d) ? { delete: (accesses as any).delete } : {}), // TODO: remove this "as any"
    };
  } else if ('CRUD' in accesses) {
    ret = {
      create: accesses.CRUD,
      update: accesses.CRUD,
      read: accesses.CRUD,
      delete: accesses.CRUD,
    };
  } else ret = accesses;
  return ret;
}

function crudsAdd<D extends DType>(
  crud1: Partial<CRU<D>> | undefined,
  crud2: Partial<CRU<D>> | undefined,
  d: D
): Partial<CRU<D>> | undefined {
  if (!crud1) return crud2;
  if (!crud2) return crud1;
  const inner = (a: any | undefined, b: any | undefined) =>
    a === undefined ? b : a;
  const ret: CRU<''> = {
    create: inner(crud1!.create, crud2!.create),
    read: inner(crud1!.read, crud2!.read),
    update: inner(crud1!.update, crud2!.update),
  };
  if (d === 'D')
    return {
      ...ret,
      delete: inner((crud1! as any).delete, (crud2! as any)!.delete),
    } as CRU<D>;
  else return ret as CRU<D>;
}

function finalizeCRUD<D extends DType>(
  crud: Partial<CRU<D>> | undefined,
  d: D
): CRU<D> {
  if (!crud)
    return accessesReducer(
      (d === '' ? { CRU: '*' } : { CRUD: '*' }) as CRUBuilder<D>,
      d
    ) as CRU<D>;
  return crudsAdd(
    crud,
    {
      create: '',
      read: '',
      update: '',
      ...(d === '' ? {} : { delete: '' }),
    } as Partial<CRU<D>>,
    d
  ) as CRU<D>;
}

type PropertyDecorator<T> = (target: T, propertyKey: string | symbol) => void;
type PropertyDecorator_ = <T>(target: T, propertyKey: string | symbol) => void;
type ClassDecorator<T> = (cls: CtorType<T>) => CtorType<T>;
type ClassDecorator_ = <T2 extends It>(cls: CtorType<T2>) => CtorType<T2>;
type MethodDecorator_ = <T, K extends PropertyKey>(
  target: Record<K, T>,
  propertyKey: K
) => void;
type MethodDecorator<V> = <K extends PropertyKey>(
  t: Record<K, V>,
  p: K
) => void;
interface MultiDecorator_<T> extends ClassDecorator_, MethodDecorator<T> {}
interface MultiDecorator<T, T2>
  extends ClassDecorator<T2>,
    MethodDecorator<T> {}

class ItSomething extends It {
  public AAAAAAA: number = 0;
}

// These types are from https://stackoverflow.com/questions/71376136/test-if-a-type-is-any-or-unknown
type IsStrictAny<T> = 0 extends 1 & T ? T : never;
type IsNotStrictAny<T> = T extends IsStrictAny<T> ? never : T;
type IsVoid<T> = T extends void ? T : never;
type IsStrictVoid<T> = IsVoid<T> & IsNotStrictAny<T>;
type IsStrictNever<T> = [T] extends [never] ? true : false;
type IsStrictUnknown<T> = T extends IsStrictAny<T>
  ? never
  : unknown extends T
  ? true
  : never;

export class BaseLoader {
  public constructor(readonly baseSchema: BaseSchema) {
    this.init();
  }

  public readonly DependsOn = <T>(
    dependencies: (t: T) => Tracable[]
  ): PropertyDecorator<T> => Reflect.metadata('DependsOn', dependencies) as any;

  public readonly AccessProperty = <T>(
    accesses: (CRUBuilder<''> | string) | ((t: T) => CRUBuilder<''>)
  ): PropertyDecorator<T> => {
    if (typeof accesses === 'string') accesses = { CRU: accesses };
    if (typeof accesses !== 'function') {
      const accessesCRU: CRUBuilder<''> = accesses;
      accesses = (t: T) => accessesCRU;
    }
    return Reflect.metadata('Access', accesses) as any;
    // return <T>(target: T, propertyKey: string | symbol) => {};
  };
  public readonly Access = <T extends It = ItSomething>(
    accesses: CRUBuilder<'D'> | string | ((t: T) => CRUBuilder<'D'>)
    // TODO: make an error if there are more keys!
  ): T extends ItSomething ? ClassDecorator_ : ClassDecorator<T> => {
    if (typeof accesses === 'string') accesses = { CRUD: accesses } as any;
    if (typeof accesses !== 'function') {
      const accessesCRU = accesses as CRUBuilder<'D'>;
      accesses = ((t: T) => accessesCRU) as any;
    }
    return Reflect.metadata('Access', accesses) as any;
    // return (<T2 extends It>(cls: CtorType<T2>) => cls) as any;
  };

  private rolesInheritance?: {
    [name: string]: {
      inherits: string[];
    };
  };
  private enums?: { [name: string]: string[] };
  public init() {
    store = {};
    const ret = this.baseSchema(this);
    this.rolesInheritance = ret.rolesInheritance;
    this.enums = ret.enums;

    for (const cls of ret.classes) make(cls);
    doLater();
    for (const cls of ret.classes) {
      const o = make(cls);
      for (const key of Object.keys(o)) o[key].tracedPath.push(key);
    }
  }
  public toApiBase() {
    const parseCondition = (c: CompositeAccessType): string => {
      function parseString(s: string) {
        return s;
      }
      function parseTracedPath(p: Tracable) {
        return '.' + p.tracedPath.join('.');
      }
      if (typeof c === 'string' || 'tracedPath' in c) c = new And(c);
      if (c instanceof And) c = new Or(c);
      return c.options
        .map((o) => {
          const o2 = o instanceof And ? o : new And(o);
          return o2.conditions
            .map((c2) => {
              if (typeof c2 === 'string') return parseString(c2);
              return parseTracedPath(c2);
            })
            .join(' & ');
        })
        .join('|');
    };
    const parseAccess = (crud: any) =>
      Object.fromEntries(
        Object.entries(crud).map(([k, v]) => [k, parseCondition(v as any)])
      );
    const typeNameConversion = (attribute: Referenceable<It>) => {
      if ('enum' in attribute)
        return Object.entries(this.enums!).find(
          ([k, v]) => v == (attribute as any)['enum']
        )![0];
      const typeName = attribute._type.name;
      if (typeName == 'Integer') return 'Int';
      if (typeName == 'BigInteger') return 'BigInteger';
      if (typeName == 'Date') return 'DateTime';
      if (typeName == 'Json') return 'Json';
      return typeName;
    };
    let enums = { ...this.enums };
    delete enums['Roles'];
    return {
      version: '1.2.1',
      roles: this.rolesInheritance,
      enums,
      classes: Object.fromEntries(
        Object.entries(store)
          .filter(
            ([name, o]) =>
              ![
                'Date',
                'Number',
                'Float',
                'Boolean',
                'String',
                'Integer',
                'Json',
                'BigInteger',
              ].includes(name)
          )
          .map(([name, o]) => [
            name,
            {
              ...(Reflect.hasMetadata('Access', o.constructor)
                ? {
                    null: parseAccess(
                      Reflect.getMetadata('Access', o.constructor)(o)
                    ),
                  }
                : {}),
              ...Object.fromEntries(
                Object.entries(o as { [key: string]: Referenceable<It> })
                  .filter(
                    ([attributeName, attribute]) =>
                      !['id'].includes(attributeName)
                  )
                  .map(([attributeName, attribute]) => [
                    attributeName,
                    {
                      type:
                        typeNameConversion(attribute) +
                        (optionalKinds.includes(attribute._kind) ? '?' : '') +
                        (arrayKinds.includes(attribute._kind) ? '[]' : ''),
                      ...(uniqueKinds.includes(attribute._kind)
                        ? { unique: true }
                        : {}),
                      ...(attribute._reference
                        ? {
                            reference: (
                              attribute._reference as Tracable['tracedPath']
                            )[0],
                          }
                        : {}),
                      ...(Reflect.hasMetadata('Access', o, attributeName)
                        ? parseAccess(
                            Reflect.getMetadata('Access', o, attributeName)(o)
                          )
                        : {}),
                    },
                  ])
              ),
            },
          ])
      ),
    };
  }
  /*private static parseCondition(
    models: Model<null, null>[],
    from: any,
    c: CompositeAccessType
  ): Access {
    function parseString(s: string) {
      return new RoleName(s);
    }
    function parseTracedPath(p: Tracable) {
      const at: Model = from.constructor.name;
      return new AttributePath(
        p.tracedPath.map((part) => {
          from;
          return;
        })
      );
    }
    if (typeof c === 'string' || 'tracedPath' in c) c = new And(c);
    if (c instanceof And) c = new Or(c);
    return new Access(
      c.options.map((o) => {
        const o2 = o instanceof And ? o : new And(o);
        return new AccessOption(
          o2.conditions.map((c2) => {
            if (typeof c2 === 'string') return parseString(c2);
            return parseTracedPath(c2);
          })
        );
      })
    );
  }
  public toApiBase(): ApiBase {
    function getRwAccess<D extends true | false>(
      target: any,
      propertyKey: D extends true ? null : string
    ): D extends true ? RWDAccess : RWAccess {
      const isProperty = propertyKey !== null;
      const hasMetadata = isProperty
        ? Reflect.hasMetadata('Access', target, propertyKey)
        : Reflect.hasMetadata('Access', target);
      let access = hasMetadata
        ? isProperty
          ? Reflect.getMetadata('Access', target, propertyKey)
          : Reflect.getMetadata('Access', target)
        : undefined;
      const result: CRUBuilder<'' | 'D'> = access(target);
      const crud = finalizeCRUD(
        accessesReducer(result, isProperty ? 'D' : ''),
        isProperty ? 'D' : ''
      );
      const conv = (v: CompositeAccessType) =>
        BaseLoader.parseCondition(models, target, v);
      if (isProperty)
        return new RWAccess(
          conv(crud.create),
          conv(crud.update),
          conv(crud.read)
        ) as any;
      else
        return new RWDAccess(
          conv(crud.create),
          conv(crud.update),
          conv(crud.read),
          conv((crud as any).delete)
        );
    }
    const models = Object.entries(store).map(([name, o]) => {
      return new Model(
        name,
        Object.entries(o as { [key: string]: Referenceable }).map(
          ([attributeName, attribute]) => {
            return new Attribute(
              Reflect.hasMetadata('Virtual', o),
              attributeName,
              arrayKinds.includes(attribute._kind),
              optionalKinds.includes(attribute._kind),
              null,
              attribute.constructor.name,
              uniqueKinds.includes(attribute._kind),
              !!attribute._reference
                ? attribute._reference[attribute._reference.length - 1]
                : undefined,
              null
            );
          }
        ),
        Reflect.hasMetadata('Virtual', o),
        null
      );
    });
    function doAccess(model: Model, attribute?: Attribute) {
      if (!attribute)
        model.rootAccess = getRwAccess<true>(store[model.name], null);
      else attribute.rwAccess = getRwAccess(store[model.name], attribute.name);
    }

    store = {};

    return new ApiBase(models);
  }*/
}

export class Or {
  public readonly options: (And | AccessType)[];
  public constructor(...args: (And | AccessType)[]) {
    this.options = [...args];
  }
}
export class And {
  public readonly conditions: AccessType[];
  public constructor(...args: AccessType[]) {
    this.conditions = [...args];
  }
}
export const or = (...args: (And | AccessType)[]) => new Or(...args);
export const and = (...args: AccessType[]) => new And(...args);

export type BaseSchema = (baseLoader: BaseLoader) => {
  enums: { [name: string]: string[] };
  rolesInheritance: {
    [name: string]: {
      inherits: string[];
    };
  };
  classes: (new () => any)[];
};

export const base = new BaseLoader(baseSchema);
export const apiBase = base.toApiBase();
