import { IBlock } from 'features/block/blockAPI';
import { BlockType } from 'features/block/blockSlice';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { IPhase } from 'features/phase/phaseAPI';
import { AnyObject } from 'helpers/objects';
import { store } from './store';

export type UnreadCounter = (block: IBlock) => number;

const unreadCounters = new Map<BlockType, UnreadCounter>();
export function registerUnreadCounter(
  blockType: BlockType,
  counter: UnreadCounter
) {
  unreadCounters.set(blockType, counter);
}

export function getUnreadMessagesCount(
  messages: AnyObject[],
  since: Date | null,
  key: string = 'time'
): number {
  const meId = store.getState().user.userId;
  const count = messages.filter((m) => {
    if (m?.author?.id === meId) return false;
    let time = m[key];
    // if (typeof time !== 'number') time = new Date(time);
    return time ? (since ? time > since : true) : true;
  }).length;
  return count;
}

export function getDialogueUnreadCount(dialogue: IDialogue): number {
  let count = 0;
  dialogue.phases?.forEach((p) => {
    count += getPhaseUnreadCount(p);
  });
  return count;
}

export function getPhaseUnreadCount(phase: IPhase): number {
  let count = 0;
  phase.blocks?.forEach((b) => {
    const counter = unreadCounters.get(
      (b.childType as BlockType) ?? BlockType.Chat
    );
    if (counter) count += counter(b);
  });
  return count;
}

export function getBlockUnreadCount(block: IBlock): number {
  const counter = unreadCounters.get(
    (block.childType as BlockType) ?? BlockType.Chat
  );
  return counter ? counter(block) : 0;
}
