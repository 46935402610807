import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { useAppDispatch } from 'app/hooks';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { useForms } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import { navigateIn } from 'components/navigation/Navigation';
import {
  UserStateProps,
  mapUserStateToProps,
  registerAsync,
} from 'features/user/userSlice';
import { GUEST_TRYOUT_ACCESS_CODE } from 'helpers/consts';
import { makeCSV } from 'helpers/helpers';

function UnconnectedUserRegCodeForm(props: FormProps & UserStateProps) {
  const { show, setShow } = props;
  const refSubmitBtn = useRef(null);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const emptyData = {
    registrationCode: '',
  };
  const [data, setData] = useState(emptyData);
  const [hasRegistered, setHasRegistered] = useState<boolean>(false);
  const { code } = useParams();
  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);

  useEffect(() => {
    if (show) {
      setData(emptyData);
      doFormMsg(NoFormMsg, setFormMsg);
      setHasRegistered(false);
      if (window.location.pathname.startsWith('/join') && code) {
        setData((prev) => {
          return { ...prev, registrationCode: code };
        });
        if (code === GUEST_TRYOUT_ACCESS_CODE) handleSubmit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  function formatRegCode(regcode: string): string {
    if (!regcode) return '';
    return makeCSV(regcode);
  }

  const handleChangeData = (e: SyntheticEvent) => {
    doFormMsg(NoFormMsg, setFormMsg);
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [target.name]: target.value,
    });
  };

  function handleCancel() {
    setData(emptyData);
    return true;
  }

  async function handleSubmit(): Promise<void> {
    if (hasRegistered) {
      setShow(false);
      return;
    }
    return new Promise((resolve, reject) => {
      try {
        if (code === GUEST_TRYOUT_ACCESS_CODE) data.registrationCode = code;
        else data.registrationCode = formatRegCode(data.registrationCode);
        dispatch(registerAsync(data)).then((response: { payload: any }) => {
          switch (response.payload?.response.status) {
            case 200:
            case 204:
              doFormMsg(
                {
                  message: intl.formatMessage({
                    id: 'USERS.REGCODE.SUCCESS',
                  }),
                  success: true,
                },
                setFormMsg
              );
              setHasRegistered(true);
              resolve();
              if (code === GUEST_TRYOUT_ACCESS_CODE) navigateIn('/tryout', 0);
              else navigateIn('/', 3000);
              break;
            case 406:
            case 500:
            default:
              doFormMsg(
                {
                  message: intl.formatMessage({ id: 'USERS.REGCODE.FAILED' }),
                  success: false,
                },
                setFormMsg
              );
              reject();
              break;
          }
        });
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg
        );
        console.log('Registration request failed for unclear reason.', err);
        reject();
      }
    });
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="narrow"
      title={<FormattedMessage id="USERS.REGCODE.FORM_TITLE" />}
      onSubmit={handleSubmit}
      noFooter={true}
      footer={
        <>
          {/* <Form.Group as={Row} className="form"> */}
          {/* <FormMessage {...formMsg} /> */}
          <Button variant="primary wide" ref={refSubmitBtn}>
            <FormattedMessage
              id={hasRegistered ? 'X.OK' : 'USERS.REGCODE.SUBMIT'}
            />
          </Button>
          {hasRegistered ? null : (
            <Button
              variant="outline-secondary wide"
              onClick={() => {
                setShow(false);
              }}
            >
              <FormattedMessage id="X.CANCEL" />
            </Button>
          )}
          {/* </Form.Group> */}
        </>
      }
      refSubmitBtn={refSubmitBtn}
      onCancel={handleCancel}
      enableEscape
      formMsg={formMsg}
      delayClose={3000}
    >
      <Form.Group as={Row}>
        <Form.Text>
          <FormattedMessage id={'USERS.REGCODE.INSTR'} />
        </Form.Text>
      </Form.Group>
      <Form.Group as={Row} className="last_row" controlId="RegistrationCode">
        <Form.Label column sm="12" md="12" lg="12">
          <FormattedMessage id="USERS.SIGNUP.REGCODE" />
        </Form.Label>
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            type="text"
            name="registrationCode"
            value={data.registrationCode || ''}
            onChange={handleChangeData}
            required
            minLength={3}
          />
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id="USERS.SIGNUP.REGCODE_FDBCK" />
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

const UserRegCodeForm = connect(mapUserStateToProps)(
  UnconnectedUserRegCodeForm
);
export default UserRegCodeForm;

export function UserRegCodeFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  children?: ReactNode;
}) {
  const { onClick, className, children } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.UserLoginForm.setShow(false);
    forms.UserRegCodeForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <div onClick={handleClick} className={className}>
      {children || <FormattedMessage id="NAVIGATION.REGCODE" />}
    </div>
  );
}
