import { ArrayToUnion19 } from '../typeHelpers.js';
import { AsyncMessage } from './asyncMessage.js';

export const PayloadWithRooms = <
  T1 extends readonly any[],
  T2 extends readonly any[],
  T3 extends readonly any[]
>(inner: {
  out: T1;
  in: T2;
  inout: T3;
}) =>
  ({
    out: [
      {
        event: 'joinRoom',
        roomId: '',
      },
      {
        event: 'leaveRoom',
        roomId: '',
      },
      {
        event: 'inner',
        roomId: '',
        inner: [...inner.out, ...inner.inout],
      },
    ],
    in: [
      {
        event: 'inner',
        roomId: '',
        inner: [...inner.in, ...inner.inout],
      },
    ],
  } as const);

export type PayloadWithRooms<
  Inner extends {
    readonly out: any;
    readonly in: any;
    readonly inout: any;
  }
> = ArrayToUnion19<
  ReturnType<typeof PayloadWithRooms<Inner['out'], Inner['in'], Inner['inout']>>
>;

export type InnerPayloadFromRoomEventFlat = {
  [Type in 'calling' | 'documents']: Extract<
    AsyncMessage,
    { event: Type }
  >['payload'] extends PayloadWithRooms<{
    out: (infer U1)[];
    in: (infer U2)[];
    inout: [];
  }>
    ? {
        out: U1;
        in: U2;
      }
    : never;
};

export type InnerPayloadFromRoomEvent = {
  [Type in keyof InnerPayloadFromRoomEventFlat]: {
    out: Exclude<
      InnerPayloadFromRoomEventFlat[Type]['out'],
      InnerPayloadFromRoomEventFlat[Type]['in']
    >;
    in: Exclude<
      InnerPayloadFromRoomEventFlat[Type]['in'],
      InnerPayloadFromRoomEventFlat[Type]['out']
    >;
    inout: Exclude<
      Exclude<
        | InnerPayloadFromRoomEventFlat[Type]['out']
        | InnerPayloadFromRoomEventFlat[Type]['in'],
        Exclude<
          InnerPayloadFromRoomEventFlat[Type]['out'],
          InnerPayloadFromRoomEventFlat[Type]['in']
        >
      >,
      Exclude<
        InnerPayloadFromRoomEventFlat[Type]['in'],
        InnerPayloadFromRoomEventFlat[Type]['out']
      >
    >;
  };
};

/*
type InOut = {
  [Type in AsyncMessageTypesWithRooms]: InnerPayloadFromRoomEvent[Type]['out']['event'] &
    InnerPayloadFromRoomEvent[Type]['in']['event'];
};
*/
