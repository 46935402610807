import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { callGet, Retrieving } from 'helpers/apiTypes';
import { withoutSeconds } from 'helpers/helpers';
import { prepareForBackend, toArrayOfIdObject } from 'helpers/objects';

export type IDialogue = API['Dialogue']['read'];
export type IDialogueCreate = API['Dialogue']['create'];
export type IDialogueUpdate = API['Dialogue']['update'] & {
  phasesOverwrite?: { id: number }[];
  keywordsOverwrite?: { id: number }[];
  subscribersOverwrite?: { id: number }[];
  moderatorsOverwrite?: { id: number }[];
  userFeedbacksOverwrite?: { id: number }[];
};

function prepareForUpdate(dialogue: IDialogue): IDialogueUpdate {
  dialogue.startedAt = withoutSeconds(dialogue.startedAt);
  dialogue.closedAt = withoutSeconds(dialogue.closedAt);
  const updated = prepareForBackend(dialogue, [
    'id',
    'subscribersCount',
    'author',
    'phases',
    'keywords',
    'subscribers',
    'moderators',
    'userFeedbacks',
  ]) as IDialogueUpdate;
  updated.phasesOverwrite = toArrayOfIdObject(dialogue.phases);
  updated.keywordsOverwrite = toArrayOfIdObject(dialogue.keywords);
  updated.subscribersOverwrite = toArrayOfIdObject(dialogue.subscribers);
  updated.moderatorsOverwrite = toArrayOfIdObject(dialogue.moderators);
  updated.userFeedbacksOverwrite = toArrayOfIdObject(dialogue.userFeedbacks);
  return updated;
}
function prepareForCreate(dialogue: IDialogue): IDialogueCreate {
  dialogue.startedAt = withoutSeconds(dialogue.startedAt);
  dialogue.closedAt = withoutSeconds(dialogue.closedAt);
  return prepareForBackend(dialogue, ['id', 'author']) as IDialogueCreate;
}

export const fetchDialogue = async (id: number) => {
  try {
    // New way ---
    const query = {
      '*': true,
      background: {
        '*': true,
      },
      subscribers: {
        '*': true,
        // id: true,
        // username: true,
      },
      moderators: {
        id: true,
        username: true,
      },
      phases: {
        '*': true,
        blocks: {
          '*': true,
          phase: {
            id: true,
          },
          childChatBlock: {
            '*': true,
            messages: {
              '*': true,
              author: {
                '*': true,
                avatar: {
                  uri: true,
                },
              },
              likes: {
                '*': true,
                liker: {
                  id: true,
                  username: true,
                },
              },
              replyTo: {
                '*': true,
                author: {
                  '*': true,
                  avatar: {
                    uri: true,
                  },
                },
              },
              files: {
                '*': true,
              },
            },
          },
          childLibraryBlock: {
            '*': true,
            files: {
              '*': true,
              author: {
                '*': true,
              },
            },
            links: {
              '*': true,
              author: {
                '*': true,
              },
            },
          },
          childListBlock: {
            '*': true,
            lists: {
              '*': true,
              parent: {
                id: true,
                listType: true,
              },
              listItems: {
                '*': true,
                message: {
                  '*': true,
                  author: {
                    '*': true,
                    avatar: {
                      uri: true,
                    },
                  },
                  likes: {
                    '*': true,
                    liker: {
                      id: true,
                      username: true,
                    },
                  },
                  replyTo: {
                    id: true,
                  },
                  replies: {
                    '*': true,
                    author: {
                      '*': true,
                      avatar: {
                        uri: true,
                      },
                    },
                    replyTo: {
                      id: true,
                    },
                  },
                  files: {
                    '*': true,
                  },
                },
              },
            },
          },
          childPollBlock: {
            '*': true,
            poll: {
              '*': true,
              options: {
                '*': true,
                message: {
                  '*': true,
                  files: {
                    '*': true,
                  },
                  author: {
                    '*': true,
                    avatar: {
                      uri: true,
                    },
                  },
                },
                votes: {
                  '*': true,
                  voter: {
                    id: true,
                  },
                },
              },
            },
          },
          childDocumentBlock: {
            '*': true,
            document: {
              '*': true,
            },
          },
          childSudokuBlock: {
            '*': true,
          },
        },
      },
      project: {
        '*': true,
        files: {
          '*': true,
          author: {
            '*': true,
          },
        },
      },
    } as const satisfies Retrieving<'Dialogue'>;
    const response = await callGet('Dialogue', query, id);
    // ---

    // Old way
    // const response = await api.get(
    //   `Dialogue/${id}?and=*,subscribers.(id,username),moderators.(id,username),phases.(*,blocks.(*,childChatBlock.(*,messages.(*,author.*,likes.(*,liker.(id,username)),replyTo.(*,author.*),files.*)),childListBlock.(*,childSingleListBlock.(*,list.(*,listItems.(*,message.(*,author.*,likes.(*,liker.(id,username)),replyTo.(*,author.*),files.*))))))),project.*`
    // );
    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const postDialogueData = async (data: IDialogue) => {
  try {
    const response: any = await api.post('Dialogue', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchDialogueData = async (data: IDialogue) => {
  try {
    const response = await api.patch(
      'Dialogue/' + data.id,
      prepareForUpdate(data)
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteDialogueData = async (id: number) => {
  try {
    const response: any = await api.delete('Dialogue/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
