import classNames from 'classnames';
import React, {
  PropsWithChildren,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import FileUpload from 'components/files/FileUpload';
import FormattedMessage from 'components/formatters/FormattedMessage';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { useForms } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import { getTextAreaHeight } from 'components/messages/TextAreaInput';
import { imageFileTypes } from 'features/files/fileSlice';
import { setLocale } from 'features/intl/intlSlice';
import { IUser } from 'features/user/userAPI';
import {
  UserStateProps,
  getAuthorName,
  mapUserStateToProps,
  patchUserAsync,
  userForgotAsync,
} from 'features/user/userSlice';
import { PASSWORD_PATTERN, USERNAME_PATTERN } from 'helpers/consts';
import UserAvatar from './UserAvatar';

import {
  DialogueStateProps,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import './UserForm.scss';

type UserProfileFormProps = {
  author?: IUser;
};

function UnconnectedUserProfileForm(
  props: UserProfileFormProps & FormProps & UserStateProps & DialogueStateProps
) {
  const { author, show, setShow, onSubmit, onCancel, onClose, authors } = props;
  const { user, userIsGuest } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<IUser & { password?: string }>({
    ...(author || user),
  });
  const [file, setFile] = useState<File | undefined>(undefined); // for avatar image
  const [previewSrc, setPreviewSrc] = useState<string | null>(null); // state for storing previewImage
  const [editing, setEditing] = useState(false);
  const [upgraded, setUpgraded] = useState<boolean>(false);

  useEffect(() => {
    setData({ ...(author || user) });
    setPreviewSrc((author || user)?.avatar?.uri || null);
  }, [user, author]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
    if (!show) setPreviewSrc(null);
  }, [show]);

  useEffect(() => {
    const ta = document.getElementById('About');
    if (ta) {
      ta.style.height =
        getTextAreaHeight(ta as HTMLTextAreaElement, 2, 5)[0] + 'px';
    }
  }, [show, data]);

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [target.name]: target.value,
    });
    if (target.name === 'locale')
      dispatch(setLocale(target.value === 'nl' ? 'nl' : 'en'));
  }

  async function handleSubmit(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!editing) {
        resolve();
        return;
      }
      try {
        if (data.username) data.username = data.username.trim();
        // if (userIsGuest)
        //   data.isVerified = false;
        dispatch(
          patchUserAsync({
            data: { ...data, isGuest: userIsGuest },
            file: file,
            user: user,
          })
        ).then((response: { payload: any }) => {
          switch (response.payload?.response.status) {
            case 200:
            case 201:
              response.payload.response.data?.locale &&
                dispatch(setLocale(response.payload.response.data?.locale));
              setEditing(false); // we can stop editing
              onSubmit && onSubmit();
              resolve();
              if (userIsGuest) {
                setUpgraded(true);
                doFormMsg(
                  {
                    message: intl.formatMessage({
                      id: 'USERS.GUESTS.UPGRADED',
                    }),
                    success: true,
                  },
                  setFormMsg
                );
              }
              break;
            case 409:
              doFormMsg(
                {
                  message: intl.formatMessage({
                    id: 'USERS.SIGNUP.NAME_IN_USE',
                  }),
                  success: false,
                },
                setFormMsg
              );
              reject();
              break;
            case 500:
            default:
              doFormMsg(
                {
                  message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
                  success: false,
                },
                setFormMsg
              );
              console.log(
                'Profile update request failed (',
                response.payload.status,
                response.payload.data?.message,
                ')'
              );
              reject(response.payload.data?.message);
              break;
          }
        });
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg
        );
        console.log('Profile update request failed for unclear reason.', err);
        reject();
      }
    });
  }

  function handleCancel(): boolean {
    setEditing(false);
    setData({ ...user });
    setPreviewSrc(user?.avatar?.uri || null);
    // user?.avatar && setPreviewSrc(user.avatar.uri);
    onCancel && onCancel();
    return true;
  }

  function handleClose(): void {
    !editing && onClose && onClose();
  }

  const onResetPwd = () => {
    try {
      dispatch(userForgotAsync(data)).then((response: { payload: any }) => {
        switch (response.payload?.response.status) {
          case 200:
          case 204:
            doFormMsg(
              {
                message: intl.formatMessage({ id: 'USERS.FORGOT.SUCCESS' }),
                success: true,
              },
              setFormMsg
            );
            break;
          default:
            break;
        }
      });
    } catch (err) {
      doFormMsg(
        {
          message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
          success: false,
        },
        setFormMsg
      );
      console.log('Reset password request failed for unclear reason.', err);
    }
  };

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="user_form"
      title={null}
      onSubmit={handleSubmit}
      onCancel={author ? undefined : handleCancel}
      onClose={handleClose}
      enableEscape
      allowEdit={!author && !upgraded}
      onEdit={() => setEditing(true)}
      editing={editing}
      formMsg={formMsg}
      editBtnText={
        userIsGuest ? (
          <FormattedMessage id="USERS.GUESTS.EDIT" />
        ) : (
          <FormattedMessage id="X.EDIT" />
        )
      }
      submitBtnText={
        editing ? (
          userIsGuest ? (
            <FormattedMessage id="USERS.GUESTS.EDIT" />
          ) : (
            <FormattedMessage id="X.SAVE" />
          )
        ) : (
          <FormattedMessage id="X.OK" />
        )
      }
    >
      <Row className="blurInDemo">
        <Form.Group as={Col} controlId="fileDrop" sm={3} md={3} lg={3}>
          <FileUpload
            types={imageFileTypes}
            currentSrc={data.avatar?.uri || null}
            previewSrc={previewSrc}
            size={96}
            defaultImg={<UserAvatar user={author || user} large />}
            setPreviewSrc={setPreviewSrc}
            showMsg={(m) => doFormMsg(m, setFormMsg)}
            setFile={setFile}
            onDelete={() => {
              // set the id to null to indicate the avatar needs to be deleted
              data.avatar = null;
            }}
            confirmationText={
              <FormattedMessage id="USERS.PROFILE.DELETE_AVATAR_CNF" />
            }
            disabled={!editing}
          />
        </Form.Group>
        <Col sm={9} md={9} lg={9}>
          <Form.Group as={Row} controlId="Name">
            <Col sm={12} md={12} lg={12}>
              <Form.Control
                className="field title"
                disabled={!editing}
                type="text"
                name="username"
                value={
                  (editing ? data.username : getAuthorName(data, authors)) || ''
                }
                onChange={handleChangeData}
                placeholder={intl.formatMessage({
                  id: 'USERS.PROFILE.NAME_PLCH',
                })}
                required
                pattern={
                  data.username !== (author || user).username
                    ? USERNAME_PATTERN
                    : undefined
                }
              />
              <Form.Control.Feedback type="invalid">
                <div className="feedback">
                  <FormattedMessage id="USERS.SIGNUP.NAME_FDBCK" />
                </div>
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {author || !editing ? null : (
            <>
              {' '}
              {editing ? (
                <Form.Label column sm="12" md="12" lg="12">
                  <FormattedMessage id="USERS.SIGNUP.EMAIL_INSTR" />
                </Form.Label>
              ) : null}
              <Form.Group as={Row} controlId="Email">
                <Col sm={12} md={12} lg={12}>
                  <Form.Control
                    className="field private"
                    disabled={!editing}
                    type="email"
                    name="email"
                    value={data.email || ''}
                    onChange={handleChangeData}
                    placeholder={intl.formatMessage({
                      id: 'USERS.PROFILE.EMAIL_PLCH',
                    })}
                    required
                    // pattern={EMAIL_PATTERN}
                    title={intl.formatMessage({
                      id: 'USERS.PROFILE.EMAIL_INSTR',
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    <div className="feedback">
                      <FormattedMessage id="USERS.LOGIN.EMAIL_FDBCK" />
                    </div>
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </>
          )}
          {editing && userIsGuest ? (
            <Form.Group as={Row} controlId="Password">
              <Form.Label column sm="12" md="12" lg="12">
                <FormattedMessage id="USERS.SIGNUP.PASSWORD_INSTR" />
              </Form.Label>
              <Col sm={12} md={12} lg={12}>
                <div className="passwd">
                  <Form.Control
                    type="password"
                    name="password"
                    autoComplete="password"
                    value={data.password || ''}
                    onChange={handleChangeData}
                    required
                    pattern={PASSWORD_PATTERN}
                  />
                </div>
                <Form.Text className="text-muted instruction">
                  <FormattedMessage id="USERS.SIGNUP.PASSWORD_HINT" />
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  <FormattedMessage id="USERS.SIGNUP.PASSWORD_FDBCK" />
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          ) : null}
          {author || !editing ? null : (
            <Form.Group as={Row} controlId="Locale">
              <Col sm={12} md={12} lg={12}>
                <div className="language">
                  <FormattedMessage id="LOCALE.LANGUAGE" />:
                  <Form.Select
                    className="field"
                    disabled={!editing}
                    name="locale"
                    value={data.locale || ''}
                    onChange={handleChangeData}
                    required
                  >
                    <option value={'en'}>
                      <FormattedMessage id="LOCALE.EN" />
                    </option>
                    <option value={'nl'}>
                      <FormattedMessage id="LOCALE.NL" />
                    </option>
                  </Form.Select>
                </div>
                {editing ? (
                  <Form.Text className="text-muted instruction">
                    <FormattedMessage id="LOCALE.LANGUAGE_INSTR" />
                  </Form.Text>
                ) : null}
              </Col>
            </Form.Group>
          )}
          {/* Email: {data.email}
          {userIsGuest ? 'guest' : 'user'} */}
          <Form.Group as={Row} controlId="About">
            <Col sm={12} md={12} lg={12}>
              <Form.Control
                className="field"
                disabled={!editing}
                type="text"
                name="about"
                as="textarea"
                rows={3}
                value={data.about?.trim() ?? ''}
                onChange={handleChangeData}
                placeholder={
                  editing
                    ? intl.formatMessage({
                        id: 'USERS.PROFILE.ABOUT_PLCH',
                      })
                    : undefined
                }
                title={
                  editing
                    ? intl.formatMessage({
                        id: 'USERS.PROFILE.ABOUT_INSTR',
                      })
                    : undefined
                }
              />
            </Col>
          </Form.Group>
          {editing && !userIsGuest ? (
            <Form.Group as={Row} controlId="Password">
              <Col sm={12} md={12} lg={12}>
                <Button onClick={onResetPwd} variant="outline-secondary small">
                  <FormattedMessage id="USERS.PROFILE.RESETPWD" />
                </Button>
              </Col>
            </Form.Group>
          ) : null}
          {/* <div>
            {
              <div>
                <FormattedMessage id="USERS.PROFILE.ROLE" />:{' '}
                {user?.roles?.length > 0 ? user?.roles[0] : ''}
              </div>
            }
          </div> */}
        </Col>
      </Row>
    </ModalDialog>
  );
}

const UserProfileForm = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedUserProfileForm)
);
export default UserProfileForm;

export function UserProfileFormLink(
  props: {
    author?: IUser;
    onClick?: (e: React.MouseEvent) => void;
    className?: string;
  } & PropsWithChildren
) {
  const { author, onClick, children, className } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.UserProfileForm.setAuthor(author);
    forms.UserProfileForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <div onClick={handleClick} className={classNames('author_link', className)}>
      {children || <FormattedMessage id="NAVIGATION.MY_PROFILE" />}
    </div>
  );
}
