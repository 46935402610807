import type {
  Request as ExpressRequest,
  Response as ExpressResponse,
} from 'express';

import { typemap } from '#src/apibaseparser.js';

export type ID = {
  id?: number | null | null;
};
export type IDCreate = ID & {
  id: number | null | null;
};
export type IDRead = ID & {};

interface ParsedQs {
  [key: string]: undefined | string | string[] | ParsedQs | ParsedQs[];
}
export interface UserRequest<
  P = { [key: string]: string },
  ResBody = any,
  ReqBody = any,
  ReqQuery = ParsedQs
> extends ExpressRequest<P, ResBody, ReqBody, ReqQuery, { user: number }> {}

export interface UserResponse<ResBody = any>
  extends ExpressResponse<ResBody, { user: number }> {}

// Based on: https://github.com/microsoft/TypeScript/issues/3192#issuecomment-261720275
export function makeEnum<T extends { [index: string]: U }, U extends string>(
  x: T
) {
  return x;
}

export const typescriptTypes: typemap<string> = {
  String: 'string',
  Boolean: 'boolean',
  Int: 'number',
  BigInteger: 'number',
  Float: 'number',
  Decimal: 'number',
  DateTime: 'Date',
  Json: 'any',
  Bytes: 'byte[]',
  Email: 'string',
};

export type APIEndpoints<T extends { Read: any; Create: any; Update: any }> = {
  Create: {
    RequestParams: {
      id: number;
    };
    RequestQuery: {
      and?: string;
    };
    RequestBody: undefined;
    RequestHeaders: {};
    ResponseBody:
      | T['Read'][]
      | {
          message: string;
        };
  };
  Detail: {
    RequestParams: {
      id: number;
    };
    RequestQuery: {
      and?: string;
    };
    RequestBody: undefined;
    RequestHeaders: {};
    ResponseBody:
      | T['Read']
      | {
          message: string;
        };
  };
  Update: {
    RequestParams: {
      id: number;
    };
    RequestQuery: {};
    RequestBody: undefined;
    RequestHeaders: {};
    ResponseBody:
      | T['Update']
      | {
          message: string;
        };
  };
  Delete: {
    RequestParams: {
      id: number;
    };
    RequestQuery: {
      and?: string;
    };
    RequestBody: undefined;
    RequestHeaders: {};
    ResponseBody: {
      message: string;
    };
  };
  Post: {
    RequestParams: {};
    RequestQuery: {
      and?: string;
    };
    RequestBody: T['Create'];
    RequestHeaders: {};
    ResponseBody: {
      message: string;
      _id: number;
    };
  };
};
