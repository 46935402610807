import { showModal } from 'components/forms/ModalDialog';
import { truncateTo } from 'helpers/helpers';
import { FormattedMessage, IntlShape } from 'react-intl';

export function confirmDelete(
  content: string | undefined,
  isModeration: boolean,
  onDelete: () => boolean,
  intl: IntlShape
) {
  showModal(
    <div className="dialog_header center">
      {/* <Icon
        className="smallmargin"
        symbol={IconSymbol.alert}
        variant={IconVariant.accent}
        size={40}
      /> */}
      <FormattedMessage id="GENERAL.DELETE_ITEM.TITLE" />
    </div>,
    <>
      <div className="title">
        {isModeration ? (
          <>
            <FormattedMessage id="GENERAL.DELETE_ITEM.MODERATION" />
            <br />
          </>
        ) : null}
        <FormattedMessage id="GENERAL.DELETE_ITEM.CONFIRM" />
        <br />
        {`"${truncateTo(content ?? '', 50)}"`}
      </div>
      <div className="text">
        <FormattedMessage id="GENERAL.DELETE_ITEM.NOTICE" />
      </div>
    </>,
    {
      intl: intl,
      className: 'pd_alert',
      submitBtnText: <FormattedMessage id="GENERAL.DELETE_ITEM.DELETE" />,
      cancelBtnText: <FormattedMessage id="GENERAL.DELETE_ITEM.NO_DELETE" />,
      onSubmit: onDelete,
      editing: true,
      cancelDefault: true,
    }
  );
}
