import { Droppable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { AnyOrderObject } from 'helpers/objects';
import { toUniqueId } from 'helpers/sorting';
import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSortableContext } from './SortableContext';

import { ListType } from 'features/block/blockSlice';
import './Sortable.scss';

type SortableListProps = {
  listInfo: SortableListInfo;
  // Set the 'itemType' prop only for lists that should accept
  // only one type of item (e.g. the blocks in a phase).
  // For lists that should accept multiple types of items,
  // we work with the 'listType' prop set on the list.
  itemType?: SortableItemType;
  accepting?: SortableItemType[];
  className?: string;
  horizontal?: boolean;
  disabled?: boolean;
  copySource?: boolean;
};

export enum SortableItemType {
  SortableItem = 'SortableItem',
  Dialogue = 'Dialogue',
  Phase = 'Phase',
  Block = 'Block',
  List = 'List',
  Item = 'Item',
  Option = 'Option',
  Message = 'Message',
  File = 'File',
}

export enum SortableListType {
  SortableList = 'SortableList',
  Dialogues = 'Dialogues',
  Phases = 'Phases',
  Blocks = 'Blocks',
  Lists = 'Lists',
  Items = 'Items',
  Options = 'Options',
  Messages = 'Messages',
  Files = 'Files',
}

export type SortableListInfo = {
  id: number;
  relatedIds?: number[];
  listType: SortableListType;
  subListType?: ListType;
  items: AnyOrderObject[];
  altItems?: AnyOrderObject[];
  canvas?: boolean;
  locked?: boolean;
};

const SortableList = forwardRef<
  HTMLDivElement,
  SortableListProps & PropsWithChildren
>(function (props, ref) {
  const {
    listInfo,
    className,
    itemType,
    accepting = itemType ? [itemType] : undefined,
    horizontal = false,
    disabled = false,
    copySource = false,
  } = props;
  const { getDragItemType, isCopying } = useSortableContext();
  const uniqueId = toUniqueId(
    listInfo.relatedIds ? [listInfo.id, ...listInfo.relatedIds] : listInfo.id,
    listInfo.listType || SortableListType.SortableList
  );
  // const accepting = getDropTarget(uniqueId)?.accepting;
  const dragItemType = getDragItemType();
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled);
  const [isCopySource, setIsCopySource] = useState<boolean>(copySource);
  const draggingFromThis = useRef<boolean>(false);

  useEffect(() => {
    // the disabled prop has priority
    if (disabled) return;
    // There are two ways to define what kind of items are acceptable
    // in a sortable list:
    // 1. Set the itemType prop - this will be the only type of item accepted
    //    In this case, the dropTarget does not need to be set in SortableContext.
    // 2. Do not set the itemType prop, but set the accepting prop in the
    //    dropTarget in SortableContext.
    // This useEffect callback will determine whether or not the list should
    // be disabled, based on the above.
    if (itemType) {
      setIsDisabled(false);
      return;
    }
    const accepted = accepting?.includes(dragItemType);
    setIsDisabled(!accepted);
  }, [accepting, dragItemType, itemType, disabled, uniqueId]);

  useEffect(() => {
    setIsCopySource(copySource || (isCopying() && draggingFromThis.current));
  }, [isCopying, setIsCopySource, draggingFromThis, copySource]);

  function getListStyle(isDraggingOver: boolean) {
    return {
      // background: isDraggingOver ? 'lightblue' : undefined,
    };
  }

  return (
    <Droppable
      droppableId={uniqueId}
      type={itemType}
      isDropDisabled={disabled || isDisabled || isCopySource}
      direction={horizontal ? 'horizontal' : undefined}
    >
      {(provided, snapshot) => {
        draggingFromThis.current = !!snapshot.draggingFromThisWith;
        return (
          <div
            className={classNames('sortable_list', className, {
              draggedOver: snapshot.isDraggingOver,
              canvas: listInfo.canvas,
              horizontal: horizontal ? true : undefined,
              copySource: isCopySource,
              dragSource: draggingFromThis.current,
            })}
            id={uniqueId}
            {...provided.droppableProps}
            ref={(element) => {
              provided.innerRef(element);
              if (typeof ref === 'function') ref(element);
              else if (ref) ref.current = element;
            }}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {/* <div>Unique ID: {uniqueId}</div> */}
            {props.children}
            {!(disabled || isDisabled || isCopySource) && provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
});

export default SortableList;
