import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import ScrollObserver from 'components/scrollobserver/ScrollObserver';
import { mapUserStateToProps, UserStateProps } from 'features/user/userSlice';
import { getHtml, renderHtml } from 'helpers/helpers';

import {
  AppSettingsStateProps,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { SLUGS } from 'helpers/consts';
import './StaticPage.scss';

type StaticPageProps = {
  slug?: string;
  className?: string;
};

function UnconnectedStaticPage(
  props: StaticPageProps & UserStateProps & AppSettingsStateProps
) {
  const { slug = SLUGS.FRONT, locale, className, settings } = props;
  const [content, setContent] = useState<string>('');
  const scrollableRef = useRef(null);

  useEffect(() => {
    async function getContent() {
      const sl =
        slug === SLUGS.FRONT
          ? SLUGS.FRONT + (settings.rights.guestTryout ? '-tryout' : '')
          : slug;
      const html = await getHtml(sl, locale);
      setContent(html);
    }
    getContent();
  }, [slug, locale, settings.rights.guestTryout]);

  return (
    <div className={classNames('static_page', className)}>
      <div className="page_container">
        {/* {bg && <GridBG />}n */}
        <ScrollObserver
          scrollableRef={scrollableRef}
          customScrollbar={false}
          toBottom={false}
        >
          <div className="page_content" ref={scrollableRef}>
            {renderHtml(content)}
          </div>
        </ScrollObserver>
      </div>
    </div>
  );
}

const StaticPage = connect(mapUserStateToProps)(
  connect(mapAppSettingsStateToProps)(UnconnectedStaticPage)
);
export default StaticPage;
