import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { withoutSeconds } from 'helpers/helpers';
import { prepareForBackend, toArrayOfIdObject } from 'helpers/objects';

export type IPhase = API['Phase']['read'];
export type IPhaseCreate = API['Phase']['create'];
type IPhaseUpdate = API['Phase']['update'] & {
  blocksOverwrite?: { id: number }[];
};

function prepareForUpdate(phase: IPhase): IPhaseUpdate {
  phase.dateBegin = withoutSeconds(phase.dateBegin);
  phase.dateEnd = withoutSeconds(phase.dateEnd);
  const updated = prepareForBackend(phase, ['id', 'blocks']) as IPhaseUpdate;
  updated.blocksOverwrite = toArrayOfIdObject(phase.blocks);
  return updated;
}
function prepareForCreate(phase: IPhase): IPhaseCreate {
  phase.dateBegin = withoutSeconds(phase.dateBegin);
  phase.dateEnd = withoutSeconds(phase.dateEnd);
  return prepareForBackend(phase, ['id']) as IPhaseCreate;
}

export const postPhaseData = async (data: IPhase) => {
  try {
    const response: any = await api.post('Phase', prepareForCreate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchPhaseData = async (data: IPhase) => {
  try {
    const response: any = await api.patch(
      'Phase/' + data.id,
      prepareForUpdate(data)
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deletePhaseData = async (id: number) => {
  try {
    const response: any = await api.delete('Phase/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
