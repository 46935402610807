import classNames from 'classnames';
import { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { useForms } from 'components/forms/Forms';
import { ModalDialog, showModal } from 'components/forms/ModalDialog';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import UserAvatar from 'components/user/UserAvatar';
import { IUser, Role } from 'features/user/userAPI';
import {
  UserStateProps,
  emptyUser,
  mapUserStateToProps,
} from 'features/user/userSlice';
import { deleteUserAsync } from 'features/users/usersSlice';
import { PASSWORD_PATTERN, USERNAME_PATTERN } from 'helpers/consts';

import './UserDataForm.scss';

export type UserSettings = {
  username: string;
  email: string;
  admin: boolean;
  manager: boolean;
  isVerified: boolean;
  password?: string;
  registrationCode?: string;
};

type UserDataFormProps = {
  currentEditedUser: IUser;
};

function UnconnectedUserDataForm(
  props: FormProps & UserStateProps & UserDataFormProps
) {
  const {
    show,
    userId,
    userIsAdmin,
    setShow,
    onSubmit,
    onCancel,
    onClose,
    currentEditedUser,
  } = props;
  const intl = useIntl();
  const [reset, setReset] = useState(true);
  const dispatch = useAppDispatch();
  const focusRef = useRef<HTMLInputElement | null>(null);
  const isNew = !currentEditedUser;
  const u = currentEditedUser ?? emptyUser;

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  const [userData, setUserData] = useState<UserSettings>({
    username: u.username ?? '',
    email: u.email ?? '',
    admin: false,
    manager: false,
    isVerified: false,
    password: isNew ? '' : undefined,
    registrationCode: isNew ? '' : undefined,
  });

  useEffect(() => {
    if (!userIsAdmin) {
      setShow(false);
      setReset(false);
      return;
    }
    if (reset || show) {
      const u = currentEditedUser ?? emptyUser;
      setUserData({
        username: u.username ?? '',
        email: u.email ?? '',
        admin: u.roles?.includes(Role.admin) || false,
        manager: u.roles?.includes(Role.manager) || false,
        isVerified: u.isVerified || false,
        password: undefined,
        registrationCode: undefined,
      });
      setReset(false);
    }
    if (focusRef.current) focusRef.current.focus();
  }, [currentEditedUser, reset, show, focusRef, userIsAdmin, setShow]);

  async function handleSubmit(): Promise<void> {
    onSubmit && onSubmit(userData);
    setShow(false);
  }

  function handleCancel(): boolean {
    doFormMsg(NoFormMsg, setFormMsg);
    onCancel && onCancel();
    setReset(true);
    setShow(false);
    return true;
  }

  function handleClose(): boolean {
    onClose && onClose();
    setReset(true);
    return true;
  }

  function handleDeleteParticipant() {
    function doDelete(): boolean {
      dispatch(deleteUserAsync({ id: currentEditedUser.id! }));
      setShow(false);
      return true;
    }
    showModal(
      <div>
        <FormattedMessage id="USERS.FORM.DELETE_USER" />
      </div>,
      <>
        <div className="title">
          <FormattedMessage id="USERS.FORM.DELETE_USER_CONF" />
          <br />
          <span className="user_deleting">
            {userData.username} - {userData.email}
          </span>
        </div>
        <div className="text">
          <FormattedMessage id="USERS.FORM.DELETE_USER_CONF_2" />
        </div>
        <Icon
          symbol={IconSymbol.alert}
          variant={IconVariant.accent}
          size={80}
        />
        <div className="text">
          <FormattedMessage id="GENERAL.DELETE.NOTICE" />
        </div>
      </>,
      {
        intl: intl,
        className: 'pd_alert',
        submitBtnText: <FormattedMessage id="X.DELETE" />,
        onSubmit: doDelete,
        editing: true,
        cancelDefault: true,
      }
    );
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    setUserData((prev) => {
      return {
        ...prev,
        [name]: checked,
      };
    });
  }

  function handleInputFieldChange(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setUserData((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="user_status_form narrow"
      title={
        isNew ? (
          <FormattedMessage id="USERS.FORM.ADD_USER" />
        ) : (
          <FormattedMessage id="USERS.FORM.USER_SETTINGS" />
        )
      }
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onClose={handleClose}
      enableEscape
      allowEdit={true}
      formMsg={formMsg}
    >
      {isNew ? null : (
        <div className="user_id">
          <UserAvatar user={u} small />
          ID: {u.id}
        </div>
      )}
      <div className="user_name">
        <Form.Control
          className="field"
          type="text"
          name="username"
          placeholder="name"
          autoComplete="off"
          value={userData.username || ''}
          onChange={handleInputFieldChange}
          required
          pattern={USERNAME_PATTERN}
          ref={focusRef}
        />
      </div>
      <div className="user_email">
        <Form.Control
          className="field"
          type="email"
          name="email"
          placeholder="email"
          autoComplete="off"
          value={userData.email || ''}
          onChange={handleInputFieldChange}
          // required
        />
      </div>
      {isNew ? null : (
        <>
          <div>
            <Form.Check
              id={'isVerified'}
              type="checkbox"
              label={intl.formatMessage({ id: 'USERS.FORM.VERIFIED' })}
              name="isVerified"
              checked={userData.isVerified}
              onChange={handleCheckboxChange}
              disabled={u.id === userId}
            />
            <Form.Check
              id="admin"
              type="checkbox"
              label="Admin"
              name="admin"
              checked={userData.admin}
              onChange={handleCheckboxChange}
              disabled={u.id === userId}
            />
            <Form.Check
              id="manager"
              type="checkbox"
              label="Manager"
              name="manager"
              checked={userData.manager}
              onChange={handleCheckboxChange}
              disabled={u.id === userId}
            />
          </div>
          <div className="delete_user_section">
            <div onClick={handleDeleteParticipant}>
              <Icon symbol={IconSymbol.bin} />
              <FormattedMessage id="USERS.FORM.DELETE_USER" />
            </div>
          </div>
        </>
      )}
      {isNew ? (
        <>
          <div className="user_password">
            <Form.Control
              type="password"
              name="password"
              autoComplete="new-password"
              placeholder="password"
              value={userData.password || ''}
              onChange={handleInputFieldChange}
              required
              pattern={PASSWORD_PATTERN}
            />
          </div>
          <div className="user_regcode">
            <Form.Control
              type="text"
              name="registrationCode"
              placeholder="registration code"
              value={userData.registrationCode || ''}
              onChange={handleInputFieldChange}
              // required
            />
          </div>
        </>
      ) : null}
    </ModalDialog>
  );
}

const UserDataForm = connect()(
  connect(mapUserStateToProps)(UnconnectedUserDataForm)
);
export default UserDataForm;

export function UserDataFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  tag?: 'span' | 'div';
  title?: string;
  onCloseAll?: () => void;
  children?: ReactNode;
  user: IUser | null;
}) {
  const { onClick, children, title, tag, className, user } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.UserDataForm.setCurrentEditedUser(user);
    forms.UserDataForm.setShow(true);
    onClick && onClick(e);
  }

  const TagName = tag ?? 'div';
  return (
    <TagName
      className={classNames(className, {
        button: !!onClick,
      })}
      onClick={handleClick}
      title={title}
    >
      {children}
    </TagName>
  );
}
