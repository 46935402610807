import { useAppDispatch } from 'app/hooks';
import { setLocale } from 'features/intl/intlSlice';
import { LocaleCode } from 'helpers/intl/messages';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type SetLocaleProps = {
  locale: LocaleCode;
};
export default function SetLocale(props: SetLocaleProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function setLoc() {
      await dispatch(setLocale(props.locale));
      navigate('/');
    }
    setLoc();
  }, [dispatch, navigate, props.locale]);

  return <></>;
}
