import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Link,
  NavigateFunction,
  useLocation,
  useParams,
} from 'react-router-dom';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import { useAppDispatch } from 'app/hooks';
import { ProjectsFormLink } from 'components/admin/ProjectsForm';
import { UsersFormLink } from 'components/admin/UsersForm';
import { DialogueFormLink } from 'components/forms/DialogueForm';
import UserAvatar from 'components/user/UserAvatar';
import { UserLoginFormLink } from 'components/user/UserLoginForm';
import { UserLogoutLink } from 'components/user/UserLogoutLink';
import { UserProfileFormLink } from 'components/user/UserProfileForm';
import { UserSignupFormLink } from 'components/user/UserSignupForm';
import {
  DialogueStateProps,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { setLocale } from 'features/intl/intlSlice';
import {
  UserStateProps,
  getAuthorName,
  mapUserStateToProps,
} from 'features/user/userSlice';
import DropMenu from './DropMenu';
import { Logo } from './Logo';

import { ResetBlockOrderAttributes } from 'components/admin/AdminActions';
import { AppSettingsFormLink } from 'components/admin/AppSettingsForm';
import { CreateProjectFormLink } from 'components/forms/CreateProjectForm';
import { UserRegCodeFormLink } from 'components/user/UserRegCode';
import {
  AdminStateProps,
  mapAdminStateToProps,
  setDemoMode,
  setShowIds,
} from 'features/admin/adminSlice';
import {
  AppSettingsStateProps,
  isRightGranted,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { IS_DEV, SLUGS } from 'helpers/consts';

import {
  DialogueToDocxLink,
  DialogueToPdfLink,
} from 'components/dialogues/DialogueView';
import './Navigation.scss';

var navigationTimeOut: NodeJS.Timeout | undefined = undefined;
export function navigateIn(
  path: string, // can be relative
  ms: number,
  navigate?: NavigateFunction,
  // use navigate if the page does not need refreshing
  // e.g., for /login, do not use navigate because the page needs refresh to show the login form
  beforeNavigate?: () => void
) {
  clearTimeout(navigationTimeOut);
  navigationTimeOut = undefined;
  if (ms > 0) {
    navigationTimeOut = setTimeout(() => {
      if (beforeNavigate) beforeNavigate();
      if (navigate) navigate(path);
      else window.location.href = path;
    }, ms);
  } else {
    if (beforeNavigate) beforeNavigate();
    if (navigate) navigate(path);
    else window.location.href = path;
  }
}

type NavigationProps = {
  bg?: boolean;
  white?: boolean;
};

function UnconnectedNavigation(
  props: NavigationProps &
    UserStateProps &
    DialogueStateProps &
    AdminStateProps &
    AppSettingsStateProps
) {
  const {
    bg = false,
    white = false,
    userLoggedIn,
    user,
    userIsGuest,
    userIsAdmin,
    userIsManager,
    userCanEdit,
    locale,
    dialogue,
    demoMode,
    showIds,
    connectionError,
    authors,
    settings,
  } = props;

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const location = useLocation();
  let { dialogueId } = useParams();
  if (!dialogueId)
    dialogueId =
      settings.rights.guestTryout && location.pathname === '/' + SLUGS.TRYOUT
        ? settings.rights.tryoutDialogue
        : undefined;
  const [extraWeight, setExtraWeight] = useState(0);

  const LocaleSelector = () => {
    function setLoc() {
      dispatch(setLocale(locale !== 'nl' ? 'nl' : 'en'));
      // userLoggedIn &&
      //   dispatch(
      //     patchUserAsync({
      //       data: { locale: locale !== 'nl' ? 'nl' : 'en' },
      //       user: user,
      //     })
      //   );
    }
    const loc = () => {
      return locale !== 'nl' ? (
        <FormattedMessage id="LOCALE.NL" />
      ) : (
        <FormattedMessage id="LOCALE.EN" />
      );
    };
    return <div onClick={setLoc}>{loc()}</div>;
  };

  function Breadcrumbs() {
    if (!userLoggedIn) return null;
    return (
      <>
        {/* {window.location.pathname.split('/')[1] === 'myprojects' ? (
          <div className="nav_myprojects">
            <FormattedMessage id="NAVIGATION.MY_PROJECTS" />
          </div>
        ) : (
          <div className="nav_myprojects">
            <Link to="/">
              <FormattedMessage id="NAVIGATION.MY_PROJECTS" />
            </Link>
          </div>
        )} */}
        {dialogue.id ? ( // we're on a dialogue page
          <div className="nav_title">
            {/* <div className="dash_div" /> */}
            <div className="project_name">{dialogue.project?.title}</div>
            {/* <div className="dash_div" /> */}
            <div className="dialogue_name">{dialogue.title}</div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }

  function hasAddTool(): boolean {
    return userCanEdit(dialogue);
  }

  function NotLoggedInMenu() {
    if (userLoggedIn) return null;
    return (
      <>
        <UserLoginFormLink className="nav_item_cta right" />
        <UserSignupFormLink className="nav_item_cta right" />
        <div className="nav_item locale">
          <LocaleSelector />
        </div>
        <div className="nav_item nav_about">
          <Link to={`/${SLUGS.ABOUT}`}>
            <FormattedMessage id="NAVIGATION.ABOUT" />
          </Link>
        </div>
        {/* <AccessibilityMenu /> */}
        {/* {['', SLUGS.WELCOME, SLUGS.ABOUT, SLUGS.GUEST].includes(
          window.location.pathname.split('/')[1]
        ) ? (
          <div className="nav_cta">
            <UserLoginFormLink />
            <UserSignupFormLink />
          </div>
        ) : null} */}
        <DropMenu
          className={classNames('nav_item nav_help', {
            with_add_tool: hasAddTool(),
          })}
        >
          <DropMenu.Trigger>
            <FormattedMessage id="NAVIGATION.HELP.HELP" />
          </DropMenu.Trigger>
          <DropMenu.Items>
            <Link to={`/${SLUGS.HELP}`}>
              <FormattedMessage id="NAVIGATION.HELP.GENERAL" />
            </Link>
            <Link to={`/${SLUGS.HELP}/${SLUGS.TOOLS}`}>
              <FormattedMessage id="NAVIGATION.HELP.TOOLS" />
            </Link>
            <Link to={`/${SLUGS.PRIVACY}`}>
              <FormattedMessage id="NAVIGATION.HELP.PRIVACY" />
            </Link>
            <Link to={`/${SLUGS.CONSENT}`}>
              <FormattedMessage id="NAVIGATION.HELP.CONSENT" />
            </Link>
          </DropMenu.Items>
        </DropMenu>
        <UserLoginFormLink className="nav_item nav_user">
          <UserAvatar user={user} />
        </UserLoginFormLink>
      </>
    );
  }

  function LoggedInMenu() {
    if (!userLoggedIn) return null;
    return (
      <>
        {dialogueId ? (
          <>
            {userCanEdit(dialogue) ? (
              <DropMenu className="nav_item right">
                <DropMenu.Trigger>
                  <FormattedMessage id="NAVIGATION.THIS_DIALOGUE" />
                </DropMenu.Trigger>
                <DropMenu.Items>
                  <DialogueFormLink className="nav_item" dialogue={dialogue}>
                    <FormattedMessage id="NAVIGATION.OVERVIEW" />
                  </DialogueFormLink>
                  {/* <SharingCodesFormLink className="nav_item nav_overview right" /> */}
                  <DialogueToPdfLink dialogue={dialogue} intl={intl} />
                  <DialogueToDocxLink dialogue={dialogue} intl={intl} />
                </DropMenu.Items>
              </DropMenu>
            ) : (
              <DialogueFormLink className="nav_item" dialogue={dialogue}>
                <FormattedMessage id="NAVIGATION.THIS_DIALOGUE" />
              </DialogueFormLink>
            )}
          </>
        ) : userIsManager ||
          (isRightGranted('userCreateProject') && !userIsGuest) ? (
          <div className="nav_item nav_about right">
            <CreateProjectFormLink
              hintProps={{
                hint: intl.formatMessage({
                  id: 'NAVIGATION.NEW_DIALOGUE_HINT',
                }),
                offset: { x: 40, y: 28 },
                offsetRight: true,
              }}
            >
              <FormattedMessage id="NAVIGATION.NEW_DIALOGUE" />
            </CreateProjectFormLink>
          </div>
        ) : null}
        {window.location.pathname.split('/')[1] !== SLUGS.MYPROJECTS ? (
          <div className="nav_item right">
            <Link to="/">
              <FormattedMessage id="NAVIGATION.MY_DIALOGUES" />
            </Link>
          </div>
        ) : null}
        <DropMenu
          className={classNames('nav_item nav_help right', {
            with_add_tool: hasAddTool(),
          })}
        >
          <DropMenu.Trigger>
            <FormattedMessage id="NAVIGATION.HELP.HELP" />
          </DropMenu.Trigger>
          <DropMenu.Items>
            <Link to={`/${SLUGS.HELP}`}>
              <FormattedMessage id="NAVIGATION.HELP.GENERAL" />
            </Link>
            <Link to={`/${SLUGS.TOOLS}`}>
              <FormattedMessage id="NAVIGATION.HELP.TOOLS" />
            </Link>
            <Link to={`/${SLUGS.PRIVACY}`}>
              <FormattedMessage id="NAVIGATION.HELP.PRIVACY" />
            </Link>
            <Link to={`/${SLUGS.CONSENT}`}>
              <FormattedMessage id="NAVIGATION.HELP.CONSENT" />
            </Link>
            <Link to={`/${SLUGS.WELCOME}`}>
              <FormattedMessage id="NAVIGATION.HELP.WELCOME" />
            </Link>
          </DropMenu.Items>
        </DropMenu>
        {/* <AccessibilityMenu /> */}
        <DropMenu className="nav_item nav_user">
          <DropMenu.Trigger>
            <UserAvatar user={user} />
            <div
              className={classNames('user_name', {
                center: user.username && user.username.length < 9,
                blurInDemo: true,
                guest: userIsGuest,
              })}
            >
              {getAuthorName(user, authors)}
            </div>
          </DropMenu.Trigger>
          <DropMenu.Items>
            <UserProfileFormLink />
            <Link to="/">
              <FormattedMessage id="NAVIGATION.MY_DIALOGUES" />
            </Link>
            <LocaleSelector />
            {userIsGuest ? null : <UserRegCodeFormLink />}
            <UserLogoutLink />
          </DropMenu.Items>
        </DropMenu>
      </>
    );
  }

  function AdminMenu() {
    if (!userIsManager) return null;
    return (
      <>
        <DropMenu className="nav_item nav_admin right">
          <DropMenu.Trigger>
            <FormattedMessage id="NAVIGATION.ADMIN" />
          </DropMenu.Trigger>
          <DropMenu.Items>
            {userIsAdmin ? (
              <UsersFormLink>
                <FormattedMessage id={'NAVIGATION.ADMIN_MENU.USERS'} />
              </UsersFormLink>
            ) : null}
            <ProjectsFormLink>
              <FormattedMessage id={'NAVIGATION.ADMIN_MENU.PROJECTS'} />
            </ProjectsFormLink>
            <CreateProjectFormLink>
              <FormattedMessage id="NAVIGATION.ADMIN_MENU.NEW_PROJECT" />
            </CreateProjectFormLink>
            <AppSettingsFormLink>
              <FormattedMessage id={'NAVIGATION.ADMIN_MENU.SETTINGS'} />
            </AppSettingsFormLink>
            <div
              className=""
              onClick={() => {
                dispatch(setDemoMode(!demoMode));
              }}
            >
              <FormattedMessage id="NAVIGATION.ADMIN_MENU.DEMO_MODE" />{' '}
              <FormattedMessage id={`X.${demoMode ? 'OFF' : 'ON'}`} />
            </div>
            <Link to={`/${SLUGS.TEST}`}>Test page</Link>
            <div
              className=""
              onClick={() => {
                dispatch(setShowIds(!showIds));
              }}
            >
              <FormattedMessage id="NAVIGATION.ADMIN_MENU.SHOW_IDS" />{' '}
              <FormattedMessage id={`X.${showIds ? 'OFF' : 'ON'}`} />
            </div>
            {dialogue ? (
              <ResetBlockOrderAttributes dialogue={dialogue} />
            ) : null}
          </DropMenu.Items>
        </DropMenu>
      </>
    );
  }

  function AccessibilityMenu() {
    if (true) return null;
    function toggleBold() {
      const newVal = extraWeight ? 0 : 300;
      setExtraWeight(newVal);
      document.documentElement.style.setProperty('--extra-weight', '' + newVal);
    }
    const cls = classNames({ extraweight: true, selected: extraWeight });
    return (
      <div onClick={toggleBold} className={cls}>
        A
      </div>
    );
  }

  function Notifications() {
    if (connectionError <= 0) return null;
    return (
      <>
        {connectionError > 1 ? <div className="notifications_overlay" /> : null}
        <div
          className={classNames('notifications', {
            async_ready:
              AsyncAPI.connection != null &&
              AsyncAPI.connection.readyState === 1,
            async_fail1: connectionError === 1,
            async_fail2: connectionError === 2,
            async_fail3: connectionError === 3,
          })}
        >
          {connectionError > 0 ? (
            <h1 className="bold">
              <FormattedMessage id="NAVIGATION.ERROR_CONNECTION.TITLE" />
              &nbsp;
            </h1>
          ) : null}
          {connectionError === 1 ? (
            <div>
              <FormattedMessage id="NAVIGATION.ERROR_CONNECTION.LEVEL1" />
            </div>
          ) : connectionError > 1 ? (
            <div>
              <FormattedMessage id="NAVIGATION.ERROR_CONNECTION.LEVEL2" />
              &nbsp;
              <a href="/">
                <FormattedMessage id="NAVIGATION.ERROR_CONNECTION.RELOAD" />
              </a>
            </div>
          ) : null}
        </div>
      </>
    );
  }

  return (
    <div className="navigation_container">
      <Notifications />
      <div className={classNames('navigation', { demoMode: demoMode })}>
        <div className="nav_home">
          <Link to={'/'} title={intl.formatMessage({ id: 'NAVIGATION.HOME' })}>
            <Logo locale={locale} bg={bg || white} />
          </Link>
        </div>
        <Breadcrumbs />
        <AccessibilityMenu />
        <AdminMenu />
        <LoggedInMenu />
        <NotLoggedInMenu />
      </div>
      {IS_DEV ? (
        <div className="nav_info">
          {/* <div className="debug">demoMode: {demoMode ? 'on' : 'off'}</div> */}
        </div>
      ) : null}
    </div>
  );
}

const Navigation = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(
    connect(mapAdminStateToProps)(
      connect(mapAppSettingsStateToProps)(UnconnectedNavigation)
    )
  )
);
export default Navigation;
