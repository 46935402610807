import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { BlockInfo, useForms } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import { getTextAreaHeight } from 'components/messages/TextAreaInput';
import { IBlock } from 'features/block/blockAPI';
import {
  BlockType,
  emptyBlock,
  patchBlockAsync,
  postBlockAsync,
} from 'features/block/blockSlice';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { AnyOrderObject } from 'helpers/objects';
import { getOrderForLast } from 'helpers/sorting';

interface LibrarySettingsFormProps {
  stateMan: StateMan;
  blockInfo: BlockInfo;
}

function UnconnectedLibrarySettingsForm(
  props: LibrarySettingsFormProps &
    FormProps &
    UserStateProps &
    DialogueStateProps
) {
  const { show, setShow, blockInfo, userCanEdit, dialogue, stateMan } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [reset, setReset] = useState(true);
  const [data, setData] = useState<IBlock>({});

  useEffect(() => {
    if (reset || show) {
      if (blockInfo) {
        if (blockInfo.block) {
          // existing block
          setData({ ...blockInfo.block });
        } else if (blockInfo.phase) {
          // new block
          setData({
            ...emptyBlock,
            childType: BlockType.Library,
            phase: blockInfo.phase,
            order: getOrderForLast(blockInfo.phase?.blocks as AnyOrderObject[]),
          });
        }
      }
      setReset(false);
    }
  }, [blockInfo, reset, show]);

  useEffect(() => {
    const ta = document.getElementById('blockDescription');
    if (ta) {
      ta.style.height =
        getTextAreaHeight(ta as HTMLTextAreaElement, 1, 3, true, 4)[0] + 'px';
    }
  }, [show, data]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      [target.name]: target.value,
    });
  }

  async function handleSubmit() {
    try {
      if (data && blockInfo.block) {
        // existing block
        dispatch(
          patchBlockAsync({
            stateMan: stateMan,
            data: data,
          })
        ).then((response: { payload: any }) => {
          switch (response.payload?.response.status) {
            case 200:
            case 201:
              doFormMsg(
                {
                  message: 'Library settings saved',
                  success: true,
                  timeout: 1500,
                },
                setFormMsg
              );
              setShow(false);
              break;
            case 500:
            default:
              const m = 'Internal error, library settings not saved.';
              doFormMsg(
                {
                  message: m,
                  success: false,
                },
                setFormMsg
              );
              break;
          }
        });
      } else {
        // new block
        dispatch(
          postBlockAsync({
            data,
            stateMan,
          })
        ).then((response: { payload: any }) => {
          switch (response.payload?.response.status) {
            case 200:
            case 201:
              doFormMsg(
                {
                  message: 'Library settings saved',
                  success: true,
                  timeout: 1500,
                },
                setFormMsg
              );
              setShow(false);
              break;
            case 500:
            default:
              const m = 'Internal error, chat settings not saved.';
              doFormMsg(
                {
                  message: m,
                  success: false,
                },
                setFormMsg
              );
              break;
          }
        });
      }
    } catch (err) {
      doFormMsg(
        {
          message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
          success: false,
        },
        setFormMsg
      );
      console.log('List update request failed for unclear reason.', err);
    }
  }

  function handleCancel(): boolean {
    doFormMsg(NoFormMsg, setFormMsg);
    // onCancel && onCancel();
    setReset(true);
    setShow(false);
    return true;
  }

  function handleClose(): void {
    // onClose && onClose();
  }

  if (!data) return null;
  if (!userCanEdit(dialogue)) return null;
  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form library_settings_form narrow"
      title={
        <FormattedMessage
          id={
            blockInfo.block
              ? 'BLOCKS.FORM.SETTINGS'
              : 'LISTS.FORM.FORM_TITLE_LIBRARY'
          }
        />
      }
      onSubmit={handleSubmit}
      onCancel={userCanEdit(dialogue) ? handleCancel : undefined}
      onClose={handleClose}
      enableEscape
      formMsg={formMsg}
    >
      <Form.Group as={Row} controlId="blockTitle">
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            className="field subtitle"
            type="text"
            placeholder={intl.formatMessage({
              id: 'BLOCKS.FORM.TITLE_PLCH',
            })}
            name={'name'}
            value={data.name || ''}
            onChange={handleChangeData}
            required
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="blockDescription">
        <Col sm={12} md={12} lg={12}>
          <Form.Control
            className="field"
            type="text"
            as="textarea"
            placeholder={intl.formatMessage({
              id: 'LISTS.FORM.DESCRIPTION_PLCH',
            })}
            name={'description'}
            value={data.description || ''}
            onChange={handleChangeData}
          />
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

const LibrarySettingsForm = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(UnconnectedLibrarySettingsForm)
);
export default LibrarySettingsForm;

export function LibrarySettingsFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  block: IBlock;
  stateMan: StateMan;
  className?: string;
  children?: ReactNode;
}) {
  const { onClick, block } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.LibrarySettingsForm.setCurrentBlockInfo({ block });
    forms.LibrarySettingsForm.setCurrentStateMan(() => props.stateMan);
    forms.LibrarySettingsForm.setShow(true);
    onClick && onClick(e);
  }

  return (
    <div className="form_settings_link" onClick={handleClick}>
      {props.children}
    </div>
  );
}
