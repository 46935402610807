import { Retrieving, callGet } from 'helpers/apiTypes';

/* export const fetchDialogues = async () => {
  try {
    const response = await api.get('Dialogue?and=*,subscribers');
    return response.data;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
 */

export const fetchDialogues = async () => {
  try {
    // New way ---
    const query = {
      '*': true,
      subscribers: { '*': true },
    } as const satisfies Retrieving<'Dialogue'>;
    const response = await callGet('Dialogue', query);

    return response.data;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const fetchDialogueIds = async (userId: number) => {
  try {
    // New way ---
    const query = {
      id: true,
      subscribedToDialogues: {
        id: true,
      },
    } as const satisfies Retrieving<'User'>;
    const response = await callGet('User', query, userId);

    return { response: response.data.subscribedToDialogues[0].id };
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
