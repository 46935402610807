import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';

import { prepareForBackend } from 'helpers/objects';

export type ILike = API['Like']['read'];
export type ILikeCreate = API['Like']['create'];
function prepareForCreate(like: ILikeCreate): ILikeCreate {
  return prepareForBackend(like) as ILikeCreate;
}

export const postLike = async (data: ILikeCreate) => {
  try {
    const response = await api.post('Like', prepareForCreate(data));
    // const id = response.data._id;
    // response.data = { ...data, id: id };
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const deleteLike = async (id: number) => {
  try {
    const response = await api.delete('Like/' + id);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
