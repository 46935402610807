import classNames from 'classnames';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Status } from '@thuas/pd-schemas/built/generated/api-types';
import { useAppDispatch } from 'app/hooks';
import Participants from 'components/dialogues/Participants';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { FormContext } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import { ProjectFormLink } from 'components/forms/ProjectForm';
import { ToolHint } from 'components/messages/ToolHint';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { fetchDialoguesAsync } from 'features/dialogue/dialoguesSlice';
import { IProject } from 'features/projects/projectsAPI';
import {
  ProjectsStateProps,
  Subscriptions,
  fetchProjectsAsync,
  mapProjectsStateToProps,
  patchProjectAsync,
  patchSubscriptionsAsync,
} from 'features/projects/projectsSlice';
import { IUser } from 'features/user/userAPI';
import {
  UsersStateProps,
  fetchUsersAsync,
  mapUsersStateToProps,
} from 'features/users/usersSlice';
import { pickPrimitiveProps } from 'helpers/objects';
import { sortBy } from 'helpers/sorting';
import { Paginator, paginateItems } from './Paginator';
import { DialogHeader } from './helpers/DialogHeader';

import { toLowerCase } from 'helpers/helpers';
import './ProjectsForm.scss';

function UnconnectedProjectsForm(
  props: FormProps & UsersStateProps & ProjectsStateProps
) {
  const { show, setShow, users, projects } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [editing, setEditing] = useState<Set<number>>(new Set());
  const [filteredProjects, setFilteredProjects] = useState<IProject[]>([]);
  const [searchItem, setSearchItem] = useState<string>('');
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [showIds, setShowIds] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [reload, setReload] = useState<boolean>(false);
  const scrollableRef = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    if (reload || show) {
      dispatch(fetchUsersAsync());
      dispatch(fetchProjectsAsync());
      dispatch(fetchDialoguesAsync());
      setSearchItem('');
      setReload(false);
    }
  }, [dispatch, show, reload]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  useEffect(() => {
    if (searchItem && searchItem.length > 0)
      setFilteredProjects(
        projects.filter((p: IProject) =>
          p.title?.toLowerCase().includes(searchItem.toLowerCase())
        )
      );
    else setFilteredProjects(projects);
  }, [searchItem, projects]);

  function handleCancel(): boolean {
    if (editing.size === 0) return true;
    doFormMsg(
      {
        message: 'Please finish or cancel editing first.',
        success: false,
        timeout: 3000,
      },
      setFormMsg
    );
    return false;
  }

  function rowEditing(start: boolean, id: number) {
    setEditing((prev) => {
      if (start) prev.add(id);
      else {
        prev.delete(id);
        setReload(true);
      }
      return prev;
    });
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form projects_form wide"
      title={
        <DialogHeader
          title={<FormattedMessage id={'PROJECTS.FORM.TITLE'} />}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          showIds={showIds}
          setShowIds={setShowIds}
          searchItem={searchItem}
          setSearchItem={setSearchItem}
        />
      }
      noFooter={true}
      footer={
        <FormFooter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemCount={filteredProjects.length}
          itemsPerPage={itemsPerPage}
        />
      }
      onCancel={handleCancel}
      editing={true}
      enableEscape={false}
      formMsg={formMsg}
      refScrollable={scrollableRef}
    >
      <div className="description">
        <FormattedMessage
          id="PROJECTS.FORM.PROJECT_COUNT"
          values={{ count: filteredProjects.length }}
        />
        {', '}
        <FormattedMessage
          id="PROJECTS.FORM.DIALOGUE_COUNT"
          values={{
            count: filteredProjects.reduce(
              (c, p) => c + (p.dialogues?.length ?? 0),
              0
            ),
          }}
        />
      </div>
      <TableHead />
      <TableBody
        currentPage={currentPage}
        projects={filteredProjects}
        users={sortBy(users, 'username')}
        itemsPerPage={itemsPerPage}
        scrollableRef={scrollableRef}
        rowEditing={rowEditing}
        showIds={showIds}
        setFormMsg={setFormMsg}
      />
    </ModalDialog>
  );
}

const ProjectsForm = connect(mapUsersStateToProps)(
  connect(mapProjectsStateToProps)(UnconnectedProjectsForm)
);
export default ProjectsForm;

function TableHead() {
  return (
    <div className="projects_thead project_row">
      <div className="project_name">
        <FormattedMessage id="PROJECTS.FORM.NAME" />
      </div>
      <div className="project_participants">
        <FormattedMessage id="PROJECTS.FORM.PARTICIPANTS" />
      </div>
      <div className="project_dialogues">
        <div className="dialogue_item">
          <div className="dialogue_name">
            <FormattedMessage id="PROJECTS.FORM.DIALOGUES" />
          </div>
          <div className="dialogue_participants">
            <FormattedMessage id="PROJECTS.FORM.PARTICIPANTS" />
            {' ('}
            <FormattedMessage id="PROJECTS.FORM.MODERATORS" />
            {')'}
          </div>
        </div>
      </div>
      <div className="project_save"></div>
    </div>
  );
}

type DialogueItemProps = {
  dialogue: IDialogue;
  onChange: (subscriptions: Subscriptions) => void;
  showIds: boolean;
};
function DialogueItem(props: DialogueItemProps) {
  const { dialogue, onChange, showIds } = props;
  // const [changes, setChanges] = useState<boolean>(false);
  const [subscribers, setSubscribers] = useState<IUser[]>([]);
  const [moderators, setModerators] = useState<IUser[]>([]);
  const statusRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSubscribers(dialogue.subscribers ?? []);
    setModerators(dialogue.moderators ?? []);
  }, [dialogue]);

  return (
    <div key={dialogue.id} className="dialogue_item">
      <div className="dialogue_name">
        <div>
          {showIds ? '[' + dialogue.id + '] ' : null}
          {dialogue.title}
        </div>
        <div
          className="dialogue_status"
          onDoubleClick={() => {
            // editingRow(true);
          }}
          ref={statusRef}
        >
          <div className="status">{dialogue.status || ''}</div>
          {dialogue.registrationCode ? (
            <div className="regcode">
              {'reg.code'}
              <ToolHint
                hint={dialogue.registrationCode ?? ''}
                toolRef={statusRef}
                offset={{ x: -10 }}
                offsetRight={true}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="dialogue_participants">
        <Participants
          project={dialogue.project}
          subscribers={subscribers!}
          moderators={moderators}
          setSubscribers={setSubscribers}
          setModerators={setModerators}
          noLabel
          editing={true}
          onChange={(subs, mods) => {
            onChange({ dialogue, subscribers: subs, moderators: mods });
          }}
          showId={showIds}
        />
      </div>
    </div>
  );
}

type TableRowProps = {
  project: IProject;
  users: IUser[];
  lastRow: boolean;
  rowEditing: (start: boolean, id: number) => void;
  showIds: boolean;
  setFormMsg: Dispatch<SetStateAction<FormMsg>>;
};

function TableRow(props: TableRowProps) {
  const { users, project, lastRow, rowEditing, showIds, setFormMsg } = props;
  const [changesTriggered, setChangesTriggered] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [rowData, setRowData] = useState<IProject>(project);
  const [subscribers, setSubscribers] = useState<IUser[]>([]);
  const [dialogues, setDialogues] = useState<IDialogue[]>([]);
  const [changedSubscriptions, setChangedSubscriptions] = useState<
    Map<number, Subscriptions>
  >(new Map());
  const statusRef = useRef<HTMLInputElement>(null);
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    setSubscribers([...(project.subscribers ?? [])]);
    setDialogues([...(project.dialogues ?? [])]);
  }, [project]);

  function editingRow(start: boolean) {
    setChangesTriggered(start);
    rowEditing(start, rowData.id ?? -1);
    setEditing(start);
  }

  function handleReset() {
    setRowData(project);
    setSubscribers([...(project.subscribers ?? [])]);
    setDialogues([...(project.dialogues ?? [])]);
    editingRow(false);
  }

  function handleSave() {
    dispatch(
      patchProjectAsync({
        data: {
          ...pickPrimitiveProps(project),
          subscribers,
          status: rowData.status,
          registrationCode: rowData.registrationCode,
        } as IProject,
      })
    ).then((response: { payload: any }) => {
      switch (response.payload?.response.status) {
        case 200:
        case 201:
          // TODO: await this?
          dispatch(
            patchSubscriptionsAsync({ subscriptions: changedSubscriptions })
          );
          editingRow(false);
          doFormMsg(
            {
              message: 'Project updated',
              success: true,
              timeout: 1500,
            },
            setFormMsg
          );
          break;
        case 500:
        default:
          const m = 'Internal error, project update not saved.';
          doFormMsg(
            {
              message: m,
              success: false,
            },
            setFormMsg
          );
          break;
      }
    });
  }

  function updateDialogueParticipants(subscriptions: Subscriptions) {
    setChangedSubscriptions((prev) =>
      prev.set(subscriptions.dialogue.id!, subscriptions)
    );
    editingRow(true);
  }

  function handleChangeData(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    editingRow(true);
    setRowData({
      ...rowData,
      [target.name]: target.value,
    });
  }

  return (
    <>
      <div className="project_name">
        <div>
          {showIds ? '[' + project.id + '] ' : null}
          {project.title}
        </div>
        <div className="project_status">
          <Form.Select
            className="field status"
            // disabled={!editing}
            name="status"
            value={rowData.status || ''}
            onChange={handleChangeData}
            required
          >
            <option value={Status.draft}>
              <FormattedMessage id="PROJECT.STATUS.DRAFT" />
            </option>
            <option value={Status.private}>
              <FormattedMessage id="PROJECT.STATUS.PRIVATE" />
            </option>
            <option value={Status.public}>
              <FormattedMessage id="PROJECT.STATUS.PUBLIC" />
            </option>
            <option value={Status.closed}>
              <FormattedMessage id="PROJECT.STATUS.CLOSED" />
            </option>
          </Form.Select>
          {project.registrationCode || editing ? (
            <>
              <Form.Control
                className="field regcode"
                type="text"
                // disabled={!editing}
                placeholder={'reg.code'}
                name="registrationCode"
                value={editing ? rowData.registrationCode || '' : 'reg.code'}
                onChange={handleChangeData}
                onDoubleClick={() => {
                  editingRow(true);
                }}
                ref={statusRef}
              />
              {editing ? null : (
                <ToolHint
                  hint={project.registrationCode ?? ''}
                  toolRef={statusRef}
                  offset={{ x: -10 }}
                  offsetRight={true}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className="project_participants">
        <Participants
          subscribers={subscribers}
          setSubscribers={setSubscribers}
          noLabel
          editing={true}
          onChange={() => {
            setChangesTriggered(true);
          }}
          showId={showIds}
        />
      </div>
      <div className="project_dialogues">
        {dialogues?.length ? (
          sortBy(dialogues, 'title')?.map((d: IDialogue) => (
            <DialogueItem
              key={d.id}
              dialogue={d}
              onChange={updateDialogueParticipants}
              showIds={showIds}
            />
          ))
        ) : (
          <div className="dialogue_item">None</div>
        )}
      </div>
      <div className="project_save">
        <Button onClick={handleSave} size="sm" disabled={!changesTriggered}>
          <FormattedMessage id="X.SAVE" />
        </Button>
        <Button
          onClick={handleReset}
          size="sm"
          variant="outline-secondary"
          disabled={!changesTriggered}
        >
          <FormattedMessage id="X.CANCEL" />
        </Button>
      </div>
    </>
  );
}

type TableBodyProps = {
  currentPage: number;
  projects: IProject[];
  users: IUser[];
  itemsPerPage: number;
  rowEditing: (start: boolean, id: number) => void;
  showIds: boolean;
  setFormMsg: Dispatch<SetStateAction<FormMsg>>;
  scrollableRef?: React.RefObject<HTMLTableSectionElement>;
};
function TableBody(props: TableBodyProps) {
  const {
    currentPage,
    itemsPerPage,
    projects,
    rowEditing,
    users,
    showIds,
    setFormMsg,
    scrollableRef,
  } = props;
  const [paginatedProjects, setPaginatedProjects] = useState<any>([]);

  useEffect(() => {
    setPaginatedProjects(
      paginateItems(
        sortBy(projects, 'title', 'ASC', toLowerCase),
        currentPage,
        itemsPerPage
      )
    );
  }, [currentPage, itemsPerPage, projects]);

  return (
    <div className="projects_tbody" ref={scrollableRef}>
      {paginatedProjects.map((project: IProject, i: number, a: IUser[]) => (
        <div key={project.id} className="project_row">
          <TableRow
            project={project}
            users={users}
            lastRow={i === a.length - 1}
            rowEditing={rowEditing}
            showIds={showIds}
            setFormMsg={setFormMsg}
          />
        </div>
      ))}
    </div>
  );
}

type FormFooterProps = {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  itemCount: number;
  itemsPerPage: number;
};
function FormFooter(props: FormFooterProps) {
  const { currentPage, setCurrentPage, itemCount, itemsPerPage } = props;

  return (
    <>
      <Paginator
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemCount={itemCount}
        itemsPerPage={itemsPerPage}
      />
      <ProjectFormLink>
        <Button variant="outline-secondary">
          <FormattedMessage id="NAVIGATION.ADMIN_MENU.NEW_PROJECT" />
        </Button>
      </ProjectFormLink>
    </>
  );
}

export function ProjectsFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  tag?: 'span' | 'div';
  title?: string;
  onCloseAll?: () => void;
  children?: ReactNode;
}) {
  const { onClick, children, title, tag, className } = props;
  const forms = useContext(FormContext);

  function handleClick(e: React.MouseEvent) {
    forms.ProjectsForm.setShow(true);
    onClick && onClick(e);
  }

  const TagName = tag ?? 'div';
  return (
    <TagName
      className={classNames(className)}
      onClick={handleClick}
      title={title}
    >
      {children}
    </TagName>
  );
}
