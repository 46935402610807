import { SortableListType } from 'components/sortables/SortableList';
import { IBlock } from 'features/block/blockAPI';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { IList } from 'features/list/listAPI';
import { IListItem } from 'features/list/listItemAPI';
import { IMessage } from 'features/message/messageAPI';
import { IPhase } from 'features/phase/phaseAPI';
import { IOption, IPoll } from 'features/poll/pollAPI';
import { AnyIdObject } from './objects';
import { fromUniqueId } from './sorting';

export type DeepFindObjectType = {
  // index: number;
  phase?: IPhase;
  block?: IBlock;
  list?: IList | IPoll;
  object?: AnyIdObject;
};
export function deepFindObject(
  itemUId: string,
  listUId: string,
  haystack: IDialogue
): DeepFindObjectType | null {
  const { id: itemId, type: itemType } = fromUniqueId(itemUId);
  const { id: listId, type: listType } = fromUniqueId(listUId);
  let phase: IPhase | undefined = undefined;
  let block: IBlock | undefined = undefined;
  let list: IList | IPoll | IMessage[] | undefined = undefined;
  // let lists: IList[] | undefined = undefined;
  let object: AnyIdObject | undefined = undefined;
  switch (listType as SortableListType) {
    case SortableListType.Blocks:
      phase = haystack.phases?.find((ph: IPhase) => ph.id === listId);
      if (!phase) return null;
      block = phase.blocks?.find((b: IBlock) => b.id === itemId) as AnyIdObject;
      break;
    case SortableListType.Items:
      phase = haystack.phases?.find((ph: IPhase) => {
        block = ph.blocks?.find((b: IBlock) => {
          if (!b.childListBlock) return false;
          if (itemId) {
            list = b.childListBlock.lists?.find((l) => {
              if (!object)
                object = l.listItems?.find(
                  (i: IListItem) => i.id === itemId
                ) as AnyIdObject;
              return !!object;
            });
          }
          if (!list)
            list = b.childListBlock.lists?.find(
              (l: IList) => l.id === listId
            ) as IList & AnyIdObject;
          return !!list;
        });
        return !!block;
      });
      break;
    case SortableListType.Options:
      phase = haystack.phases?.find((ph: IPhase) => {
        block = ph.blocks?.find((b: IBlock) => {
          if (!b.childPollBlock) return false;
          list = b.childPollBlock.poll ?? undefined;
          if (itemId)
            object = b.childPollBlock.poll?.options?.find(
              (p) => p.id === itemId
            ) as AnyIdObject;
          return !!list;
        });
        return !!block;
      });
      break;
    case SortableListType.Messages:
      phase = haystack.phases?.find((ph: IPhase) => {
        block = ph.blocks?.find((b: IBlock) => {
          if (!b.childChatBlock) return false;
          list = b.childChatBlock.messages as IMessage[];
          if (itemId) {
            object = list?.find(
              (i: IListItem) => i.id === itemId
            ) as AnyIdObject;
            // if we looked for a specific object,
            // but didn't find it,
            // then the list is not the right list
            if (!object) list = undefined;
          }
          return !!list;
        });
        return !!block;
      });
      break;
    default:
  }
  // if (!object || !list) return null;
  return { phase, block, list, object };
}

export function reFindObject(
  src: DeepFindObjectType,
  haystack: any
): DeepFindObjectType | null {
  try {
    if (!src.phase || !src.block) return null;
    let phase: IPhase | undefined = undefined;
    let block: IBlock | undefined = undefined;
    let list: IList | IPoll | undefined = undefined;
    let object: AnyIdObject | undefined = undefined;
    phase = haystack.phases.find((ph: IPhase) => ph.id === src.phase!.id);
    if (!phase) return null;
    block = phase.blocks!.find((b: IBlock) => b.id === src.block!.id);
    if (!block) return null;
    if (block.childListBlock && block.childListBlock.lists) {
      list = block.childListBlock.lists.find(
        (l: IList) => l.id === src.list!.id
      );
      if (list && (list as IList).listItems && src.object?.id)
        object = (list as IList).listItems?.find(
          (i: IListItem) => i.id === src.object!.id
        ) as AnyIdObject;
    }
    if (block.childPollBlock) {
      list = block.childPollBlock.poll as IPoll;
      if (list && (list as IPoll).options && src.object?.id)
        object = (list as IPoll).options?.find(
          (i: IOption) => i.id === src.object!.id
        ) as AnyIdObject;
    }
    return { phase, block, list, object };
  } catch (error) {
    console.log('error: ', error);
    return null;
  }
}
