import api from 'helpers/api';

import { API } from '@thuas/pd-schemas';
import { prepareForBackend } from 'helpers/objects';

export type IPollBlock = API['PollBlock']['read'];
export type IPollBlockCreate = API['PollBlock']['create'];

function prepareForCreate(list: IPollBlock): IPollBlockCreate {
  return prepareForBackend(list, ['id']) as IPollBlockCreate;
}

export const postPollBlock = async (data: IPollBlock) => {
  try {
    return await api.post('PollBlock', prepareForCreate(data));
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchPollBlock = async (id: number, data: IPollBlock) => {
  try {
    return await api.patch('PollBlock/' + id, data);
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
