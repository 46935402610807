import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { ILikeCreate, deleteLike, postLike } from './likeAPI';

export const postLikeAsync = createAsyncThunk(
  'message/postLike',
  async (
    {
      data,
      stateMan,
    }: {
      stateMan: StateMan;
      data: ILikeCreate;
    },
    thunkAPI
  ) => {
    try {
      return { response: await postLike(data), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const deleteLikeAsync = createAsyncThunk(
  'message/deleteLike',
  async (
    {
      id,
      stateMan,
    }: {
      id: number;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await deleteLike(id), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export function addLikeCases(builder: ActionReducerMapBuilder<IDialogueState>) {
  builder
    .addCase(postLikeAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(postLikeAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const likes = stateObj.likes || stateObj.message.likes;
      if (likes) {
        const id = action.payload.response.data._id;
        const data = {
          ...action.meta.arg.data,
          id: id,
        };
        likes.push(data);
      }
    })
    .addCase(postLikeAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(deleteLikeAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(deleteLikeAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);

      const likes = stateObj.likes;
      const id = action.meta.arg.id;
      if (likes) {
        likes.splice(
          likes.findIndex((l: any) => l.id === id),
          1
        );
      } else if (stateObj.message.likes) {
        stateObj.message.likes.splice(
          stateObj.message.likes.findIndex((l: any) => l.id === id),
          1
        );
      }
    })
    .addCase(deleteLikeAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });
}
