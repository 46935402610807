import { BlobClient } from '@azure/storage-blob';
import api from 'helpers/api';

import { API } from '@thuas/pd-schemas';
import { prepareForBackend, toArrayOfIdObject } from 'helpers/objects';

export type IFile = API['File']['read'];
export type IFileCreate = Omit<
  API['File']['create'],
  | 'file_path'
  | 'fileName'
  | 'uri'
  | 'projects'
  | 'dialogues'
  | 'avatarsFor'
  | 'messages'
  | 'libraries'
  | 'projectFiles'
  | 'documentFiles'
> & {
  libraries?: [id: number];
  projectFiles?: [id: number];
  documentFiles?: [id: number];
  librariesAdd?: { id: number }[];
  projectFilesAdd?: { id: number }[];
  documentFilesAdd?: { id: number }[];
};

type IFileUpdate = API['File']['update'] & {
  librariesOverwrite?: { id: number }[];
  projectFilesOverwrite?: { id: number }[];
  documentFilesOverwrite?: { id: number }[];
};
function prepareForUpdate(file: IFile): IFileUpdate {
  const updated = prepareForBackend(file, [
    'id',
    'author',
    'uri',
    'fileName',
    'file_path',
    'libraries',
    'projectFiles',
    'documentFiles',
  ]) as IFileUpdate;
  updated.librariesOverwrite = toArrayOfIdObject(file.libraries);
  updated.projectFilesOverwrite = toArrayOfIdObject(file.projectFiles);
  updated.documentFilesOverwrite = toArrayOfIdObject(file.documentFiles);
  return updated;
}

export async function postFile(file: File, data: IFileCreate) {
  try {
    const response = await api.post('File', {
      ...data,
      fileName: file.name,
      librariesAdd: data.libraries
        ? data.libraries.map((x) => ({ id: x }))
        : [],
      projectFilesAdd: data.projectFiles
        ? data.projectFiles.map((x) => ({ id: x }))
        : [],
      documentFilesAdd: data.documentFiles
        ? data.documentFiles.map((x) => ({ id: x }))
        : [],
    });
    const blobClient = new BlobClient(response.data.message);
    await blobClient.getBlockBlobClient().uploadData(file);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export const getFile = async (id: number) => {
  try {
    const response = await api.get(`File/${id}?and=*`);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const getFileURIByFilePath = async (file_path: string) => {
  try {
    const response = await api.get(`File?and=*`);
    for (const file of response.data) {
      if (file.file_path === file_path) {
        return file.uri;
      }
    }
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchFile = async (data: IFile) => {
  try {
    const response = await api.patch('File/' + data.id, prepareForUpdate(data));
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export async function deleteFile(fileId?: number) {
  try {
    if (!fileId) return;
    const findFile = await api.get(`File/${fileId}?and=uri`);
    const response = await api.delete(`File/${fileId}`);

    if (response.status === 204) {
      const blobClient = new BlobClient(findFile.data.uri);
      await blobClient.getBlockBlobClient().deleteIfExists();
    }

    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}
