import { persistStore } from 'redux-persist';

import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction,
} from '@reduxjs/toolkit';

import dialogueReducer from 'features/dialogue/dialogueSlice';
import { persistedIntlReducer } from 'features/intl/persistedIntlReducer';
import projectsReducer from 'features/projects/projectsSlice';

import adminReducer from 'features/admin/adminSlice';
import appSettingsReducer from 'features/admin/appSettingsSlice';
import absoluteBlockReducer from 'features/block/absoluteBlockSlice';
import dialoguesReducer from 'features/dialogue/dialoguesSlice';
import userReducer from 'features/user/userSlice';
import usersReducer from 'features/users/usersSlice';

const combinedReducer = combineReducers({
  intl: persistedIntlReducer,
  user: userReducer,
  admin: adminReducer,
  projects: projectsReducer,
  dialogue: dialogueReducer,
  dialogues: dialoguesReducer,
  users: usersReducer,
  appSettings: appSettingsReducer,
  absoluteBlock: absoluteBlockReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  return combinedReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const persistor = persistStore(store);
