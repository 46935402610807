// The types defined in this file have no dependencies

type Tail<T extends readonly any[]> = ((...t: T) => void) extends (
  h: any,
  ...r: infer R
) => void
  ? R
  : never;

// ArrayToUnion is not tail-recursive!
// Tail-recursiveness helps Typescript to optimize the recursion,
// and therefore giving less faulty warnings of infinite recursion.

export type ArrayToUnion<
  T,
  Depth = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'
> = Depth extends `x${infer Depth2}`
  ? T extends readonly any[]
    ? T['length'] extends 0
      ? never
      : ArrayToUnion<Tail<T>, Depth2> | ArrayToUnion<T[0], Depth2>
    : {} extends T
    ? T
    : {
        [key in keyof T]: ArrayToUnion<T[key], Depth2>;
      }
  : T;

export type ArrayToUnion0<T> = T;
export type ArrayToUnion1<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion0<Tail<T>> | ArrayToUnion0<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion0<T[key]>; };
export type ArrayToUnion2<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion1<Tail<T>> | ArrayToUnion1<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion1<T[key]>; };
export type ArrayToUnion3<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion2<Tail<T>> | ArrayToUnion2<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion2<T[key]>; };
export type ArrayToUnion4<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion3<Tail<T>> | ArrayToUnion3<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion3<T[key]>; };
export type ArrayToUnion5<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion4<Tail<T>> | ArrayToUnion4<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion4<T[key]>; };
export type ArrayToUnion6<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion5<Tail<T>> | ArrayToUnion5<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion5<T[key]>; };
export type ArrayToUnion7<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion6<Tail<T>> | ArrayToUnion6<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion6<T[key]>; };
export type ArrayToUnion8<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion7<Tail<T>> | ArrayToUnion7<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion7<T[key]>; };
export type ArrayToUnion9<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion8<Tail<T>> | ArrayToUnion8<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion8<T[key]>; };
export type ArrayToUnion10<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion9<Tail<T>> | ArrayToUnion9<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion9<T[key]>; };
export type ArrayToUnion11<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion10<Tail<T>> | ArrayToUnion10<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion10<T[key]>; };
export type ArrayToUnion12<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion11<Tail<T>> | ArrayToUnion11<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion11<T[key]>; };
export type ArrayToUnion13<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion12<Tail<T>> | ArrayToUnion12<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion12<T[key]>; };
export type ArrayToUnion14<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion13<Tail<T>> | ArrayToUnion13<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion13<T[key]>; };
export type ArrayToUnion15<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion14<Tail<T>> | ArrayToUnion14<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion14<T[key]>; };
export type ArrayToUnion16<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion15<Tail<T>> | ArrayToUnion15<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion15<T[key]>; };
export type ArrayToUnion17<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion16<Tail<T>> | ArrayToUnion16<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion16<T[key]>; };
export type ArrayToUnion18<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion17<Tail<T>> | ArrayToUnion17<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion17<T[key]>; };
export type ArrayToUnion19<T> = T extends readonly any[] ? T['length'] extends 0 ? never : ArrayToUnion18<Tail<T>> | ArrayToUnion18<T[0]> : {} extends T ? T : { [key in keyof T]: ArrayToUnion18<T[key]>; };

export type ArrayToUnionInfinite<T> = T extends readonly any[]
  ? T['length'] extends 0
    ? never
    : ArrayToUnionInfinite<Tail<T>> | ArrayToUnionInfinite<T[0]>
  : {} extends T
  ? T
  : {
      [key in keyof T]: ArrayToUnionInfinite<T[key]>;
    };

export const getKeys = Object.keys as <T extends object>(
  obj: T
) => Array<keyof T>;

export type UnionToIntersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;

export type StringLiteral<T> = T extends string
  ? string extends T
    ? never
    : T
  : never;

/** @summary Assert that value is of type T. Usefull if the Typescript compiler is dumb. */
export function knowIs<T>(value: any): asserts value is T {}

type EntriesType =
  | [PropertyKey, unknown][]
  | ReadonlyArray<readonly [PropertyKey, unknown]>;

/**
 *
 * @author https://stackoverflow.com/a/69019874/815305
 * @summary This is a typed version of Object.fromEntries.
 */
export function createTypedObjectFromEntries<ARR_T extends EntriesType>(
  arr: ARR_T
) {
  type DeepWritable<OBJ_T> = {
    -readonly [P in keyof OBJ_T]: DeepWritable<OBJ_T[P]>;
  };

  type UnionObjectFromArrayOfPairs<ARR_T extends EntriesType> =
    DeepWritable<ARR_T> extends (infer R)[]
      ? R extends [infer key, infer val]
        ? { [prop in key & PropertyKey]: val }
        : never
      : never;
  type MergeIntersectingObjects<ObjT> = { [key in keyof ObjT]: ObjT[key] };
  type EntriesToObject<ARR_T extends EntriesType> = MergeIntersectingObjects<
    UnionToIntersection<UnionObjectFromArrayOfPairs<ARR_T>>
  >;

  return Object.fromEntries(arr) as EntriesToObject<ARR_T>;
}

/**
 *
 * @author https://stackoverflow.com/a/69019874/815305
 * @summary This is a typed version of Object.entries.
 */
export function getTypedObjectEntries<
  OBJ_T extends Record<PropertyKey, unknown>
>(obj: OBJ_T) {
  type PickByValue<OBJ_T, VALUE_T> = // From https://stackoverflow.com/a/55153000
    Pick<
      OBJ_T,
      { [K in keyof OBJ_T]: OBJ_T[K] extends VALUE_T ? K : never }[keyof OBJ_T]
    >;
  type ObjectEntries<OBJ_T> = // From https://stackoverflow.com/a/60142095
    {
      [K in keyof OBJ_T]: [keyof PickByValue<OBJ_T, OBJ_T[K]>, OBJ_T[K]];
    }[keyof OBJ_T][];

  return Object.entries(obj) as ObjectEntries<OBJ_T>;
}
