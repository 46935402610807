import { ArrayToUnion } from '../typeHelpers.js';
import { PayloadWithRooms } from './withRooms.js';

export const CallingMessage = {
  event: 'calling',
  payload: PayloadWithRooms({
    out: [
      { event: 'sending signal', payload: {} },
      { event: 'returning signal', payload: {} },
      { event: 'join meeting', payload: {} },
      { event: 'leave meeting', payload: {} },
      { event: 'cleanup request', payload: {} },
      { event: 'get preview', payload: {} },
    ],
    in: [
      { event: 'receiving returned signal', payload: {} },
      { event: 'all users', payload: {} },
      { event: 'user joined', payload: {} },
      { event: 'user left', payload: {} },
      { event: 'preview', payload: {} },
    ],
    inout: [
      { event: 'toggled video', payload: {} },
      { event: 'toggled audio', payload: {} },
      { event: 'toggled recording', payload: {} },
    ],
  } as const),
} as const;

export type CallingMessage = ArrayToUnion<typeof CallingMessage>;
