import { useEffect, useRef, useState } from 'react';

import { useAppDispatch } from 'app/hooks';
import DialogueView from 'components/dialogues/DialogueView';
import { navigateIn } from 'components/navigation/Navigation';
import {
  AppSettingsStateProps,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { GUEST_TRYOUT_ACCESS_CODE } from 'helpers/consts';
import { useRandomName } from 'helpers/randomName';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

export function UnconnectedTryOut(
  props: UserStateProps & AppSettingsStateProps
) {
  const { userId, user, settings } = props;
  const getRandomName = useRandomName();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [okay, setOkay] = useState<boolean>(false);
  const working = useRef<boolean>(false);
  const guestTryoutAccessCode =
    settings.rights.tryoutCode ?? GUEST_TRYOUT_ACCESS_CODE;

  useEffect(() => {
    console.log('user:', user);
    if (!settings._initial && !settings.rights.guestTryout) navigateIn('/', 0);
    // async function loginGuest(): Promise<string> {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       const name = getRandomName();
    //       const guestData: ISignupGuestData = {
    //         username: name,
    //         guestAccessCode: GUEST_TRYOUT_ACCESS_CODE,
    //       };
    //       dispatch(signupGuestAsync(guestData)).then(
    //         (response: { payload: any }) => {
    //           switch (response.payload?.response.status) {
    //             case 201:
    //               console.log(
    //                 intl.formatMessage({
    //                   id: 'USERS.SIGNUP.SUCCESS',
    //                 })
    //               );
    //               working.current = false;
    //               resolve(name);
    //               break;
    //             case 406:
    //               console.log(
    //                 intl.formatMessage({
    //                   id: 'USERS.SIGNUP.FAILED_CODE',
    //                 })
    //               );
    //               reject();
    //               break;
    //             case 500:
    //             default:
    //               console.log(
    //                 intl.formatMessage({
    //                   id: 'USERS.SIGNUP.FAILED',
    //                 })
    //               );
    //               reject();
    //               break;
    //           }
    //         }
    //       );
    //     } catch (err) {
    //       console.log('Signup request failed for unclear reason.', err);
    //       reject();
    //     }
    //   });
    // }

    if (!okay) {
      if (user._initial && userId === 0) {
        navigateIn(`/guest/${guestTryoutAccessCode}`, 0);
      } else if (userId !== undefined) setOkay(true);
      else setOkay(false);
    }

    // if (!okay && !working.current && user._initial && userId === 0) {
    //   working.current = true;
    //   loginGuest()
    //     .then((response: any) => {
    //       setOkay(true);
    //     })
    //     .catch((err) => {
    //       console.log('Signup request failed for unclear reason.', err);
    //     });
    // } else if (userId !== undefined) setOkay(true);
    // else setOkay(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userId, settings]);

  return okay ? <DialogueView tryout /> : <div>Loading...</div>;
}

const TryOut = connect(mapUserStateToProps)(
  connect(mapAppSettingsStateToProps)(UnconnectedTryOut)
);
export default TryOut;
