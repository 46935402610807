import { AnyOrderObject } from 'helpers/objects';
import { xyPosition } from 'helpers/positions';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SortableItemType, SortableListInfo } from './SortableList';

export type DropTargetInfo = {
  id: string;
  isCanvas: boolean;
  accepting: string[];
};

type SortableContextType = {
  getDropTarget: (id: string) => DropTargetInfo | undefined;
  setDropTarget: (
    id: string,
    accepting: string[],
    isCanvas?: boolean
  ) => { id: string; target: DropTargetInfo } | undefined;
  dragId: string | null;
  setDragId: (id: string | null) => void;
  getDragItemType: () => SortableItemType;
  setDragItemType: (itemType: SortableItemType) => void;
  getDragItem: () => AnyOrderObject | null;
  setDragItem: (item: AnyOrderObject | null) => void;
  getDragSource: () => SortableListInfo | null;
  setDragSource: (list?: SortableListInfo) => void;
  getDropTargetDiv: (id: string) => HTMLDivElement | null;
  getDragPos: () => xyPosition;
  setDragPos: (xyPos: xyPosition) => void;
  isCopying: () => boolean;
  setIsCopying: (copying: boolean) => void;
};

const SortableContext = createContext<SortableContextType | undefined>(
  undefined
);

export function useSortableContext(): SortableContextType {
  const context = useContext(SortableContext);
  if (!context) {
    throw new Error('Missing SortableContextProvider.');
  }
  return context;
}

export default function SortableContextProvider({
  children,
}: PropsWithChildren) {
  const [droptargets] = useState(new Map<string, DropTargetInfo>());
  // dragId is set to initiate re-rendering of SortableList and so must be a state var.
  // It can also be used to check if a drag operation is ongoing.
  const [dragId, setDragId] = useState<string | null>(null);
  // dragType and dragPos are is set during rendering of SortableItem
  // and thus cannot be state var - to avoid a render loop.
  const dragItem = useRef<AnyOrderObject | null>(null);
  const dragItemType = useRef<SortableItemType>(SortableItemType.SortableItem);
  const dragSource = useRef<SortableListInfo | null>(null);
  const dragPos = useRef<xyPosition>(undefined); // cannot be state var to avoid render loop
  const modKeyPressed = useRef<boolean>(false);

  function getDropTarget(id: string): DropTargetInfo | undefined {
    if (!id) {
      console.warn(`getDropTarget: Cannot get without id`);
      return undefined;
    }
    return droptargets.get(id);
  }

  function setDropTarget(
    id: string,
    accepting: string[],
    isCanvas: boolean = false
  ): { id: string; target: DropTargetInfo } | undefined {
    if (!id) {
      console.warn(`setDropTarget: Cannot set without id`);
      return undefined;
    }
    const target: DropTargetInfo = {
      id: id,
      accepting: accepting,
      isCanvas: isCanvas,
    };
    droptargets.set(id, target);
    return { id, target };
  }

  function getDragItemType(): SortableItemType {
    return dragItemType.current;
  }

  function setDragItemType(
    itemType: SortableItemType = SortableItemType.SortableItem
  ) {
    dragItemType.current = itemType;
  }

  function getDragItem(): AnyOrderObject | null {
    return dragItem.current;
  }

  function setDragItem(item: AnyOrderObject | null = null) {
    dragItem.current = item;
  }

  function getDragSource(): SortableListInfo | null {
    return dragSource.current;
  }

  function setDragSource(list: SortableListInfo | null = null) {
    dragSource.current = list;
  }

  function getDropTargetDiv(id: string): HTMLDivElement | null {
    return document.getElementById(id) as HTMLDivElement;
  }

  function getDragPos(): xyPosition {
    return dragPos.current;
  }

  function setDragPos(xyPos: xyPosition) {
    dragPos.current = xyPos;
  }

  // set up event listener for copying items while dragging
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (['Control', 'Alt'].includes(event.key)) {
        modKeyPressed.current = true;
      }
    };
    const handleKeyUp = (event: KeyboardEvent) => {
      if (['Control', 'Alt'].includes(event.key)) {
        modKeyPressed.current = false;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  function isCopying(): boolean {
    return modKeyPressed.current || (getDragSource()?.locked ?? false);
  }

  function setIsCopying(copying: boolean) {
    modKeyPressed.current = copying;
  }

  const contextValue: SortableContextType = {
    getDropTarget,
    setDropTarget,
    dragId,
    setDragId,
    getDragItemType,
    setDragItemType,
    getDragItem,
    setDragItem,
    getDragSource,
    setDragSource,
    getDropTargetDiv,
    getDragPos,
    setDragPos,
    isCopying,
    setIsCopying,
  };

  // disable all @hello-pangea/dnd development warnings
  (window as any)['__@hello-pangea/dnd-disable-dev-warnings'] = true;
  return (
    <SortableContext.Provider value={contextValue}>
      {children}
    </SortableContext.Provider>
  );
}
