import { Text } from '@react-pdf/renderer';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { UserProfileFormLink } from 'components/user/UserProfileForm';
import {
  DialogueStateProps,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IMessage } from 'features/message/messageAPI';
import { IUser } from 'features/user/userAPI';
import { getAuthorName } from 'features/user/userSlice';
import { sameId } from 'helpers/objects';

import pfdStyles from 'css/pdfStyles';
import { ITableCellBorders, Paragraph, TextRun } from 'docx';

// export function useAuthors() {
//   const getAuthors = useCallback((dialogue?: IDialogue): IAuthor[] => {
//     const currentDialogue = store.getState().dialogue.dialogue;
//     const d: IDialogue = dialogue ?? currentDialogue;
//     console.log('collecting authors from dialogue', d.id);
//     if (!d || !d.subscribers) return [];
//     const userMap: Map<number, IUser> = new Map<number, IUser>([]);
//     d.subscribers.map((u) => userMap.set(u.id!, u));
//     d.phases?.forEach((ph) => {
//       ph.blocks?.forEach((bl) => {
//         switch (bl.childType) {
//           case BlockType.Chat:
//             bl.childChatBlock?.messages?.forEach((m) => {
//               if (m.author) {
//                 userMap.set(m.author.id!, m.author);
//               }
//             });
//         }
//       });
//     });
//     const authors = sortBy(
//       Array.from(userMap.values()).map((u: IUser) => {
//         return { ...u, seq: 0 };
//       }) as IAuthor[],
//       'username', // first sort by username
//       'ASC',
//       undefined,
//       'id', // then by id (first registered gets lowest seq number)
//       'ASC'
//     );
//     authors.forEach((u: IAuthor, i, arr) => {
//       if (i > 0 && arr[i - 1].username === u.username) {
//         arr[i].seq = (arr[i - 1].seq || 1) + 1;
//         if (!arr[i - 1].seq) arr[i - 1].seq = 1;
//       }
//     });
//     return authors;
//   }, []);
//   return getAuthors;
// }

type AuthorProps = {
  author?: IUser;
  me?: boolean;
  passive?: boolean;
  message?: IMessage;
  prev?: number | IUser;
  color?: string;
};
function UnconnectedAuthor(props: AuthorProps & DialogueStateProps) {
  const { me = false, passive = false, color, authors } = props;
  const author = props.author || props.message?.author;
  return (
    <div
      className={classNames('msg_author', {
        same: props.prev && sameId(author, props.prev),
        blurInDemo: true,
      })}
      style={{ color: color }}
    >
      {/* <UserPopup user={author} /> */}
      {me ? (
        <UserProfileFormLink>
          {passive ? (
            <FormattedMessage id="X.YOU_P" />
          ) : (
            <FormattedMessage id="X.YOU" />
          )}
        </UserProfileFormLink>
      ) : typeof author === 'object' ? (
        <UserProfileFormLink author={author}>
          {/* {author.username} */}
          {getAuthorName(author, authors)}
        </UserProfileFormLink>
      ) : (
        'unknown'
      )}
    </div>
  );
}

const Author = connect(mapDialogueStateToProps)(UnconnectedAuthor);
export default Author;

export function AuthorToPdf(props: AuthorProps) {
  const { me = false, passive = false, color } = props;
  const author = props.author || props.message?.author;
  return (
    <Text style={{ ...pfdStyles.author, color: color ?? 'black' }}>
      {author?.username ?? 'unknown'}
    </Text>
  );
}

export function authorToDocx(
  props: AuthorProps & { borders?: ITableCellBorders; indent?: boolean }
) {
  const { me = false, passive = false, color, borders, indent } = props;
  const author = props.author || props.message?.author;
  return new Paragraph({
    children: [
      new TextRun({
        text: author?.username,
        color: color,
      }),
    ],
    style: 'messageAuthor',
    indent: indent ? { left: 6 * 20 } : {},
  });
}
