import classNames from 'classnames';
import React, {
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { useForms } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import { IAppSettings } from 'features/admin/appSettingsAPI';
import {
  AppSettingsStateProps,
  BlockTypeValues,
  ListTypeValues,
  emptySettings,
  mapAppSettingsStateToProps,
  patchAppSettingsAsync,
} from 'features/admin/appSettingsSlice';
import { BlockType } from 'features/block/blockSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';

import { GUEST_TRYOUT_ACCESS_CODE } from 'helpers/consts';
import './ProjectsForm.scss';

function UnconnectedAppSettingsForm(
  props: FormProps & UserStateProps & AppSettingsStateProps
) {
  const { show, setShow, userIsAdmin, settings } = props;
  const dispatch = useAppDispatch();
  const [data, setData] = useState<IAppSettings>(emptySettings);
  // {
  //   rights: {},
  //   tools: {},
  //   id: 0,
  // }
  // );
  const intl = useIntl();
  const scrollableRef = useRef<HTMLTableSectionElement>(null);
  const listsRef = useRef<HTMLDivElement>();

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  useEffect(() => {
    if (show && settings)
      setData({
        ...settings,
        rights: settings.rights ?? {},
        tools: {
          blocks: [...settings.tools?.blocks],
          lists: [...settings.tools?.lists],
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (!userIsAdmin) return null;

  function handleCheckRights(name: string, checked: boolean) {
    setData({
      ...data,
      rights: { ...data.rights, [name]: !checked },
    });
  }

  function handleChangeRights(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setData({
      ...data,
      rights: { ...data.rights, [target.name]: target.value },
    });
  }

  function handleCheckTool(name: string, checked: boolean) {
    let arr: string[];
    let arrName: string;
    if (BlockTypeValues.includes(name)) {
      arrName = 'blocks';
      arr = data.tools.blocks;
    } else {
      arrName = 'lists';
      arr = data.tools.lists;
    }
    if (arr) {
      const i = arr.indexOf(name);
      if (!checked) {
        if (i < 0) arr.push(name);
      } else if (i >= 0) arr.splice(i, 1);
      setData({
        ...data,
        tools: { ...data.tools, [arrName]: arr },
      });
    }
  }

  async function handleSubmit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(patchAppSettingsAsync({ data })).then(
          (response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                resolve();
                break;
              case 401:
              default:
                doFormMsg(
                  {
                    message: intl.formatMessage({ id: 'X.FAILED' }),
                    success: false,
                  },
                  setFormMsg
                );
                reject();
                break;
            }
          }
        );
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg
        );
        console.log('Login request failed for unclear reason.', err);
        reject();
      }
    });
  }

  function toolName(key: string): string {
    return intl.formatMessage({ id: `TOOLS.${key.toUpperCase()}` });
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form appsettings_form"
      title={<FormattedMessage id={'ADMIN.SETTINGS.FORM_TITLE'} />}
      noFooter={false}
      onSubmit={handleSubmit}
      onCancel={() => true}
      editing={true}
      enableEscape={false}
      formMsg={formMsg}
      refScrollable={scrollableRef}
    >
      <div className="header">
        <div className="subheading">
          <FormattedMessage id="ADMIN.SETTINGS.USERS.RIGHTS" />
        </div>
      </div>
      <Form.Group as={Row}>
        <Col sm={12} md={12} lg={12}>
          <SettingsCheckbox
            key={'userCreateProject'}
            name={'userCreateProject'}
            label={intl.formatMessage({
              id: 'ADMIN.SETTINGS.USERS.CREATE_PROJECT',
            })}
            checked={data?.rights?.userCreateProject ?? false}
            onCheck={handleCheckRights}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6} md={6} lg={6}>
          <SettingsCheckbox
            key={'guestTryout'}
            name={'guestTryout'}
            label={intl.formatMessage({
              id: 'ADMIN.SETTINGS.USERS.GUEST_TRYOUT',
            })}
            checked={data?.rights?.guestTryout ?? false}
            onCheck={handleCheckRights}
          />
        </Col>
        <Col sm={6} md={6} lg={6}>
          <Form.Control
            type="number"
            name="tryoutDialogue"
            value={data?.rights?.tryoutDialogue ?? 0}
            onChange={handleChangeRights}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={6} md={6} lg={6}>
          {intl.formatMessage({ id: 'ADMIN.SETTINGS.USERS.GUEST_TRYOUT_CODE' })}
        </Col>
        <Col sm={6} md={6} lg={6}>
          <Form.Control
            type="text"
            name="tryoutCode"
            value={data?.rights?.tryoutCode ?? GUEST_TRYOUT_ACCESS_CODE}
            onChange={handleChangeRights}
          />
        </Col>
      </Form.Group>
      <div className="header">
        <div className="subheading">
          <FormattedMessage id="ADMIN.SETTINGS.MODERATORS.TOOLS_AVAILABLE" />
        </div>
      </div>
      <Form.Group as={Row}>
        <Col sm={6} md={6} lg={6}>
          {BlockTypeValues.map((t) => {
            return (
              <SettingsCheckbox
                key={t}
                name={t}
                label={toolName(t)}
                checked={data?.tools?.blocks?.includes(t) ?? false}
                onCheck={handleCheckTool}
              />
            );
          })}
        </Col>
        <Col sm={6} md={6} lg={6} ref={listsRef}>
          <div
            className={classNames('text', {
              disabled: !data.tools.blocks?.includes(BlockType.List) ?? false,
            })}
          >
            <FormattedMessage id="ADMIN.SETTINGS.MODERATORS.LISTTYPES" />:
          </div>
          {ListTypeValues.map((t) => {
            return (
              <SettingsCheckbox
                key={t}
                name={t}
                label={toolName(t)}
                checked={data?.tools?.lists?.includes(t) ?? false}
                onCheck={handleCheckTool}
                disabled={!data.tools.blocks?.includes(BlockType.List) ?? false}
              />
            );
          })}
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

const AppSettingsForm = connect()(
  connect(mapUserStateToProps)(
    connect(mapAppSettingsStateToProps)(UnconnectedAppSettingsForm)
  )
);
export default AppSettingsForm;

type SettingsCheckboxProps = {
  name: string;
  label: string;
  checked: boolean;
  onCheck: (name: string, checked: boolean) => void;
  disabled?: boolean;
};

function SettingsCheckbox(props: SettingsCheckboxProps) {
  const { name, label, checked, onCheck, disabled = false } = props;

  function handleChange(event: SyntheticEvent) {
    onCheck(name, checked);
  }

  return (
    <Form.Group controlId={name}>
      <Form.Check
        className="checkbox"
        checked={checked}
        name={name}
        label={label}
        onChange={handleChange}
        type={'checkbox'}
        disabled={disabled}
      />
    </Form.Group>
  );
}

export function AppSettingsFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  tag?: 'span' | 'div';
  title?: string;
  onCloseAll?: () => void;
  children?: ReactNode;
}) {
  const { onClick, children, title, tag, className } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.AppSettingsForm.setShow(true);
    onClick && onClick(e);
  }

  const TagName = tag ?? 'div';
  return (
    <TagName
      className={classNames(className)}
      onClick={handleClick}
      title={title}
    >
      {children}
    </TagName>
  );
}
