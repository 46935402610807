import classNames from 'classnames';
import { IUser } from 'features/user/userAPI';
import { getColorForId } from 'helpers/colors';

import { useIntl } from 'react-intl';
import './UserAvatar.scss';

type UserAvatarProps = {
  user?: IUser;
  dark?: boolean;
  tiny?: boolean;
  small?: boolean;
  large?: boolean;
  medium?: boolean;
  className?: string;
};

export default function UserAvatar(props: UserAvatarProps) {
  const {
    user,
    dark = false,
    tiny = false,
    small = false,
    large = false,
    medium = false,
    className,
  } = props;
  // const uri = (user && user.avatar?.uri) || '/icons/user-dark.svg';
  const uri = (user && user.avatar?.uri) || null;
  const color = getColorForId(user?.id ?? 0);
  const intl = useIntl();
  let letter = user?.username ? user.username[0] : '?';
  const identifier = intl.formatMessage({ id: 'USERS.ANONYMOUS.IDENTIFIER' });
  if ((user?.username?.indexOf(identifier) ?? -1) >= 0) {
    const letter_from = parseInt(
      intl.formatMessage({ id: 'USERS.ANONYMOUS.LETTER_FROM' })
    );
    letter = user?.username ? user.username.split(' ')[letter_from][0] : '?';
  }

  return (
    <>
      <div
        className={classNames('user_avatar', className, {
          user_avatar_dark: dark,
          user_avatar_tiny: tiny,
          user_avatar_small: small,
          user_avatar_large: large,
          user_avatar_medium: medium,
          blurInDemo: true,
        })}
        style={
          uri
            ? {
                backgroundImage: `url(${uri})`,
                border: '1px solid transdparent',
              }
            : {
                backgroundColor: `${color}`,
                color: `${color}`,
                border: `1px solid ${color}`,
              }
        }
      >
        {uri ? null : (
          <div className="user_letter">
            <svg
              id="letter"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 60 60"
            >
              <text
                transform="translate(30 55)"
                fill={`${color}`}
                fontFamily="Arial-Black, 'Arial Black'"
                fontSize="20"
                fontWeight="800"
                textAnchor="middle"
              >
                <tspan x="0" y="0">
                  {letter}
                </tspan>
              </text>
            </svg>
          </div>
        )}
      </div>
    </>
  );
}
