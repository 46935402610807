import { store } from 'app/store';
import { IUser } from 'features/user/userAPI';

// Color schemes for the colors in lists
export type PDColor = {
  name: string;
  hex: string;
};
export type PDPalet = {
  red: PDColor;
  orange: PDColor;
  yellow: PDColor;
  green: PDColor;
  blue: PDColor;
  purple: PDColor;
  grey: PDColor;
  black: PDColor;
  white: PDColor;
  none: PDColor;
};
export const ListColorSchemes = {
  bright: {
    red: { name: 'red', hex: '#EA5555' },
    orange: { name: 'orange', hex: '#F39C3C' },
    yellow: { name: 'yellow', hex: '#ECD03F' },
    green: { name: 'green', hex: '#6EB35E' },
    blue: { name: 'blue', hex: '#4996C8' },
    purple: { name: 'purple', hex: '#774ED8' },
    grey: { name: 'grey', hex: '#919191' },
    black: { name: 'black', hex: '#000000' },
    white: { name: 'white', hex: '#FFFFFF' },
    none: { name: 'none', hex: 'transparent' },
  },
  pastel: {
    red: { name: 'red', hex: '#E08E8E' },
    orange: { name: 'orange', hex: '#FFAA48' },
    yellow: { name: 'yellow', hex: '#DDCF69' },
    green: { name: 'green', hex: '#ABD997' },
    blue: { name: 'blue', hex: '#C3E7E8' },
    purple: { name: 'purple', hex: '#CDB5D9' },
    grey: { name: 'grey', hex: '#C1C1C1' },
    black: { name: 'black', hex: '#000000' },
    white: { name: 'white', hex: '#FFFFFF' },
    none: { name: 'none', hex: 'transparent' },
  },
};
// Returns the <i>-th distinct color in a range of size <num>.
export function getColorInRange(
  i: number,
  num: number,
  saturation: number = 100,
  lightness: number = 30,
  asHex?: boolean
): string | undefined {
  if (i < 0) return undefined;
  const hue = (i / num) * 360; // Spread hues evenly
  const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  return asHex ? hslToHex(color) : color;
}

// Returns a color that is unique for <id> in the given set of id's.
// For author names in bold on a light grey background,
// saturation 100 and lightness 30 are a good choice.
export function getColorForId(
  id: number,
  ids: number[] = [],
  props?: {
    saturation?: number; // 0-100
    lightness?: number; // 0-100
    asHex?: boolean;
  }
): string | undefined {
  const {
    saturation = 100, // 0-100
    lightness = 20, // 0-100
    asHex = false,
  } = props ?? {};
  let idList: number[] = ids;
  if (idList.length === 0) {
    const dlg = store.getState().dialogue?.dialogue;
    if (dlg) idList = dlg.subscribers?.map((s: IUser) => s.id) ?? [];
  }
  const num = idList.length;
  const i = idList.findIndex((x) => x === id);
  const color =
    i >= 0 ? getColorInRange(i, num, saturation, lightness, asHex) : '#CCCCCC';
  return color;
}

// Returns <num> distinct colors.
export function getColorRange(
  num: number,
  saturation: number = 100, // 0-100
  lightness: number = 20 // 0-100
): string[] {
  const col: string[] = [];
  for (var i = 0; i < num; i++) {
    col.push(getColorInRange(i, num, saturation, lightness) || '');
  }
  return col;
}

const toHex = (value: number): string => {
  const hex = Math.round(value * 255).toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export function hslToHex(hsl: string): string {
  const hslRegex = /^hsl\(\s*(\d+),\s*(\d+)%?,\s*(\d+)%?\)$/;
  const result = hsl.match(hslRegex);
  if (!result) throw new Error('Invalid HSL format');
  const h = parseInt(result[1]);
  const s = parseInt(result[2]) / 100;
  const l = parseInt(result[3]) / 100;

  // Convert HSL to RGB
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;
  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = r + m;
  g = g + m;
  b = b + m;
  return `${toHex(r)}${toHex(g)}${toHex(b)}`;
}
