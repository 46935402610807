import { API } from '@thuas/pd-schemas';
import { IDocumentCreate } from 'features/document/documentAPI';
import api from 'helpers/api';
import { prepareForBackend } from 'helpers/objects';

export type IDocumentBlock = API['DocumentBlock']['read'];
export type IDocumentBlockCreate = API['DocumentBlock']['create'];
export type IDocumentBlockCreateWithDocument = Omit<
  IDocumentBlockCreate,
  'document'
> & {
  document: IDocumentCreate & { id: number };
};
type IDocumentBlockUpdate = API['DocumentBlock']['update'] & {};

function prepareForUpdate(block: IDocumentBlock): IDocumentBlockUpdate {
  const updated = prepareForBackend(
    block,
    ['id', 'document'],
    true // ignore array-attributes and attributes with value undefined
  ) as IDocumentBlockUpdate;
  return updated;
}

export const postDocumentBlock = async (data: IDocumentBlockCreate) => {
  try {
    return await api.post('DocumentBlock', data);
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};

export const patchDocumentBlock = async (id: number, data: IDocumentBlock) => {
  try {
    const res = await api.patch('DocumentBlock/' + id, prepareForUpdate(data));
    return res;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
};
