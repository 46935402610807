import classNames from 'classnames';
import {
  Dispatch,
  MouseEventHandler,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import { useAppDispatch } from 'app/hooks';
import { getUnreadMessagesCount, registerUnreadCounter } from 'app/unreadCount';
import FileUpload from 'components/files/FileUpload';
import {
  FormMessage,
  FormMsg,
  NoFormMsg,
  doFormMsg,
} from 'components/forms/FormMessage';
import Icon, { IconSymbol } from 'components/icons/Icon';
import TextAreaInput from 'components/messages/TextAreaInput';
import DropMenu from 'components/navigation/DropMenu';
import ScrollObserver from 'components/scrollobserver/ScrollObserver';
import { IBlock } from 'features/block/blockAPI';
import { BlockType } from 'features/block/blockSlice';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IFile, IFileCreate } from 'features/files/fileAPI';
import {
  AcceptedMimeType,
  FolderLocation,
  allFileTypes,
  getFolderName,
  postFileAsync,
} from 'features/files/fileSlice';
import { getVisitedState } from 'features/uiState/uiStateSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { Retrieving, constructURL } from 'helpers/apiTypes';
import { AnyObject } from 'helpers/objects';
import { sortBy, sortByTime } from 'helpers/sorting';

import Collapsable from 'components/collapsable/Collapsable';
import LibraryFile from 'components/files/LibraryFile';
import { linkifyText } from 'components/messages/Linkify';
import { IMessage } from 'features/message/messageAPI';
import { postMessageAsync } from 'features/message/messageSlice';
import { ChildBlockProps } from './Block';
import './LibraryBlock.scss';

function getUnread(block: IBlock): number {
  const lastVisited: Date | null = getVisitedState('block', block.id)[2];
  const count = getUnreadMessagesCount(
    block.childLibraryBlock?.files ?? [],
    lastVisited,
    'uploadTime'
  );
  return count;
}
registerUnreadCounter(BlockType.Library, getUnread);

export default function LibraryBlock(props: ChildBlockProps) {
  const { block, onUpdate } = props;
  const scrollableRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  function stateMan(obj: any): any {
    const o = props.stateMan(obj);
    return o?.childLibraryBlock;
  }

  const asyncAPIQuery = `LibraryBlock/${
    block.childLibraryBlock?.id
  }?and=${constructURL({
    '*': true,
    files: {
      '*': true,
      author: {
        // '*': true,
        about: true,
        email: true,
        id: true,
        isVerified: true,
        locale: true,
        username: true,
      },
    },
    links: {
      '*': true,
      author: {
        // '*': true,
        about: true,
        email: true,
        id: true,
        isVerified: true,
        locale: true,
        username: true,
      },
    },
  } as const satisfies Retrieving<'LibraryBlock'>)}`;

  const [webSocket] = useState(AsyncAPI.connection);

  useEffect(() => {
    console.log('Main useEffect called! (LibraryBlock)');

    if (webSocket?.readyState !== 1) {
      console.log(
        'useEffect() main (LibraryBlock) --> connection is not ready, readyState is not on 1'
      );
      return;
    }
    AsyncAPI.doMessage(asyncAPIQuery);

    const onMessageCallback = (payload: any, change: string) => {
      // switch (change) {
      //   case 'new':
      //     dispatch(asyncApiFilesNew({ data: payload, stateMan }));
      //     break;
      //   case 'updated':
      //     dispatch(asyncApiFilesUpdated({ data: payload, stateMan }));
      //     break;
      //   case 'removed':
      //     dispatch(asyncApiFilesRemoved({ data: payload, stateMan }));
      //     break;
      // }
      onUpdate();
    };

    AsyncAPI.addOnMessageCallbackQueries(asyncAPIQuery, onMessageCallback);

    return () => {
      if (webSocket?.readyState !== 1) {
        console.log(
          'useEffect() return (Messages) --> connection is not ready, readyState is not on 1'
        );
        return;
      }
      AsyncAPI.doMessageDisconnect(asyncAPIQuery);

      const index = AsyncAPI.onMessageCallbacksQueries.findIndex(
        ({ callback }) => callback === onMessageCallback
      );

      if (index !== -1) {
        AsyncAPI.onMessageCallbacksQueries.splice(index, 1);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, webSocket?.readyState]);

  return (
    <div className={classNames('block_content')} ref={scrollableRef}>
      <LibraryBlockContent
        block={block}
        showDescription={props.showDescription}
        scrollableRef={scrollableRef}
        stateMan={stateMan}
      />
    </div>
  );
}

type LibraryBlockContentProps = {
  block: IBlock;
  showDescription?: boolean;
  scrollableRef: React.RefObject<HTMLDivElement>;
  stateMan: StateMan;
};

function UnconnectedLibraryBlockContent(
  props: LibraryBlockContentProps & DialogueStateProps
) {
  const { block, scrollableRef, dialogue } = props;
  if (!block.childLibraryBlock || !block.childLibraryBlock.files) return null;
  const files = block.childLibraryBlock.files;
  const links = block.childLibraryBlock.links;
  return (
    <div className="library" ref={scrollableRef}>
      {props.showDescription && block.description ? (
        <div className="block_description">{block.description}</div>
      ) : null}
      <FileList
        block={block}
        forProject={false}
        files={files}
        links={links ?? []}
        scrollableRef={scrollableRef}
        stateMan={props.stateMan}
      />
      {dialogue.project?.files?.length ? (
        <>
          {/* <div className="files_header">
            <FormattedMessage id="FILES.DIALOGUE" />
          </div> */}
          <FileList
            block={block}
            forProject={true}
            files={dialogue.project?.files}
            links={[]}
            scrollableRef={scrollableRef}
            stateMan={props.stateMan}
          />
        </>
      ) : null}
    </div>
  );
}

const LibraryBlockContent = connect(mapDialogueStateToProps)(
  UnconnectedLibraryBlockContent
);

type SortProp = {
  descr: boolean; // sort by description, if false sort by date
  // date: boolean;
  asc_descr: boolean; // ascending by description
  asc_date: boolean; // ascending by date
};
type FolderData = { folder: string; files: AnyObject[]; open: boolean };
type FoldersData = Map<string, FolderData>;
type FileListProps = {
  block: IBlock;
  forProject: boolean;
  files: IFile[];
  links: IMessage[];
  scrollableRef: React.RefObject<HTMLDivElement>;
  stateMan: StateMan;
};
function FileList(props: FileListProps) {
  const { block, forProject, files, links, scrollableRef, stateMan } = props;
  const [sortProp, setSortProp] = useState<SortProp>({
    descr: true,
    // date: false,
    asc_descr: true,
    asc_date: false,
  });
  const [previewSrc, setPreviewSrc] = useState<string | null>(null);
  const [previewMimeType, setPreviewMimeType] = useState<AcceptedMimeType>(
    AcceptedMimeType.image
  );
  const [file, setFile] = useState<File | undefined>(undefined);
  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  const uploadTrigger = useRef<MouseEventHandler | undefined>(undefined);
  // const filesAndLinks = files.concat(
  //   links.map((link) => {
  //     return { ...link, description: link.content, uploadTime: link.time };
  //   })
  // );
  // const sortedfiles = sortProp.descr
  //   ? sortProp.asc_descr
  //     ? sortBy(filesAndLinks, 'description')
  //     : sortBy(filesAndLinks, 'description', 'DSC')
  //   : sortProp.asc_date
  //   ? sortByTime(filesAndLinks, 'uploadTime')
  //   : sortByTime(filesAndLinks, 'uploadTime', 'DSC');
  // const listinfo: SortableListInfo = {
  //   id: block.id as number,
  //   listType: SortableListType.Files,
  //   items: sortedfiles as AnyOrderObject[],
  //   canvas: false,
  // };
  const [folders, setFolders] = useState<FoldersData>(new Map());
  useEffect(() => {
    setFolders((prev) => {
      const newFolders = new Map(prev);
      if (!files) return newFolders;
      // newFolders.clear();
      newFolders.forEach((f) => (f.files = []));
      newFolders.set('.', { folder: '.', files: [], open: true });
      const filesAndLinks = files.concat(
        links.map((link) => {
          return { ...link, description: link.content, uploadTime: link.time };
        })
      );
      const sortedfiles = sortProp.descr
        ? sortProp.asc_descr
          ? sortBy(filesAndLinks, 'description')
          : sortBy(filesAndLinks, 'description', 'DSC')
        : sortProp.asc_date
        ? sortByTime(filesAndLinks, 'uploadTime')
        : sortByTime(filesAndLinks, 'uploadTime', 'DSC');
      sortedfiles.forEach((f) => {
        let folder = '.';
        if (f.hasOwnProperty('size')) {
          const path = (f.description as string).split('/');
          if (path.length > 1) folder = path[0].trim();
          if (!newFolders.has(folder)) {
            newFolders.set(folder, { folder, files: [f], open: false });
          } else {
            newFolders.get(folder)?.files.push(f);
          }
        } else {
          newFolders.get(folder)?.files.push(f);
        }
      });
      newFolders.forEach((f) => {
        if (f.files.length === 0) newFolders.delete(f.folder);
      });
      return newFolders;
    });
  }, [files, links]);

  function toggleSortBy(descr: boolean) {
    setSortProp((prev) => ({
      ...prev,
      descr: descr === prev.descr ? prev.descr : !prev.descr,
      asc_descr: descr && prev.descr ? !prev.asc_descr : prev.asc_descr,
      asc_date: !descr && !prev.descr ? !prev.asc_date : prev.asc_date,
    }));
  }
  function SortByToggle({
    descr,
    disabled = false,
  }: {
    descr: boolean;
    disabled?: boolean;
  }) {
    return (
      <div
        className={classNames({
          files_description: descr,
          files_date: !descr,
          files_sortby: true,
          files_disabled: disabled,
        })}
        onClick={() => (disabled ? {} : toggleSortBy(descr))}
      >
        <FormattedMessage id={descr ? 'FILES.DESCRIPTION' : 'FILES.UPLOADED'} />
        {disabled ? null : (
          <>
            {' '}
            <span
              className={classNames({
                show_not:
                  (descr && !sortProp.descr) || (!descr && sortProp.descr),
              })}
            >
              {descr ? (
                sortProp.asc_descr ? (
                  <>&#8595;</>
                ) : (
                  <>&#8593;</>
                )
              ) : sortProp.asc_date ? (
                <>&#8595;</>
              ) : (
                <>&#8593;</>
              )}
            </span>
          </>
        )}
      </div>
    );
  }

  type FolderProps = {
    folder: FolderData;
    toggleFolder: (folder: string) => void;
  };
  function Folder(props: FolderProps) {
    const { folder, toggleFolder } = props;
    const [open, setOpen] = useState<boolean>(folder.open);
    if (folder.folder === '.')
      return (
        <>
          {folder.files.map((f, i) => {
            return (
              <LibraryFile
                key={i}
                file={f}
                stateMan={stateMan}
                blockRef={scrollableRef}
              />
            );
          })}
        </>
      );
    return (
      <Collapsable className="folder" dimension="height">
        <Collapsable.Controller
          open={open}
          setOpen={() => {
            setOpen(!open);
            toggleFolder(folder.folder);
          }}
          noPlus={true}
          icon={open ? IconSymbol.folder_open : IconSymbol.folder}
        >
          {folder.folder.trim()}
        </Collapsable.Controller>
        <Collapsable.Content open={open} dimension="height">
          {folder.files.map((f, i) => {
            return (
              <LibraryFile
                key={i}
                file={f}
                stateMan={stateMan}
                blockRef={scrollableRef}
              />
            );
          })}
        </Collapsable.Content>
      </Collapsable>
    );
  }

  function toggleFolder(folder: string) {
    const prev = folders.get(folder);
    if (prev) {
      folders.set(folder, { ...prev, open: !prev.open });
    }
  }

  return (
    <FileUpload
      types={allFileTypes}
      currentSrc={null}
      defaultImg={null}
      previewSrc={previewSrc}
      setPreviewSrc={setPreviewSrc}
      noPreview={true}
      previewMimeType={previewMimeType}
      setPreviewMimeType={setPreviewMimeType}
      showMsg={(m) => doFormMsg(m, setFormMsg)}
      setFile={setFile}
      withTrigger={true}
      onDelete={() => {}}
      className={'files_container'}
      renderTrigger={(trigger) => (uploadTrigger.current = trigger)}
    >
      <ScrollObserver
        scrollableRef={scrollableRef}
        toBottom={true}
        mutations={true}
        customScrollbar={false}
      >
        {folders.size > 0 ? (
          // <SortableList
          //   key={listinfo.id}
          //   listInfo={listinfo}
          //   itemType={SortableItemType.File}
          //   className={'files'}
          //   horizontal={false}
          //   disabled={true}
          // >
          <div className="files">
            <div className="files_header">
              <div className="files_icon" />
              <SortByToggle descr={true} disabled={true} />
              <SortByToggle descr={false} disabled={true} />
            </div>
            {Array.from(folders.entries()).map(
              ([folderkey, folder], i: number) => {
                if (folderkey !== '.')
                  return (
                    <Folder
                      key={i}
                      folder={folder}
                      toggleFolder={toggleFolder}
                    />
                  );
                return null;
              }
            )}
            {folders.get('.') ? (
              <Folder folder={folders.get('.')!} toggleFolder={toggleFolder} />
            ) : null}
            {/* {sortedfiles.map(
              (file: AnyObject, i: number, a: Array<AnyObject>) => {
                let path: string[] = [];
                if (file.hasOwnProperty('size')) {
                  path = (file.description as string).split('/');
                }
                return (
                  <Fragment key={i}>
                    <SortableItem
                      className={path.length > 1 ? 'files_infolder' : undefined}
                      key={file.id!}
                      list={listinfo}
                      item={file as AnyOrderObject}
                      itemType={SortableItemType.File}
                      index={i}
                      disabled={true}
                    >
                    <LibraryFile
                      file={file}
                      stateMan={stateMan}
                      blockRef={scrollableRef}
                    />
                    </SortableItem>
                  </Fragment>
                );
              }
            )} */}
          </div>
        ) : (
          // </SortableList>
          <div className={'no_messages'}>
            <FormattedMessage id="FILES.NO_CONTENT" />
          </div>
        )}
      </ScrollObserver>
      <div className="tail_container">
        <LibraryBlockTail
          block={block}
          forProject={forProject}
          previewSrc={previewSrc}
          setPreviewSrc={setPreviewSrc}
          previewMimeType={previewMimeType}
          formMsg={formMsg}
          setFormMsg={setFormMsg}
          file={file}
          setFile={setFile}
          uploadTrigger={uploadTrigger}
          stateMan={stateMan}
        />
      </div>
    </FileUpload>
  );
}

type LibraryBlockTailProps = {
  block: IBlock;
  showDescription?: boolean;
  forProject: boolean;
  previewSrc: string | null;
  setPreviewSrc: Dispatch<SetStateAction<string | null>>;
  previewMimeType: AcceptedMimeType;
  formMsg: FormMsg;
  setFormMsg: Dispatch<SetStateAction<FormMsg>>;
  file: File | undefined;
  setFile: Dispatch<SetStateAction<File | undefined>>;
  uploadTrigger: RefObject<MouseEventHandler | undefined>;
  stateMan: StateMan;
};

function UnconnectedLibraryBlockTail(
  props: LibraryBlockTailProps & UserStateProps & DialogueStateProps
) {
  const {
    block,
    forProject,
    previewSrc,
    setPreviewSrc,
    previewMimeType,
    formMsg,
    setFormMsg,
    file,
    setFile,
    uploadTrigger,
    user,
    userId,
    userCanEdit,
    dialogue,
  } = props;
  const [description, setDescription] = useState<string>('');
  const [showSend, setShowSend] = useState(false);
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (previewSrc) {
      if (previewMimeType === AcceptedMimeType.image) {
        const image = new Image();
        image.onload = () => {
          setSize({ width: image.width, height: image.height });
        };
        image.src = previewSrc;
      } else {
        setSize({ width: 48, height: 48 });
      }
    }
  }, [previewMimeType, previewSrc]);

  function handleDescriptionChange(input: string, hasText: boolean) {
    setShowSend(hasText);
    setDescription(input);
  }

  function submitFile(e?: React.KeyboardEvent | React.MouseEvent) {
    if (!description) {
      setFormMsg({
        message: intl.formatMessage({ id: 'FILES.NO_DESCRIPTION' }),
        success: false,
        timeout: 1500,
      });
      return;
    }
    const { links } = linkifyText(description);
    if (!file) {
      // no file posted?
      if (!links.length) {
        // no link either
        setFormMsg({
          message: intl.formatMessage({ id: 'FILES.NO_FILE' }),
          success: false,
          timeout: 1500,
        });
        return;
      } else {
        // link posted
        dispatch(
          postMessageAsync({
            data: {
              libraryBlockContainer: block.childLibraryBlock?.id,
              content: description,
              edited: false,
              time: new Date(),
              author: userId,
              replyTo: null,
              checked: false,
              color: 'none',
              likes: [],
              replies: [],
              files: [],
            },
            author: user,
            file: undefined,
            stateMan: props.stateMan,
          })
        ).then((response: any) => {
          switch (response.payload?.response.status) {
            case 201:
              break;
            default:
              console.log(
                response,
                response?.payload.response.status,
                response?.payload.response.data?.message
              );
          }
          setDescription('');
          setShowSend(false);
          setPreviewSrc(null);
          setFile(undefined);
        });
      }
    } else {
      // file posted
      const forProject = e && e.altKey;
      const data: IFileCreate = {
        libraries:
          forProject || forProject ? undefined : [block.childLibraryBlock!.id!],
        projectFiles:
          forProject || forProject ? [dialogue.project?.id!] : undefined,
        description: description,
        uploadTime: new Date(),
        folder: getFolderName(
          forProject ? FolderLocation.Project : FolderLocation.Dialogue,
          'Library'
        ),
        order: '',
        size: file.size,
        mimeType: file.type,
      };
      dispatch(
        postFileAsync({
          data: data,
          author: user,
          file: file,
          stateMan: props.stateMan,
        })
      ).then((response: any) => {
        switch (response.payload?.response.status) {
          case 201:
            break;
          default:
            console.log(
              response,
              response?.payload.response.status,
              response?.payload.response.data?.message
            );
        }
        setDescription('');
        setShowSend(false);
        setPreviewSrc(null);
        setFile(undefined);
      });
    }
  }

  function removeImage(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    setPreviewSrc(null);
    setFile(undefined);
  }

  return userCanEdit(dialogue) ? (
    <>
      <div className="dropzone_container">
        {previewSrc ? (
          <div
            className="preview_image"
            style={{
              backgroundImage: `url(${previewSrc})`,
              height: size.height,
              width: size.width,
            }}
          />
        ) : null}
        {formMsg ? (
          <FormMessage
            message={formMsg.message}
            success={formMsg.success}
            timeout={5000}
            setter={setFormMsg}
          />
        ) : null}
        {previewSrc ? (
          <>
            <div
              onClick={uploadTrigger.current ?? undefined}
              className="dropzone_trigger"
              style={{ cursor: 'Pointer' }}
            />
          </>
        ) : null}
        {previewSrc && <div className="dropzone_bin" onClick={removeImage} />}
      </div>
      <div className="msg_tail">
        <DropMenu right={true} up={true} shift={8}>
          <DropMenu.Trigger>
            <div className="msg_input_plus" />
          </DropMenu.Trigger>
          <DropMenu.Items>
            <div onClick={uploadTrigger.current ?? undefined}>
              <Icon symbol={IconSymbol.document} className="msg_input_option" />
              <div>
                <FormattedMessage id="DOCUMENTS.UPLOAD.DOCUMENT" />
              </div>
            </div>
          </DropMenu.Items>
        </DropMenu>
        <div className="msg_input">
          <TextAreaInput
            onChange={handleDescriptionChange}
            onSubmit={(s, b, e) => submitFile(e)}
            placeholder={intl.formatMessage({
              id: forProject
                ? 'FILES.DESCRIPTION_PROJECT'
                : 'FILES.DESCRIPTION_DIALOGUE',
            })}
            value={description}
            escapeClears={false}
          />
        </div>
        {showSend ? (
          <div className="msg_input_send" onClick={(e) => submitFile(e)} />
        ) : null}
      </div>
    </>
  ) : null;
}

const LibraryBlockTail = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedLibraryBlockTail)
);
export { LibraryBlockTail };
