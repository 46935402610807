import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import {
  DialogueStateProps,
  fetchDialogueAsync,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';

import {
  AppSettingsStateProps,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { IAuthorName, IUser } from 'features/user/userAPI';

import { store } from 'app/store';
import Collapsable from 'components/collapsable/Collapsable';
// import { useAuthors } from 'components/user/Author';
import { getAuthors } from 'features/user/userSlice';
import './Test.scss';

function TestPage(
  props: UserStateProps & DialogueStateProps & AppSettingsStateProps
) {
  const { dialogue } = props;
  const dispatch = useAppDispatch();
  // const getAuthors = useAuthors();

  const authors = useMemo(() => {
    return getAuthors(dialogue);
  }, [dialogue]);

  useEffect(() => {
    dispatch(fetchDialogueAsync(3));
  }, [dispatch]);

  return (
    <>
      <div>Hello {props.user.username}</div>
      <div>Test page content goes here</div>
      <br />
      <div>
        Dialogue {dialogue.id} - {dialogue.title}
      </div>
      <div style={{ display: 'flex', gap: '20px' }}>
        <div>
          <div>{dialogue.subscribers?.length} subscribers in this dialogue</div>
          <div>
            {dialogue.subscribers?.map((s: IUser) => {
              return <div key={s.id}>{s.username}</div>;
            })}
          </div>
        </div>
        <div>
          <div>{authors?.length} authors in this dialogue</div>
          <div>
            {authors?.map((a: IAuthorName) => {
              return <div key={a.id}>{a.authorname}</div>;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

function UnconnectedTest(
  props: UserStateProps & DialogueStateProps & AppSettingsStateProps
) {
  const {
    // dialogue,
    settings,
  } = props;
  // const dispatch = useAppDispatch();
  // const [users, setUsers] = useState<IUser[]>([]);
  // const [projectUsers, setProjectUsers] = useState<IUser[]>([]);

  // useEffect(() => {
  //   dispatch(fetchDialogueAsync(58));
  // }, [dispatch]);

  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [openProps, setOpenProps] = useState<boolean>(false);
  const [openRedux, setOpenRedux] = useState<boolean>(false);
  const state = store.getState();

  return (
    <>
      <div className="static_page test">
        <div className="top">
          <Collapsable dimension="height">
            <Collapsable.Controller
              open={openSettings}
              setOpen={setOpenSettings}
            >
              <h1>Settings</h1>
            </Collapsable.Controller>
            <Collapsable.Content open={openSettings} dimension="height">
              <div>
                <pre>{JSON.stringify(settings, undefined, 2)}</pre>
              </div>
            </Collapsable.Content>
          </Collapsable>
          <Collapsable dimension="height">
            <Collapsable.Controller open={openProps} setOpen={setOpenProps}>
              <h1>Props</h1>
            </Collapsable.Controller>
            <Collapsable.Content open={openProps} dimension="height">
              <div>
                <pre>{JSON.stringify(props, undefined, 2)}</pre>
              </div>
            </Collapsable.Content>
          </Collapsable>
          <Collapsable dimension="height">
            <Collapsable.Controller open={openRedux} setOpen={setOpenRedux}>
              <h1>Redux State</h1>
            </Collapsable.Controller>
            <Collapsable.Content open={openRedux} dimension="height">
              <div>
                <pre>{JSON.stringify(state, undefined, 2)}</pre>
              </div>
            </Collapsable.Content>
          </Collapsable>
        </div>
        <TestPage {...props} />
      </div>
    </>
  );
}

const Test = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(
    connect(mapAppSettingsStateToProps)(UnconnectedTest)
  )
);
export default Test;
