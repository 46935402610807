import { DropResult } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Status } from '@thuas/pd-schemas/built/generated/api-types';
import { useAppDispatch } from 'app/hooks';
import Participants from 'components/dialogues/Participants';
import FileUpload from 'components/files/FileUpload';
import FormattedMessage from 'components/formatters/FormattedMessage';
import PeriodPicker from 'components/formatters/PeriodPicker';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { ModalDialog, showModal } from 'components/forms/ModalDialog';
import Icon, { IconSymbol, IconVariant } from 'components/icons/Icon';
import { getTextAreaHeight } from 'components/messages/TextAreaInput';
import SortableContainer from 'components/sortables/SortableContainer';
import SortableItem from 'components/sortables/SortableItem';
import SortableList, {
  SortableItemType,
  SortableListInfo,
  SortableListType,
} from 'components/sortables/SortableList';
import { IDialogue, IDialogueCreate } from 'features/dialogue/dialogueAPI';
import {
  DialogueStateProps,
  emptyDialogue,
  mapDialogueStateToProps,
  patchDialogueAsync,
  postDialogueAsync,
  postPatchAndDeletePhases,
} from 'features/dialogue/dialogueSlice';
import { IFile } from 'features/files/fileAPI';
import { imageFileTypes } from 'features/files/fileSlice';
import { IPhase } from 'features/phase/phaseAPI';
import { emptyPhase } from 'features/phase/phaseSlice';
import { IUser } from 'features/user/userAPI';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import { copyLink, generateUuidCode } from 'helpers/helpers';
import {
  AnyOrderObject,
  asNumber,
  newId,
  pickPrimitiveProps,
  sameId,
} from 'helpers/objects';
import { xyPosition } from 'helpers/positions';
import { getOrderForLast, getOrderUsingId, sortByOrder } from 'helpers/sorting';
import { useForms } from './Forms';
import { SharingCodesFormLink } from './SharingCodes';

import {
  AppSettingsStateProps,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { GUEST_TRYOUT_ACCESS_CODE } from 'helpers/consts';
import './SettingsForm.scss';

type DialogueFormProps = {
  dialogue: IDialogue;
  onCloseAll?: () => void;
};

function UnconnectedDialogueForm(
  props: DialogueFormProps &
    FormProps &
    UserStateProps &
    DialogueStateProps &
    AppSettingsStateProps
) {
  const {
    show,
    setShow,
    onSubmit,
    onCancel,
    onClose,
    dialogue,
    userCanEdit,
    userIsManager,
    settings,
  } = props;
  const intl = useIntl();
  const [editing, setEditing] = useState(false);
  const [reset, setReset] = useState(true);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<IDialogue | IDialogueCreate>({
    ...emptyDialogue,
  });
  const [phases, setPhases] = useState<IPhase[]>([]);
  const [deletedPhaseIds, setDeletedPhaseIds] = useState<number[]>([]);
  const [subscribers, setSubscribers] = useState<IUser[]>([]);
  const [moderators, setModerators] = useState<IUser[]>([]);
  const [previewSrc, setPreviewSrc] = useState<string | null>(null); // state for storing previewImage
  const [file, setFile] = useState<File | undefined>(undefined); // for avatar image
  const fixedPhases = sortByOrder(
    phases.filter((ph) => ph.fixed) as AnyOrderObject[]
  );
  const sortedPhases = sortByOrder(
    phases.filter((ph) => !ph.fixed) as AnyOrderObject[]
  );
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (reset || show) {
      if (dialogue) {
        setData({ ...dialogue });
        setPhases(sortByOrder(dialogue.phases as (IPhase & AnyOrderObject)[]));
        setDeletedPhaseIds([]);
        setSubscribers(dialogue.subscribers as IUser[]);
        setModerators(dialogue.moderators as IUser[]);
        setFile(undefined);
        setPreviewSrc(dialogue.background?.uri ?? '');
      } else {
        setData({ ...emptyDialogue });
        setPhases([]);
        setDeletedPhaseIds([]);
        setSubscribers([]);
        setModerators([]);
        setFile(undefined);
        setPreviewSrc(null);
      }
      setReset(false);
    }
  }, [dialogue, reset, show]);

  useEffect(() => {
    const ta = document.getElementById('dialogueDescription');
    if (ta) {
      ta.style.height =
        getTextAreaHeight(ta as HTMLTextAreaElement, 3, 8, editing, 4)[0] +
        'px';
    }
  }, [show, data, editing]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  function handleChangeData(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) {
    // const target = event.target as HTMLInputElement;
    const { target } = event;
    const { name } = target;
    const code =
      name === 'status' &&
      target.value === Status.private &&
      !data.registrationCode?.length
        ? generateUuidCode()
        : undefined;
    setData({
      ...data,
      registrationCode: code ?? data.registrationCode,
      [name]: target.value,
    });
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    const not = name.split('/')[0] === 'not' ?? false;
    const att = name.split('/')[1] ?? name;
    setData((prev) => {
      return {
        ...prev,
        [att]: not ? !checked : checked,
      };
    });
  }

  function handleCheckGuestAccess(event: React.ChangeEvent<HTMLInputElement>) {
    handleCheckboxChange(event);
    if (event.target.checked) {
      if (
        settings.rights.guestTryout &&
        sameId(dialogue.id, asNumber(settings.rights.tryoutDialogue))
      ) {
        setData((prev) => {
          return { ...prev, guestAccessCode: GUEST_TRYOUT_ACCESS_CODE };
        });
      } else if (!data.guestAccessCode) {
        setData((prev) => {
          return {
            ...prev,
            guestAccessCode: generateUuidCode(),
          };
        });
      }
    }
  }

  async function handleSubmit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        if (editing) {
          if (
            data.status === Status.private &&
            !data.registrationCode?.length
          ) {
            doFormMsg(
              {
                message: intl.formatMessage({
                  id: 'DIALOGUE.FORM.MISSING_REGCODE',
                }),
                success: false,
              },
              setFormMsg
            );
            // TODO: this is temporarily disabled, until moderators can edit regcode
            // reject();
            // return;
          }
          if (data.allowGuestAccess && !data.guestAccessCode?.length) {
            doFormMsg(
              {
                message: intl.formatMessage({
                  id: 'PROJECT.FORM.MISSING_GUESTCODE',
                }),
                success: false,
              },
              setFormMsg
            );
            reject();
            return;
          }
        } else {
          resolve();
          return;
        }
        if (data && dialogue) {
          // existing dialogue
          await dispatch(
            postPatchAndDeletePhases({
              phases,
              deletedPhaseIds,
              stateMan,
              intl,
            })
          );
          dispatch(
            patchDialogueAsync({
              data: {
                ...pickPrimitiveProps(data),
                subscribers,
                moderators,
              } as IDialogue,
              file: file,
              dialogue: dialogue,
              stateMan,
            })
          ).then((response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                doFormMsg(
                  {
                    message: 'Dialogue updated',
                    success: true,
                    timeout: 1500,
                  },
                  setFormMsg
                );
                setEditing(false); // we can stop editing
                onSubmit && onSubmit();
                resolve();
                break;
              case 500:
              default:
                const m = 'Internal error, dialogue settings not saved.';
                doFormMsg(
                  {
                    message: m,
                    success: false,
                  },
                  setFormMsg
                );
                reject(m);
                break;
            }
          });
        } // new dialogue
        else
          dispatch(
            postDialogueAsync({ data: data as IDialogue, stateMan })
          ).then((response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                doFormMsg(
                  {
                    message: 'Dialogue created.',
                    success: true,
                    timeout: 1500,
                  },
                  setFormMsg
                );
                setEditing(false); // we can stop editing
                onSubmit && onSubmit();
                resolve();
                break;
              case 500:
                doFormMsg(
                  {
                    message: 'Internal error, dialogue not created.',
                    success: false,
                  },
                  setFormMsg
                );
                reject();
                break;
              default:
                reject();
                break;
            }
          });
      } catch (err) {
        doFormMsg(
          {
            message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
            success: false,
          },
          setFormMsg
        );
        console.log(
          'Dialogue update or post request failed for unclear reason.',
          err
        );
        reject();
      }
    });
  }

  function handleCancel(): boolean {
    doFormMsg(NoFormMsg, setFormMsg);
    onCancel && onCancel();
    setEditing(false);
    setReset(true);
    return true;
  }

  function handleClose(): void {
    !editing && onClose && onClose();
  }

  // The function below must be called as a normal function,
  // not turned into an FC.
  // This is because with an FC the input elements will not be
  // managed correctly by React, since they would be tied to
  // a prop and not a state var.
  function renderPhaseEntry(phase: IPhase, index: number) {
    function handleChangePhaseData(e: SyntheticEvent) {
      const target = e.target as HTMLInputElement;
      setPhases(
        phases.map((ph) => {
          if (ph.id === phase.id) {
            if (target.type === 'checkbox')
              return { ...ph, [target.name]: target.checked };
            return { ...ph, [target.name]: target.value };
          } else if (target.type === 'checkbox' && target.checked)
            return { ...ph, [target.name]: false };
          return ph;
        })
      );
    }
    function setPhaseDates(from?: Date, to?: Date) {
      setPhases(
        phases.map((ph) => {
          if (ph.id === phase.id) {
            const newPh = {
              ...ph,
              dateBegin: from !== undefined ? from : ph.dateBegin,
              dateEnd: to !== undefined ? to : ph.dateEnd,
            };
            return newPh;
          }
          return ph;
        })
      );
    }
    function removePhase(id: number) {
      function doDelete(): boolean {
        // add the phase id to the deletedPhaseIds array
        setDeletedPhaseIds((prev) => [...prev, id]);
        // remove the phase from the phases array
        setPhases(phases.filter((ph) => ph.id !== id));
        return true;
      }
      showModal(
        <FormattedMessage id="DIALOGUE.FORM.PHASES.DELETE_PHASE" />,
        <>
          <div className="title">
            <FormattedMessage
              id="GENERAL.DELETE.OBJECT.CNF"
              values={{
                object: intl
                  .formatMessage({ id: 'PHASES.PHASE' })
                  .toLowerCase(),
              }}
            />
          </div>
          <div className="text">
            <FormattedMessage
              id="GENERAL.DELETE.OBJECT.CNF2"
              values={{
                object: intl
                  .formatMessage({ id: 'PHASES.PHASE' })
                  .toLowerCase(),
              }}
            />
          </div>
          <Icon
            symbol={IconSymbol.alert}
            variant={IconVariant.accent}
            size={80}
          />
          <div className="text">
            <FormattedMessage id="GENERAL.DELETE.NOTICE" />
          </div>
        </>,
        {
          intl: intl,
          className: 'pd_alert',
          submitBtnText: <FormattedMessage id="X.DELETE" />,
          onSubmit: doDelete,
          editing: true,
          cancelDefault: true,
        }
      );
    }

    return (
      <div
        key={phase.id}
        className={classNames(
          'settings_item',
          'phase_item',
          'as-form-control',
          {
            phase_active: phase.active,
          }
        )}
      >
        {phase.fixed ? null : (
          <Form.Check
            className="field checkbox active_phase"
            type="checkbox"
            name="active"
            checked={phase.active}
            disabled={!editing}
            onChange={handleChangePhaseData}
          />
        )}
        <div className="settings_col descr">
          <Form.Group as={Row} controlId={'phaseTitle' + index}>
            <Col sm={1} md={1} lg={1} className="phase_number">
              {phase.fixed ? null : index + 1}
            </Col>
            <Col sm={11} md={11} lg={11}>
              <Form.Control
                className="field subtitle"
                type="text"
                disabled={!editing}
                placeholder={
                  phase.fixed
                    ? intl.formatMessage({
                        id: 'PHASES.LIBRARY',
                      })
                    : intl.formatMessage({
                        id: 'DIALOGUE.FORM.PHASES.TITLE_PLCH',
                      })
                }
                name="name"
                value={phase.name || ''}
                onChange={handleChangePhaseData}
                required={!phase.fixed}
              />
            </Col>
          </Form.Group>
          {editing || phase.description ? (
            <Form.Group as={Row} controlId={'phaseDescription' + index}>
              <Col sm={12} md={12} lg={12}>
                <Form.Control
                  className="field text"
                  type="text"
                  disabled={!editing}
                  placeholder={intl.formatMessage({
                    id: 'DIALOGUE.FORM.PHASES.DESCRIPTION_PLCH',
                  })}
                  as="textarea"
                  // rows={1}
                  name="description"
                  value={phase.description || ''}
                  onChange={handleChangePhaseData}
                />
              </Col>
            </Form.Group>
          ) : null}
        </div>
        <div className="settings_col period subheading">
          {phase.fixed ? null : (
            <PeriodPicker
              from={phase.dateBegin as Date}
              setFrom={(date: Date) => {
                setPhaseDates(date);
              }}
              to={phase.dateEnd as Date}
              setTo={(date: Date) => {
                setPhaseDates(undefined, date);
              }}
              showDate={true}
              showYear={true}
              // hideTime={true}
              editing={editing}
            />
          )}
          {editing ? (
            <Icon
              symbol={IconSymbol.bin}
              className="phase_remove"
              onClick={() => removePhase(phase.id as number)}
            />
          ) : null}
        </div>
      </div>
    );
  }

  function addPhase(fixed?: boolean) {
    const newPh: IPhase = {
      ...emptyPhase,
      id: newId(phases.map((ph) => ph.id as number)),
      dialogue: dialogue,
      blocks: [],
      active: false,
      order: '',
      fixed: fixed,
      dateBegin: data.startedAt,
      dateEnd: data.closedAt,
    };
    const newPhases = [...phases];
    newPh.order = getOrderForLast(newPhases as AnyOrderObject[]);
    newPhases.unshift(newPh);
    setPhases(newPhases);
  }

  function DialogueStatus() {
    return (
      <>
        <Form.Select
          className="field status"
          disabled={!editing}
          name="status"
          value={data.status || ''}
          onChange={handleChangeData}
          required
        >
          {/* {userIsManager ? ( */}
          <option value={Status.draft}>
            <FormattedMessage id="DIALOGUE.STATUS.DRAFT" />{' '}
            <FormattedMessage id="DIALOGUES.DIALOGUE" lowercase />
          </option>
          {/* ) : null} */}
          <option value={Status.private}>
            <FormattedMessage id="DIALOGUE.STATUS.PRIVATE" />{' '}
            <FormattedMessage id="DIALOGUES.DIALOGUE" lowercase />
          </option>
          <option value={Status.public}>
            <FormattedMessage id="DIALOGUE.STATUS.PUBLIC" />{' '}
            <FormattedMessage id="DIALOGUES.DIALOGUE" lowercase />
          </option>
          {userIsManager && dialogue.id ? (
            <option value={Status.closed}>
              <FormattedMessage id="DIALOGUE.STATUS.CLOSED" />{' '}
              <FormattedMessage id="DIALOGUES.DIALOGUE" lowercase />
            </option>
          ) : null}
        </Form.Select>
        <Form.Text className="text-muted instruction" hidden={!editing}>
          <FormattedMessage id="DIALOGUE.FORM.STATUS_INSTR" />
        </Form.Text>
      </>
    );
  }

  function reorderPhases(result: DropResult, newPos: xyPosition) {
    const { source, destination } = result;
    if (!destination) return;
    if (!sortedPhases) return;
    // const sortedPhases = sortByOrder(phases as AnyOrderObject[]);
    const moved = sortedPhases[source.index];
    const dest = sortedPhases[destination.index];
    if (!moved || !dest) return;
    const newOrder = getOrderUsingId(sortedPhases, moved.id!, dest.id!);
    setPhases((prev) =>
      prev.map((ph, i) => {
        if (sameId(ph.id, moved.id)) return { ...ph, order: newOrder };
        if (ph.fixed) return { ...ph, order: '' };
        return ph;
      })
    );
  }

  function setDates(from?: Date, to?: Date) {
    setData({
      ...data,
      startedAt: from !== undefined ? from : data.startedAt,
      closedAt: to !== undefined ? to : data.closedAt,
    });
  }

  // function copyLink(guest: boolean = false) {
  //   if (data.registrationCode || (guest && data.guestAccessCode)) {
  //     const rootUrl = `${window.location.protocol}//${window.location.host}`;
  //     navigator.clipboard.writeText(
  //       `${rootUrl}/${guest ? 'guest' : 'signup'}/${
  //         guest ? data.guestAccessCode : data.registrationCode
  //       }`
  //     );
  //     setCopied(true);
  //     setTimeout(() => {
  //       setCopied(false);
  //     }, 3000);
  //   }
  // }

  function stateMan(obj: any): any {
    return obj?.dialogue;
  }

  if (!data) return null;
  const disabled = !(editing || !dialogue);
  const listinfo: SortableListInfo = editing
    ? {
        id: 1,
        listType: SortableListType.Phases,
        items: sortedPhases,
        canvas: false,
      }
    : { id: 0, listType: SortableListType.Phases, items: [], canvas: false };
  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form wide dialogue_form"
      title={null} // no title for this dialog
      onSubmit={handleSubmit}
      onCancel={userCanEdit(dialogue) ? handleCancel : undefined}
      onClose={handleClose}
      enableEscape
      allowEdit={userCanEdit(dialogue)}
      onEdit={() => setEditing(true)}
      editing={editing}
      formMsg={formMsg}
    >
      <div className="header">
        <FormattedMessage
          id={
            dialogue.status
              ? `DIALOGUE.STATUS.${dialogue.status.toUpperCase()}`
              : ''
          }
        />{' '}
        <FormattedMessage id="DIALOGUES.DIALOGUE" />
      </div>
      <Form.Group as={'div'} className="settings_item">
        <div className="settings_col descr">
          <Form.Control
            className="field title"
            type="text"
            disabled={!editing}
            placeholder={intl.formatMessage({
              id: 'DIALOGUE.FORM.TITLE_PLCH',
            })}
            name="title"
            value={data.title || ''}
            onChange={handleChangeData}
            required
            minLength={3}
          />
          {editing ? <DialogueStatus /> : null}
          <Form.Control
            id="dialogueDescription"
            className="field text"
            type="text"
            disabled={!editing}
            placeholder={intl.formatMessage({
              id: 'DIALOGUE.FORM.DESCRIPTION_PLCH',
            })}
            as="textarea"
            rows={1}
            name="description"
            value={data.description || ''}
            onChange={handleChangeData}
            // required
          />
        </div>
        <div className="settings_col img img_r">
          <FileUpload
            types={imageFileTypes}
            currentSrc={
              data.background ? (data.background as IFile).uri! : null
            }
            previewSrc={previewSrc}
            setPreviewSrc={setPreviewSrc}
            defaultImg={<img src="/img/backgroundImage.png" alt="" />}
            showMsg={(m) => doFormMsg(m, setFormMsg)}
            setFile={setFile}
            onDelete={() => {
              // set the id to null to indicate the image needs to be deleted
              data.background = null;
            }}
            confirmationText={
              <FormattedMessage id="DIALOGUE.FORM.DELETE_BACKGROUND_CNF" />
            }
            disabled={!editing}
            withTrigger={true}
            showTrigger={true}
          />
        </div>
      </Form.Group>
      {userCanEdit(dialogue) ? (
        <Form.Group as={Row} controlId="regCode" className="reg_access">
          <Col sm={9} md={9} lg={9}>
            {userIsManager ||
            data.status === Status.private ||
            data.status === Status.draft ? (
              <>
                <div className="regcode label">
                  <FormattedMessage id="PROJECT.FORM.REGCODE" />
                  :&nbsp;
                  {userIsManager ? (
                    <>
                      {editing ? (
                        <>
                          <Icon
                            className="reg_access_link"
                            symbol={IconSymbol.cycle}
                            hoverVariant={IconVariant.dark}
                            onClick={() => {
                              setData({
                                ...data,
                                registrationCode: generateUuidCode(),
                              });
                            }}
                            hintProps={{
                              hint: intl.formatMessage({
                                id: 'PROJECT.FORM.REGCODE_GENERATE',
                              }),
                              offset: { x: 20, y: 20 },
                              offsetRight: false,
                            }}
                            size={16}
                            inline
                          />
                          <Form.Control
                            className="field"
                            type="text"
                            disabled={disabled}
                            placeholder={
                              editing
                                ? intl.formatMessage({
                                    id: 'PROJECT.FORM.REGCODE_PLCH',
                                  })
                                : '-'
                            }
                            name="registrationCode"
                            value={data.registrationCode || ''}
                            onChange={handleChangeData}
                            // required
                          />
                        </>
                      ) : (
                        data.registrationCode
                      )}
                    </>
                  ) : (
                    data.registrationCode
                  )}
                  {data.registrationCode ? (
                    <Icon
                      className="reg_access_link"
                      symbol={IconSymbol.copy}
                      hoverVariant={IconVariant.dark}
                      onClick={() => {
                        copyLink(`signup/${data.registrationCode}`, setCopied);
                      }}
                      hintProps={{
                        hint: intl.formatMessage({
                          id: copied
                            ? 'PROJECT.FORM.COPIEDLINK'
                            : 'PROJECT.FORM.COPYLINK',
                        }),
                        offset: { x: 20, y: 20 },
                        offsetRight: false,
                      }}
                      size={16}
                      inline
                    />
                  ) : null}
                </div>
                <div className="guest_access">
                  {editing ? (
                    <Form.Check
                      id={'allowGuestAccess'}
                      type="checkbox"
                      label={intl.formatMessage({
                        id: 'PROJECT.FORM.ALLOW_GUEST',
                      })}
                      name="allowGuestAccess"
                      checked={data.allowGuestAccess ?? false}
                      onChange={handleCheckGuestAccess}
                      disabled={!editing}
                    />
                  ) : data.allowGuestAccess ? (
                    <div className="regcode label">
                      <FormattedMessage id="PROJECT.FORM.ALLOW_GUEST" />
                    </div>
                  ) : null}
                  {data.allowGuestAccess ? (
                    <>
                      <div>
                        ,&nbsp;
                        {intl.formatMessage({ id: 'PROJECT.FORM.WITHCODE' })}
                        {`: ${data.guestAccessCode} `}
                        <Icon
                          className="reg_access_link"
                          symbol={IconSymbol.copy}
                          hoverVariant={IconVariant.dark}
                          onClick={() => {
                            copyLink(
                              `guest/${data.guestAccessCode}`,
                              setCopied
                            );
                          }}
                          hintProps={{
                            hint: intl.formatMessage({
                              id: copied
                                ? 'PROJECT.FORM.COPIEDLINK'
                                : 'PROJECT.FORM.COPYLINK_GUESTS',
                            }),
                            offset: { x: 20, y: 20 },
                            offsetRight: false,
                          }}
                          size={16}
                          inline
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
          </Col>
          <Col sm={3} md={3} lg={3} className="sharing">
            {userIsManager ||
            data.status === Status.private ||
            data.status === Status.draft ? (
              <SharingCodesFormLink
                asButton={true}
                className="btn-outline-secondary"
              />
            ) : null}
          </Col>
        </Form.Group>
      ) : null}
      <Form.Group as={Row}>
        <Col sm={12} md={12} lg={12}>
          <div className="header">
            <div className="subheading">
              <FormattedMessage id="DIALOGUE.VIEW.PARTICIPATION" />
            </div>
            <div className="period subheading">
              <FormattedMessage id="PROJECT.VIEW.PERIOD" />
            </div>
          </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="dialogueSubscribersPeriod">
        <Col sm={8} md={8} lg={8}>
          <Participants
            subscribers={subscribers}
            setSubscribers={setSubscribers}
            moderators={moderators as IUser[]}
            setModerators={setModerators}
            editing={editing}
            project={dialogue.project}
          />
        </Col>
        <Col sm={4} md={4} lg={4} className="period subheading">
          <PeriodPicker
            from={data.startedAt as Date}
            setFrom={(date: Date) => {
              setDates(date);
            }}
            to={data.closedAt as Date}
            setTo={(date: Date) => {
              setDates(undefined, date);
            }}
            showDate={true}
            showYear={true}
            // hideTime={true}
            editing={editing}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={12} md={12} lg={12}>
          <div className="header">
            <div className="subheading">
              <FormattedMessage id="DIALOGUE.VIEW.PHASES" />
            </div>
            {/* <div className="period subheading">
              <FormattedMessage id="PROJECT.VIEW.PERIOD" />
            </div> */}
          </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="dialoguePhases">
        <Col sm={12} md={12} lg={12}>
          <div className="phases">
            {phases ? (
              editing ? (
                <>
                  {fixedPhases.map((ph, i) => {
                    return renderPhaseEntry(ph, -i);
                  })}
                  <SortableContainer reorder={reorderPhases}>
                    <SortableList
                      listInfo={listinfo}
                      itemType={SortableItemType.Phase}
                    >
                      {sortedPhases.map((ph, i) => {
                        return (
                          <SortableItem
                            key={ph.id}
                            list={listinfo}
                            item={ph}
                            itemType={SortableItemType.Phase}
                            index={i}
                            withHandle={false}
                          >
                            {renderPhaseEntry(ph, i)}
                          </SortableItem>
                        );
                      })}
                    </SortableList>
                  </SortableContainer>
                  <div className="admin_section">
                    {phases.length > 0 &&
                    phases.some((ph) => ph.fixed) ? null : (
                      <Button
                        onClick={() => addPhase(true)}
                        variant="outline-secondary"
                      >
                        <FormattedMessage id="DIALOGUE.FORM.PHASES.ADD_FIXEDPHASE" />
                      </Button>
                    )}
                    <Button
                      onClick={() => addPhase()}
                      variant="outline-secondary"
                    >
                      <FormattedMessage id="DIALOGUE.FORM.PHASES.ADD_PHASE" />
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {/* {fixedPhases.map((ph, i) => {
                    return renderPhaseEntry(ph, -i);
                  })} */}
                  {sortedPhases.map((ph, i) => {
                    return renderPhaseEntry(ph, i);
                  })}
                </>
              )
            ) : null}
          </div>
        </Col>
      </Form.Group>
    </ModalDialog>
  );
}

const DialogueForm = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(
    connect(mapAppSettingsStateToProps)(UnconnectedDialogueForm)
  )
);
export default DialogueForm;

export function DialogueFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  dialogue?: any;
  tag?: 'span' | 'div';
  title?: string;
  onCloseAll?: () => void;
  children?: ReactNode;
}) {
  const { onClick, dialogue = null, children, title, tag, className } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.DialogueForm.setDialogue(dialogue);
    forms.DialogueForm.setShow(true);
    onClick && onClick(e);
  }

  const TagName = tag ?? 'div';
  return (
    <TagName
      className={classNames('nav_item', className)}
      onClick={handleClick}
      title={title}
    >
      {children}
    </TagName>
  );
}
