import { parse } from './apibaseparser.js';
import { includes } from './typescriptHelper.js';

// These are the basic types
export const basicTypes = [
  'String',
  'Boolean',
  'Int',
  'BigInteger',
  'Float',
  'Decimal',
  'DateTime',
  'Json',
  'Bytes',
  'Email',
] as const;

export class Attribute<
  AccessRead extends never | null = never,
  ModelSet extends never | null = never,
> {
  public constructor(
    public readonly name: string,
    public readonly array: boolean,
    public readonly optional: boolean,
    public rwAccess: RWAccess | AccessRead,
    public readonly type: string,
    public readonly unique: boolean,
    public readonly reference: string | undefined,
    public model: Model<AccessRead, ModelSet> | ModelSet, // = containing model
  ) {}
  public isBasic() {
    return includes(basicTypes, this.type);
  }
  public isPrimitive() {
    return this.isBasic() || parse().enums.some((e) => e.name == this.type);
  }
  public references(): Model | null {
    if (this.isPrimitive()) return null;
    else return parse().findModel(this.type);
  }
  public isNotCreatable() {
    if (!this.rwAccess)
      throw new Error(`I don't know whether ${this} is readOnly!`);
    return this.rwAccess.create.isNever();
  }
  public isNotUpdatable() {
    if (!this.rwAccess)
      throw new Error(`I don't know whether ${this} is readOnly!`);
    return this.rwAccess.update.isNever();
  }
  public isNotReadable() {
    if (!this.rwAccess)
      throw new Error(`I don't know whether ${this} is writeOnly!`);
    return this.rwAccess.read.isNever();
  }
}

export class Model<
  AccessRead extends never | null = never,
  ModelSet extends never | null = never,
> {
  constructor(
    public readonly name: string,
    public readonly attributes: Attribute<AccessRead, ModelSet>[],
    public rootAccess: RWDAccess | AccessRead,
  ) {
    Object.freeze(attributes);
    for (const attribute of this.attributes) attribute.model = this;
  }
  primitives() {
    return this.attributes.filter((at) => at.isPrimitive());
  }
  findAttribute(name: string): Attribute<AccessRead, ModelSet> {
    const ret = this.attributes.find((a) => a.name == name);
    // if (!ret)
    //   throw new Error(`Cannot find attribute ${name}!`);
    return ret!;
  }
}

export class RWAccess {
  public constructor(
    public readonly create: Access<Condition>,
    public readonly read: Access<Condition>,
    public readonly update: Access<Condition>,
  ) {}
}

export class RWDAccess extends RWAccess {
  public readonly delete: Access<Condition>;
  public constructor(
    create: Access<Condition>,
    read: Access<Condition>,
    update: Access<Condition>,
    delete_: Access<Condition>,
  ) {
    super(create, read, update);
    this.delete = delete_;
  }
}

export declare type Enum = {
  name: string;
  options: string[];
};

export declare type RoleItem = { name: string; inherits: string[] };

export declare type Links<
  AccessRead extends never | null = never,
  ModelSet extends never | null = never,
> = Map<Attribute<AccessRead, ModelSet>, Attribute<AccessRead, ModelSet>>;

export class ApiBase<
  AccessRead extends never | null = never,
  ModelSet extends never | null = never,
> {
  models: Model<AccessRead, ModelSet>[];
  enums: Enum[];
  links: Links<AccessRead, ModelSet>;
  roles: RoleItem[];
  constructor(
    models: Model<AccessRead, ModelSet>[],
    enums: Enum[],
    links: Links<AccessRead, ModelSet>,
    roles: RoleItem[],
  ) {
    this.models = models;
    this.enums = enums;
    this.links = links;
    this.roles = roles;
  }
  findModel(name: string) {
    const ret = this.models.find((m) => m.name == name);
    if (!ret) throw new Error(`Cannot find model ${name}!`);
    return ret;
  }
  roleHasRole(role: string, hasRole: string) {
    if (role === hasRole || hasRole === asterisk) return true;
    const role_ = this.roles.find((r) => r.name == role);
    if (role_ === undefined) throw new Error('This role is unknown: ' + role_);
    for (const superRole of role_.inherits)
      if (this.roleHasRole(superRole, hasRole)) return true;
    return false;
  }
  rolesHaveRole(roles: string[], hasRole: string) {
    return roles.some((r) => this.roleHasRole(r, hasRole));
  }
  modelByName(name: string) {
    return this.models.find((m) => m.name == name);
  }
}

export class RoleName {
  public constructor(public readonly name: string) {}
  public isAlways() {
    return this.name == asterisk;
  }
}
export class AttributePath {
  public contains(that: AttributePath) {
    return (
      !(that.attributes.length > this.attributes.length) &&
      that.attributes.every((value, index) => value === this.attributes[index])
    );
  }
  public constructor(public readonly attributes: Attribute[]) {
    Object.freeze(attributes);
  }
  public static combine(
    path1: AttributePath,
    path2: AttributePath,
  ): AttributePath {
    return new AttributePath(path1.attributes.concat(path2.attributes));
  }
}
function isRoleName(c: Condition): c is RoleName {
  return (c as RoleName).name !== undefined;
}
export declare type Condition = RoleName | AttributePath;
export class AccessOption<ConditionType extends Condition = Condition> {
  public constructor(public readonly conditions: ConditionType[]) {
    Object.freeze(this.conditions);
  }
}
export function isRoleCondition(condition: Condition): condition is RoleName {
  return (<any>condition).attributes === undefined;
}
export type SimplifiedAccess = Access<AttributePath>;
export class Access<ConditionType extends Condition = Condition> {
  // always = (options = undefined)
  // never = (options = [])
  // rest = (options = [...])
  public notAlways(): this is { options: AccessOption<ConditionType>[] } {
    return this.options !== undefined;
  }
  public constructor(public readonly options?: AccessOption<ConditionType>[]) {
    Object.freeze(this.options);
  }
  public isAlways(): this is { options: undefined } {
    return !this.simplify([]).notAlways();
  }
  public isNever() {
    return this.options !== undefined && this.options.length == 0;
  }
  public simplify(roles: string[]): SimplifiedAccess {
    let ret: AccessOption<AttributePath>[] = [];
    if (!this.notAlways()) return allAccess;
    for (let option of this.options) {
      let failure = false;
      let remaining: AttributePath[] = [];
      for (let and of option.conditions) {
        if (isRoleCondition(and)) {
          const and_ = and;
          if (
            !(
              and_.name == '*' ||
              roles.some((r) => parse().roleHasRole(r, and_.name))
            )
          )
            failure = true;
        } else {
          remaining.push(and);
        }
      }
      if (!failure)
        if (remaining.length > 0)
          ret.push(new AccessOption<AttributePath>(remaining));
        else return allAccess;
    }
    return new Access<AttributePath>(ret);
  }
}
export const asterisk = '*';
export const allAccess: SimplifiedAccess = new Access(undefined);
export const noAccess: SimplifiedAccess = new Access([]);

export const RWDAllAccess = new RWDAccess(
  allAccess,
  allAccess,
  allAccess,
  allAccess,
);
