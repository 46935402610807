import classNames from 'classnames';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useAppDispatch } from 'app/hooks';
import { FormMsg, NoFormMsg, doFormMsg } from 'components/forms/FormMessage';
import { FormProps } from 'components/forms/FormProps';
import { useForms } from 'components/forms/Forms';
import { ModalDialog } from 'components/forms/ModalDialog';
import Icon, { IconSymbol } from 'components/icons/Icon';
import DropMenu from 'components/navigation/DropMenu';
import UserAvatar from 'components/user/UserAvatar';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { fetchDialoguesAsync } from 'features/dialogue/dialoguesSlice';
import { IProject } from 'features/projects/projectsAPI';
import {
  ProjectsStateProps,
  fetchProjectsAsync,
  mapProjectsStateToProps,
} from 'features/projects/projectsSlice';
import { IUser, Role, RoleType } from 'features/user/userAPI';
import { ISignupData, signupUserAsync } from 'features/user/userSlice';
import {
  UsersStateProps,
  fetchUsersAsync,
  mapUsersStateToProps,
  modifyUserAsync,
} from 'features/users/usersSlice';
import { hideEmail, toLowerCase } from 'helpers/helpers';
import { sortBy } from 'helpers/sorting';
import { ModeratorIcon } from './ModeratorIcon';
import { Paginator } from './Paginator';
import { UserDataFormLink, UserSettings } from './UserDataForm';
import { DialogHeader } from './helpers/DialogHeader';

import './UsersForm.scss';

function UnconnectedUsersForm(
  props: FormProps & UsersStateProps & ProjectsStateProps
) {
  const { show, setShow, users, projects } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [editing, setEditing] = useState<Set<number>>(new Set());
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [searchItem, setSearchItem] = useState<string>('');
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [showIds, setShowIds] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [reload, setReload] = useState<boolean>(false);
  const scrollableRef = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    if (reload || show) {
      dispatch(fetchUsersAsync());
      dispatch(fetchProjectsAsync());
      dispatch(fetchDialoguesAsync());
      setSearchItem('');
      setReload(false);
    }
  }, [dispatch, show, reload]);

  const [formMsg, setFormMsg] = useState<FormMsg>(NoFormMsg);
  useEffect(() => {
    doFormMsg(NoFormMsg, setFormMsg);
  }, [show]);

  useEffect(() => {
    if (searchItem && searchItem.length > 0)
      setFilteredUsers(
        users.filter(
          (u: IUser) =>
            u.username?.toLowerCase().includes(searchItem.toLowerCase()) ||
            u.email?.toLowerCase().includes(searchItem.toLowerCase())
        )
      );
    else setFilteredUsers(users);
  }, [searchItem, users]);

  function handleCancel(): boolean {
    if (editing.size === 0) return true;
    doFormMsg(
      {
        message: 'Please finish or cancel editing first.',
        success: false,
        timeout: 3000,
      },
      setFormMsg
    );
    return false;
  }

  function rowEditing(start: boolean, id: number) {
    setEditing((prev) => {
      if (start) prev.add(id);
      else prev.delete(id);
      return prev;
    });
  }

  function handleNewUser(userData: UserSettings): boolean {
    const newUser: ISignupData = {
      username: userData.username,
      email: userData.email,
      password: userData.password ?? '',
      registrationCode: userData.registrationCode ?? '',
    };
    try {
      dispatch(signupUserAsync(newUser)).then((response: { payload: any }) => {
        switch (response.payload?.response?.status) {
          case 201:
            dispatch(
              modifyUserAsync({
                data: {
                  ...newUser,
                  id: response.payload.response.data._id,
                  isVerified: true, // TODO: consider false
                },
                newUser: true,
              })
            );
            setReload(true);
            break;
          default:
            break;
        }
      });
      return true;
    } catch (err) {
      doFormMsg(
        {
          message: intl.formatMessage({ id: 'X.FAILED_UNKNOWN' }),
          success: false,
        },
        setFormMsg
      );
      console.log(
        'Project update or post request failed for unclear reason.',
        err
      );
      return false;
    }
  }

  return (
    <ModalDialog
      show={show}
      setShow={setShow}
      className="settings_form users_form wide"
      title={
        <DialogHeader
          title={<FormattedMessage id={'USERS.FORM.TITLE'} />}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          showIds={showIds}
          setShowIds={setShowIds}
          searchItem={searchItem}
          setSearchItem={setSearchItem}
        />
      }
      noFooter={true}
      footer={
        <FormFooter
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemCount={filteredUsers.length}
          itemsPerPage={itemsPerPage}
          handleNewUser={handleNewUser}
        />
      }
      onCancel={handleCancel}
      editing={false}
      enableEscape={false}
      formMsg={formMsg}
      refScrollable={scrollableRef}
    >
      <div className="description">
        <FormattedMessage
          id="USERS.FORM.USER_COUNT"
          values={{ count: filteredUsers.length }}
        />
      </div>
      <TableHead />
      <TableBody
        currentPage={currentPage}
        projects={projects}
        users={filteredUsers}
        itemsPerPage={itemsPerPage}
        scrollableRef={scrollableRef}
        rowEditing={rowEditing}
        showIds={showIds}
        setFormMsg={setFormMsg}
      />
    </ModalDialog>
  );
}

const UsersForm = connect(mapUsersStateToProps)(
  connect(mapProjectsStateToProps)(UnconnectedUsersForm)
);
export default UsersForm;

type validatedSubscriptions = {
  missingProjects: number[];
  missingDialogues: number[];
  invalidDialogues: number[];
  valid: boolean;
};

type RowDataCategory = 'dialogue' | 'project';

function TableHead() {
  return (
    <div className="users_thead user_project_row">
      <div className="user_avatar"></div>
      <div className="user_user">
        <FormattedMessage id="USERS.FORM.NAME" />
      </div>
      <div className="user_role">
        <FormattedMessage id="USERS.FORM.ROLE" />
      </div>
      <div className="user_subscr user_project">
        <FormattedMessage id="USERS.FORM.PROJECT" />
      </div>
      <div className="user_subscr">
        <div className="subscription_item">
          <div>
            <FormattedMessage id="USERS.FORM.DIALOGUE" />
          </div>
          <div className="right">
            <FormattedMessage id="USERS.FORM.MODERATOR" />
          </div>
        </div>
      </div>
      <div className="user_save"></div>
    </div>
  );
}

type TableRowProps = {
  user: IUser;
  projects: IProject[];
  lastRow: boolean;
  rowEditing: (start: boolean, id: number) => void;
  validatedSubscriptions: validatedSubscriptions;
  showIds: boolean;
  setFormMsg: Dispatch<SetStateAction<FormMsg>>;
};

function TableRow(props: TableRowProps) {
  const {
    user,
    projects,
    lastRow,
    rowEditing,
    validatedSubscriptions,
    showIds,
    setFormMsg,
  } = props;
  const [changesTriggered, setChangesTriggered] = useState<Boolean>(false);
  const dispatch = useAppDispatch();
  const [rowData, setRowData] = useState<IUser>(user);
  const forms = useForms();

  function editingRow(start: boolean) {
    setChangesTriggered(start);
    rowEditing(start, rowData.id ?? -1);
  }

  function handleSubmitUserSettings(userData: UserSettings): boolean {
    const roles: RoleType[] = [Role.user];
    if (userData.admin) {
      roles.push(Role.admin);
    }
    if (userData.manager) {
      roles.push(Role.manager);
    }
    setRowData((prevData) => ({
      ...prevData,
      username: userData.username,
      email: userData.email,
      isVerified: userData.isVerified,
      roles: roles,
    }));
    editingRow(true);
    return true;
  }

  function editUserSettings() {
    try {
      forms.UserDataForm.setOnSubmit(() => {
        return handleSubmitUserSettings;
      });
    } catch (error) {
      console.log(error);
    }
  }

  function handleReset() {
    setRowData(user);
    editingRow(false);
  }

  function handleModerationChange(
    user: IUser,
    checked: boolean,
    dialogue?: IDialogue
  ) {
    if (!dialogue) return;
    setRowData((prevData) => {
      const updatedDialogues = checked
        ? [...(prevData.moderatesDialogues ?? []), dialogue]
        : (prevData.moderatesDialogues ?? []).filter(
            (d) => d.id !== dialogue.id
          );
      return {
        ...prevData,
        moderatesDialogues: updatedDialogues,
      };
    });
    editingRow(true);
  }

  function handleSave() {
    if (rowData.id) {
      dispatch(modifyUserAsync({ data: rowData, newUser: false })).then(
        (response: { payload: any }) => {
          switch (response.payload?.response.status) {
            case 200:
            case 201:
              doFormMsg(
                {
                  message: 'User updated',
                  success: true,
                  timeout: 1500,
                },
                setFormMsg
              );
              break;
            case 500:
            default:
              const m = 'Internal error, user update not saved.';
              doFormMsg(
                {
                  message: m,
                  success: false,
                },
                setFormMsg
              );
              break;
          }
        }
      );
    }
    editingRow(false);
  }

  function handleDropMenuChange(
    item: IProject | IDialogue,
    rowDataCategory: RowDataCategory
  ) {
    if (rowDataCategory === 'project') {
      if (
        !(rowData.subscribedToProjects ?? []).some(
          (project) => project.id === item.id
        )
      ) {
        setRowData((prevData) => ({
          ...prevData,
          subscribedToProjects: [
            ...(prevData.subscribedToProjects ?? []),
            item,
          ],
        }));
      }
    } else if (rowDataCategory === 'dialogue') {
      if (
        !(rowData.subscribedToDialogues ?? []).some(
          (dialogue) => dialogue.id === item.id
        )
      ) {
        setRowData((prevData) => ({
          ...prevData,
          subscribedToDialogues: [
            ...(prevData.subscribedToDialogues ?? []),
            item,
          ],
        }));
      }
    }
    setChangesTriggered(true);
  }

  function handleRemoveProjectOrDialogue(
    item: IProject | IDialogue,
    rowDataCategory: RowDataCategory
  ) {
    if (rowDataCategory === 'project') {
      setRowData((prevData) => {
        const updatedProjects = (prevData.subscribedToProjects ?? []).filter(
          (pr) => pr.id !== item.id
        );
        const updatedDialogues = (prevData.subscribedToDialogues ?? []).filter(
          (dialogue) => {
            return !projects
              .find((pr: IProject) => pr.id === item.id)!
              .dialogues?.some(
                (projectDialogue: IDialogue) =>
                  projectDialogue.id === dialogue.id
              );
          }
        );
        const updatedModeratesDialogues = (
          prevData.moderatesDialogues ?? []
        ).filter((md: IDialogue) => {
          return updatedDialogues.some(
            (filteredDialogue) => md.id === filteredDialogue.id
          );
        });
        return {
          ...prevData,
          subscribedToProjects: updatedProjects,
          subscribedToDialogues: updatedDialogues,
          moderatesDialogues: updatedModeratesDialogues,
        };
      });
    } else if (rowDataCategory === 'dialogue') {
      setRowData((prevData) => ({
        ...prevData,
        subscribedToDialogues: [
          ...(prevData.subscribedToDialogues ?? []).filter(
            (dl) => dl.id !== item.id
          ),
        ],
        moderatesDialogues: [
          ...(prevData.moderatesDialogues ?? []).filter(
            (dl) => dl.id !== item.id
          ),
        ],
      }));
    }
    setChangesTriggered(true);
  }

  const subscribedProject =
    rowData.subscribedToProjects && rowData.subscribedToProjects.length
      ? rowData.subscribedToProjects
      : [{ id: 0 }];
  return (
    <>
      {subscribedProject.map(
        (rowProject: IProject, i: number, a: IProject[]) => {
          let foundProject = undefined;
          let projectDialogues: IDialogue[] = [];
          let subscribedProjectDialogues: IDialogue[] = [];
          if (typeof rowProject !== 'number') {
            foundProject = projects.find(
              (project: IProject) => project.id === rowProject.id
            );
            projectDialogues = foundProject?.dialogues ?? [];
            subscribedProjectDialogues =
              projectDialogues?.filter((projectDialogue) =>
                (rowData.subscribedToDialogues ?? []).some(
                  (rowDialogue) => projectDialogue.id === rowDialogue.id
                )
              ) ?? [];
          }
          return (
            <div
              key={rowProject.id}
              className="user_project_row"
              // style={{ zIndex: i }}
            >
              <div className="user_avatar">
                {i > 0 ? null : <UserAvatar user={user} small />}
              </div>
              <div
                className={classNames('user_user', {
                  invalid_subscriptions: !validatedSubscriptions.valid,
                })}
              >
                {i > 0 ? null : (
                  <>
                    <UserDataFormLink
                      user={rowData}
                      onClick={editUserSettings}
                      className="user_name"
                    >
                      <div>
                        {showIds ? '[' + user.id + '] ' : null}
                        {rowData.username}
                      </div>
                      <div>{hideEmail(rowData.email!)}</div>
                    </UserDataFormLink>
                    <div className="user_messages">
                      {'msgs: ' +
                        (user.messages?.length ?? 0) +
                        ' likes: ' +
                        (user.likes?.length ?? 0)}
                      <br />
                      {'votes: ' +
                        (user.votes?.length ?? 0) +
                        ' files: ' +
                        (user.uploadedFiles?.length ?? 0)}
                    </div>
                  </>
                )}
              </div>
              <div
                className={classNames('user_role', {
                  user_unverified: !rowData.isVerified,
                })}
              >
                {i > 0 ? null : (
                  <UserDataFormLink user={rowData} onClick={editUserSettings}>
                    {rowData.roles?.includes(Role.admin)
                      ? Role.admin
                      : rowData.roles?.includes(Role.manager)
                      ? Role.manager
                      : rowData.email?.length
                      ? Role.user
                      : 'guest'}
                  </UserDataFormLink>
                )}
              </div>
              <div className="user_subscr user_project">
                <div className="subscription_cell">
                  {rowProject.id === 0 ? null : (
                    <div className="subscription_item">
                      <div
                        className="project_name"
                        title={rowProject.id?.toString()}
                      >
                        {showIds ? '[' + rowProject.id + '] ' : null}
                        {rowProject.title}
                      </div>
                      <Icon
                        symbol={IconSymbol.bin}
                        className="item_remove"
                        onClick={() =>
                          handleRemoveProjectOrDialogue(rowProject, 'project')
                        }
                      />
                    </div>
                  )}
                  {i < a.length - 1 ? null : (
                    <>
                      {!validatedSubscriptions.valid &&
                      validatedSubscriptions.missingProjects.length > 0 ? (
                        <div className="invalid_subscriptions">
                          <FormattedMessage id="USERS.FORM.MISSING_PROJECTS" />
                          {': '}
                          {validatedSubscriptions.missingProjects.join(',')}
                        </div>
                      ) : null}
                      <DropMenu className="add_menu" right={true} up={true}>
                        <DropMenu.Trigger>
                          <Icon symbol={IconSymbol.plus} size={18} />
                        </DropMenu.Trigger>
                        <DropMenu.Items>
                          {projects
                            .filter(
                              (project: IProject) =>
                                !(rowData.subscribedToProjects ?? []).some(
                                  (rowProject) => rowProject.id === project.id
                                )
                            )
                            .map((project: IProject) => (
                              <div
                                key={project.id}
                                onClick={() =>
                                  handleDropMenuChange(project, 'project')
                                }
                              >
                                {showIds ? '[' + project.id + '] ' : null}
                                {project.title}
                              </div>
                            ))}
                        </DropMenu.Items>
                      </DropMenu>
                    </>
                  )}
                </div>
              </div>
              <div className="user_subscr">
                {subscribedProjectDialogues.map((dialogue: IDialogue) => (
                  <div key={dialogue.id} className="subscription_item">
                    <div
                      className="dialogue_name"
                      title={dialogue.id?.toString()}
                    >
                      {showIds ? '[' + dialogue.id + '] ' : null}
                      {dialogue.title}
                    </div>
                    <Icon
                      symbol={IconSymbol.bin}
                      className="item_remove"
                      onClick={() =>
                        handleRemoveProjectOrDialogue(dialogue, 'dialogue')
                      }
                    />
                    <ModeratorIcon
                      key={dialogue.id}
                      user={rowData}
                      dialogue={dialogue}
                      onChange={handleModerationChange}
                    />
                  </div>
                ))}
                {!validatedSubscriptions.valid &&
                validatedSubscriptions.missingDialogues.filter(
                  (d) => Math.floor(d) === rowProject.id
                ).length > 0 ? (
                  <div className="invalid_subscriptions">
                    <FormattedMessage id="USERS.FORM.MISSING_DIALOGUES" />
                    {': '}
                    {validatedSubscriptions.missingDialogues.join(',')}
                  </div>
                ) : null}
                <DropMenu className="add_menu" right={true} up={true}>
                  <DropMenu.Trigger>
                    <Icon symbol={IconSymbol.plus} size={18} />
                  </DropMenu.Trigger>
                  <DropMenu.Items>
                    {(
                      projectDialogues?.filter(
                        (dialogue: IDialogue) =>
                          !(rowData.subscribedToDialogues ?? []).some(
                            (rowDialogue: IDialogue) =>
                              rowDialogue.id === dialogue.id
                          )
                      ) ?? []
                    ).map((dialogue: IDialogue, id: number) => (
                      <div
                        key={id}
                        onClick={() =>
                          handleDropMenuChange(dialogue, 'dialogue')
                        }
                      >
                        {showIds ? '[' + dialogue.id + '] ' : null}
                        {dialogue.title}
                      </div>
                    ))}
                  </DropMenu.Items>
                </DropMenu>
              </div>
              <div className="user_save">
                {i === 0 && (
                  <div>
                    <Button
                      onClick={handleSave}
                      size="sm"
                      disabled={!changesTriggered}
                    >
                      <FormattedMessage id="X.SAVE" />
                    </Button>
                    <Button
                      onClick={handleReset}
                      size="sm"
                      variant="outline-secondary"
                      disabled={!changesTriggered}
                    >
                      <FormattedMessage id="X.CANCEL" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          );
        }
      )}
    </>
  );
}

type TableBodyProps = {
  currentPage: number;
  projects: IProject[];
  users: IUser[];
  itemsPerPage: number;
  rowEditing: (start: boolean, id: number) => void;
  showIds: boolean;
  setFormMsg: Dispatch<SetStateAction<FormMsg>>;
  scrollableRef?: React.RefObject<HTMLTableSectionElement>;
};
function TableBody(props: TableBodyProps) {
  const {
    currentPage,
    itemsPerPage,
    projects,
    rowEditing,
    users,
    showIds,
    setFormMsg,
    scrollableRef,
  } = props;
  const [paginatedUsers, setPaginatedUsers] = useState<any>([]);

  function paginateUsers(users: IUser[], currentPage: any, itemsPerPage: any) {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return users.slice(startIndex, endIndex);
  }

  function combineIds(project: number, dialogue: number): number {
    return parseFloat(project.toString() + '.' + dialogue.toString());
  }

  function validateSubscriptions(user: IUser): validatedSubscriptions {
    let missingProjects: Set<number> = new Set();
    let missingDialogues: Set<number> = new Set();
    let invalidDialogues: Set<number> = new Set();
    let valid = true;
    const pp = user.subscribedToProjects?.map((p) => p.id) ?? [];
    (user.subscribedToDialogues ?? []).forEach((d: IDialogue) => {
      if (d?.project && !pp.includes(d.project.id)) {
        missingProjects.add(d.project.id as number);
        invalidDialogues.add(d.id as number);
        valid = false;
      }
    });
    (user.moderatesDialogues ?? []).forEach((d: IDialogue) => {
      if (d?.project && !pp.includes(d.project.id)) {
        missingProjects.add(d.project.id as number);
        invalidDialogues.add(d.id as number);
        valid = false;
      }
      if (
        !user.subscribedToDialogues?.map((sd) => sd.id).includes(d.id as number)
      ) {
        missingDialogues.add(
          combineIds(d.project!.id as number, d.id as number)
        );
        invalidDialogues.add(d.id as number);
        valid = false;
      }
    });
    return {
      missingProjects: Array.from(missingProjects),
      missingDialogues: Array.from(missingDialogues),
      invalidDialogues: Array.from(invalidDialogues),
      valid,
    };
  }

  useEffect(() => {
    setPaginatedUsers(
      paginateUsers(
        sortBy(users, 'username', 'ASC', toLowerCase),
        currentPage,
        itemsPerPage
      )
    );
  }, [currentPage, itemsPerPage, users]);

  return (
    <div className="users_tbody" ref={scrollableRef}>
      {paginatedUsers.map((user: IUser, i: number, a: IUser[]) => (
        <div
          key={user.id}
          className="user_row"
          // style={{ zIndex: i }}
        >
          <TableRow
            user={user}
            projects={projects}
            lastRow={i === a.length - 1}
            rowEditing={rowEditing}
            validatedSubscriptions={validateSubscriptions(user)}
            showIds={showIds}
            setFormMsg={setFormMsg}
          />
        </div>
      ))}
    </div>
  );
}

type FormFooterProps = {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  itemCount: number;
  itemsPerPage: number;
  handleNewUser: (userData: UserSettings) => boolean;
};
function FormFooter(props: FormFooterProps) {
  const {
    currentPage,
    setCurrentPage,
    itemCount,
    itemsPerPage,
    handleNewUser,
  } = props;
  const forms = useForms();

  function newUserSettings() {
    try {
      forms.UserDataForm.setOnSubmit(() => {
        return handleNewUser;
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Paginator
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        itemCount={itemCount}
        itemsPerPage={itemsPerPage}
      />
      <UserDataFormLink user={null} onClick={newUserSettings}>
        <Button variant="outline-secondary">
          <FormattedMessage id="USERS.FORM.ADD_USER" />
        </Button>
      </UserDataFormLink>
    </>
  );
}

export function UsersFormLink(props: {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  tag?: 'span' | 'div';
  title?: string;
  onCloseAll?: () => void;
  children?: ReactNode;
}) {
  const { onClick, children, title, tag, className } = props;
  const forms = useForms();

  function handleClick(e: React.MouseEvent) {
    forms.UsersForm.setShow(true);
    onClick && onClick(e);
  }

  const TagName = tag ?? 'div';
  return (
    <TagName
      className={classNames(className)}
      onClick={handleClick}
      title={title}
    >
      {children}
    </TagName>
  );
}
