function isValid(
  board: number[][],
  row: number,
  col: number,
  num: number
): boolean {
  for (let i = 0; i < 9; i++) {
    if (board[row][i] === num || board[i][col] === num) {
      return false;
    }
    if (
      board[Math.floor(row / 3) * 3 + Math.floor(i / 3)][
        Math.floor(col / 3) * 3 + (i % 3)
      ] === num
    ) {
      return false;
    }
  }
  return true;
}

function findEmptyLocation(board: number[][]): [number, number] | null {
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (board[row][col] === 0) {
        return [row, col];
      }
    }
  }
  return null;
}

function solveSudoku(board: number[][]): boolean {
  const empty = findEmptyLocation(board);
  if (!empty) {
    return true;
  }
  const [row, col] = empty;
  for (let num = 1; num <= 9; num++) {
    if (isValid(board, row, col, num)) {
      board[row][col] = num;
      if (solveSudoku(board)) {
        return true;
      }
      board[row][col] = 0;
    }
  }
  return false;
}

function generateFullBoard(): number[][] {
  const board = Array.from({ length: 9 }, () => Array(9).fill(0));
  function fillBoard(): boolean {
    const empty = findEmptyLocation(board);
    if (!empty) {
      return true;
    }
    const [row, col] = empty;
    const nums = Array.from({ length: 9 }, (_, i) => i + 1);
    shuffle(nums);
    for (const num of nums) {
      if (isValid(board, row, col, num)) {
        board[row][col] = num;
        if (fillBoard()) {
          return true;
        }
        board[row][col] = 0;
      }
    }
    return false;
  }
  fillBoard();
  return board;
}

function countSolutions(board: number[][]): number {
  let count = 0;
  const empty = findEmptyLocation(board);
  if (!empty) {
    return 1;
  }
  const [row, col] = empty;
  for (let num = 1; num <= 9; num++) {
    if (isValid(board, row, col, num)) {
      board[row][col] = num;
      count += countSolutions(board);
      board[row][col] = 0;
      if (count > 1) {
        return count;
      }
    }
  }
  return count;
}

function shuffle<T>(array: T[]): void {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function containsAtLeastEightDigits(board: number[][]): boolean {
  const digitCount = new Set<number>();
  for (const row of board) {
    for (const num of row) {
      if (num !== 0) {
        digitCount.add(num);
      }
    }
  }
  return digitCount.size >= 8;
}

function removeNumbers(board: number[][], numToRemove: number): number[][] {
  const positions: [number, number][] = [];
  for (let r = 0; r < 9; r++) {
    for (let c = 0; c < 9; c++) {
      positions.push([r, c]);
    }
  }
  shuffle(positions);

  let removed = 0;
  for (const [row, col] of positions) {
    if (removed >= numToRemove) {
      break;
    }
    const backup = board[row][col];
    board[row][col] = 0;
    const boardCopy = board.map((row) => row.slice());
    if (
      countSolutions(boardCopy) !== 1 ||
      !containsAtLeastEightDigits(boardCopy)
    ) {
      board[row][col] = backup;
    } else {
      removed++;
    }
  }
  return board;
}

function arrayToString(puzzle: number[][]): string {
  const s = puzzle.map((row) => row.join('')).join('');
  return s.replace(/0/g, '.');
}

export function generateSudoku(numToRemove: number): number[][] {
  const fullBoard = generateFullBoard();
  let puzzle = removeNumbers(fullBoard, numToRemove);
  while (!containsAtLeastEightDigits(puzzle)) {
    puzzle = removeNumbers(fullBoard, numToRemove);
  }
  return puzzle;
}

export function generateSudokuAsString(numToRemove: number): string {
  return arrayToString(generateSudoku(numToRemove));
}
