import {
  END_POINT,
  RECONNECT_ATTEMPTS1,
  RECONNECT_ATTEMPTS2,
  RECONNECT_DELAY1,
  RECONNECT_DELAY2,
  RECONNECT_DELAY3,
  RECONNECT_NOTIFY,
} from 'helpers/consts';
import {
  convertToWebSocketURL,
  currentTime,
  deepSearchKey,
  removeCommonValuesComplex,
  removeCommonValuesDeep,
  removeCommonValuesSimple,
} from './helpers';

import {
  AsyncMessage,
  CallingMessage,
  DocumentsMessage,
} from '@thuas/pd-schemas';
import { store } from 'app/store';
import { setConnectionError } from 'features/admin/appSettingsSlice';

type EventType = 'documents' | 'calling';
type DocumentEvent = 'new-rt' | 'caret';
type CallingEvent =
  | 'sending signal'
  | 'returning signal'
  | 'join meeting'
  | 'leave meeting'
  | 'toggled video'
  | 'toggled audio'
  | 'toggled recording'
  | 'cleanup request'
  | 'get preview';

type ChangeType = 'new' | 'updated' | 'removed';
const NEW: ChangeType = 'new';
const UPDATED: ChangeType = 'updated';
const REMOVED: ChangeType = 'removed';

export class AsyncAPI {
  static connection: WebSocket;

  static id: string;

  static webSocketURL: string = convertToWebSocketURL(END_POINT!);

  static onMessageCallbacksQueries: {
    query: string;
    callback: (payload: any, change: string) => void;
  }[] = [];

  static onMessageCallbacksNonQueries: {
    callback: (payload: any) => void;
    roomId: string;
  }[] = [];

  static applied: {
    [query: string]: any[];
  } = {};

  static listeningTo: { [key: string]: number } = {};

  static heartbeatInterval: NodeJS.Timeout;

  static reconnectCounter: number = 0;

  static setupConnection() {
    this.connection = new WebSocket(this.webSocketURL);
    console.log('Connect requested!');

    this.connection.onopen = (event) => {
      console.log(
        'onopen listener fired (The connection is open and ready to communicate.)'
      );

      this.reconnectCounter = 0;
      if (this.reconnectTimer) clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
      store.dispatch(setConnectionError(0));
      this.pingPong();
      this.requestId();

      this.connection.onmessage = (event) => {
        const data = event.data;

        // string messages (pingPong and requestId)
        if (typeof data === 'string') {
          // pingPong
          if (data === 'pong') {
            console.log(
              `AsyncAPI heartbeat received - ${data} from server - ${currentTime()}`
            );
          }
          // requestId
          if (data.substring(0, 3) === 'id:') {
            this.id = data.split(':')[1];
          }
        }
        // object/data messages (calling, documents, queries)
        else {
          data.text().then((e: any) => {
            const parsed = JSON.parse(e);

            // documents and calling
            if (parsed.type === 'documents' || parsed.type === 'calling') {
              // Call all registered callbacks with the received data
              this.onMessageCallbacksNonQueries.forEach(
                ({ callback, roomId }) => {
                  if (parsed.payload.roomId === roomId)
                    callback(parsed.payload);
                }
              );
            }
            // queries
            else {
              // Call all registered callbacks with the received data
              this.onMessageCallbacksQueries.forEach(({ query, callback }) => {
                let returnValue: any;
                let change: string;
                this.applied[query] = [];

                const processPayload = (
                  payload: any[],
                  changeType: ChangeType
                ) => {
                  let uniqueArray = [];

                  switch (changeType) {
                    case NEW:
                      uniqueArray = removeCommonValuesComplex(
                        payload ?? [],
                        this.applied[query]
                      );
                      break;
                    case UPDATED:
                      uniqueArray = removeCommonValuesDeep(
                        payload ?? [],
                        this.applied[query]
                      );
                      break;
                    case REMOVED:
                      uniqueArray = removeCommonValuesSimple(
                        payload ?? [],
                        this.applied[query]
                      );
                      break;
                  }

                  console.log(`"${changeType}" uniqueArray: `, uniqueArray);

                  if (uniqueArray.length === 0) return;

                  returnValue = uniqueArray[0];
                  change = changeType;

                  console.log(`"${changeType}" returnValue: `, returnValue);
                  console.log(`"${changeType}" change: `, change);

                  callback(returnValue, change);

                  this.applied[query].push(returnValue);
                };

                if (deepSearchKey(parsed, query)) {
                  console.log('parsed.payload[query]: ', parsed.payload[query]);

                  if (deepSearchKey(parsed, NEW)) {
                    processPayload(parsed.payload[query].new, NEW);
                  }

                  if (deepSearchKey(parsed, UPDATED)) {
                    processPayload(parsed.payload[query].updated, UPDATED);
                  }

                  if (deepSearchKey(parsed, REMOVED)) {
                    processPayload(parsed.payload[query].removed, REMOVED);
                  }
                }
              });
            }
          });
        }
      };
    };

    this.connection.onclose = (event) => {
      this.reconnectWithTimeout();
    };

    this.connection.onerror = (event) => {
      this.reconnectWithTimeout();
    };

    return this.connection;
  }

  static reconnectTimer: NodeJS.Timeout | null = null;

  static reconnectWithTimeout() {
    if (!this.reconnectTimer) {
      // set the timer only once per error or onclose event
      if (this.reconnectCounter < RECONNECT_ATTEMPTS1) {
        if (this.reconnectCounter > RECONNECT_NOTIFY)
          store.dispatch(setConnectionError(1)); // shows error to the user
        // console.log('setting timer 1');
        this.reconnectTimer = setTimeout(() => {
          this.reconnectCounter++;
          this.reconnectTimer = null;
          this.connect();
        }, RECONNECT_DELAY1);
      } else if (this.reconnectCounter < RECONNECT_ATTEMPTS2) {
        store.dispatch(setConnectionError(2));
        // console.log('setting timer 2');
        this.reconnectTimer = setTimeout(() => {
          this.reconnectCounter++;
          this.reconnectTimer = null;
          this.connect();
        }, RECONNECT_DELAY2);
      } else {
        store.dispatch(setConnectionError(3));
        // console.log('setting timer 3');
        this.reconnectTimer = setTimeout(() => {
          this.reconnectCounter++;
          this.reconnectTimer = null;
          this.connect();
        }, RECONNECT_DELAY3);
      }
    }
  }

  static connect() {
    this.setupConnection();
  }

  static connectAndReturn() {
    return this.setupConnection();
  }

  static disconnect() {
    this.connection.close();
    console.log('disconnect() --> Connection closed!');

    clearInterval(this.heartbeatInterval);
  }

  static addOnMessageCallbackQueries(
    query: string,
    callback: (payload: any, change: string) => void
  ) {
    this.onMessageCallbacksQueries.push({ query, callback });
  }

  static addOnMessageCallbackNonQueries(
    callback: (payload: any) => void,
    roomId: string
  ) {
    this.onMessageCallbacksNonQueries.push({ callback, roomId });
  }

  static pingPong() {
    this.heartbeatInterval = setInterval(() => {
      if (this.connection.readyState !== 1) return;

      console.log(
        `AsyncAPI sending heartbeat - pinging to server - ${currentTime()}`
      );
      this.connection.send('ping');
    }, 60_000); // 60k = 1 minute, 30k = 30 seconds
  }

  static requestId() {
    this.connection.send('id_request');
  }

  static doMessage(query: string) {
    if (this.connection.readyState !== 1) {
      console.log(
        'doMessageQueries() connection is not ready, readyState is not on 1'
      );
      return;
    }

    if (!(query in this.listeningTo)) this.listeningTo[query] = 0;

    if (this.listeningTo[query] === 0)
      this.connection.send(
        JSON.stringify({
          event: 'queries',
          payload: { connect: true, data: query },
        })
      );
    this.listeningTo[query]++;

    /*
    console.log(
      'doMessageQueries() --> Query listener created on query: ',
      query
    );
    console.log(
      'JSON.stringify: ',
      JSON.stringify({
        event: 'queries',
        payload: { connect: true, data: query },
      })
    );
    */
  }

  static doMessageDisconnect(query: string) {
    if (this.connection.readyState !== 1) {
      console.log(
        'doMessageQueries() connection is not ready, readyState is not on 1'
      );
      return;
    }

    if (!(query in this.listeningTo) || this.listeningTo[query] === 0)
      throw new Error('Cannot disconnect if not connected');

    this.listeningTo[query]--;

    if (this.listeningTo[query] === 0)
      this.connection.send(
        JSON.stringify({
          event: 'queries',
          payload: { connect: false, data: query },
        })
      );

    /*
    console.log(
      'doMessageQueries() --> Query listener created on query: ',
      query
    );
    console.log(
      'JSON.stringify: ',
      JSON.stringify({
        event: 'queries',
        payload: { connect: false, data: query },
      })
    );
    */
  }

  static joinRoom(eventType: EventType, roomId: string) {
    this.connection.send(
      JSON.stringify({
        event: eventType,
        payload: {
          event: 'joinRoom',
          roomId,
        } as AsyncMessage['payload']['out'],
      })
    );
  }

  static leaveRoom(eventType: EventType, roomId: string) {
    this.connection.send(
      JSON.stringify({
        event: eventType,
        payload: {
          event: 'leaveRoom',
          roomId,
        } as AsyncMessage['payload']['out'],
      })
    );
  }

  static doMessageDoc(roomId: string, event: DocumentEvent, payload: any) {
    this.connection.send(
      JSON.stringify({
        event: 'documents',
        payload: {
          event: 'inner',
          roomId,
          inner: {
            event,
            payload,
          },
        } as DocumentsMessage['payload']['out'],
      })
    );
  }

  static doMessageCall(roomId: string, event: CallingEvent, payload: any) {
    this.connection.send(
      JSON.stringify({
        event: 'calling',
        payload: {
          event: 'inner',
          roomId,
          inner: {
            event,
            payload,
          },
        } as CallingMessage['payload']['out'],
      })
    );
  }
}
