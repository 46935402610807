// https://twitter.com/WrocTypeScript/status/1306296710407352321
export type TupleUnion<U extends { [s: string]: any }, R extends any[] = []> = {
  [S in keyof U]: Exclude<keyof U, S> extends never
    ? [...R, S]
    : TupleUnion<{ [ss in Exclude<keyof U, S>]: string }, [...R, S]>;
}[keyof U];

// https://fettblog.eu/typescript-array-includes/
export function includes<T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U
): el is T {
  return coll.includes(el as T);
}
