import React, { useEffect, useState } from 'react';

import { inScreenPortion } from 'helpers/helpers';
import './ToolHint.scss';

export type ToolHintProps = {
  hint: string | JSX.Element;
  offset?: { x?: number; y?: number };
  offsetRight?: boolean;
  offsetBottom?: boolean;
  width?: number;
};
type ToolRefProp = {
  toolRef: React.RefObject<HTMLElement>;
};

export function ToolHint(
  props: React.PropsWithChildren<ToolHintProps & ToolRefProp>
): JSX.Element {
  const {
    hint,
    offset,
    offsetRight = false,
    offsetBottom = false,
    toolRef,
    width,
  } = props;
  const { x = -32, y = 0 } = offset ?? {};
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const fromBottom = offsetBottom || inScreenPortion(toolRef.current, -130);

  useEffect(() => {
    const tool = toolRef.current;
    if (tool) {
      tool.addEventListener('mouseover', handleMouseOver);
      tool.addEventListener('mouseleave', handleMouseLeave);
    } else console.log('no tool found');
    return () => {
      if (tool) {
        tool.removeEventListener('mouseover', handleMouseOver);
        tool.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [toolRef]);

  function handleMouseOver(e: MouseEvent): void {
    setShowTooltip(true);
  }

  const handleMouseLeave = (): void => {
    setShowTooltip(false);
  };

  return (
    <>
      {showTooltip ? (
        <div
          className={'toolhint'}
          style={{
            left: offsetRight ? undefined : x,
            right: offsetRight ? x : undefined,
            top: fromBottom ? undefined : y,
            bottom: fromBottom ? y : undefined,
            width: width ? 'min-content' : undefined,
            // minWidth: 80,
            maxWidth: width,
            // overflowWrap: width ? 'anywhere' : undefined,
            whiteSpace: width ? 'normal' : 'nowrap',
          }}
        >
          {hint}
        </div>
      ) : null}
    </>
  );
}
