// swaps two elements in a copy of the given array
export function swapArrayElements<T>(arr: T[], i1: number, i2: number): T[] {
  return swapArrayElementsMutate([...arr], i1, i2);
}
// swaps two elements and mutates the given array
export function swapArrayElementsMutate<T>(
  arr: T[],
  i1: number,
  i2: number
): T[] {
  if (i1 < 0 || i1 >= arr.length || i2 < 0 || i2 >= arr.length) {
    throw new Error('Invalid indices (out of range)');
  }
  console.log('arr-: ', JSON.stringify(arr));
  [arr[i1], arr[i2]] = [arr[i2], arr[i1]];
  console.log('arr+: ', JSON.stringify(arr));
  return arr;
}

// moves an element in a copy of the given array
export function moveArrayElement<T>(arr: T[], from: number, to: number): T[] {
  return moveArrayElementMutate([...arr], from, to);
}
// moves an element and mutates the given array
export function moveArrayElementMutate<T>(
  arr: T[],
  from: number,
  to: number
): T[] {
  if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
    throw new Error('Invalid indices (out of range)');
  }
  const [removedElement] = arr.splice(from, 1);
  arr.splice(to, 0, removedElement);
  return arr;
}

// moves an item from one array to another, mutating both arrays
// the two arrays can be the same
export function moveItemFromTo<T>(
  arrayFrom: T[],
  from: number,
  arrayTo: T[],
  to: number
): void {
  if (from < 0 || from >= arrayFrom.length || to < 0 || to >= arrayTo.length) {
    throw new Error('Invalid indices (out of range)');
  }
  const [removedElement] = arrayFrom.splice(from, 1);
  arrayTo.splice(to, 0, removedElement);
}

// functions for random elements in array
export function shuffleArray<T>(array: T[]): T[] {
  const arrayCopy = [...array];
  for (let i = arrayCopy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
  }
  return arrayCopy;
}

export function getRandomElements<T>(arr: T[], n: number = 1): T[] {
  const shuffled = arr.sort(() => Math.random() - 0.5);
  return shuffled.slice(0, n);
}

export function getRandomIndices<T>(arr: T[], n: number = 1): number[] {
  const ind = arr.map((_, i) => i);
  const shuffled = ind.sort(() => Math.random() - 0.5);
  return shuffled.slice(0, n);
}
