import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { prepareForBackend } from 'helpers/objects';

export type IUiState = API['UiState']['read'];
export type IUiStateUpdate = API['UiState']['update'];
export type IUiStateCreate = API['UiState']['create'];

function prepareForUpdate(ui: IUiState): IUiStateUpdate {
  const updated = prepareForBackend(ui, ['user']) as IUiStateUpdate;
  return updated;
}

function prepareForCreate(ui: IUiStateCreate): IUiStateCreate {
  return prepareForBackend(ui, ['user']) as IUiStateCreate;
}

export async function postUiState(data: IUiStateCreate) {
  try {
    const uiData = prepareForCreate(data);
    const response = await api.post('UiState', uiData);
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function patchUiState(data: IUiState) {
  try {
    const response = await api.patch(
      'UiState/' + data.id,
      prepareForUpdate(data)
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}
