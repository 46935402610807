import classNames from 'classnames';

import Icon, { IconSymbol } from 'components/icons/Icon';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import { IUser } from 'features/user/userAPI';

type ModeratorIconProps = {
  user: IUser;
  moderators?: IUser[];
  dialogue?: IDialogue;
  // setModerators: Dispatch<SetStateAction<IUser[]>>;
  onChange?: (user: IUser, checked: boolean, dialogue?: IDialogue) => void;
};

export function ModeratorIcon(props: ModeratorIconProps) {
  const { user, dialogue, moderators, onChange } = props;
  const isDialogueIncluded = dialogue
    ? (user.moderatesDialogues || []).some((d) => d.id === dialogue.id)
    : moderators
    ? moderators.some((m) => m.id === user.id)
    : undefined;

  return (
    <Icon
      className={classNames('user_moderator', {
        is_moderator: isDialogueIncluded,
      })}
      symbol={
        isDialogueIncluded ? IconSymbol.moderator : IconSymbol.not_moderator
      }
      onClick={
        onChange
          ? () => {
              onChange(user, !isDialogueIncluded, dialogue);
            }
          : undefined
      }
    />
  );
}
