import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { IPhase, IPhaseCreate, patchPhaseData } from './phaseAPI';

export const emptyPhase: IPhaseCreate = {
  name: '',
  order: '',
  description: '',
  dialogue: 0,
  blocks: [],
  active: false,
};

export const patchPhaseAsync = createAsyncThunk(
  'phase/patchPhase',
  async (
    {
      data,
      stateMan,
    }: {
      data: IPhase;
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      return { response: await patchPhaseData(data), stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export function addPhaseCases(
  builder: ActionReducerMapBuilder<IDialogueState>
) {
  builder
    .addCase(patchPhaseAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchPhaseAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      let ph: IPhase;
      if (stateObj.hasOwnProperty('phases')) {
        const phs = stateObj.phases;
        ph = phs.find((m: any) => m.id === action.meta.arg.data.id);
      } else ph = stateObj;
      Object.assign(ph, action.meta.arg.data);
    })
    .addCase(patchPhaseAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });
}
