import { IS_DEV } from './consts';

export function assertDate(d: Date): Date {
  const date = new Date(d);
  if (isNaN(date.getTime()))
    throw new Error('invalid date given: ' + d.toString());
  return date;
}

export function assertDates(...dd: Date[]): Date[] {
  return dd.map((d) => assertDate(d));
}

export function onSameDay(date1: Date, date2?: Date) {
  try {
    if (typeof date2 === 'undefined') return false;
    const [d1, d2] = assertDates(date1, date2);
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  } catch (error) {
    IS_DEV && console.log('Invalid date passed. ' + error);
    return false;
  }
}

export function inSameYear(date1: Date, date2?: Date): boolean {
  try {
    if (typeof date2 === 'undefined') return false;
    const [d1, d2] = assertDates(date1, date2);
    return d1.getFullYear() === d2.getFullYear();
  } catch (error) {
    IS_DEV && console.log('Invalid date passed. ' + error);
    return false;
  }
}

export function similarTime(
  date1: Date,
  date2?: Date,
  maxSeconds: number = 300
): boolean {
  try {
    if (typeof date2 === 'undefined') return false;
    const [d1, d2] = assertDates(date1, date2);
    if (!onSameDay(d1, d2)) return false;
    const maxms = maxSeconds * 1000;
    const diff = Math.abs(d1.getTime() - d2.getTime());
    return diff <= maxms;
  } catch (error) {
    IS_DEV && console.log('Invalid date/time passed. ' + error);
    return false;
  }
}

export function isTimeZero(date: Date): boolean {
  try {
    const [d] = assertDates(date);
    return !(d.getHours() + d.getMinutes());
  } catch (error) {
    IS_DEV && console.log('Invalid date/time passed. ' + error);
    return false;
  }
}
