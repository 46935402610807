import { DragUpdate, DropResult, ResponderProvided } from '@hello-pangea/dnd';
import {
  Document as PDFDocument,
  Page,
  Text,
  View,
  usePDF,
} from '@react-pdf/renderer';
import {
  ISectionOptions,
  Packer,
  Paragraph,
  Document as WordDocument,
} from 'docx';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import {
  useBeforeUnload,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { AsyncAPI } from 'app/AsyncAPI/AsyncAPI';
import { useAppDispatch } from 'app/hooks';
import { store } from 'app/store';
import MeetingGallery from 'components/blocks/MeetingGallery';
import { CollapsableContainer } from 'components/collapsable/Collapsable';
import AbsoluteContent from 'components/layout/AbsoluteContent';
import AddToolMenu from 'components/navigation/AddToolMenu';
import SortableContainer from 'components/sortables/SortableContainer';
import { useSortableContext } from 'components/sortables/SortableContext';
import { SortableItemType } from 'components/sortables/SortableList';
import {
  AppSettingsStateProps,
  isToolAvailable,
  mapAppSettingsStateToProps,
} from 'features/admin/appSettingsSlice';
import { BlockType, listIs, patchBlockAsync } from 'features/block/blockSlice';
import { IDialogue } from 'features/dialogue/dialogueAPI';
import {
  DialogueStateProps,
  asyncApiDialoguesNew,
  asyncApiDialoguesRemoved,
  asyncApiDialoguesUpdated,
  dialogueActions,
  fetchDialogueAsync,
  mapDialogueStateToProps,
  resetDialogue,
} from 'features/dialogue/dialogueSlice';
import { IList } from 'features/list/listAPI';
import { IListItem } from 'features/list/listItemAPI';
import {
  patchListItemAsync,
  postListItemAsync,
} from 'features/list/listItemSlice';
import { IMessage } from 'features/message/messageAPI';
import { IPhase } from 'features/phase/phaseAPI';
import { IOption, IPoll } from 'features/poll/pollAPI';
import { patchOptionAsync, postOptionAsync } from 'features/poll/pollSlice';
import {
  getPhaseStates,
  persistCloseDialogues,
  persistOpenDialogue,
} from 'features/uiState/uiStateSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';
import saveAs from 'file-saver';
import { Retrieving, constructURL } from 'helpers/apiTypes';
import { datesToDocx, downloadPdf, sanitizeFileName } from 'helpers/export';
import { deepFindObject, reFindObject } from 'helpers/finders';
import { AnyOrderObject, omitProps, sameId } from 'helpers/objects';
import { isConsideredLeft, xyPosition } from 'helpers/positions';
import { getOrderUsingIndex, sortByOrder } from 'helpers/sorting';
import Phase, { PhaseToPdf, phaseToDocx } from './Phase';

import docxStyles from '../../css/docxStyles';
import pdfStyles from '../../css/pdfStyles';
import './DialogueView.scss';

type DialogueViewProps = {
  tryout?: boolean;
};

function UnconnectedDialogueView(
  props: DialogueViewProps &
    UserStateProps &
    DialogueStateProps &
    AppSettingsStateProps
) {
  const { user, userId, userIsManager, dialogue, tryout, settings } = props;
  const { dialogueId } = useParams();
  const dispatch = useAppDispatch();
  const { getDragItemType } = useSortableContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !userIsManager &&
      dialogue &&
      dialogue.id &&
      (!dialogue.subscribers ||
        !dialogue.subscribers?.map((s) => s.id).includes(userId))
    ) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogue]);

  useEffect(() => {
    let id =
      tryout && settings.rights.guestTryout
        ? settings.rights.tryoutDialogue
        : dialogueId;
    if (id) {
      dispatch(fetchDialogueAsync(+id));
      if (userId) dispatch(persistOpenDialogue({ data: { o: [+id] } }));
    }
  }, [dispatch, dialogueId, userId, tryout, settings]);

  const handleCloseDialogue = useCallback(() => {
    const s = store.getState();
    if (s) {
      const d = s.dialogue.dialogue;
      if (d.id) {
        let blockIds: number[] = [];
        let phaseIds: number[] = [];
        const phStates = getPhaseStates(d);
        d.phases?.forEach((p: IPhase) => {
          const ps = phStates.find((s) => s[0] === p.id);
          if (ps && ps[1]) {
            phaseIds.push(ps[0]);
            if (p.blocks)
              blockIds = blockIds.concat(p.blocks.map((b) => b.id ?? 0));
          }
        });
        dispatch(
          persistCloseDialogues({
            data: {
              o: [d.id],
              p: phaseIds,
              b: blockIds,
            },
          })
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    // Cleanup when user navigates away to another route within the app
    return () => {
      handleCloseDialogue();
      dispatch(resetDialogue());
    };
    // Cannot add handleCloseDialogue as dependency, because
    // its definition will trigger the cleanup.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Cleanup when user navigates away to another url
  useBeforeUnload(
    React.useCallback(() => {
      handleCloseDialogue();
      dispatch(resetDialogue());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  useEffect(() => {
    // Cleanup when user navigates away to another site or
    // when the window/tab gets closed.
    // Note that this does not happen when using the
    // browser's back button (the 'popstate' event).
    // This is by design (for now): the unread state
    // of a dialogue remains unchanged.
    window.addEventListener('beforeunload', handleCloseDialogue);
    return () => {
      window.removeEventListener('beforeunload', handleCloseDialogue);
    };
  }, [handleCloseDialogue]);

  function reorderItems(
    result: DropResult,
    newPos: xyPosition,
    isCopying: boolean
  ) {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    const dragItemType = getDragItemType();
    const from = deepFindObject(draggableId, source.droppableId, dialogue);
    const to = deepFindObject('', destination.droppableId, dialogue);
    if (!from || !from.block || !to) return;
    switch (dragItemType) {
      case SortableItemType.Block: // moving a Block in an Phase
        if (!from.phase || !to.phase) return;
        // if we're not moving...
        if (source.index === destination.index && sameId(from.phase, to.phase))
          return;
        // we first move the block in the redux store
        dispatch(
          dialogueActions.moveBlock({
            toPhaseId: to.phase.id as number,
            data: from.block,
            from: from,
            fromIndex: source.index,
            to: to,
            toIndex: destination.index,
            stateMan,
          })
        );
        // then we find it again in the store (with the new order attribute)
        const newBlockData = deepFindObject(
          result.draggableId,
          result.destination.droppableId,
          store.getState().dialogue.dialogue
        );
        // then we patch it to the backend
        if (newBlockData && newBlockData.block)
          dispatch(
            patchBlockAsync({ data: newBlockData.block, stateMan })
          ).then((response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                // console.log('Blocks updated');
                break;
              case 500:
              default:
                // TODO: need a better action here, possibly force reload.
                console.log(
                  'Block not updated due to internal error. Please reload the page.'
                );
                break;
            }
          });
        break;
      case SortableItemType.Item: // moving a ListItem in a List
        if (!from.list || !from.object || !to.list) return;
        // if we're not moving, but just dropping in the same place
        if (
          source.index === destination.index &&
          sameId(from.list, to.list) &&
          !(to.list.parent && listIs(to.list.parent).ProCon)
        )
          return;
        if (isCopying) {
          // copying the item
          const order = getOrderUsingIndex(
            sortByOrder(
              (to.list as IList).listItems?.filter(
                (item) => !item.pinned
              ) as AnyOrderObject[]
            ),
            -1, // -1 indicates moving between lists
            destination.index
          );
          const msg = (from.object as IListItem).message;
          if (!msg) return;
          dispatch(
            postListItemAsync({
              data: {
                content: msg.content as string, // content is copied
                edited: false, // edited is reset for the copy
                time: new Date(), // new time set for the copy
                author: userId, // the copying user becomes the author
                replyTo: null, // replyTo is not copied
                checked: false, // checked is not copied
                color: msg.color || 'none', // color is copied
                likes: [], // likes are not copied
                replies: [], // replies are not copied
                files: msg.files as any, // files are copied
                // files: msg.files ? msg.files.map((f) => f.id as number) : [], // files are copied
              },
              listId: to.list.id as number,
              order: order,
              alt:
                to.list.parent && listIs(to.list.parent).ProCon
                  ? isConsideredLeft(newPos)
                    ? 'pro'
                    : 'con'
                  : null,
              author: user,
              stateMan: stateMan,
            })
          ).then((response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                // console.log('List item duplicated');
                break;
              case 500:
              default:
                // TODO: need a better action here, possibly force reload.
                console.log(
                  'List item not duplicated due to internal error. Please reload the page.'
                );
                break;
            }
          });
        } else {
          // from here we're moving, not copying
          const data: IListItem = {
            ...from.object,
            alt:
              to.list.parent && listIs(to.list.parent).ProCon
                ? isConsideredLeft(newPos)
                  ? 'pro'
                  : 'con'
                : null,
          };
          // set the new order attribute
          data.order = getOrderUsingIndex(
            sortByOrder(
              (to.list as IList).listItems?.filter(
                (item) => !item.pinned
              ) as AnyOrderObject[]
            ),
            sameId(from.list, to.list) ? source.index : -1, // -1 if moving between lists
            destination.index
          );
          // we first move the item in the redux store
          dispatch(
            dialogueActions.moveListItem({
              from: from,
              fromIndex: source.index,
              to: to,
              toIndex: destination.index,
              data: data,
              stateMan,
            })
          );
          // then we find it again in the store (with the new order attribute)
          to.object = from.object;
          const newItemData = reFindObject(
            to,
            store.getState().dialogue.dialogue
          );
          // then we patch it to the backend
          if (newItemData && newItemData.object) {
            dispatch(
              patchListItemAsync({
                data: omitProps(newItemData.object, ['message', 'pinned']),
                stateMan,
              })
            ).then((response: { payload: any }) => {
              switch (response.payload?.response.status) {
                case 200:
                case 201:
                  // console.log('List item updated');
                  break;
                case 500:
                default:
                  // TODO: need a better action here, possibly force reload.
                  console.log(
                    'List item not updated due to internal error. Please reload the page.'
                  );
                  break;
              }
            });
          }
        }
        break;
      case SortableItemType.Option: // moving a Poll Option
        if (!from.list || !from.object || !to.list) return;
        // if we're not moving, but just dropping in the same place
        if (source.index === destination.index && sameId(from.list, to.list))
          return;
        if (isCopying) {
          // copying the item
          const order = getOrderUsingIndex(
            sortByOrder((to.list as IPoll).options as AnyOrderObject[]),
            -1, // -1 indicates moving between lists
            destination.index
          );
          const msg = (from.object as IListItem).message;
          if (!msg) return;
          dispatch(
            postOptionAsync({
              data: {
                content: msg.content as string, // content is copied
                edited: false, // edited is reset for the copy
                time: new Date(), // new time set for the copy
                author: userId, // the copying user becomes the author
                replyTo: null, // replyTo is not copied
                checked: false, // checked is not copied
                color: msg.color || 'none', // color is copied
                likes: [], // likes are not copied
                replies: [], // replies are not copied
                files: msg.files as any, // files are copied
                // files: msg.files ? msg.files.map((f) => f.id as number) : [], // files are copied
              },
              pollId: to.list.id as number,
              order: order,
              author: user,
              stateMan: stateMan,
            })
          ).then((response: { payload: any }) => {
            switch (response.payload?.response.status) {
              case 200:
              case 201:
                // console.log('Option duplicated');
                break;
              case 500:
              default:
                // TODO: need a better action here, possibly force reload.
                console.log(
                  'Option not duplicated due to internal error. Please reload the page.'
                );
                break;
            }
          });
        } else {
          // from here we're moving, not copying
          const data: IOption = {
            ...from.object,
          };
          // set the new order attribute
          data.order = getOrderUsingIndex(
            sortByOrder((to.list as IPoll).options as AnyOrderObject[]),
            sameId(from.list, to.list) ? source.index : -1, // -1 if moving between lists
            destination.index
          );
          // we first move the item in the redux store
          dispatch(
            dialogueActions.moveOption({
              from: from,
              fromIndex: source.index,
              to: to,
              toIndex: destination.index,
              data: data,
              stateMan,
            })
          );
          // then we find it again in the store (with the new order attribute)
          to.object = from.object;
          const newItemData = reFindObject(
            to,
            store.getState().dialogue.dialogue
          );
          // then we patch it to the backend
          if (newItemData && newItemData.object) {
            dispatch(
              patchOptionAsync({ data: newItemData.object, stateMan })
            ).then((response: { payload: any }) => {
              switch (response.payload?.response.status) {
                case 200:
                case 201:
                  // console.log('List item updated');
                  break;
                case 500:
                default:
                  // TODO: need a better action here, possibly force reload.
                  console.log(
                    'List item not updated due to internal error. Please reload the page.'
                  );
                  break;
              }
            });
          }
        }
        break;
      case SortableItemType.Message: // copying a Chat message (to a List)
        if (!from.object || !to.list) return;
        // if (to.lists)
        //   to.list = to.lists[!newPos || isConsideredLeft(newPos) ? 0 : 1];
        // const order = getOrderUsingIndex(
        //   sortByOrder(to.list.listItems as AnyOrderObject[]),
        //   -1, // -1 indicates moving between lists
        //   destination.index
        // );
        const order = getOrderUsingIndex(
          // TODO: the code below is assuming we're moving to a list,
          // but we could be moving to a poll as well!
          sortByOrder((to.list as IList).listItems as AnyOrderObject[]),
          -1, // -1 indicates moving between lists
          destination.index
        );
        const msg = from.object as IMessage;
        if (!msg) return;
        dispatch(
          postListItemAsync({
            data: {
              content: msg.content as string, // content is copied
              edited: false, // edited is reset for the copy
              time: new Date(), // new time set for the copy
              author: userId, // the copying user becomes the author
              replyTo: null, // replyTo is not copied
              checked: false, // checked is not copied
              color: msg.color || 'none', // color is copied
              likes: [], // likes are not copied
              replies: [], // replies are not copied
              files: msg.files as any, // files are copied
              // files: msg.files ? msg.files.map((f) => f.id as number) : [], // files are copied
            },
            listId: to.list.id as number,
            order: order,
            author: user,
            stateMan: stateMan,
          })
        ).then((response: { payload: any }) => {
          switch (response.payload?.response.status) {
            case 200:
            case 201:
              // console.log('List item duplicated');
              break;
            case 500:
            default:
              // TODO: need a better action here, possibly force reload.
              console.log(
                'List item not duplicated due to internal error. Please reload the page.'
              );
              break;
          }
        });
        break;
      default:
        break;
    }
  }

  function onDragUpdate(update: DragUpdate, provided: ResponderProvided) {}

  function stateMan(obj: any): any {
    return obj?.dialogue;
  }

  // const asyncAPIQuery = `Dialogue/${dialogueId}?and=*,phases.*`;
  const asyncAPIQuery = `Dialogue/${dialogueId}?and=${constructURL({
    '*': true,
    subscribers: {
      id: true,
      username: true,
    },
    moderators: {
      id: true,
      username: true,
    },
    background: {
      '*': true,
    },
    phases: {
      '*': true,
      blocks: {
        '*': true,
        childChatBlock: {
          '*': true,
        },
        childListBlock: {
          '*': true,
        },
        childSudokuBlock: {
          '*': true,
        },
      },
    },
  } as const satisfies Retrieving<'Dialogue'>)}`;

  const [webSocket] = useState(AsyncAPI.connection);

  useEffect(() => {
    if (webSocket == null) return;

    console.log(`Main useEffect called! (Dialogue) - id: ${dialogueId}`);

    if (webSocket?.readyState !== 1) {
      console.log(
        'useEffect() main (Dialogue) --> connection is not ready, readyState is not on 1'
      );
      return;
    }
    AsyncAPI.doMessage(asyncAPIQuery);

    const onMessageCallback = (payload: any, change: string) => {
      switch (change) {
        case 'new':
          dispatch(asyncApiDialoguesNew({ data: payload }));
          break;
        case 'updated':
          /*
          Important!:
          Here we are checking if the incoming AsyncAPI payload (data) is a new Block. If so, we wait 500ms.
          The timeout is there to make sure that the newly added Block via the slice is succesfully added before we do any AsyncAPI logic on it.
          Without this timeout there is a chance for duplicate Blocks when adding Blocks (due to race conditions).
          If the incoming AsyncAPI payload (data) is not a new Block, we simply continue normally.
          */
          const block =
            payload?.updated?.phases?.updated?.[0]?.updated?.blocks?.new?.[0];

          if (block == null) {
            // Not adding Blocks... Continuing normally, no timeout.
            dispatch(asyncApiDialoguesUpdated({ data: payload }));
          } else {
            // Adding Blocks... Not continuing normally, 500ms timeout.
            setTimeout(() => {
              dispatch(asyncApiDialoguesUpdated({ data: payload }));
            }, 500);
          }
          break;
        case 'removed':
          dispatch(asyncApiDialoguesRemoved({ data: payload }));
          break;
      }
    };

    AsyncAPI.addOnMessageCallbackQueries(asyncAPIQuery, onMessageCallback);

    return () => {
      if (webSocket?.readyState !== 1) {
        console.log(
          'useEffect() return (Dialogue) --> connection is not ready, readyState is not on 1'
        );
        return;
      }
      AsyncAPI.doMessageDisconnect(asyncAPIQuery);

      const index = AsyncAPI.onMessageCallbacksQueries.findIndex(
        ({ callback }) => callback === onMessageCallback
      );

      if (index !== -1) {
        AsyncAPI.onMessageCallbacksQueries.splice(index, 1);
      }
    };
    // Check if webSocket is not null, before using the readyState for the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, webSocket != null ? webSocket?.readyState : null]);

  const fixedPhases = sortByOrder(
    (dialogue.phases?.filter((ph) => ph.fixed) || []) as AnyOrderObject[]
  );
  const sortedPhases = sortByOrder(
    (dialogue.phases?.filter((ph) => !ph.fixed) || []) as AnyOrderObject[]
  );
  return (
    <>
      <AddToolMenu stateMan={stateMan} />
      <div id="dialogue_container" className="dialogue_container">
        <AbsoluteContent />
        <CollapsableContainer className="phases">
          <SortableContainer reorder={reorderItems} onDragUpdate={onDragUpdate}>
            {dialogue.phases?.length ? (
              <>
                {fixedPhases.map((phase: IPhase, i) => {
                  return (
                    <Phase
                      key={phase.order}
                      index={i}
                      stateMan={stateMan}
                      dialogue={dialogue}
                      phase={phase}
                    />
                  );
                })}
                {sortedPhases.map((phase: IPhase, i) => {
                  return (
                    <Phase
                      key={phase.order}
                      index={i}
                      hideIndex={sortedPhases.length === 1}
                      stateMan={stateMan}
                      dialogue={dialogue}
                      phase={phase}
                    />
                  );
                })}
              </>
            ) : dialogue.id ? (
              <FormattedMessage id="DIALOGUE.VIEW.NO_PHASES" />
            ) : (
              <FormattedMessage id="DIALOGUE.VIEW.LOADING" />
            )}
          </SortableContainer>
        </CollapsableContainer>
        {isToolAvailable(BlockType.Meeting) ? (
          <MeetingGallery
            block={{
              order: '1',
              name: 'Meeting',
              description: 'This is where we meet',
              phase: undefined,
              childType: BlockType.Meeting,
              id: dialogue.id,
            }}
            showDescription={false}
            onUpdate={() => {}}
            stateMan={() => {}}
          />
        ) : null}
      </div>
    </>
  );
}

const DialogueView = connect(mapDialogueStateToProps)(
  connect(mapUserStateToProps)(
    connect(mapAppSettingsStateToProps)(UnconnectedDialogueView)
  )
);
export default DialogueView;

type DialogueExportProps = {
  dialogue: IDialogue;
  intl: IntlShape;
};

export function DialogueToPdfLink(props: DialogueExportProps) {
  const { dialogue, intl } = props;
  const [pdf, setPdf] = usePDF({ document: <PDFDocument /> });
  const [pdfDownloadReady, setPdfDownloadReady] = useState<boolean>(false);

  useEffect(() => {
    if (pdfDownloadReady && pdf.url && pdf.blob && !pdf.loading) {
      downloadPdf(pdf, dialogue.title ?? 'unnamed');
      setPdfDownloadReady(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf, pdfDownloadReady]);

  return (
    <div
      onClick={() => {
        const newDoc = (
          <PDFDocument pdfVersion="1.3">
            {DialogueToPdf({ dialogue, intl })}
          </PDFDocument>
        );
        setPdf(newDoc);
        setPdfDownloadReady(true);
      }}
    >
      <FormattedMessage id="X.TOPDF" />
    </div>
  );
}

export function DialogueToPdf(props: DialogueExportProps): React.ReactElement {
  const { dialogue, intl } = props;
  let content: React.ReactElement | React.ReactElement[] = (
    <Page style={pdfStyles.page}>
      <View style={pdfStyles.description}>
        <Text>No content</Text>
      </View>
    </Page>
  );
  if (dialogue.phases) {
    content = [];
    const fixedPhases = true
      ? [] // for now, skip fixed phases. TODO: reconsider this
      : sortByOrder(
          (dialogue.phases?.filter((ph) => ph.fixed) || []) as AnyOrderObject[]
        );
    const sortedPhases = sortByOrder(
      (dialogue.phases?.filter((ph) => !ph.fixed) || []) as AnyOrderObject[]
    );
    for (const [index, phase] of fixedPhases.concat(sortedPhases).entries()) {
      const s = (
        <PhaseToPdf
          key={index}
          dialogue={dialogue}
          phase={phase}
          intl={intl}
          index={index}
        />
      );
      if (s) content.push(s);
    }
  }
  return (
    <>
      <Page style={pdfStyles.page}>
        <View style={pdfStyles.title}>
          <Text>{dialogue.title ?? 'Unnamed dialogue'}</Text>
        </View>
        {dialogue.startedAt ? (
          <View style={pdfStyles.date}>
            <Text>
              {intl.formatDate(dialogue.startedAt, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
              {dialogue.closedAt ? (
                <>
                  {' - '}
                  {intl.formatDate(dialogue.closedAt, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })}
                </>
              ) : null}
            </Text>
          </View>
        ) : null}
        <View style={pdfStyles.description}>
          <Text>{dialogue.description}</Text>
        </View>
      </Page>
      {content}
    </>
  );
}

export function DialogueToDocxLink(props: DialogueExportProps) {
  const { dialogue, intl } = props;
  return (
    <div
      onClick={async () => {
        let sections: ISectionOptions[] = [
          {
            properties: {},
            children: [
              new Paragraph({
                text: dialogue.title ?? 'Unnamed dialogue',
                style: 'Title',
              }),
              datesToDocx(intl, dialogue.startedAt, dialogue.closedAt) ?? {},
              dialogue.description
                ? new Paragraph({
                    text: dialogue.description,
                    style: 'description',
                  })
                : {},
            ],
          },
        ] as ISectionOptions[];
        if (dialogue.phases) {
          const fixedPhases = true
            ? [] // for now, skip fixed phases. TODO: reconsider this
            : sortByOrder(
                (dialogue.phases?.filter((ph) => ph.fixed) ||
                  []) as AnyOrderObject[]
              );
          const sortedPhases = sortByOrder(
            (dialogue.phases?.filter((ph) => !ph.fixed) ||
              []) as AnyOrderObject[]
          );
          for (const [index, phase] of fixedPhases
            .concat(sortedPhases)
            .entries()) {
            const s = await phaseToDocx({ dialogue, phase, intl, index });
            sections.push(...s);
          }
        }
        const newDoc = new WordDocument({
          styles: { ...docxStyles },
          sections: sections,
        });
        Packer.toBlob(newDoc).then((blob) => {
          const fn = dialogue.title ?? 'Unnamed dialogue';
          const filename = sanitizeFileName(fn);
          saveAs(blob, `${filename}.docx`);
        });
      }}
    >
      <FormattedMessage id="X.TODOCX" />
    </div>
  );
}
