import { asterisk, AttributePath, Model } from './baseTypes.js';
import { apiBase, ID } from './index.js';

export const FilterOperations = {
  '=': (condition: any) => condition,
  '>': (condition: any) => ({
    gt: condition,
  }),
  '<': (condition: any) => ({
    lt: condition,
  }),
} as const;

export declare type FilterOperation = keyof typeof FilterOperations;

export type Filter = {
  attribute: string;
  condition: string;
  operator: FilterOperation;
};

// Before<Dialogue> -> heeft geen subscriberCount
// After<Dialogue> -> heeft wel subscriberCount
// Before<Project> -> ...

// where with []
// join with .

// modelName.related1 => ids of related1 are loaded into modelName
// modelName.related1.* => all primitives of related1 are loaded into modelName

// modelName.at1.(*,at2.(*,at3))
// modelName.at1.at2.at3
/*

{
  modelData,
  at1: {
    at1data,
    at2: {
      at2data,
      at3: { id: int }
    }
  }
}

*/
// modelName.at1.at2.(*,.at3)

// URL form: /api/modelName?and=.related.(relatedDeeper,relatedDeeper2.*),.related2
// Query form: modelName.(related.(relatedDeeper,relatedDeeper2.*),related2)
// Query form WRONG: modelName.related1,modelName.related2
// Query form WRONG: modelName.(*,.related1),modelName.related2
// Query form correct: modelName[id=4][id=5]
// '*' only at the end => becomes a null
// example 1 "/users/123?and=messages"
// example 2 "users[id=123,naam>bob].messages[count>9]"
// TODO: subsequent dots are ignored

export class QueryPart {
  public constructor(
    public readonly attributePath: AttributePath,
    public readonly full: boolean
  ) {}
  public static combine(path1: QueryPart, path2: QueryPart): QueryPart {
    // if (path1.full) throw new Error('Cannot combine!');
    return new QueryPart(
      AttributePath.combine(path1.attributePath, path2.attributePath),
      path2.full
    );
  }
  public contains(path: AttributePath) {
    return (
      (path.contains(this.attributePath) &&
        this.full &&
        path.attributes.length == this.attributePath.attributes.length + 1) ||
      this.attributePath.contains(path)
    );
  }
}

export class Query {
  public readonly queryParts: QueryPart[];
  public readonly model: Model;
  public readonly filters: Filter[];
  private at: number = 0;
  // fullFilter: used at the end for the actual convertion to a Filter typed variable
  private fullFilter;
  private cur() {
    return this.url.charAt(this.at);
  }
  private static wordChar = /\w/i;
  private getWord(): string {
    // word is a model or an attribute
    let word = '';
    while (this.at < this.url.length && this.cur().match(Query.wordChar))
      word += this.url.charAt(this.at++);
    return word;
  }
  private static parseFilter(str: string): Filter {
    for (const operator of Object.keys(FilterOperations)) {
      if (str.includes(operator)) {
        const ret: string[] = str.split(operator);
        return {
          attribute: ret[0],
          condition: ret[1],
          operator: operator as keyof typeof FilterOperations,
        };
      }
    }
    throw new Error(`Not a valid filter ${str}, because there is no operator!`);
  }
  private getFilter(): Filter {
    this.at++;
    let filter: Filter = Query.parseFilter(
      this.url.substring(this.at, this.url.indexOf(']', this.at))
    );
    this.at = this.url.indexOf(']', this.at) + 1;
    return filter;
  }
  private recur(model: Model): QueryPart[] {
    const curIsAsterisk = () => this.cur() == asterisk;
    const curIsPart = () => this.cur().match(Query.wordChar) || curIsAsterisk();
    const getPart = (m: Model) => {
      if (curIsAsterisk()) {
        this.at++;
        return new QueryPart(new AttributePath([]), true);
      } else return new QueryPart(new AttributePath([getAttribute(m)]), false);
    };
    if (!curIsPart())
      throw new Error(
        this.cur() +
          ' at ' +
          this.at +
          ' should be the beginning of an attribute!'
      );
    let getAttribute = (m: Model) => {
      const a = this.getWord();
      // console.log('Trying to find attribute ' + a + ' in model ' + m.name);
      return m.findAttribute(a);
    };
    let all: QueryPart[] = [];
    let current: QueryPart = getPart(model);
    const finishCurrent = () => {
      if (current.attributePath.attributes.length > 0 || current.full) {
        if (current.full) {
          // we fully expand the last attribute if we encounter a *: make many QueryParts
          // if there is a previous, continue from there, get the attributes
          // if * on the root, then take `model`
          for (let p of (current.attributePath.attributes.length > 0
            ? apiBase().modelByName(
                current.attributePath.attributes[
                  current.attributePath.attributes.length - 1
                ].type
              )!
            : model
          ).primitives())
            if (!p.rwAccess.read.isNever())
              all.push(
                QueryPart.combine(
                  current,
                  new QueryPart(new AttributePath([p]), false)
                )
              );
        } else {
          const lastAttribute =
            current.attributePath.attributes[
              current.attributePath.attributes.length - 1
            ];
          all.push(
            lastAttribute.isPrimitive()
              ? current
              : QueryPart.combine(
                  current,
                  new QueryPart(
                    new AttributePath([
                      apiBase()
                        .modelByName(lastAttribute.type)!
                        .findAttribute('id'), // <- TODO: is id here absolutely neccessary?
                    ]),
                    false
                  )
                )
          );
        }
      }
      current = new QueryPart(new AttributePath([]), false);
    };
    while (this.at < this.url.length && this.cur() != ')') {
      if (this.cur() == '.' || this.cur() == '(')
        if (current.full)
          throw new Error('Not allowed to continue after ' + asterisk);
      if (this.cur() == ',') {
        finishCurrent();
        this.at++;
      } else if (this.cur() == '.') {
        this.at++;
      } else if (this.cur() == '(') {
        this.at++;
        let rec = this.recur(
          apiBase().findModel(
            current.attributePath.attributes[
              current.attributePath.attributes.length - 1
            ].type
          )
        );
        for (let p of rec) all.push(QueryPart.combine(current, p));
        current = new QueryPart(new AttributePath([]), false);
        this.at++;
      } else if (curIsPart()) {
        // console.log(current.attributePath.attributes);
        current = QueryPart.combine(
          current,
          getPart(
            current.attributePath.attributes.length == 0
              ? model
              : apiBase().findModel(
                  current.attributePath.attributes[
                    current.attributePath.attributes.length - 1
                  ].type
                )
          )
        );
      } else throw new Error('Unkown character: ' + this.cur());
    }
    finishCurrent();
    return all;
  }
  private url: string;
  // Helper function to check if a string variable is numeric
  private isNumeric(value: string | undefined) {
    if (value === undefined) return;
    return /^\d+$/.test(value);
  }
  private reduceURL() {
    if (this.url.startsWith('/')) this.url = this.url.substring('/'.length);
    if (this.url.startsWith('api/'))
      this.url = this.url.substring('api/'.length);
    if (!this.url.includes('?')) this.url += '?and=';
    let model: string | undefined = undefined;
    let id: string | undefined = undefined;
    // filter: in the form of [projectId=1,authorId=6] for example
    let filter: string | undefined = undefined;
    if (this.url.includes('/')) {
      [model, this.url] = Query.grab(this.url, '/');
      // after model, there are two parts: id and filter
      [id, this.url] = Query.grab(this.url, '/');
      [filter, this.url] = Query.grab(this.url, '?');
      // strip '[' and ']' from filter
      filter = filter.replace(/\[|\]/g, '');
    } else [model, this.url] = Query.grab(this.url, '?');
    if (!this.url.startsWith('and=')) throw new Error('Invalid query format!');
    this.url = this.url.substring('and='.length);
    if (this.url != '') this.url = '.(' + this.url + ')';

    // if no id and filter specified, continue normally (just prepend the model to the url)
    if (id === undefined && filter === undefined) {
      this.url = model + this.url;
    } else {
      // id or filter specified, initiate string manipulation (called insertion)
      // first open a bracket
      let insertion = '[';

      // workaround: if the url only contains a id, the code misplaced the id as a filter
      // so the workaround is as follows:
      // if filter is numeric (like 55 (an id) for example)
      // just apply the filter to id and make filter undefined again
      if (this.isNumeric(filter)) {
        id = filter;
        filter = undefined;
      }

      // if id exists and is not an empty string, add id= + id to insertion
      if (id !== undefined && id !== '') insertion += 'id=' + id;
      // if id exists and is not an empty string and filter exists, add a comma to insertion
      if (id !== undefined && id !== '' && filter !== undefined)
        insertion += ',';
      // if filter exists, add the filter to insertion
      if (filter !== undefined) insertion += filter;
      // finish string manipulation by closing the bracket
      insertion += ']';
      // add string manipulated string back to url + model
      this.url = model + insertion + this.url;

      // if id did exist, aka we had to filter:
      // add the entirity of the string manipulation (so the full filter) to fullFilter
      if (id !== undefined) {
        this.fullFilter = insertion;
      }
    }
  }
  public constructor(public readonly URL: string) {
    this.url = URL;
    this.fullFilter = '';
    if (this.url.includes('/') || this.url.includes('?')) this.reduceURL();

    if (!this.cur().match(Query.wordChar))
      throw new Error('Query path should begin with model!');

    let modelName = this.getWord();
    const model = apiBase().modelByName(modelName);
    if (!model)
      throw new Error(`Couldn't find model ${modelName} in query path`);
    this.model = model;

    this.filters = [];

    // the full manipulated string (the fullFilter)
    // gets converted to a string, split by a comma (,)
    const filterArray = this.fullFilter
      ? this.fullFilter.slice(1, -1).split(',')
      : [];

    // the filter array gets analysed
    const newFilter = filterArray.map((item) => {
      // if the item we are currently looping through contains '=', '<' or '>' continue,
      // else return;
      if (item.includes('=') || item.includes('<') || item.includes('>')) {
        // operator, is either: =, < or >
        const operator = item.match(/[=<>]/g)![0];
        // split attribute and value by the operator
        const [attribute, value] = item.split(operator);
        // return an object in the form of:
        // {attribute: 'an attribute'},
        // conditon: 'an condition' OR a number
        // operator: '<' OR '=' OR '>'
        return {
          attribute,
          // @ts-ignore
          condition: isNaN(value) ? value : parseInt(value),
          operator,
        };
      } else return;
    });

    // if a filter exists, we apply the filters to the actual filters (used in genericService)
    this.filters =
      newFilter[0] !== undefined ? (newFilter as Filter[]) : ([] as Filter[]);

    Object.freeze(this.filters);

    // set the this.at (current place/index in the url) to 1 after the bracket
    if (this.url.includes(']')) {
      this.at = this.url.indexOf(']', this.at) + 1;
    }

    if (this.at == this.url.length) this.queryParts = [];
    else {
      if (this.cur() != '.')
        throw new Error(`Expected . at ${this.at} in ${this.url}`);
      this.at++;
      if (this.cur() != '(')
        throw new Error(`Expected ( at ${this.at} in ${this.url}`);
      this.at++;

      this.queryParts = this.recur(this.model);

      if (this.cur() != ')')
        throw new Error(`Expected ) at ${this.at} in ${this.url}`);
    }

    this.queryParts.push(
      new QueryPart(new AttributePath([this.model.findAttribute(ID)]), false)
    );

    Object.freeze(this.queryParts);
  }
  private static grab(str: string, char: string): [string, string] {
    const sep = str.indexOf(char);
    return [str.substring(0, sep), str.substring(sep + char.length)];
  }
  public contains(path: AttributePath) {
    return this.queryParts.some((qp) => qp.contains(path));
  }
}

export class UnknownAttributeError extends Error {
  constructor(extra: AttributePath, i: number) {
    super(
      `Unkown attribute ${extra.attributes[i]} in ${extra.attributes.join('.')}`
    );
    Object.setPrototypeOf(this, UnknownAttributeError.prototype);
  }
}
export class SimpleFieldAsReferenceError extends Error {
  constructor() {
    super();
    Object.setPrototypeOf(this, SimpleFieldAsReferenceError.prototype);
  }
}

/*
export async function transversePath(path: Path, model: Model, callback: (_: Attribute2) => Promise<boolean>) {
    let at = model;
    for (let i=0;i<path.length;i++) {
        const newAt = at.attributes.find(a => a.name == path[i]);
        if (!newAt)
            throw new UnknownAttributeError(path, i); 
        else {
            await callback(newAt);
            if (i != path.length - 1) {
                const link = apiBase().links.get(newAt);
                if (link)
                    at = apiBase().modelByName(newAt.type)!;
                else
                    throw new SimpleFieldAsReferenceError();
            }
        }
    }
}
*/
