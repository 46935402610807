import React from 'react';

type PlainTextToParaProps = {
  value: string;
  className?: string;
};
export function TextToPara({ value, className }: PlainTextToParaProps) {
  const lines = value.split('\n');
  return (
    <div className={className}>
      {lines.map((l, i) => (
        <React.Fragment key={i}>
          {l}
          {i < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </div>
  );
}
