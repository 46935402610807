import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import intlSlice from './intlSlice';

const persistConfig = {
  key: 'intl',
  storage,
};

export const persistedIntlReducer = persistReducer(persistConfig, intlSlice);
