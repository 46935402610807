import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import React from 'react';

export type AbsoluteRenderer = {
  key: string;
  props: any;
  renderer: React.FC<any>;
};

export interface IAbsoluteBlockState {
  renderers: AbsoluteRenderer[];
  open: boolean;
}
export type AbsoluteBlockStateProps = {
  renderers: AbsoluteRenderer[];
  open: boolean;
};

export function mapAbsoluteBlockStateToProps(
  state: RootState
): AbsoluteBlockStateProps {
  return {
    renderers: state.absoluteBlock.renderers,
    open: state.absoluteBlock.open,
  };
}

const initialState: IAbsoluteBlockState = {
  renderers: [],
  open: false,
};

export const absoluteBlockSlice = createSlice({
  name: 'absoluteBlock',
  initialState,
  reducers: {
    registerRenderer: (state, action: PayloadAction<AbsoluteRenderer>) => {
      const i = state.renderers.findIndex((r) => r.key === action.payload.key);
      if (i >= 0) state.renderers.splice(i, 1);
      state.renderers.push(action.payload);
    },
    unregisterRenderer: (state, action: PayloadAction<string>) => {
      const i = state.renderers.findIndex((r) => r.key === action.payload);
      if (i >= 0) state.renderers.splice(i, 1);
    },
    setRenderProps: (
      state,
      action: PayloadAction<{ key: string; props: any }>
    ) => {
      const i = state.renderers.findIndex((r) => r.key === action.payload.key);
      if (i >= 0) state.renderers[i].props = action.payload.props;
    },
    openAbsoluteBlock: (state) => {
      state.open = true;
    },
    closeAbsoluteBlock: (state) => {
      state.open = false;
    },
  },
});

export const absoluteBlockActions = absoluteBlockSlice.actions;
export const {
  registerRenderer,
  unregisterRenderer,
  setRenderProps,
  openAbsoluteBlock,
  closeAbsoluteBlock,
} = absoluteBlockSlice.actions;

export default absoluteBlockSlice.reducer;
