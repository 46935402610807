export async function fetchImageAsBase64(
  url: string
): Promise<{ base64: string; mimeType: string }> {
  const response = await fetch(url);
  const blob = await response.blob();
  const mimeType = blob.type;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result?.toString().split(',')[1];
      if (base64data) {
        resolve({ base64: base64data, mimeType });
      } else {
        reject('Error converting image to base64');
      }
    };
    reader.onerror = () => {
      reject('Error converting image to base64');
    };
  });
}

export async function getImageDimensions(
  base64data: string,
  mimeType: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = `data:${mimeType};base64,${base64data}`;
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      reject('Error loading image');
    };
  });
}

interface WidthHeight {
  width: number;
  height: number;
}

export function scaleWidthHeight(
  width: number,
  height: number,
  maxWidth: number = 600,
  maxHeight: number = 400
): WidthHeight {
  if (width <= maxWidth && height <= maxHeight) {
    return { width, height };
  }
  const aspectRatio = width / height;
  let scaledWidth = width;
  let scaledHeight = height;
  if (scaledWidth > maxWidth) {
    scaledWidth = maxWidth;
    scaledHeight = scaledWidth / aspectRatio;
  }
  if (scaledHeight > maxHeight) {
    scaledHeight = maxHeight;
    scaledWidth = scaledHeight * aspectRatio;
  }
  return {
    width: Math.floor(scaledWidth),
    height: Math.floor(scaledHeight),
  };
}

export function createBase64Pixel(color: string): string {
  const canvas = document.createElement('canvas');
  canvas.width = 1;
  canvas.height = 1;
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Failed to get canvas context');
  }
  context.fillStyle = color.startsWith('#') ? color : `#${color}`;
  context.fillRect(0, 0, 1, 1);
  return canvas.toDataURL().split(',')[1];
}
