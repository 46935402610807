import { ArrayToUnion } from '../typeHelpers.js';
import { PayloadWithRooms } from './withRooms.js';

export const DocumentsMessage = {
  event: 'documents',
  payload: PayloadWithRooms({
    inout: [
      { event: 'caret', payload: {} },
      { event: 'new-rt', payload: {} },
    ],
    out: [],
    in: [],
  } as const),
} as const;

export type DocumentsMessage = ArrayToUnion<typeof DocumentsMessage>;
