import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { IDialogueState, StateMan } from 'features/dialogue/dialogueSlice';
import { IList, deleteList, postList } from 'features/list/listAPI';
import { patchListAsync } from 'features/list/listSlice';
import { getOrderByIndex } from 'helpers/sorting';
import { IListBlock, patchListBlock } from './listBlockAPI';

export const emptyListBlock: IListBlock = {
  layout_x: 0,
  layout_y: 0,
  zoomlevel: 1,
  canvas: false,
  editable: true,
  likes: false,
  snap: false,
  grid: false,
  checkboxes: false,
  colors: false,
  attribution: false,
  listType: 'List',
  lists: [],
};

export async function makeList(name: string, order: number, clb: IListBlock) {
  const list: IList = {
    parent: clb,
    name: name,
    // order: String.fromCharCode('1'.charCodeAt(0) + order),
    order: getOrderByIndex(order),
    listItems: [],
    coordinate_x: 0,
    coordinate_y: 0,
  };
  let res = await postList(list);
  if (res.status >= 300) throw new Error('Error saving new list to database');
  list.id = res.data._id;
  res.data.list = list;
  return res;
}

export const patchListBlockAsync = createAsyncThunk(
  'list/patchListBlock',
  async (
    {
      data,
      stateMan,
      id,
    }: {
      stateMan: StateMan;
      data: any;
      id: number;
    },
    thunkAPI
  ) => {
    try {
      return { response: await patchListBlock(id, data), stateMan: stateMan };
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const postPatchAndDeleteLists = createAsyncThunk(
  'dialogue/postPatchAndDeleteLists',
  async (
    {
      lists,
      deletedListIds,
      stateMan,
    }: {
      lists: IList[];
      deletedListIds: number[];
      stateMan: StateMan;
    },
    thunkAPI
  ) => {
    try {
      const newLists = lists.filter((l) => l.id !== undefined && l.id < 0);
      const oldLists = lists.filter(
        (l) => l.id !== undefined && l.id >= 0 && !deletedListIds.includes(l.id)
      );
      let res;
      let promises = deletedListIds.map(async (id) => {
        if (id < 0) return true;
        res = await deleteList(id);
        if (res.status >= 300)
          throw new Error('Error removing phase from database');
        return res;
      });
      await Promise.all(promises);
      promises = newLists.map(async (l, i) => {
        res = await postList(l);
        if (res.status >= 300)
          throw new Error('Error saving new list to database');
        else {
          newLists[i].id = res.data._id;
          return res;
        }
      });
      await Promise.all(promises);
      let morepromises = oldLists.map(async (l) => {
        res = await thunkAPI.dispatch(patchListAsync({ data: l, stateMan }));
        if ((res.payload as any)?.response?.status >= 300) {
          throw new Error('Error saving new list to database');
        } else return res;
      });
      await Promise.all(morepromises);
      return (res as any).payload;
      // return res; // return only the last result, it indicates success for all
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export function addListBlockCases(
  builder: ActionReducerMapBuilder<IDialogueState>
) {
  builder
    .addCase(patchListBlockAsync.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(patchListBlockAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      const obj = action.meta.arg.stateMan(state);
      const childListBlock = obj.childListBlock;
      Object.assign(childListBlock, action.meta.arg.data);
    })
    .addCase(patchListBlockAsync.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    })
    .addCase(postPatchAndDeleteLists.pending, (state, action) => {
      state.status = 'loading';
      state.errors = action.payload;
    })
    .addCase(postPatchAndDeleteLists.fulfilled, (state, action) => {
      state.status = 'idle';
      const stateObj = action.meta.arg.stateMan(state);
      const listBl = stateObj.childListBlock;
      if (listBl) listBl.lists = [...action.meta.arg.lists];
    })
    .addCase(postPatchAndDeleteLists.rejected, (state, action) => {
      state.status = 'failed';
      state.errors = action.payload;
    });
}
