import { API, Primitives, ReadableModelNames } from '@thuas/pd-schemas';
import { AxiosResponse } from 'axios';
import api from './api';

// ------------------------------------------------------
// This is implementation detail
// ------------------------------------------------------
type NonArray<T> = T extends (infer T2)[] ? T2 : T;
type NonNullable<T> = Exclude<T, null | undefined>;

type RetrievingRaw<M> = {
  [key in keyof M | '*']?: key extends keyof M
    ? NonNullable<M[key]> extends Primitives
      ? true
      : NonNullable<M[key]> extends (infer T)[]
      ? RetrievingRaw<T>
      : RetrievingRaw<NonNullable<M[key]>>
    : true;
};

type RetrievalRaw<M, T extends RetrievingRaw<M>> = {
  [key in
    | keyof T
    | (T extends { '*': true }
        ? keyof {
            [key in keyof M as NonNullable<M[key]> extends Primitives
              ? key
              : never]?: any;
          }
        : never) as key extends '*' ? never : key]: key extends keyof M
    ? T[key] extends true
      ? M[key]
      : [T, NonNullable<M[key]>] extends [{ '*': true }, Primitives]
      ? M[key]
      : NonNullable<M[key]> extends (infer M2)[]
      ? T[key] extends RetrievingRaw<M2>
        ? RetrievalRaw<M2, T[key]>[]
        : never
      : T[key] extends RetrievingRaw<NonArray<NonNullable<M[key]>>>
      ? RetrievalRaw<NonArray<NonNullable<M[key]>>, T[key]>
      : never
    : never;
};

export function constructURL(request: any): string {
  return Object.entries(request)
    .map(([a, b]) => a + (b === true ? '' : '.(' + constructURL(b) + ')'))
    .join(',');
}

// ------------------------------------------------------
// This should be public
// ------------------------------------------------------
export type Retrieving<MN extends ReadableModelNames> = RetrievingRaw<
  API[MN]['read']
>;

export type Retrieval<
  MN extends ReadableModelNames,
  T extends RetrievingRaw<API[MN]['read']>
> = RetrievalRaw<API[MN]['read'], T>;

export async function callGet<
  MN extends ReadableModelNames,
  T extends Retrieving<MN>
>(
  model: MN,
  request: T,
  id?: number,
  filter?: string // TODO refine this, similar to the request arg
): Promise<{ response: AxiosResponse<any, any>; data: Retrieval<MN, T> }> {
  const url =
    id != null
      ? `${model}/${id}?and=${constructURL(request)}`
      : !!filter
      ? `${model}/${filter}?and=${constructURL(request)}`
      : `${model}?and=${constructURL(request)}`;
  // console.log('Doing a fetch to ' + url);
  const response = await api.get(url);
  // console.log('response:', response);
  const data = response.data as Retrieval<MN, T>;
  return { response, data };
  // return (await api.get(url)).data as Retrieval<MN, T>;
  // return null! /*fetch(url)*/ as Retrieval<MN, T>;
}

export async function callGetMany<
  MN extends ReadableModelNames,
  T extends Retrieving<MN>
>(
  model: MN,
  request: T
): Promise<{ response: AxiosResponse<any, any>; data: Retrieval<MN, T>[] }> {
  const response = await api.get(`${model}?and=${constructURL(request)}`);
  const data = response.data as Retrieval<MN, T>[];
  return { response, data };
}

// // ------------------------------------------------------
// // This is an example usage
// // ------------------------------------------------------
// // This is the query that is done from the frontend to the backend.
// // Use "satisfies" to get auto-completion while typing this query,
// // and to check whether the query is correct.
// const r = {
//   '*': true,
//   dialogues: {
//     '*': true,
//     status: true,
//     phases: {
//       dateBegin: true,
//       blocks: {
//         id: true,
//       },
//       id: true,
//     },
//   },
//   author: {
//     id: true,
//     '*': true,
//     dialogues: {
//       status: true,
//       author: {
//         id: true,
//         subscribedToDialogues: {
//           id: true,
//           phases: {
//             '*': true,
//           },
//         },
//       },
//     },
//   },
// } as const satisfies Retrieving<'Project'>;

// // The explicit type declaration of the following is unneccesary,
// // and only here to show the type of state.
// async () => {
//   const state: Retrieval<'Project', typeof r> = await callGet('Project', r, 1);
//   // Autocompletion and type-checking works for everything.
//   // We use the ! after indexing an array, because we cannot be sure the array is long enough.
//   console.log(state.status);
//   console.log(state.author.email);
//   console.log(state.dialogues[0]!.title);
//   console.log(
//     state.author.dialogues[0]!.author.subscribedToDialogues[3]!.phases[2]!
//       .active
//   );
//   console.log(state.author);
// };
