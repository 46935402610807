import { API } from '@thuas/pd-schemas';
import api from 'helpers/api';
import { Retrieving, callGet } from 'helpers/apiTypes';
import { omitProps, prepareForBackend } from 'helpers/objects';

export { Role } from '@thuas/pd-schemas';

export type IInitialState = {
  _initial?: boolean;
};
export function NotInitial<T>(data: T & IInitialState): T {
  return omitProps(data, ['_initial']) as T;
}
export type IAppSettings = API['ApplicationSettings']['read'] & IInitialState;
export type IAppSettingsCreate = API['ApplicationSettings']['create'];
export type IAppSettingsUpdate = API['ApplicationSettings']['update'];
function prepareForUpdate(settings: IAppSettings): IAppSettingsUpdate {
  const updated = prepareForBackend(settings) as IAppSettingsUpdate;
  return updated;
}
function prepareForCreate(settings: IAppSettingsCreate): IAppSettingsCreate {
  return { ...settings };
}

export async function postAppSettingsData(data: any) {
  try {
    const response = await api.post(
      'ApplicationSettings',
      prepareForCreate(data)
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function patchAppSettingsData(data: IAppSettings) {
  try {
    const response = await api.patch(
      'ApplicationSettings/' + data.id,
      prepareForUpdate(data)
    );
    return response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}

export async function fetchAppSettings() {
  try {
    const query = {
      '*': true,
    } as const satisfies Retrieving<'ApplicationSettings'>;
    const response = await callGet('ApplicationSettings', query);
    return response.response;
  } catch (error: any | { response?: any }) {
    throw error?.response || error;
  }
}
