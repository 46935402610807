import classNames from 'classnames';
import { ToolHint, ToolHintProps } from 'components/messages/ToolHint';
import { relativePos } from 'helpers/positions';
import React, {
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import './DropMenu.scss';

type DropMenuTriggerProps = {
  className?: string;
  isTrigger?: boolean;
  hintProps?: ToolHintProps;
};
function DropMenuTrigger(props: DropMenuTriggerProps & PropsWithChildren) {
  const { className, children, hintProps } = props;
  const triggerRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div
        className={classNames(
          'dropmenu_trigger',
          {
            ellipsis: !children,
          },
          className
        )}
        ref={triggerRef}
      >
        {children}
      </div>
      {hintProps ? (
        <ToolHint
          offset={{ x: 40, y: 20 }}
          offsetRight={true}
          {...hintProps}
          toolRef={triggerRef}
        />
      ) : null}
    </>
  );
}

type DropMenuItemsProps = {
  isItems?: boolean;
  className?: string;
  disabled?: boolean;
};
function DropMenuItems({
  // isItems = true, // used externally
  className,
  children,
  disabled = false,
}: DropMenuItemsProps & PropsWithChildren) {
  return (
    <div className={classNames('dropmenu_items', className, { disabled })}>
      {children}
    </div>
  );
}
DropMenuItems.defaultProps = {
  isItems: true,
};

type DropMenuNoteProps = {
  isNote?: boolean;
  className?: string;
};
function DropMenuNote({
  className,
  children,
}: DropMenuNoteProps & PropsWithChildren) {
  return (
    <div className={classNames('dropmenu_note label', className)}>
      {children}
    </div>
  );
}
DropMenuNote.defaultProps = {
  isNote: true,
};
function DropMenuDivider({ className }: DropMenuNoteProps) {
  return <div className={classNames('dropmenu_divider', className)} />;
}
DropMenuDivider.defaultProps = {
  isNote: true,
};

type DropMenuProps = {
  className?: string;
  noPosition?: boolean;
  right?: boolean;
  shift?: number; // translate horizontally in px
  lift?: number; // translate vertically in px
  up?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>; // the ancestor relative div in which the menu is rendered
  containerTailHeight?: number; // extra space in px to observe in the tail of the container
  // called before showing the menu, return false to prevent
  onOpen?: (e: SyntheticEvent) => boolean;
  // boolean or function that determines if the menu should be closed on click
  shouldClose?: boolean | ((e: SyntheticEvent) => boolean); // set this to true to close the menu
  hideWithOpacity?: boolean;
  debug?: boolean;
  children: ReactNode;
};

export default function DropMenu(props: DropMenuProps): JSX.Element {
  const {
    className,
    noPosition = false,
    right = false,
    shift,
    lift,
    up = false,
    containerRef,
    containerTailHeight = 0,
    onOpen,
    shouldClose = true,
    debug = false,
    children,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const dropMenuRef = useRef<HTMLDivElement | null>(null);

  function onClickOutside() {
    if (isActive) {
      const items = dropMenuRef.current!.querySelector(
        '.dropmenu_items'
      ) as HTMLElement;
      transform(items);
      setIsActive(false);
    }
  }
  useOnClickOutside(dropMenuRef, onClickOutside);

  type XY = {
    x?: number;
    y?: number;
  };
  function transform(el: HTMLElement, xy?: XY) {
    el.style.transform =
      (xy?.x ? `translateX(${xy.x}px) ` : '') +
      (xy?.y ? `translateY(${xy.y}px)` : '');
    // el.style.bottom = xy?.y ? `-15px` : '';
    // el.style.top = xy?.y ? 'unset' : '';
  }

  function onClick(e: React.MouseEvent<HTMLDivElement>) {
    // if (!parentClassListContains(e.target as HTMLElement, 'dropmenu_trigger'))
    //   return;
    e.stopPropagation();
    if (containerRef && containerRef.current) {
      const relpos = relativePos(dropMenuRef.current, containerRef.current);
      const menurect = dropMenuRef.current!.getBoundingClientRect();
      const items = dropMenuRef.current!.querySelector(
        '.dropmenu_items'
      ) as HTMLElement;
      if (!isActive && relpos?.invy) {
        items.style.height = 'auto'; // set height so we can calculate
        const itemsrect = items!.getBoundingClientRect();
        const mh = itemsrect.height;
        const my = itemsrect.top - menurect.top + 15;
        items.style.height = ''; // remove height after calculation
        const space = relpos.invy - mh - my - containerTailHeight;
        if (space < 0) {
          transform(items, { x: shift, y: space + (lift ?? 0) });
        } else transform(items, { x: shift, y: lift });
      } else transform(items, { x: shift, y: lift });
    } else {
      const items = dropMenuRef.current!.querySelector(
        '.dropmenu_items'
      ) as HTMLElement;
      transform(items, { x: shift, y: lift });
    }
    if (isActive) {
      if (typeof shouldClose === 'function' ? shouldClose(e) : shouldClose) {
        setIsActive(false);
      }
    } else if (!onOpen || onOpen(e)) setIsActive(true);
  }

  function dropmenuClassNames() {
    return classNames('dropmenu', className, {
      dropmenu_noposition: noPosition,
      dropmenu_active: isActive,
      dropmenu_right: right,
      dropmenu_up: up,
    });
  }

  function hasItems(): boolean {
    for (const child of React.Children.toArray(children)) {
      if (React.isValidElement(child)) {
        if (child.props.isItems) {
          return React.Children.toArray(child.props.children).some((c) => !!c);
        }
      }
    }
    return false;
  }

  return hasItems() ? (
    <div className={dropmenuClassNames()} ref={dropMenuRef} onClick={onClick}>
      {children}
    </div>
  ) : (
    <></>
  );
}

DropMenu.Trigger = DropMenuTrigger;
DropMenu.Items = DropMenuItems;
DropMenu.Note = DropMenuNote;
DropMenu.Divider = DropMenuDivider;
