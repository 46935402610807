import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { getMessagesForLocale } from 'features/intl/intlSlice';
import { flattenMessages } from 'helpers/intl/messages';
import { RootState } from './store';

interface IMessages {
  [key: string]: string;
}

interface IProps {
  children: React.ReactNode;
  locale: 'nl' | 'en';
  messages: IMessages;
}

const mapStateToProps = (state: RootState) => ({
  locale: state.intl.locale,
  messages: getMessagesForLocale(state.intl.locale || 'nl'),
});

const ConnectedIntlProvider = ({ children, locale, messages }: IProps) => {
  return (
    <IntlProvider
      locale={locale || 'nl'}
      messages={flattenMessages(messages, '')}
    >
      {children}
    </IntlProvider>
  );
};

export default connect(mapStateToProps)(ConnectedIntlProvider);
