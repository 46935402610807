import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { fetchDialogueIds, fetchDialogues } from './dialoguesAPI';

interface IDialoguesState {
  value: any[];
}
interface IDialoguesState {
  dialogues: any;
  status: 'idle' | 'loading' | 'failed';
  errors: any;
}

const initialState: IDialoguesState = {
  value: [],
  dialogues: [],
  status: 'idle',
  errors: '',
};

export const fetchDialoguesAsync = createAsyncThunk(
  'dialogues/fetchDialogues',
  async (x, thunkAPI) => {
    try {
      return fetchDialogues();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const fetchDialogueIdsAsync = createAsyncThunk(
  'dialogues/fetchDialogueIds',
  async (id: number, thunkAPI) => {
    try {
      return fetchDialogueIds(id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ response: error });
    }
  }
);

export const dialoguesSlice = createSlice({
  name: 'dialogues',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchDialoguesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDialoguesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dialogues = action.payload;
      })
      .addCase(fetchDialoguesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      })
      .addCase(fetchDialogueIdsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDialogueIdsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(fetchDialogueIdsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errors = action.payload;
      });
  },
});

export const getDialogues = (state: RootState) => state.dialogues;

export default dialoguesSlice.reducer;
