import { store } from 'app/store';
import classNames from 'classnames';
import { FormMessage, FormMsg, NoFormMsg } from 'components/forms/FormMessage';
import ScrollObserver from 'components/scrollobserver/ScrollObserver';
import { MODAL_CONTAINER_ID } from 'helpers/consts';
import { flattenMessages, messages } from 'helpers/intl/messages';
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { FormattedMessage, IntlProvider, IntlShape } from 'react-intl';

interface IModalDialogProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>> | ((sh: boolean) => void);
  className?: string;
  title: React.ReactNode | string;
  submitBtnText?: React.ReactNode | string;
  cancelBtnText?: React.ReactNode | string;
  editBtnText?: React.ReactNode | string;
  allowEdit?: boolean;
  editing?: boolean;
  onSubmit?: ((e?: any) => Promise<any>) | null;
  onCancel?: (() => boolean) | null; // | ((e?: any) => Promise<any>);
  onEdit?: (() => void) | ((e?: any) => Promise<any>);
  onClose?: () => void; // | (() => Promise<any>);
  noFooter?: boolean;
  footer?: React.ReactNode;
  maxHeight?: boolean;
  maxWidth?: boolean;
  refSubmitBtn?: React.RefObject<HTMLElement>;
  delayClose?: number;
  enableEscape?: boolean;
  cancelDefault?: boolean;
  omitCancel?: boolean;
  disableSubmit?: boolean;
  omitSubmit?: boolean;
  renderForm?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  refFocus?: React.RefObject<HTMLElement>;
  formMsg?: FormMsg;
  containerId?: string;
  extraValidated?: boolean;
  bare?: boolean;
  setValidForm?: Dispatch<SetStateAction<boolean>>;
  refScrollable?: React.RefObject<HTMLElement>;
}

function assertContainer(id: string, parentId?: string) {
  const p = parentId || 'App';
  let c = document.getElementById(id);
  const { demoMode } = store.getState().admin;
  if (c) {
    if (demoMode) c.classList.add('demoMode');
    else c.classList.remove('demoMode');
    return c;
  }
  c = document.createElement('div');
  if (demoMode) c.classList.add('demoMode');
  const parentElement = document.getElementById(p) as HTMLElement;
  if (!parentElement) return null;
  parentElement.appendChild(c);
  c.id = id;
  return c;
}

const ModalDialog: React.FC<IModalDialogProps> = ({
  show = false,
  setShow,
  className,
  title = <FormattedMessage id="GENERAL.FORM.ALERT" />,
  submitBtnText = null,
  cancelBtnText = <FormattedMessage id="X.CANCEL" />,
  editBtnText = <FormattedMessage id="X.EDIT" />,
  allowEdit = false, // if true, the form will be not editable at first (not_editable class) and show an edit button to enable editing (editing class)
  editing = true, // if true, the form will be editable and a cancel button is shown
  onSubmit, // callback for onSubmit, null will hide the submit button
  onCancel, // callback for onCancel, undefined or null will hide the cancel button
  onEdit, // callback for onEdit, leave this out or null to hide the edit button
  onClose, // callback that is called in all cases when the dialog is closed (cancel or submit)
  noFooter = false, // if true, the footer with submit and cancel is hidden
  footer, // react node to add before the buttons (or in place of if noFooter is true)
  maxHeight, // optional number or string to define modal height
  maxWidth, // optional number or string to define modal height
  refSubmitBtn, // ref to a custom submit button in the form
  delayClose = 0, // ms to wait before OK closes dialog
  enableEscape = false,
  cancelDefault = false, // if true, the cancel button is the default
  omitCancel = false, // if true, the cancel button is not present
  disableSubmit = false, // if true, the submit button is disabled
  omitSubmit = false, // if true, the submit button is not present
  renderForm = null, // callback that renders the form content
  refFocus, // ref for the element to receive focus on open
  children,
  formMsg = NoFormMsg,
  containerId = null,
  extraValidated = undefined,
  bare = false, // if true, only the content is shown, no other 'chrome'
  setValidForm,
  refScrollable = undefined,
}) => {
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [validated, setValidated] = useState(false);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setValidated(false);
      if (setValidForm) setValidForm(true);
      setSubmitDisabled(disableSubmit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, disableSubmit]);

  // handle escape key
  useEffect(() => {
    // check if escape is enabled and if we're not editing
    // we don't respond to escape while editing -
    // to protect the user from accidentally cancelling
    if (!enableEscape || editing) return;
    function handleKeyDown(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        e.stopPropagation(); // this is to prevent cancellation of menu clicks in the parent
        if (onClose) onClose();
      }
    }
    modalRef.current?.addEventListener('keydown', handleKeyDown);
    const modalRefListener = modalRef.current;
    return () => {
      modalRefListener?.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose, enableEscape, editing]);

  const doFocus = () => {
    refFocus?.current && refFocus.current.focus();
  };

  const handleSubmit = useCallback(
    (e: MouseEvent | FormEvent) => {
      function mayClose() {
        if (!editing || !allowEdit) {
          setValidated(false);
          setShow(false);
          onClose && onClose();
        }
      }
      e.preventDefault();
      const form: HTMLFormElement | null =
        (e.currentTarget as HTMLButtonElement).form ||
        (e.currentTarget as HTMLFormElement);
      if (form && form.checkValidity() === false) {
        setValidated(true);
        if (setValidForm) setValidForm(false);
        e.stopPropagation();
        return false;
      } else {
        onSubmit
          ? onSubmit()
              .then(() => {
                if (delayClose) {
                  setTimeout(mayClose, delayClose);
                } else {
                  mayClose();
                }
                setSubmitDisabled(false);
              })
              .catch((error) => {
                console.log('Form operation failed', error ? `(${error})` : '');
              })
          : setShow(false);
      }
    },
    [allowEdit, editing, onClose, onSubmit, delayClose, setShow]
  );

  const handleCancel = async () => {
    const canCancel = onCancel ? onCancel() : true;
    if (canCancel) {
      setValidated(false);
      if (setValidForm) setValidForm(true);
      if (!editing || !allowEdit) {
        setShow(false);
        onClose && onClose();
      }
    }
  };

  // add onclick listener for a possible custom submit btn
  useEffect(() => {
    const btn = refSubmitBtn?.current;
    if (btn) {
      btn.addEventListener('click', handleSubmit);
      return () => {
        btn.removeEventListener('click', handleSubmit);
      };
    }
  }, [refSubmitBtn, handleSubmit]);

  const formclass = classNames('pd_form', {
    editing: editing,
    not_editing: !editing,
  });

  // make sure the container div exists in the DOM
  assertContainer(MODAL_CONTAINER_ID);

  const headerClassName = classNames({
    with_title: !!title,
  });

  function isValidated() {
    if (extraValidated !== undefined) return validated && extraValidated;
    return validated;
  }

  return (
    <Modal
      show={show}
      className={'pd_modal'} // overall div
      dialogClassName={classNames(className, {
        max_height: !!maxHeight,
        max_width: !!maxWidth,
        bare: bare,
      })} // dialog div
      backdropClassName="pd_modal_backdrop" // background div
      backdrop="static"
      keyboard={enableEscape}
      onHide={handleCancel}
      onEntered={doFocus}
      // size="lg"
      aria-labelledby="modalform-title"
      centered
      // we place the modal at the end of the given element
      container={
        containerId
          ? document.getElementById(containerId)
          : document.getElementById(MODAL_CONTAINER_ID) ||
            document.getElementById('root')
      }
    >
      <Modal.Header closeButton className={headerClassName}>
        <Modal.Title id="modal-title">{title}</Modal.Title>
      </Modal.Header>
      <Form
        noValidate
        validated={isValidated()}
        className={formclass}
        onClick={() => {
          bare && handleCancel();
        }}
      >
        <ScrollObserver
          scrollableRef={refScrollable ?? scrollableRef}
          customScrollbar={false}
          toBottom={false}
          mutations={false}
        >
          <Modal.Body ref={scrollableRef}>
            <div ref={modalRef} className={'modal_inner_wrapper'}>
              <>
                {children}
                {renderForm && renderForm()}
              </>
            </div>
          </Modal.Body>
        </ScrollObserver>
        {!bare && (!noFooter || footer) && (
          <Modal.Footer>
            <FormMessage {...formMsg} />
            {footer}
            {!noFooter && !omitCancel && (
              <Button
                variant={cancelDefault ? 'primary' : 'outline-secondary'}
                onClick={handleCancel}
                className={classNames('cancel', {
                  hidden: !onCancel || (allowEdit && !editing),
                })}
              >
                {cancelBtnText}
              </Button>
            )}
            {!noFooter && !omitSubmit && (
              <Button
                variant={!cancelDefault ? 'primary' : 'secondary'}
                onClick={handleSubmit}
                disabled={submitDisabled}
                className={classNames('submit', {
                  hidden: !(onSubmit || onSubmit === undefined),
                })}
                ref={submitBtnRef}
              >
                {!submitBtnText ? (
                  editing ? (
                    <FormattedMessage id="X.SAVE" />
                  ) : (
                    <FormattedMessage id="X.OK" />
                  )
                ) : (
                  submitBtnText
                )}
              </Button>
            )}
            {!noFooter && (onSubmit || onSubmit === undefined) && allowEdit && (
              <Button
                variant={'secondary'}
                onClick={onEdit}
                className={classNames('edit', {
                  hidden: !(allowEdit && !editing),
                })}
              >
                {editBtnText}
              </Button>
            )}
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
};

function wrapIntlProvider(
  content: string | JSX.Element | null,
  intl: IntlShape
) {
  const locale = intl?.locale;
  const localeFallback = 'en';
  return intl ? (
    <IntlProvider
      locale={locale || localeFallback}
      messages={flattenMessages(messages[locale || localeFallback])}
    >
      {content}
    </IntlProvider>
  ) : (
    content
  );
}

interface IShowModalOptions {
  intl: IntlShape;
  className?: string;
  submitBtnText?: string | JSX.Element;
  cancelBtnText?: string | JSX.Element;
  onSubmit?: () => boolean;
  omitSubmit?: boolean;
  onCancel?: () => boolean;
  omitCancel?: boolean;
  cancelDefault?: boolean;
  enableEscape?: boolean;
  editing?: boolean;
  noFooter?: boolean;
  footer?: React.ReactNode;
  maxHeight?: boolean;
  maxWidth?: boolean;
  bare?: boolean;
}

function showModal(
  title: React.ReactNode,
  content: React.ReactNode,
  options: IShowModalOptions
) {
  assertContainer(MODAL_CONTAINER_ID);
  const wrapperElement = assertContainer(
    MODAL_CONTAINER_ID + '_wrapper',
    MODAL_CONTAINER_ID
  );
  if (!wrapperElement)
    throw new Error('Modal wrapper element could not be created.');
  const placeholderElement = wrapperElement.appendChild(
    document.createElement('div')
  );
  const container = createRoot(placeholderElement!);

  function unmountContainer() {
    container.unmount();
    placeholderElement.remove();
  }
  async function handleSubmit(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!options.onSubmit || options.onSubmit()) {
        unmountContainer();
        resolve();
      } else reject();
    });
  }
  function handleCancel(): boolean {
    const canCancel = options.onCancel ? options.onCancel() : true;
    if (canCancel) {
      unmountContainer();
      return true;
    }
    return false;
  }

  const dialog = wrapIntlProvider(
    <ModalDialog
      show={true}
      setShow={() => {}}
      className={options.className}
      // the options below can be overridden through the options argument
      // show
      // submitBtnText
      // cancelBtnText
      // onSubmit
      // omitSubmit
      // onCancel
      // omitCancel
      // cancelDefault
      // editing
      // noFooter
      // footer
      // maxHeight
      // maxWidth
      // bare
      enableEscape={true}
      editing={false}
      {...options}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      // these options cannot (and don't need to) be overridden
      title={title}
      onClose={undefined}
      containerId={MODAL_CONTAINER_ID + '_wrapper'}
    >
      {content}
    </ModalDialog>,
    options.intl as IntlShape
  );

  container.render(dialog);
}

export { ModalDialog, showModal };
